import _ from 'lodash';
import { createSelector, Selector } from 'reselect';
import { AppState } from '../../../rootReducer';
import { Role } from '../../../service/service.types';
import {
  BasicInfoState,
  MasqueradeUserInfo,
  MasqueradeUserState,
} from '../basicUserInfo.reducer';

const getUserInformationState = (state: AppState) => state.data.user.basicInfo;
const getMasqueradeUserInformationState = (state: AppState) =>
  state.data?.masqueradeUser?.basicInfo;

export const getUserInformation = createSelector<
  AppState,
  BasicInfoState,
  MasqueradeUserState,
  MasqueradeUserInfo | undefined
>(
  [getUserInformationState, getMasqueradeUserInformationState],
  (user, masqueradeUser) => {
    if (masqueradeUser && masqueradeUser !== 'NOT_INITIALIZED') {
      return masqueradeUser;
    } else if (user !== 'NOT_INITIALIZED') {
      return user;
    } else {
      return undefined;
    }
  },
);

export const userHasPrivileges = (
  privileges: Role[],
): Selector<AppState, boolean> =>
  createSelector<AppState, MasqueradeUserInfo | undefined, boolean>(
    [getUserInformation],
    (userInfo) => {
      return _.intersection(userInfo?.roles, privileges).length > 0;
    },
  );
