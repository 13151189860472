import { AgentProduct, Hierarchy } from '../../../../service/service.types';
import React, { useState } from 'react';
import {
  NglicModal,
  PrimaryButton,
  SecondaryButton,
} from '@nglic/component-lib/build';
import { HierarchyService } from '../../../../service/HierarchyService';
import { useSelector } from 'react-redux';
import { getBranchCode } from '../../../shared/selectors/basicUserInfo.selector';
import { AppState } from '../../../../rootReducer';
import styles from './ReviewChangesModal.module.scss';
import { ChangeSection } from './ChangeSection';
import { HierarchyUtil } from '../../../../service/transformers/Hierarchy';
import { CheckCircle, Error } from '@material-ui/icons';

interface HierarchyChange {
  agentName: string;
  oldCommissionLevel: string;
  newCommissionLevel: string;
}

export const ReviewChangesModal: React.FC<{
  open: boolean;
  originalHierarchy?: Hierarchy;
  updatedAgentProducts?: AgentProduct[];
  handleClose: () => void;
  handleCloseOnSave: () => void;
  tokens: { accessToken: string; idToken: string };
}> = ({
  open,
  originalHierarchy,
  updatedAgentProducts,
  handleClose,
  tokens,
  handleCloseOnSave,
}) => {
  const [saving, setSaving] = useState<boolean>(false);
  const state = useSelector((appState: AppState) => appState);
  const branchCode = getBranchCode(state);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [showForbiddenError, setShowForbiddenError] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  if (!updatedAgentProducts || !open || !originalHierarchy) {
    return null;
  }

  const updatedHierarchy = HierarchyUtil.transformHierarchy(
    originalHierarchy,
    updatedAgentProducts,
  );

  const handleSubmitSuccess = () => {
    setSaving(false);
    setShowSuccessMessage(true);
    setTimeout(() => {
      handleCloseOnSave();
      setShowSuccessMessage(false);
    }, 2000);
  };

  const handleSubmitError = (errorMessage: string) => {
    if (errorMessage?.includes('Forbidden')) {
      setShowForbiddenError(true);
    } else {
      setShowErrorMessage(true);
    }
    setSaving(false);
  };

  const handleSave = () => {
    setSaving(true);
    setShowErrorMessage(false);
    setShowForbiddenError(false);
    HierarchyService.editHierarchy(
      updatedHierarchy,
      originalHierarchy,
      tokens,
      branchCode,
    )
      .then(() => {
        handleSubmitSuccess();
      })
      .catch((e) => {
        handleSubmitError(e?.message);
      });
  };

  const onCancel = () => {
    setShowErrorMessage(false);
    setShowForbiddenError(false);
    handleClose();
  };

  const changes = updatedHierarchy.agents.reduce<{
    nonOverrides: HierarchyChange[];
    overrides: HierarchyChange[];
    headerLevelOvr: HierarchyChange[];
  }>(
    (acc, next) => {
      const originalAgentIndex = originalHierarchy.agents.findIndex(
        (agent) =>
          agent.agentName === next.agentName &&
          agent.producerId == next.producerId,
      );

      const originalAgent =
        originalAgentIndex !== -1
          ? originalHierarchy.agents[originalAgentIndex]
          : undefined;

      const change = {
        agentName: next.agentName,
        oldCommissionLevel: originalAgent ? originalAgent.commissionLevel : '',
        newCommissionLevel: next.commissionLevel,
      };
      if (next.overrideFlag && !originalAgent?.overrideFlag) {
        acc.overrides.push(change);
      } else if (
        next.overrideFlag &&
        originalAgent?.overrideFlag &&
        originalAgentIndex === 0 &&
        change.oldCommissionLevel !== change.newCommissionLevel
      ) {
        acc.headerLevelOvr.push(change);
      } else if (change.oldCommissionLevel !== change.newCommissionLevel) {
        acc.nonOverrides.push(change);
      }

      return acc;
    },
    { nonOverrides: [], overrides: [], headerLevelOvr: [] },
  );

  const removedAgents = [...originalHierarchy.agents]
    .filter(
      (ogAgent) =>
        updatedHierarchy.agents.find(
          (updatedAgent) =>
            ogAgent.agentName === updatedAgent.agentName &&
            ogAgent.producerId === updatedAgent.producerId,
        ) === undefined,
    )
    .map((removedAgent) => ({
      agentName: removedAgent.agentName,
      oldCommissionLevel: removedAgent.commissionLevel,
      newCommissionLevel: '',
    }));

  const getConfirmButtonText = () => {
    if (
      !changes.overrides.length &&
      (changes.nonOverrides.length > 0 || changes.headerLevelOvr.length > 0)
    ) {
      return 'CHANGE FOR DOWNLINES';
    } else if (
      changes.overrides.length > 0 &&
      !changes.nonOverrides.length &&
      !changes.headerLevelOvr.length
    ) {
      return 'CREATE OVERRIDE';
    } else {
      return 'CHANGE ALL';
    }
  };

  return (
    <NglicModal isOpened={open}>
      <div className={styles['modal-content']}>
        <div
          className={styles['title']}
        >{`${originalHierarchy.productName} Hierarchy Change`}</div>
        <div>
          <ChangeSection
            sectionLabel={'Global Override Changes'}
            description={`When making a change at this level all downline agents will be impacted.`}
            changes={changes.headerLevelOvr}
          />
          {changes.headerLevelOvr.length > 0 && (
            <div className={styles['alertText']}>
              Are you sure you want this change to reflect for{' '}
              <span>all downline agents?</span>
            </div>
          )}
        </div>
        <ChangeSection
          sectionLabel={'agent standard changes'}
          description={`The changes made below will only apply when ${
            updatedHierarchy.agents[updatedHierarchy.agents.length - 1]
              .agentName
          } makes a sale.`}
          changes={changes.nonOverrides}
        />
        <ChangeSection
          sectionLabel={'agent override changes'}
          description={
            'The changes made below will apply only to this hierarchy.'
          }
          changes={changes.overrides}
        />
        <ChangeSection
          sectionLabel={'removed agents'}
          description={'The following agents were removed from the hierarchy'}
          changes={removedAgents}
        />
        <div className={styles['button-container']}>
          <SecondaryButton
            text="Cancel"
            onClick={onCancel}
            disabled={showSuccessMessage || saving}
          />
          <PrimaryButton
            text={getConfirmButtonText()}
            onClick={handleSave}
            loading={saving}
            disabled={showSuccessMessage}
          />
        </div>
        <div className={styles['submitMessageDisplay']}>
          {showSuccessMessage && (
            <div className={styles['submitMessageDisplaySuccess']}>
              <CheckCircle className={styles['iconStyle']} />
              <p>Changes were made successfully</p>
            </div>
          )}
          {(showErrorMessage || showForbiddenError) && (
            <div className={styles['submitMessageDisplayError']}>
              <Error className={styles['iconStyle']} />
              {showErrorMessage ? (
                <p>
                  Oops, something went wrong and your changes were not saved.
                  Please try again! If the problem persists, contact Agent
                  Support.
                </p>
              ) : (
                <p>Your session has expired! Login and try again.</p>
              )}
            </div>
          )}
        </div>
      </div>
    </NglicModal>
  );
};
