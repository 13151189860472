import React, { useCallback } from 'react';
import {
  NglicCheckbox,
  Platforms,
  PrimaryButton,
  useNglScreenSize,
} from '@nglic/component-lib';
import styles from './Background.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { BackgroundData, BackgroundTitlesEnum } from './Background.types';
import { MultipleSelectRow } from './MultipleSelectRow/MultipleSelectRow';
import { TextInputRow } from './TextInputRow/TextInputRow';
import { AppState } from '../../../../rootReducer';
import { setReceiveCopyOfBackgroundCheck } from './background.action';
import { withTracker } from 'ga-4-react';
import classNames from 'classnames';

export const isValidBackgroundData = (
  backgroundData: BackgroundData[],
): boolean => {
  return backgroundData
    .reduce<boolean[]>((acc, row) => {
      if (row.selected === '') {
        acc.push(false);
        return acc;
      }
      if (row.selected === 'No') {
        acc.push(true);
      } else {
        const oneStateSelected = row.stateNames
          ? Object.values(row.stateNames).some((entry) => entry)
          : false;

        const explanationFilledOut = row.explanation
          ? row.explanation.length > 0
          : false;

        acc.push(oneStateSelected || explanationFilledOut);
      }

      return acc;
    }, [])
    .every((isValid) => isValid);
};
export const Background: React.FC<{ routeToNext?: () => void }> = ({
  routeToNext,
}) => {
  const dispatch = useDispatch();
  const { isMobile, platform } = useNglScreenSize();
  const backgroundDataTest = useSelector(
    (state: AppState) => state.presentation.contract.background.backgroundData,
  );
  // const receiveCopyOfBackgroundCheck =
  //   useSelector(
  //     (state: AppState) =>
  //       state.presentation.contract.background.willReceiveCopyOfBackgroundCheck,
  //   ) ?? false;
  const [submitDisabled, setSubmitDisabled] = React.useState(true);

  const handleSubmit = () => {
    if (!submitDisabled && routeToNext) {
      routeToNext();
    }
  };

  // const onClickReceiveBackgroundCheck = useCallback(() => {
  //   dispatch(setReceiveCopyOfBackgroundCheck(!receiveCopyOfBackgroundCheck));
  // }, [receiveCopyOfBackgroundCheck]);

  React.useEffect(() => {
    const allRowsValid = isValidBackgroundData(backgroundDataTest);
    setSubmitDisabled(!allRowsValid);
  }, [backgroundDataTest]);
  return (
    <div data-testid="background" className={styles['container']}>
      {(!isMobile || platform === Platforms.TABLET) && (
        <div className={styles['conditional-row-container']}>
          <MultipleSelectRow />
          <TextInputRow
            title={BackgroundTitlesEnum.FILED_BANKRUPTCY}
            index={1}
          />
          <TextInputRow title={BackgroundTitlesEnum.JUDGEMENT} index={2} />
          <TextInputRow title={BackgroundTitlesEnum.FELONY} index={3} />
          <TextInputRow title={BackgroundTitlesEnum.BEEN_BONDED} index={4} />
          <TextInputRow title={BackgroundTitlesEnum.HAD_A_LICENSE} index={5} />
          <TextInputRow title={BackgroundTitlesEnum.INDEBTED} index={6} />
        </div>
      )}
      {isMobile && platform === Platforms.PHONE && (
        <div className={styles['conditional-row-mobile-container']}>
          <div className={styles['divider']} />
          <MultipleSelectRow />
          <div className={styles['divider']} />
          <TextInputRow
            title={BackgroundTitlesEnum.FILED_BANKRUPTCY}
            index={1}
          />
          <div className={styles['divider']} />
          <TextInputRow title={BackgroundTitlesEnum.JUDGEMENT} index={2} />
          <div className={styles['divider']} />
          <TextInputRow title={BackgroundTitlesEnum.FELONY} index={3} />
          <div className={styles['divider']} />
          <TextInputRow title={BackgroundTitlesEnum.BEEN_BONDED} index={4} />
          <div className={styles['divider']} />
          <TextInputRow title={BackgroundTitlesEnum.HAD_A_LICENSE} index={5} />
          <div className={styles['divider']} />
          <TextInputRow title={BackgroundTitlesEnum.INDEBTED} index={6} />
          <div className={styles['divider']} />
        </div>
      )}
      <div className={styles['buttonContainer']}>
        <PrimaryButton
          text="Next"
          disabled={submitDisabled}
          onClick={handleSubmit}
        />
      </div>
      {/*<div className={styles['checkboxContainer']}>*/}
      {/*  <NglicCheckbox*/}
      {/*    onChange={onClickReceiveBackgroundCheck}*/}
      {/*    checked={receiveCopyOfBackgroundCheck}*/}
      {/*  />*/}
      {/*  <label className={classNames(styles['label'], styles['padding'])}>*/}
      {/*    I would like to receive a copy of my background check*/}
      {/*  </label>*/}
      {/*</div>*/}
    </div>
  );
};

export const TrackedBackground: React.FC<any> = withTracker(Background);
