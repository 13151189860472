import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import { AppState } from '../../../../rootReducer';
import { getCredentials } from '../../../shared/selectors/getCredentials';
import { Announcement } from '../../../../service/service.types';
import { AnnouncementService } from 'service/AnnouncementService';

export type AnnouncementsActionType =
  | 'ANNOUNCEMENTS_FETCH__SUCCESS'
  | 'ANNOUNCEMENTS_FETCH_FAILURE'
  | 'NONE';

export interface FetchAnnouncementsSuccessAction
  extends Action<AnnouncementsActionType> {
  type: 'ANNOUNCEMENTS_FETCH__SUCCESS';
  announcements: Announcement[];
}

export interface FetchAnnouncementsErrorAction
  extends Action<AnnouncementsActionType> {
  type: 'ANNOUNCEMENTS_FETCH_FAILURE';
  error: Error;
}

export const fetchAnnouncements =
  (): ThunkAction<void, AppState, any, any> =>
  async (dispatch, getState: () => AppState) => {
    const tokens = getCredentials(getState());
    try {
      const { announcements } = await AnnouncementService.getAnnouncements(
        tokens,
      );

      dispatch({
        type: 'ANNOUNCEMENTS_FETCH__SUCCESS',
        announcements,
      });
    } catch (error) {
      dispatch({
        type: 'ANNOUNCEMENTS_FETCH_FAILURE',
        error,
      });
    }
  };
