import { BFFClient } from '../clients/BFFClient';
import {
  Agent,
  Reserves,
  DocumentResponse,
  AddDocumentResponse,
  ConfirmDocumentResponse,
  DocumentType,
  DeleteDocumentResponse,
  SignContractResponse,
  UpdateAgentBody,
  UpdateProfileResponse,
  UpdateAgentProfile,
  AgentProducer,
  CCPDocumentResponse,
} from './service.types';
import { GeneralAgentInformation } from '../components/Pages/Agent/AddAgent/AgentInformation/agentInformation.reducer';
import { BaseService } from './BaseService';
import { HierarchyUtil } from './transformers/Hierarchy';
import { HierarchyUpdate } from '../components/Pages/Agent/AddAgent/Hierarchy/hierarchy.reducer';
import { v4 as uuidv4 } from 'uuid';

const AgentServicePaths = {
  AGENT: (agentId: string, branchCode: string) =>
    `/ao/agent-profile/${agentId}?branchCode=${branchCode}`,
  AGENTS: (branchCode: string, page = 1, limit = 50, query?: string) =>
    `/ao/agents?branchCode=${branchCode}&page=${page}&limit=${limit}${
      query ? `&query=${query}` : ''
    }`,
  ADD_AGENT: (branchCode: string) => `/ao/add-agent?branchCode=${branchCode}`,
  UPDATE_AGENT: (agentId: string, branchCode: string) =>
    `/ao/agents/${agentId}/?branchCode=${branchCode}`,
  UPDATE_AGENT_CONTACTS: (agentId: string, branchCode: string) =>
    `/ao/agents/${agentId}/contacts?branchCode=${branchCode}`,
  SUPERVISOR_SEARCH: '/ao/supervisor-search',
  AGENT_RESERVES: (id: string, branchCode: string) =>
    `/ao/agents/${id}/reserves?branchCode=${branchCode}`,
  DOCUMENTS: (agentId: string) => `/ao/agents/${agentId}/documents`,
  DOCUMENTS_CONFIRM: (agentId: string, documentId: string) =>
    `/ao/agents/${agentId}/documents/${documentId}/confirm`,
  CCP_DOCUMENTS: (agentId: string, branchCode: string, policyNumber: string) =>
    `/ao/agents/${agentId}/ccp-documents?branchCode=${branchCode}&policyId=${policyNumber}`,
  CCP_DOCUMENT: (agentId: string, documentId: string) =>
    `/ao/agents/${agentId}/ccp-documents/${documentId}`,
  INVITE_AGENT: (email: string) => `/ao/agent/${email}/resend-invitation`,
  TERMINATE_AGENT: (
    branchCode: string,
    agentId: string,
    producerIds: string[],
    terminatedBy: string,
  ) =>
    `/ao/agents/${agentId}?branchCode=${branchCode}&producerIds=${producerIds}&terminatedBy=${terminatedBy}`,
  GET_AGENT_SAML: (agentId: string) => `/ao/agents/${agentId}/appqore-saml`,
  GET_AGENT_TUKAIZ_TOKEN: (agentId: string) =>
    `/ao/agents/${agentId}/tukaiz-token`,
};

export class AgentService extends BaseService {
  static getBFFClient(): BFFClient {
    return new BFFClient();
  }

  static async getAgent(
    agentId: string,
    branchCode: string,
    credentials: { accessToken: string; idToken: string },
  ): Promise<Agent> {
    const { agent } = await AgentService.getBFFClient().get<{
      agent: Agent;
    }>(
      AgentServicePaths.AGENT(agentId, branchCode),
      AgentService.getBaseHeaders(credentials.accessToken, credentials.idToken),
    );

    return agent;
  }

  static async getAllAgents(
    branchCode: string,
    credentials: { accessToken: string; idToken: string },
    page = 1,
    limit = 50,
    query?: string,
  ): Promise<{ agents: Agent[]; totalAgents: number }> {
    return await AgentService.getBFFClient().get<{
      agents: Agent[];
      totalAgents: number;
    }>(
      AgentServicePaths.AGENTS(branchCode, page, limit, query),
      AgentService.getBaseHeaders(credentials.accessToken, credentials.idToken),
    );
  }

  static async searchForSupervisor(
    branchCode: string,
    credentials: { accessToken: string; idToken: string },
    productId?: string,
    query?: string,
    includeInactive?: string,
  ): Promise<AgentProducer[]> {
    const { agents } = await AgentService.getBFFClient().get<{
      agents: AgentProducer[];
    }>(
      `${
        AgentServicePaths.SUPERVISOR_SEARCH
      }?branchCode=${branchCode}&productId=${productId}${
        query ? `&query=${query}` : ''
      }${includeInactive ? `&includeInactive=${includeInactive}` : ''}`,
      AgentService.getBaseHeaders(credentials.accessToken, credentials.idToken),
    );
    return agents;
  }

  static async createAgent(
    generalInformation: GeneralAgentInformation,
    hierarchies: HierarchyUpdate[],
    reserves: Reserves,
    branchCode: string,
    credentials: { accessToken: string; idToken: string },
    approverId?: string,
    comment?: string,
  ): Promise<{ success: boolean }> {
    const res = await AgentService.getBFFClient().post<{
      success: boolean;
    }>(
      `${AgentServicePaths.ADD_AGENT(branchCode)}`,
      {
        requestId: uuidv4(),
        generalInformation: {
          ...generalInformation,
          branchCode,
        },
        reserves,
        hierarchies: this.transformHierarchyForCreateAgent(hierarchies),
        approverId,
        comment,
      },
      AgentService.getBaseHeaders(credentials.accessToken, credentials.idToken),
    );

    return { success: res.success };
  }

  static transformHierarchyForCreateAgent(
    hierarchies: HierarchyUpdate[],
  ): Array<Partial<Agent>> {
    if (hierarchies.length === 0) {
      return [];
    }

    return HierarchyUtil.transformMultipleHierarchies(hierarchies, false);
  }

  static async getAgentReservePercentages(
    agentId: string,
    branchCode: string,
    credentials: { accessToken: string; idToken: string },
  ): Promise<Reserves> {
    const res = await AgentService.getBFFClient().get<{
      reserves: Reserves;
    }>(
      `${AgentServicePaths.AGENT_RESERVES(agentId, branchCode)}`,
      AgentService.getBaseHeaders(credentials.accessToken, credentials.idToken),
    );

    return res.reserves;
  }

  static async getDocuments(
    agentId: string,
    credentials: { accessToken: string; idToken: string },
  ): Promise<DocumentResponse[]> {
    const { documents } = await AgentService.getBFFClient().get<{
      documents: DocumentResponse[];
    }>(
      `${AgentServicePaths.DOCUMENTS(agentId)}`,
      AgentService.getBaseHeaders(credentials.accessToken, credentials.idToken),
    );

    return documents;
  }

  static async getDocument(
    agentId: string,
    documentId: string,
    credentials: { accessToken: string; idToken: string },
  ): Promise<DocumentResponse> {
    return AgentService.getBFFClient().get<DocumentResponse>(
      `${AgentServicePaths.DOCUMENTS(agentId)}/${documentId}`,
      AgentService.getBaseHeaders(credentials.accessToken, credentials.idToken),
    );
  }

  static async addDocument(
    agentId: string,
    name: string,
    md5: string,
    type: DocumentType,
    credentials: { accessToken: string; idToken: string },
  ): Promise<AddDocumentResponse> {
    return await AgentService.getBFFClient().post<AddDocumentResponse>(
      `${AgentServicePaths.DOCUMENTS(agentId)}`,
      {
        name,
        md5,
        type,
      },
      AgentService.getBaseHeaders(credentials.accessToken, credentials.idToken),
    );
  }

  static async confirmDocument(
    agentId: string,
    documentId: string,
    credentials: { accessToken: string; idToken: string },
  ): Promise<ConfirmDocumentResponse> {
    return await AgentService.getBFFClient().post<AddDocumentResponse>(
      `${AgentServicePaths.DOCUMENTS_CONFIRM(agentId, documentId)}`,
      {},
      AgentService.getBaseHeaders(credentials.accessToken, credentials.idToken),
    );
  }

  static async deleteDocument(
    agentId: string,
    documentId: string,
    credentials: { accessToken: string; idToken: string },
  ): Promise<DeleteDocumentResponse> {
    return await AgentService.getBFFClient().delete<DeleteDocumentResponse>(
      `${AgentServicePaths.DOCUMENTS(agentId)}/${documentId}`,
      undefined,
      AgentService.getBaseHeaders(credentials.accessToken, credentials.idToken),
    );
  }

  static async getCCPDocuments(
    agentId: string,
    branchCode: string,
    policyNumber: string,
    credentials: { accessToken: string; idToken: string },
  ): Promise<CCPDocumentResponse> {
    return await AgentService.getBFFClient().get<CCPDocumentResponse>(
      `${AgentServicePaths.CCP_DOCUMENTS(agentId, branchCode, policyNumber)}`,
      AgentService.getBaseHeaders(credentials.accessToken, credentials.idToken),
    );
  }

  static async getCCPDocument(
    agentId: string,
    documentId: string,
    credentials: { accessToken: string; idToken: string },
  ): Promise<CCPDocumentResponse> {
    return await AgentService.getBFFClient().get<CCPDocumentResponse>(
      `${AgentServicePaths.CCP_DOCUMENT(agentId, documentId)}`,
      AgentService.getBaseHeaders(credentials.accessToken, credentials.idToken),
    );
  }

  static async signContract(
    agentId: string,
    branchCode: string,
    body: UpdateAgentBody,
    credentials: { accessToken: string; idToken: string },
  ): Promise<SignContractResponse> {
    return await AgentService.getBFFClient().post<SignContractResponse>(
      `${AgentServicePaths.UPDATE_AGENT(agentId, branchCode)}`,
      body,
      AgentService.getBaseHeaders(credentials.accessToken, credentials.idToken),
    );
  }

  static async editProfile(
    agentId: string,
    branchCode: string,
    body: UpdateAgentProfile,
    credentials: { accessToken: string; idToken: string },
  ): Promise<UpdateProfileResponse> {
    return await AgentService.getBFFClient().post<UpdateProfileResponse>(
      `${AgentServicePaths.UPDATE_AGENT_CONTACTS(agentId, branchCode)}`,
      body,
      AgentService.getBaseHeaders(credentials.accessToken, credentials.idToken),
    );
  }

  static async resendInvite(
    email: string,
    credentials: { accessToken: string; idToken: string },
  ): Promise<{ success: boolean }> {
    return await AgentService.getBFFClient().post<{ success: boolean }>(
      AgentServicePaths.INVITE_AGENT(email),
      AgentService.getBaseHeaders(credentials.accessToken, credentials.idToken),
    );
  }

  static async terminateAgent(
    branchCode: string,
    agentId: string,
    credentials: { accessToken: string; idToken: string },
    producerIds: string[],
    terminatedBy: string,
  ): Promise<{ success: boolean }> {
    const res = await AgentService.getBFFClient().delete<{
      success: boolean;
    }>(
      `${AgentServicePaths.TERMINATE_AGENT(
        branchCode,
        agentId,
        producerIds,
        terminatedBy,
      )}`,
      undefined,
      AgentService.getBaseHeaders(credentials.accessToken, credentials.idToken),
    );

    return { success: res.success };
  }

  static async getAgentAppQoreSAML(
    agentId: string,
    credentials: { accessToken: string; idToken: string },
  ): Promise<{ saml: string }> {
    return await AgentService.getBFFClient().get<{
      saml: string;
    }>(
      `${AgentServicePaths.GET_AGENT_SAML(agentId)}`,
      AgentService.getBaseHeaders(credentials.accessToken, credentials.idToken),
    );
  }

  static async getAgentTukaizToken(
    agentId: string,
    credentials: { accessToken: string; idToken: string },
  ): Promise<{ token: string }> {
    return await AgentService.getBFFClient().get<{
      token: string;
    }>(
      `${AgentServicePaths.GET_AGENT_TUKAIZ_TOKEN(agentId)}`,
      AgentService.getBaseHeaders(credentials.accessToken, credentials.idToken),
    );
  }
}
