import { BFFClient } from '../clients/BFFClient';
import { Announcement } from './service.types';
import { BaseService } from './BaseService';

const AnnouncementServicePaths = {
  ANNOUNCEMENTS: () => `/ao/announcements`,
};

export class AnnouncementService extends BaseService {
  static getBFFClient(): BFFClient {
    return new BFFClient();
  }

  static async getAnnouncements(credentials: {
    accessToken: string;
    idToken: string;
  }): Promise<{ announcements: Announcement[] }> {
    const resp = await AnnouncementService.getBFFClient().get<{
      data: Announcement[];
    }>(
      AnnouncementServicePaths.ANNOUNCEMENTS(),
      AnnouncementService.getBaseHeaders(
        credentials.accessToken,
        credentials.idToken,
      ),
    );
    return {
      announcements: resp?.data as Announcement[],
    };
  }
}
