import { Action } from 'redux';
import produce from 'immer';

import { CommissionsActivity } from '../../../../service/service.types';
import {
  CommissionActivitiesActionType,
  FetchAllCommissionActivitiesFailureAction,
  FetchAllCommissionActivitiesSuccessAction,
  FetchAllCommissionReservesActivitiesFailureAction,
  FetchAllCommissionReservesActivitiesSuccessAction,
} from './commissions.action';

export interface CommissionsActivityInitState {
  initialCommissions: CommissionsActivity[];
  commissionsActivities: CommissionsActivity[] | undefined;
  totalCommissionsActivities: number | undefined;
  reservesCommissionsActivities: CommissionsActivity[] | undefined;
  totalReservesCommissionsActivities: number | undefined;
  commissionsActivityStatementDates: string[] | undefined;
  totalCommissionsActivityStatementDates: number | undefined;
}

export type CommissionActivitiesReservesErrorState = {
  commissionActivitiesReservesError: Error;
} & CommissionsActivityInitState;

export type CommissionActivitiesErrorState = {
  commissionActivitiesError: Error;
} & CommissionsActivityInitState;

export type CommissionStatementDatesErrorState = {
  commissionStatementDatesError: Error;
} & CommissionsActivityInitState;

export type CommissionActivitiesState =
  | CommissionsActivityInitState
  | CommissionActivitiesErrorState
  | CommissionStatementDatesErrorState
  | CommissionActivitiesReservesErrorState;

export const commissionActivitiesReducer = (
  state: CommissionsActivityInitState = {
    initialCommissions: [],
    commissionsActivities: undefined,
    totalCommissionsActivities: undefined,
    reservesCommissionsActivities: undefined,
    totalReservesCommissionsActivities: undefined,
    commissionsActivityStatementDates: undefined,
    totalCommissionsActivityStatementDates: undefined,
  },
  action: Action<CommissionActivitiesActionType>,
): CommissionActivitiesState => {
  return produce(state, (draftState: CommissionActivitiesState) => {
    switch (action.type) {
      case 'FETCH_INITIAL_COMMISSION_ACTIVITIES_SUCCESS':
        draftState = {
          ...draftState,
          initialCommissions: (
            action as FetchAllCommissionActivitiesSuccessAction
          ).result.commissions,
          totalCommissionsActivities: (
            action as FetchAllCommissionActivitiesSuccessAction
          ).result.totalCommissions,
        };
        return draftState;
      case 'FETCH_INITIAL_COMMISSION_ACTIVITIES_FAILURE':
        draftState = {
          ...draftState,
          commissionActivitiesError: (
            action as FetchAllCommissionActivitiesFailureAction
          ).error,
          initialCommissions: [],
        };
        return draftState;
      case 'FETCH_COMMISSION_ACTIVITIES_SUCCESS':
        draftState = {
          ...draftState,
          commissionsActivities: (
            action as FetchAllCommissionActivitiesSuccessAction
          ).result.commissions,
          totalCommissionsActivities: (
            action as FetchAllCommissionActivitiesSuccessAction
          ).result.totalCommissions,
        };
        return draftState;
      case 'FETCH_COMMISSION_ACTIVITIES_FAILURE':
        draftState = {
          ...draftState,
          commissionActivitiesError: (
            action as FetchAllCommissionActivitiesFailureAction
          ).error,
          commissionsActivities: [],
          totalCommissionsActivities: undefined,
        };
        return draftState;
      case 'FETCH_COMMISSION_RESERVES_ACTIVITIES_SUCCESS':
        draftState = {
          ...draftState,
          reservesCommissionsActivities: (
            action as FetchAllCommissionReservesActivitiesSuccessAction
          ).result.reserves,
          totalReservesCommissionsActivities: (
            action as FetchAllCommissionReservesActivitiesSuccessAction
          ).result.totalReserves,
        };
        return draftState;
      case 'FETCH_COMMISSION_RESERVES_ACTIVITIES_FAILURE':
        draftState = {
          ...draftState,
          commissionActivitiesReservesError: (
            action as FetchAllCommissionReservesActivitiesFailureAction
          ).error,
          reservesCommissionsActivities: [],
          totalReservesCommissionsActivities: undefined,
        };
        return draftState;
      case 'RESET_COMMISSIONS':
        draftState = {
          initialCommissions: [],
          commissionsActivities: undefined,
          totalCommissionsActivities: undefined,
          reservesCommissionsActivities: undefined,
          totalReservesCommissionsActivities: undefined,
          commissionsActivityStatementDates: undefined,
          totalCommissionsActivityStatementDates: undefined,
        };
        return draftState;
      default:
        return state;
    }
  });
};

export function isCommissionStatementDatesErrorState(
  state: CommissionActivitiesState,
): state is CommissionStatementDatesErrorState {
  return (
    (state as CommissionStatementDatesErrorState)
      .commissionStatementDatesError !== undefined
  );
}

export function isCommissionActivitiesErrorState(
  state: CommissionActivitiesState,
): state is CommissionActivitiesErrorState {
  return (
    (state as CommissionActivitiesErrorState).commissionActivitiesError !==
    undefined
  );
}
export function isCommissionActivitiesReservesErrorState(
  state: CommissionActivitiesState,
): state is CommissionActivitiesReservesErrorState {
  return (
    (state as CommissionActivitiesReservesErrorState)
      .commissionActivitiesReservesError !== undefined
  );
}
