import {
  NglicModal,
  PrimaryButton,
  SecondaryButton,
} from '@nglic/component-lib';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../../../../../rootReducer';
import { Agent } from '../../../../../../../service/service.types';
import { terminateAgent } from '../../../../agentTermination.action';
import styles from './AgentTerminationConfirmModal.module.scss';

interface AgentTerminationConfirmModalProps {
  open: boolean;
  handleClose: () => void;
  selectedAgent: Agent;
}
export const AgentTerminationConfirmModal: React.FC<AgentTerminationConfirmModalProps> =
  ({ open, handleClose, selectedAgent }) => {
    const [loading, setLoading] = useState<boolean>(false);
    const dispatch = useDispatch();
    const data = useSelector(
      (data: AppState) => data.presentation.terminateAgent,
    );
    const handleTermination = () => {
      if (selectedAgent) {
        setLoading(true);
        dispatch(terminateAgent(selectedAgent.id, selectedAgent.producerIds));
      }
    };

    useEffect(() => {
      if (data !== 'INCOMPLETE') {
        setLoading(false);
        handleClose();
      }
    }, [data]);
    return (
      <NglicModal isOpened={open}>
        <div className={styles['modal-content']}>
          <div className={styles['header']}>
            <div className={styles['title']}>Terminate an Agent</div>
          </div>
          <div className={styles['content']}>
            <div className={styles['text']}>
              Terminating an agent will submit a request to NGL Agent
              Contracting. Agents will still be able to sell policies within 10
              days of this request.
            </div>
            <div className={styles['text']}>
              Agent:{' '}
              <span className={styles['agent-name']}>{selectedAgent.name}</span>
            </div>
            <div className={styles['text-question']}>
              Are you sure you want to terminate this agent?
            </div>
          </div>
          <div className={styles['button-container']}>
            <SecondaryButton text="CANCEL" onClick={handleClose} />
            <span className={styles['divider']}></span>
            <PrimaryButton
              text="TERMINATE AGENT"
              onClick={handleTermination}
              loading={loading}
            />
          </div>
        </div>
      </NglicModal>
    );
  };
