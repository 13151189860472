import React, { useState } from 'react';
import { UserService } from '../../../service/UserService';
import { AppState } from '../../../rootReducer';
import { useSelector } from 'react-redux';
import { getCredentials } from '../../shared/selectors/getCredentials';

interface FeatureWrapperProps {
  featureName: string;
  params?: Record<string, any>;
}

export const FeatureWrapper: React.FC<FeatureWrapperProps> = ({
  featureName,
  params = {},
  children,
}) => {
  const state: AppState = useSelector((state: AppState) => state);
  const tokens = getCredentials(state);
  const [isVisible, setIsVisible] = useState<boolean>(false);

  React.useEffect(() => {
    UserService.isFeatureViewable(featureName, 'ao', params, {
      accessToken: tokens.accessToken,
      idToken: tokens.idToken,
    }).then((res) => {
      setIsVisible(res.visible);
    });
  }, []);
  return <div>{isVisible && children}</div>;
};
