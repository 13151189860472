import { NglicModal, PrimaryButton } from '@nglic/component-lib';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styles from './ClaimsModal.module.scss';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { useHistory } from 'react-router-dom';
import { AppState } from '../../../../../../rootReducer';
import { getUserInformation } from '../../../../../shared/selectors/getUserInformation';
import { isUserFuneralHome } from '@nglic/utilities-ts/build';

interface SubmittedClaimProps {
  open: boolean;
  handleCloseSubmit: () => void;
  clearClaim: () => void;
  setSubmitClicked: (clicked) => void;
}
export const SubmittedClaim: React.FC<SubmittedClaimProps> = ({
  open,
  handleCloseSubmit,
  clearClaim,
  setSubmitClicked,
}) => {
  const userInfo = useSelector((state: AppState) => getUserInformation(state));
  const dispatch = useDispatch();
  const history = useHistory();
  return (
    <NglicModal isOpened={open}>
      <div className={styles['submitted-container']}>
        <div className={styles['submitted-header']}>
          <div className={styles['title']}>
            <p>
              {isUserFuneralHome(userInfo?.roles)
                ? 'Claim Submitted'
                : 'Claim Started'}
            </p>
          </div>
          <IconButton
            onClick={() => {
              handleCloseSubmit();
            }}
            className={styles['submitted-icon-button']}
          >
            <CloseIcon
              className={styles['submitted-close-icon']}
              onClick={() => {
                setSubmitClicked(false);
              }}
            />
          </IconButton>
        </div>
        <div className={styles['submitted-content']}>
          <a
            onClick={() => {
              history.push('/manage-business/documents');
              dispatch(clearClaim());
            }}
          >
            Go to Document Upload
          </a>
          <p>
            Follow the link to upload any support documentation for this claim
          </p>
        </div>

        <div className={styles['close-button']}>
          <PrimaryButton
            text={'CLOSE'}
            onClick={() => {
              handleCloseSubmit();
            }}
          />
        </div>
      </div>
    </NglicModal>
  );
};
