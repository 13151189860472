import React from 'react';
import NewIcon from '../../../assets/img/NewIcon/New.svg';

//This component is used for assets that we want to use across the app.
//Workaround until we find a proper solution for this/
export const HiddenAssets: React.FC = () => {
  return (
    <div style={{ display: 'none' }}>
      <img src={NewIcon} />
    </div>
  );
};
