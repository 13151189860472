import React from 'react';
import styles from './ReservesReview.module.scss';

export interface ReservesReviewProps {
  reserves: any;
}

const groupValues = (name?: string, fieldData?: string) => {
  if (name === 'minMax') {
    return fieldData ? ` $${fieldData}` : ' None';
  }
  return fieldData ? ` ${fieldData} %` : ' 0 %';
};
export const ReservesReviewBody: React.FC<{
  key: string;
  title: string;
  fieldData?: string;
  name?: string;
}> = ({ title, fieldData, name }) => {
  return (
    <p key={title} className={styles['key-value-text']}>
      <strong>{title} :</strong>
      {groupValues(name, fieldData)}
    </p>
  );
};

export const ReservesReview: React.FC<ReservesReviewProps> = ({
  reserves,
}: ReservesReviewProps) => {
  const resevesData = [
    {
      title: 'Single Pay',
      value: reserves?.singlePay,
    },
    {
      title: 'Credit Card',
      value: reserves?.creditCard,
    },
    {
      title: 'Direct Bill',
      value: reserves?.directBill,
    },
    {
      title: 'Renewal',
      value: reserves?.renewal,
    },
    {
      title: 'EFT',
      value: reserves?.eft,
    },
    {
      title: 'Min Amount',
      value: reserves?.minAmount,
      name: 'minMax',
    },
    {
      title: 'Max Amount',
      value: reserves?.maxAmount,
      name: 'minMax',
    },
  ];

  return (
    <div data-testid="reservesReview">
      {resevesData.map(({ title, value, name }) => (
        <ReservesReviewBody
          key={title}
          title={title}
          fieldData={value}
          name={name}
        />
      ))}
    </div>
  );
};
