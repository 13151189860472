import React, { useState } from 'react';
import { FormControlLabel, Radio } from '@material-ui/core';
import styles from './ConditionalLevelDropdown.module.scss';
import classNames from 'classnames';
import {
  NglicSimpleSelect,
  NglicSimpleSelectType,
  Option,
} from '@nglic/component-lib/build';
import { createLevelArray } from '@nglic/utilities-ts/build';

export const ConditionalLevelDropdown: React.FC<{
  maxLevel: number;
  minLevel: number;
  selectedValue?: Option;
  onOptionSelect: (level?: Option) => void;
}> = ({ onOptionSelect, maxLevel, minLevel, selectedValue }) => {
  const [showDropdown, setShowDropdown] = useState<boolean>(
    selectedValue !== undefined,
  );

  React.useEffect(() => {
    if (selectedValue) {
      setShowDropdown(true);
    }
  }, [selectedValue]);
  const options = createLevelArray({ max: maxLevel, min: minLevel })
    .reverse()
    .map((key) => ({
      id: `${key}`,
      name: `Level ${key}`,
    }));

  return (
    <div className={styles['root']}>
      <div>
        <FormControlLabel
          value="yes"
          onClick={() => {
            setShowDropdown(true);
          }}
          control={
            <Radio
              className={classNames(styles['radio'], {
                [styles['active']]: showDropdown,
              })}
              disableRipple={true}
              checked={showDropdown}
            />
          }
          label="Yes"
        />
        <FormControlLabel
          value="no"
          onClick={() => {
            setShowDropdown(false);
            onOptionSelect();
          }}
          control={
            <Radio
              disableRipple={true}
              className={classNames(styles['radio'], {
                [styles['active']]: !showDropdown,
              })}
              checked={!showDropdown}
            />
          }
          label="No"
        />
      </div>
      <div>
        {showDropdown && (
          <div aria-label={'commission-level-dropdown'}>
            <NglicSimpleSelect
              label={'Select Commission Level'}
              selectedOption={selectedValue}
              type={NglicSimpleSelectType?.MAINTAIN_VALUE}
              options={options}
              onChange={onOptionSelect}
            />
          </div>
        )}
      </div>
    </div>
  );
};
