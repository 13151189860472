import { createSelector } from 'reselect';
import { AppState } from '../../../../rootReducer';
import { PaymentData } from './PaymentMethod.types';

const getPaymentDataState = (state: AppState) =>
  state.presentation.contract.paymentMethod;

export const getPaymentData = createSelector(
  [getPaymentDataState],
  (paymentData) => {
    if (paymentData !== 'INCOMPLETE') {
      return paymentData as PaymentData;
    } else {
      return undefined;
    }
  },
);
