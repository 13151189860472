import { BFFClient } from '../clients/BFFClient';
import { Role, User, UserInfo } from './service.types';
import { BaseService } from './BaseService';
import { JwtClient } from '../clients/JwtClient';

const UserServicePaths = {
  GET_BASIC_USER_INFO: '/ao/user',
  GET_BASIC_USER_BY_COGNITO_UUID: (id: string) => `/ao/user/${id}`,
  IS_FEATURED: '/ao/is-featured',
  IS_CHILD_FEATURED: '/ao/is-child-featured',
  CREATE_USER: `/ao/user`,
};

const UserServicePathsV2 = {
  GET_USERS: (query: string, limit: number, page: number) =>
    `/v2/ao/user?page=${page}&limit=${limit}${query ? `&query=${query}` : ''}`,
};

export interface CreateUserBySupportUser {
  firstName: string;
  lastName: string;
  email: string;
  roles: string[];
  mfaEnabled: 'true' | 'false';
}

export class UserService extends BaseService {
  static async getUserInfoByRole(
    email: string,
    credentials: { accessToken: string; idToken: string },
    roles: Role[],
  ): Promise<Partial<UserInfo>> {
    if (roles.includes(Role.SUPPORT)) {
      return this.getSupportUserInfo(email, roles, credentials);
    }

    return this.getAgentUserInfo(email, roles, credentials);
  }

  static async getMasqueradeUser(
    cognitoUuid: string,
    credentials: { accessToken: string; idToken: string },
  ): Promise<Partial<UserInfo>> {
    const res = await UserService.getBFFClient().get<UserInfo>(
      UserServicePaths.GET_BASIC_USER_BY_COGNITO_UUID(cognitoUuid),
      UserService.getBaseHeaders(credentials.accessToken, credentials.idToken),
    );

    return res as Partial<UserInfo>;
  }

  private static async getAgentUserInfo(
    email: string,
    roles: Role[],
    credentials: { accessToken: string; idToken: string },
  ): Promise<Partial<UserInfo>> {
    const res = await UserService.getBFFClient().get<UserInfo>(
      `${UserServicePaths.GET_BASIC_USER_INFO}?email=${encodeURIComponent(
        email,
      )}`,
      UserService.getBaseHeaders(credentials.accessToken, credentials.idToken),
    );

    return { ...res, roles } as Partial<UserInfo>;
  }

  private static async getSupportUserInfo(
    email: string,
    roles: Role[],
    credentials: { accessToken: string; idToken: string },
  ): Promise<Partial<UserInfo>> {
    const decodedToken = await JwtClient.decodeToken(credentials.idToken);
    return {
      firstName: decodedToken['given_name'],
      lastName: decodedToken['family_name'],
      email,
      roles,
    };
  }

  static async isFeatureViewable(
    featureName: string,
    appName: string,
    params: Record<string, any>,
    credentials: { accessToken: string; idToken: string },
  ): Promise<{ visible: boolean }> {
    return await UserService.getBFFClient().post<{ visible: boolean }>(
      `${UserServicePaths.IS_FEATURED}`,
      {
        featureName,
        appName,
        params,
      },
      UserService.getBaseHeaders(credentials.accessToken, credentials.idToken),
    );
  }

  static async isChildFeatureViewable(
    parentFeatureName: string,
    childFeatureName: string,
    appName: string,
    params: Record<string, any>,
    credentials: { accessToken: string; idToken: string },
  ): Promise<{ visible: boolean }> {
    return await UserService.getBFFClient().post<{ visible: boolean }>(
      `${UserServicePaths.IS_CHILD_FEATURED}`,
      {
        parentFeatureName,
        childFeatureName,
        appName,
        params,
      },
      UserService.getBaseHeaders(credentials.accessToken, credentials.idToken),
    );
  }

  static async getUsersAsSupportUser(
    query: string,
    limit: number,
    page: number,
    credentials: { accessToken: string; idToken: string },
  ): Promise<{ users: User[]; totalUsers: number }> {
    return await UserService.getBFFClient().get<{
      users: User[];
      totalUsers: number;
    }>(
      UserServicePathsV2.GET_USERS(query, limit, page),
      UserService.getBaseHeaders(credentials.accessToken, credentials.idToken),
    );
  }

  static async createUserAsSupportUser(
    userData: CreateUserBySupportUser,
    credentials: { accessToken: string; idToken: string },
  ): Promise<{ success: boolean }> {
    return await UserService.getBFFClient().post<{ success: boolean }>(
      UserServicePaths.CREATE_USER,
      userData,
      UserService.getBaseHeaders(credentials.accessToken, credentials.idToken),
    );
  }

  static getBFFClient(): BFFClient {
    return new BFFClient();
  }
}
