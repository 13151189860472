import React, { ChangeEvent, useState } from 'react';
import MaskedInput from 'react-text-mask';
import {
  DatePicker,
  FlexDirection,
  NglicCheckbox,
  NglicRadioGroup,
  NglicSimpleSelect,
  Option,
  Platforms,
  PrimaryButton,
  TextInput,
  useNglScreenSize,
} from '@nglic/component-lib/build';
import {
  DemographicErrorsEnum,
  GenderEnum,
  PhoneNumberTypesEnum,
} from '../contract.types';
import {
  isValidBirthDate,
  containsEmptyFieldV2,
  isNineDigits,
} from '@nglic/utilities-ts/build';
import {
  Address,
  ErrorMessages,
  InitErrorMessages,
  UserData,
} from './PersonalInformation.types';
import styles from './PersonalInformation.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import LocationAutocompleteComponent, {
  AddressType,
} from '../../../modules/LocationAutocompleteComponent/LocationAutocompleteComponent';
import { addPersonalInformation } from './personalInformation.action';
import {
  createPhoneNumberPipe,
  createSSNPipe,
} from '@nglic/utilities-ts/build';
import { AppState } from '../../../../rootReducer';
import { CommunicationPreference, State } from 'service/service.types';
import { fetchStates } from '../../../shared/state.action';
import { PhoneInput } from '../../../modules/PhoneInput/PhoneInput';
import { withTracker } from 'ga-4-react';

export const PhoneNumberMask = (props) => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        '(',
        /[1-9]/,
        /\d/,
        /\d/,
        ')',
        ' ',
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      showMask
      pipe={createPhoneNumberPipe}
    />
  );
};

const SSNNumberMask = (props) => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      showMask
      pipe={createSSNPipe}
    />
  );
};

const communicationPreferenceOptions: Option[] = [
  {
    id: CommunicationPreference.WORK_PHONE,
    name: CommunicationPreference.WORK_PHONE,
  },
  {
    id: CommunicationPreference.HOME_PHONE,
    name: CommunicationPreference.HOME_PHONE,
  },
  {
    id: CommunicationPreference.MOBILE_PHONE,
    name: CommunicationPreference.MOBILE_PHONE,
  },
  {
    id: CommunicationPreference.EMAIL,
    name: CommunicationPreference.EMAIL,
  },
  {
    id: CommunicationPreference.CONTRACT_UPLINE,
    name: CommunicationPreference.CONTRACT_UPLINE,
  },
  {
    id: CommunicationPreference.TEXT,
    name: CommunicationPreference.TEXT,
  },
];

export const PersonalInformation: React.FC<{ routeToNext?: () => void }> = ({
  routeToNext,
}) => {
  const { isMobile, platform } = useNglScreenSize();
  const [personalInformation, setPersonalInformation] =
    React.useState<UserData>({
      firstName: '',
      lastName: '',
      middleName: '',
      gender: '',
      birthday: '',
      ssn: '',
      phoneNumber: '',
      phoneNumberType: '',
      noMiddleName: false,
    });

  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] =
    React.useState<ErrorMessages>(InitErrorMessages);
  const [submitDisabled, setSubmitDisabled] = React.useState(true);
  const [validHomeAddress, setValidHomeAddress] = React.useState(false);
  const [validWorkAddress, setValidWorkAddress] = React.useState(false);
  const [workAddress, setWorkAddress] = React.useState<Address | undefined>();
  const [homeAddress, setHomeAddress] = React.useState<Address | undefined>();

  const [workAddressSameAsHomeAddress, setWorkAddressSameAsHomeAddress] =
    useState(false);

  const [agentWorkingState, setAgentWorkingState] = useState<Option>({
    id: '',
    name: '',
  });

  const [communicationPreferece, setCommunicationPreferece] = useState<Option>({
    id: '',
    name: '',
  });

  const personalInfoRedux = useSelector(
    (state: AppState) => state.presentation.contract.personalInformation,
  );
  const states: State[] | undefined = useSelector(
    (state: AppState) => state.data.state.states,
  );

  React.useEffect(() => {
    if (personalInfoRedux !== 'INCOMPLETE') {
      setPersonalInformation({
        firstName: personalInfoRedux.firstName,
        middleName: personalInfoRedux.middleName,
        lastName: personalInfoRedux.lastName,
        gender: personalInfoRedux.gender,
        birthday: personalInfoRedux.birthday,
        ssn: personalInfoRedux.ssn,
        phoneNumber: personalInfoRedux.phoneNumber,
        phoneNumberType: personalInfoRedux.phoneNumberType,
        noMiddleName: personalInfoRedux.noMiddleName,
      });
      if (!personalInfoRedux.workAddressSameAsHomeAddress) {
        setWorkAddress(personalInfoRedux.workAddress);
        setValidWorkAddress(true);
      }
      setHomeAddress(personalInfoRedux.homeAddress);
      setValidHomeAddress(true);
      setAgentWorkingState({
        id: personalInfoRedux?.agentWorkingStates
          ? personalInfoRedux?.agentWorkingStates[0]?.state
          : '',
        name: personalInfoRedux?.agentWorkingStates
          ? personalInfoRedux?.agentWorkingStates[0]?.state
          : '',
      });
      setCommunicationPreferece({
        id: personalInfoRedux?.communicationPreference
          ? personalInfoRedux?.communicationPreference
          : '',
        name: personalInfoRedux?.communicationPreference
          ? personalInfoRedux?.communicationPreference
          : '',
      });
      setWorkAddressSameAsHomeAddress(
        personalInfoRedux.workAddressSameAsHomeAddress || false,
      );
    }
    if (!states || states?.length === 0) {
      dispatch(fetchStates());
    }
  }, []);

  React.useEffect(() => {
    const validSSN = isNineDigits(personalInformation.ssn);
    const validBirthDate = isValidBirthDate(personalInformation.birthday);
    const { middleName, noMiddleName, ...requiredPersonalInformationFields } =
      personalInformation;
    const values = Object.values(requiredPersonalInformationFields);
    const validAddress = workAddressSameAsHomeAddress
      ? validHomeAddress
      : validHomeAddress && validWorkAddress;
    // const validateAgentWorkingState = Object.values(agentWorkingState);
    const validateCommunicationPreference = Object.values(
      communicationPreferece,
    );
    setSubmitDisabled(
      containsEmptyFieldV2(values) ||
        // containsEmptyField(validateAgentWorkingState) ||
        containsEmptyFieldV2(validateCommunicationPreference) ||
        !validSSN ||
        !validBirthDate ||
        !validAddress,
      //|| (!middleName && !noMiddleName),
    );
  }, [
    personalInformation,
    validWorkAddress,
    validHomeAddress,
    workAddressSameAsHomeAddress,
    agentWorkingState,
    communicationPreferece,
  ]);

  const genderRadioGroups = [
    {
      label: GenderEnum.MALE,
      onClick: () => handleOnChangeInput('gender', GenderEnum.MALE),
      selected: personalInformation.gender === GenderEnum.MALE,
    },
    {
      label: GenderEnum.FEMALE,
      onClick: () => handleOnChangeInput('gender', GenderEnum.FEMALE),
      selected: personalInformation.gender === GenderEnum.FEMALE,
    },
  ];
  const phoneNumberTypeRadioGroups = [
    {
      label: PhoneNumberTypesEnum.CELL,
      onClick: () =>
        handleOnChangeInput('phoneNumberType', PhoneNumberTypesEnum.CELL),
      selected:
        personalInformation.phoneNumberType === PhoneNumberTypesEnum.CELL,
    },
    {
      label: PhoneNumberTypesEnum.WORK,
      onClick: () =>
        handleOnChangeInput('phoneNumberType', PhoneNumberTypesEnum.WORK),
      selected:
        personalInformation.phoneNumberType === PhoneNumberTypesEnum.WORK,
    },
    {
      label: PhoneNumberTypesEnum.FAX,
      onClick: () =>
        handleOnChangeInput('phoneNumberType', PhoneNumberTypesEnum.FAX),
      selected:
        personalInformation.phoneNumberType === PhoneNumberTypesEnum.FAX,
    },
    {
      label: PhoneNumberTypesEnum.HOME,
      onClick: () =>
        handleOnChangeInput('phoneNumberType', PhoneNumberTypesEnum.HOME),
      selected:
        personalInformation.phoneNumberType === PhoneNumberTypesEnum.HOME,
    },
  ];

  const handleOnChangeInput = React.useCallback(
    (inputName: string, newValue: string | boolean) => {
      setPersonalInformation({
        ...personalInformation,
        [inputName]: newValue,
      });
    },
    [personalInformation],
  );

  const handleErrorMessage = (
    input: DemographicErrorsEnum,
    newValue: string,
  ): void => {
    switch (input) {
      case DemographicErrorsEnum.BIRTHDAY:
        setErrorMessage({
          ...errorMessage,
          Birthday: {
            error: !isValidBirthDate(newValue),
            message: !isValidBirthDate(newValue)
              ? 'Please select a date in the past'
              : '',
          },
        });
        break;
      case DemographicErrorsEnum.SSN:
        isNineDigits(newValue);
        setErrorMessage({
          ...errorMessage,
          SSN: {
            error: !isNineDigits(newValue),
            message: !isNineDigits(newValue) ? 'Must be 9 digits' : '',
          },
        });
        break;
      default:
    }
  };

  const handleSubmit = () => {
    if (!submitDisabled && routeToNext) {
      const createAgentWorkingStatesArray = [
        {
          state: agentWorkingState?.id,
        },
      ];
      dispatch(
        addPersonalInformation({
          ...personalInformation,
          middleName: personalInformation.noMiddleName
            ? ''
            : personalInformation.middleName,
          homeAddress,
          workAddress: workAddressSameAsHomeAddress ? homeAddress : workAddress,
          agentWorkingStates: createAgentWorkingStatesArray,
          workAddressSameAsHomeAddress,
          communicationPreference: communicationPreferece.name,
        }),
      );
      routeToNext();
    }
  };

  const options = states?.reduce<any[]>((acc, next) => {
    return [
      ...acc,
      {
        id: next.code,
        name: next.desc,
      },
    ];
  }, []);

  const handleOnChange = (value: Option) => {
    setAgentWorkingState({
      id: value?.id,
      name: value?.id,
    });
  };

  const handleOnChangeCommunicationPreference = (value: Option) => {
    setCommunicationPreferece({
      id: value?.id,
      name: value?.id,
    });
  };

  return (
    <div
      data-testid="personal-information"
      className={styles['personal-information-root']}
    >
      <div className={styles['container']}>
        <div className={styles['item']}>
          <TextInput
            label="First Name"
            value={personalInformation.firstName}
            onChange={(newValue: string) =>
              handleOnChangeInput('firstName', newValue)
            }
          />
          <TextInput
            label="Middle Name"
            value={personalInformation.middleName}
            // disabled={personalInformation.noMiddleName}
            onChange={(newValue: string) =>
              handleOnChangeInput('middleName', newValue)
            }
          />
          <TextInput
            label="Last Name"
            value={personalInformation.lastName}
            onChange={(newValue: string) =>
              handleOnChangeInput('lastName', newValue)
            }
            id={'first-name-input'}
          />
        </div>
        {/*<div className={styles['checkbox-container']}>*/}
        {/*  <NglicCheckbox*/}
        {/*    onChange={(event: ChangeEvent<HTMLInputElement>) =>*/}
        {/*      handleOnChangeInput('noMiddleName', event.target.checked)*/}
        {/*    }*/}
        {/*    checked={personalInformation.noMiddleName}*/}
        {/*  />*/}
        {/*  <label className={styles['label']}> No middle name </label>*/}
        {/*</div>*/}
        <div className={styles['radio-buttons']}>
          <NglicRadioGroup
            title="Gender"
            radios={genderRadioGroups}
            radiosFlexDirection={
              platform === Platforms.PHONE
                ? FlexDirection.COLUMN
                : FlexDirection.ROW
            }
          />
        </div>
        <div className={styles['birthdayContainer']}>
          <DatePicker
            toolTip={
              !isMobile
                ? { message: 'Select your birthday', altText: '' }
                : undefined
            }
            label="Birthday"
            onChange={(newValue: string) => {
              handleOnChangeInput('birthday', newValue);
              handleErrorMessage(DemographicErrorsEnum.BIRTHDAY, newValue);
            }}
            error={errorMessage.Birthday}
          />
        </div>
        <div className={styles['ssnContainer']}>
          <TextInput
            onChange={(newValue: string) => {
              const strippedValue = newValue.replace(/-/g, '');
              handleOnChangeInput('ssn', strippedValue);
              handleErrorMessage(DemographicErrorsEnum.SSN, strippedValue);
            }}
            value={personalInformation.ssn}
            id={'ssn-input'}
            error={errorMessage.SSN}
            label="Social Security Number"
            toolTip={
              !isMobile
                ? { message: 'Enter Social Security Number', altText: '' }
                : undefined
            }
            inputComponent={SSNNumberMask}
          />
        </div>
        <LocationAutocompleteComponent
          baseInputId={'home_location_input'}
          setIsValid={setValidHomeAddress}
          value={homeAddress}
          onAddressChange={setHomeAddress}
        />

        <div className={styles['work-address-checkbox-container']}>
          <NglicCheckbox
            onChange={() =>
              setWorkAddressSameAsHomeAddress(!workAddressSameAsHomeAddress)
            }
            checked={workAddressSameAsHomeAddress}
          />
          <label className={styles['label']}>
            Work Address is the same as Home Address
          </label>
        </div>

        {!workAddressSameAsHomeAddress && (
          <LocationAutocompleteComponent
            baseInputId={'word_location_input'}
            addressType={AddressType.WORK}
            workAddressType
            value={workAddress}
            setIsValid={setValidWorkAddress}
            onAddressChange={setWorkAddress}
          />
        )}
        <div className={styles[!isMobile ? 'item' : 'mobile-phone-number']}>
          <PhoneInput
            onChange={(value: string) =>
              handleOnChangeInput('phoneNumber', value)
            }
            currentValue={personalInformation.phoneNumber}
          />
          <div className={styles['radio-buttons']}>
            <NglicRadioGroup
              radios={phoneNumberTypeRadioGroups}
              radiosFlexDirection={
                platform === Platforms.PHONE
                  ? FlexDirection.COLUMN
                  : FlexDirection.ROW
              }
            />
          </div>
        </div>
        {/*<div className={styles['singleSelect']}>*/}
        {/*  <NglicSimpleSelect*/}
        {/*    selectedOption={agentWorkingState}*/}
        {/*    onChange={(value) => {*/}
        {/*      handleOnChange(value);*/}
        {/*    }}*/}
        {/*    label={'Please Confirm Your Resident State'}*/}
        {/*    options={options || []}*/}
        {/*    loading={!states}*/}
        {/*  />*/}
        {/*</div>*/}
        <div className={styles['singleSelect']}>
          <NglicSimpleSelect
            selectedOption={communicationPreferece}
            onChange={(value) => {
              handleOnChangeCommunicationPreference(value);
            }}
            label={'Communication Preference'}
            options={communicationPreferenceOptions}
          />
        </div>
        <div className={styles['buttonContainer']}>
          <PrimaryButton
            text="Next"
            disabled={submitDisabled}
            onClick={handleSubmit}
          />
        </div>
      </div>
    </div>
  );
};

export const TrackedPersonalInformation: React.FC<any> =
  withTracker(PersonalInformation);
