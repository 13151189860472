import React, { useCallback, useState } from 'react';
import styles from './HierarchyModal.module.scss';
import AddCircleIcon from '@material-ui/icons/AddCircleOutline';
import { AgentHierarchyGroup } from './AgentHierarchyGroup';
import {
  NglicModal,
  NglicSimpleSelect,
  Option,
  PrimaryButton,
  SecondaryButton,
} from '@nglic/component-lib/build';
import {
  AgentProducer,
  AgentProduct,
  Product,
} from '../../../service/service.types';
import { ProductDropdown } from '../ProductDropdown/ProductDropdown';
import {
  SearchResultsDisplayType,
  SupervisorSearch,
} from '../SupervisorSearch/SupervisorSearch';
import { HierarchyUtil } from '../../../service/transformers/Hierarchy';
import getUuidByString from 'uuid-by-string';

export interface HierarchyModalProps {
  isOpen: boolean;
  description?: string;
  agentProducts?: AgentProduct[];
  newAgentLevels?: Option[];
  title?: string;
  saving?: boolean;
  onCancel: () => void;
  onClose?: () => void;
  product?: Product;
  showProductDropdown?: boolean;
  onSave: (
    updatedHierarchies: AgentProduct[],
    originalHierarchies: AgentProduct[],
  ) => void;
  levelZeroPositionMap?: Record<string, number>;
}

const genIdForAgentProduct = (agentProduct: AgentProduct) => {
  return getUuidByString(JSON.stringify(agentProduct));
};

export const HierarchyModal: React.FC<HierarchyModalProps> = ({
  isOpen,
  title,
  description,
  onSave,
  onCancel,
  agentProducts,
  saving,
  newAgentLevels,
  showProductDropdown,
  product,
}: HierarchyModalProps) => {
  const initProduct = {
    id: product?.id || '',
    name: product?.name || '',
    commissionLevel: product?.commissionLevel || '',
  };
  const [searchText, setSearchText] = useState<string>('');
  const [selectedAgent, setSelectedAgent] = useState<AgentProducer>();
  const [newAgentLevel, setNewAgentLevel] = useState<Option>({
    id: '',
    name: '',
  });
  const [selectedProduct, setSelectedProduct] = useState<Product>(initProduct);
  const [updatedHierarchies, setUpdatedHierarchies] = useState<AgentProduct[]>(
    agentProducts || [],
  );

  const sortCommissionLevelAscending = (agentProducts: AgentProduct[]) => {
    return [...agentProducts].sort(
      (a, b) => parseFloat(a.commissionLevel) - parseFloat(b.commissionLevel),
    );
  };

  // Keeps track of all level zeroes in the hierarchy
  const [levelZeroPositionMap, setLevelZeroPositionMap] = useState<
    Record<string, number>
  >({});
  React.useEffect(() => {
    if (!agentProducts) {
      return;
    }

    const positionMap = agentProducts.reduce((acc, node, index) => {
      if (node.commissionLevel === '0') {
        return {
          ...acc,
          [genIdForAgentProduct(node)]: index,
        };
      }
      return acc;
    }, {});

    setLevelZeroPositionMap(positionMap);
  }, [agentProducts]);

  // Update ordering based on where the comssion level zeroes should be
  React.useEffect(() => {
    setUpdatedHierarchies(
      HierarchyUtil.insertAgentProductAtPosition(
        agentProducts ?? [],
        updatedHierarchies,
        levelZeroPositionMap,
      ),
    );
  }, [levelZeroPositionMap]);

  // This is in respect to how the user sees the movement on the screen
  const onLevelZeroPositionMoveDown = useCallback(
    (agentProduct: AgentProduct) => {
      const oldLevelZeroPosition =
        levelZeroPositionMap[genIdForAgentProduct(agentProduct)];
      const secondToLastPosition = updatedHierarchies.length - 2;
      if (
        oldLevelZeroPosition === undefined ||
        oldLevelZeroPosition === secondToLastPosition
      ) {
        return;
      }

      const newLevelZeroPosition = oldLevelZeroPosition + 1;
      const takenPositions = Object.values(levelZeroPositionMap).filter(
        (val) => val !== oldLevelZeroPosition,
      );
      const newLevelZeroMap = Object.entries(levelZeroPositionMap).reduce(
        (acc, next) => {
          if (next[1] === newLevelZeroPosition) {
            return {
              ...acc,
              [next[0]]: HierarchyUtil.getNextLowestPosition(
                next[1],
                takenPositions,
              ),
            };
          }
          return {
            ...acc,
            [next[0]]: next[1],
          };
        },
        {},
      );

      setLevelZeroPositionMap({
        ...newLevelZeroMap,
        [genIdForAgentProduct(agentProduct)]: newLevelZeroPosition,
      });
    },
    [levelZeroPositionMap],
  );

  // This is in respect to how the user sees the movement on the screen
  const onLevelZeroPositionMoveUp = useCallback(
    (agentProduct: AgentProduct) => {
      const oldLevelZeroPosition =
        levelZeroPositionMap[genIdForAgentProduct(agentProduct)];
      const firstPosition = 0;
      if (
        oldLevelZeroPosition === undefined ||
        oldLevelZeroPosition === firstPosition
      ) {
        return;
      }

      const newLevelZeroPosition = oldLevelZeroPosition - 1;
      const takenPositions = Object.values(levelZeroPositionMap).filter(
        (val) => val !== oldLevelZeroPosition,
      );
      const newLevelZeroMap = Object.entries(levelZeroPositionMap).reduce(
        (acc, next) => {
          if (next[1] === newLevelZeroPosition) {
            return {
              ...acc,
              [next[0]]: HierarchyUtil.getNextHighestPosition(
                next[1],
                updatedHierarchies.length,
                takenPositions,
              ),
            };
          }
          return {
            ...acc,
            [next[0]]: next[1],
          };
        },
        {},
      );

      setLevelZeroPositionMap({
        ...newLevelZeroMap,
        [genIdForAgentProduct(agentProduct)]: newLevelZeroPosition,
      });
    },
    [levelZeroPositionMap],
  );

  const onAddNewAgent = React.useCallback(() => {
    if (!selectedAgent) {
      return;
    }
    if (newAgentLevel.id === '') {
      return;
    }

    const newAgent = {
      agentName: selectedAgent.name,
      producerId: selectedAgent.producerId,
      productId: selectedProduct.id,
      productName: selectedProduct.name,
      commissionLevel: newAgentLevel.id,
      agentId: selectedAgent.id,
    };

    let levelZeroMap = Object.entries(levelZeroPositionMap).reduce(
      (acc, next) => {
        return {
          ...acc,
          [next[0]]: next[1] + 1, // If an item was at the end of the list it should stay there
        };
      },
      {},
    );

    if (newAgent.commissionLevel === '0') {
      levelZeroMap = {
        ...levelZeroMap,
        [genIdForAgentProduct(newAgent)]: 0,
      };
    }

    const newData = sortCommissionLevelAscending([
      ...updatedHierarchies,
      newAgent,
    ]);
    setUpdatedHierarchies(
      HierarchyUtil.insertAgentProductAtPosition(
        agentProducts ?? [],
        sortCommissionLevelAscending(newData),
        levelZeroMap,
      ),
    );
    setLevelZeroPositionMap(levelZeroMap);
    setSelectedAgent(undefined);
    setSearchText('');
    setNewAgentLevel({
      id: '',
      name: '',
    });
  }, [updatedHierarchies, selectedAgent, newAgentLevel, levelZeroPositionMap]);

  const removeAgent = React.useCallback(
    (selectedAgent: AgentProduct) => {
      const newData = updatedHierarchies.reduce<AgentProduct[]>((acc, next) => {
        if (selectedAgent.id !== next.id) {
          return [...acc, next];
        }
        return acc;
      }, []);

      const levelZeroMap = Object.entries(levelZeroPositionMap)
        .sort((a, b) => a[1] - b[1])
        .reduce((acc, next, index, arr) => {
          const isRemovedNode = genIdForAgentProduct(selectedAgent) === next[0];

          const previousValuePositionZeroIndex =
            index !== 0 ? arr[index - 1][1] : undefined;
          if (
            previousValuePositionZeroIndex !== next[1] - 1 &&
            !isRemovedNode
          ) {
            return {
              ...acc,
              [next[0]]: next[1] === 0 ? 0 : next[1] - 1,
            };
          } else if (!isRemovedNode) {
            return {
              ...acc,
              [next[0]]: next[1],
            };
          }

          return acc;
        }, {});

      setUpdatedHierarchies(newData);
      setLevelZeroPositionMap(levelZeroMap);
    },
    [updatedHierarchies, levelZeroPositionMap],
  );

  const handleSave = useCallback(() => {
    setSelectedAgent(undefined);
    setSearchText('');
    setNewAgentLevel({
      id: '',
      name: '',
    });
    setUpdatedHierarchies(agentProducts || []);
    setSelectedProduct(initProduct);
    onSave(updatedHierarchies, agentProducts || []);
  }, [updatedHierarchies]);

  const handleCancel = () => {
    setUpdatedHierarchies(agentProducts || []);
    setSelectedAgent(undefined);
    setSearchText('');
    setSelectedProduct(initProduct);
    setNewAgentLevel({
      id: '',
      name: '',
    });
    onCancel();
  };

  const onCommissionLevelChange = React.useCallback(
    (agent: AgentProduct, newLevel: Option) => {
      let positionMap = levelZeroPositionMap;

      const newData = updatedHierarchies.reduce<AgentProduct[]>(
        (acc, next, index) => {
          const nextId = genIdForAgentProduct(next);
          if (genIdForAgentProduct(agent) === nextId) {
            const isLowestInHierarchy =
              [...(agentProducts || [])].shift() === next.id;
            const newAgent = {
              ...next,
              commissionLevel: newLevel.id,
              overrideFlag:
                !isLowestInHierarchy && next.commissionLevel !== newLevel.id,
            };

            if (newLevel.id === '0') {
              positionMap = {
                ...positionMap,
                [genIdForAgentProduct(newAgent)]: index,
              };
            }
            return [...acc, newAgent];
          }
          return [...acc, next];
        },
        [],
      );

      setUpdatedHierarchies(
        HierarchyUtil.insertAgentProductAtPosition(
          agentProducts ?? [],
          sortCommissionLevelAscending(newData),
          positionMap,
        ),
      );
      setLevelZeroPositionMap(positionMap);
    },
    [updatedHierarchies, levelZeroPositionMap],
  );
  const onAgentSelect = (agent: AgentProducer) => {
    setSearchText(`${agent.name} (${agent.producerId})`);
    setSelectedAgent(agent);
  };

  return (
    <NglicModal isOpened={isOpen}>
      <div className={styles['modal-content']}>
        <div className={styles['header']}>
          <p className={styles['title']}>{title}</p>
          <p className={styles['description']}>{description}</p>
        </div>

        {showProductDropdown && (
          <div className={styles['product-container']}>
            <ProductDropdown onProductSelect={setSelectedProduct} />
          </div>
        )}
        <p className={styles['section-title']}>Add to Hierarchy</p>
        <div className={styles['controls']}>
          <div className={styles['row-item-large']}>
            <SupervisorSearch
              product={selectedProduct}
              isDisabled={
                showProductDropdown ? selectedProduct.id === '' : false
              }
              placeHolderText={'Search for agent'}
              onAgentSelect={onAgentSelect}
              type={SearchResultsDisplayType.NAME}
              initSearchValue={searchText}
              includeInactive="true"
            />
          </div>
          <div className={styles['row-item']}>
            <NglicSimpleSelect
              selectedOption={newAgentLevel}
              onChange={setNewAgentLevel}
              label={'Select Level'}
              disabled={showProductDropdown ? selectedProduct.id === '' : false}
              options={newAgentLevels || []}
            />
          </div>
          <div
            className={styles['add-button-container']}
            onClick={onAddNewAgent}
          >
            <AddCircleIcon className={styles['add-icon']} />
            <p className={styles['add-hierarchy-text']}>Add to Hierarchy</p>
          </div>
        </div>
        <div className={styles['items-container']}>
          <div className={styles['items-title-container']}>
            <div className={styles['row-item-small']} />
            <div className={styles['row-item-large']}>
              <p className={styles['section-title']}>Name</p>
            </div>
            <div className={styles['row-item-x-large']}>
              <p className={styles['section-title']}>Commission Level</p>
            </div>
          </div>
          {updatedHierarchies.length > 0 && (
            <AgentHierarchyGroup
              agentProducts={updatedHierarchies}
              onLevelSelect={onCommissionLevelChange}
              onRemoveItem={removeAgent}
              onLevelZeroPositionMoveDown={onLevelZeroPositionMoveDown}
              onLevelZeroPositionMoveUp={onLevelZeroPositionMoveUp}
            />
          )}
        </div>
        <div className={styles['button-container']}>
          <SecondaryButton text="Cancel" onClick={handleCancel} />
          <PrimaryButton text="Save" onClick={handleSave} loading={saving} />
        </div>
      </div>
    </NglicModal>
  );
};
