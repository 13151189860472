import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// App Redux
import { AppState } from '../../../../rootReducer';
import { fetchReserveCommissionActivities } from './commissions.action';
import { fetchAgentReservePercentages } from '../../Contract/Review/reserves/reserves.action';

import { ReservesDrawer } from './Drawer/ReservesDrawer';

// Styles
import styles from './Comissions.module.scss';
import { StatementDownloadsTable } from './StatementDownloadsTable/StatementDownloadsTable';
import { CommissionsTable } from './CommissionsTable/CommissionsTable';
import { fetchAgentReserveFundBalance } from './Drawer/CurrentBalance/reserveFundBalance.action';
import { getUserInformation } from '../../../shared/selectors/getUserInformation';

export const Commissions: React.FC<{ routeToNext?: () => void }> = ({}) => {
  const dispatch = useDispatch();
  const [isReservesDrawerOpen, setIsReservesDrawerOpen] = useState(false);

  const basicInfo = useSelector((state: AppState) => getUserInformation(state));

  useEffect(() => {
    if (basicInfo) {
      dispatch(fetchAgentReservePercentages());
      dispatch(fetchAgentReserveFundBalance());
      // Prefetch for the reserves drawer
      dispatch(fetchReserveCommissionActivities(1, 5));
    }
  }, [basicInfo]);

  return (
    <div className={styles['root']}>
      <div className={styles['commissions-table-wrapper']}>
        <div className={styles['table-title-block']}>
          <h1 className={styles['table-title']}>Commissions</h1>
          <a
            className={styles['reserves-link']}
            onClick={() => setIsReservesDrawerOpen(true)}
          >
            VIEW RESERVES ACTIVITY
          </a>
        </div>
        <div className={styles['mobile-divider']}>
          <span>Activity</span>
        </div>
        <CommissionsTable />
      </div>
      <StatementDownloadsTable />
      <div className={styles['payment-notice']}>
        <p>NO PAYMENT WILL GENERATE IF UNDER $50.00</p>
        <p>
          If you have signed up for EFT, payments of $50.00 or more will be
          deposited three banking days later. If you are interested in signing
          up for EFT, please complete a direct deposit enrollment form.
        </p>
      </div>
      <ReservesDrawer
        isOpen={isReservesDrawerOpen}
        onClose={() => setIsReservesDrawerOpen(false)}
      />
    </div>
  );
};
