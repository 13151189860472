import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import {
  AddDocumentResponse,
  DocumentResponse,
} from '../../../../service/service.types';
import { AppState } from '../../../../rootReducer';
import { loadComplete, loadStarted } from '../../../shared/loading.action';
import { getAgentId } from '../../../shared/selectors/basicUserInfo.selector';
import { getCredentials } from '../../../shared/selectors/getCredentials';
import { AgentService } from '../../../../service/AgentService';
import { DocumentPendingUpload } from '../../Contract/DocumentUpload/document.types';
import { CustomError } from './documents.reducer';

export type UploadDocumentActionType =
  | 'DOCUMENT_GET_DOCUMENTS'
  | 'DOCUMENT_GET_DOCUMENTS_FAILURE'
  | 'DOCUMENT_ADDED_DOCUMENT'
  | 'DOCUMENT_UPLOADED_DOCUMENT'
  | 'DOCUMENT_CONFIRMED_DOCUMENT'
  | 'DOCUMENT_DELETED'
  | 'DOCUMENT_UPLOAD_FAILURE'
  | 'DOCUMENT_DELETE_FAILURE'
  | 'DOCUMENT_CLEAR_ERROR'
  | 'DOCUMENT_NONE'
  | 'DOCUMENT_ADD_STAGED_DOCUMENTS'
  | 'DOCUMENT_DELETE_STAGED_DOCUMENTS'
  | 'DOCUMENTS_CLEAR_ERROR';

export interface AddedStagedDocumentAction
  extends Action<UploadDocumentActionType> {
  type: 'DOCUMENT_ADD_STAGED_DOCUMENTS';
  stagedDocument: DocumentPendingUpload;
}

export interface DeleteStagedDocumentAction
  extends Action<UploadDocumentActionType> {
  type: 'DOCUMENT_DELETE_STAGED_DOCUMENTS';
  fileName: string;
}

export interface GetDocumentsAction extends Action<UploadDocumentActionType> {
  type: 'DOCUMENT_GET_DOCUMENTS';
  documents: DocumentResponse[];
}

export interface GetDocumentsFailureAction
  extends Action<UploadDocumentActionType> {
  type: 'DOCUMENT_GET_DOCUMENTS_FAILURE';
  error: Error;
}

export interface AddedDocumentAction extends Action<UploadDocumentActionType> {
  type: 'DOCUMENT_ADDED_DOCUMENT';
  document: DocumentResponse;
}

export interface UploadedDocumentAction
  extends Action<UploadDocumentActionType> {
  type: 'DOCUMENT_UPLOADED_DOCUMENT';
  document: DocumentResponse;
}

export interface ConfirmedDocumentAction
  extends Action<UploadDocumentActionType> {
  type: 'DOCUMENT_CONFIRMED_DOCUMENT';
  document: DocumentResponse;
}

export interface DeleteDocumentAction extends Action<UploadDocumentActionType> {
  type: 'DOCUMENT_DELETED';
  documentId: string;
}

export interface UploadDocumentFailureAction
  extends Action<UploadDocumentActionType> {
  type: 'DOCUMENT_UPLOAD_FAILURE';
  error: CustomError;
}

export interface DeleteDocumentFailureAction
  extends Action<UploadDocumentActionType> {
  type: 'DOCUMENT_DELETE_FAILURE';
  error: Error;
}

export interface ClearErrorDocumentAction
  extends Action<UploadDocumentActionType> {
  type: 'DOCUMENT_CLEAR_ERROR';
  documentName: string;
}

export interface ClearErrorDocumentsAction
  extends Action<UploadDocumentActionType> {
  type: 'DOCUMENTS_CLEAR_ERROR';
}

const createStagedAddDocumentAction = (
  stagedDocument: DocumentPendingUpload,
): AddedStagedDocumentAction => ({
  type: 'DOCUMENT_ADD_STAGED_DOCUMENTS',
  stagedDocument,
});

const createDeleteStagedDocumentAction = (
  fileName: string,
): DeleteStagedDocumentAction => ({
  type: 'DOCUMENT_DELETE_STAGED_DOCUMENTS',
  fileName,
});

const createGetDocumentsAction = (
  documents: DocumentResponse[],
): GetDocumentsAction => ({
  type: 'DOCUMENT_GET_DOCUMENTS',
  documents,
});

const createGetDocumentFailureAction = (
  error: Error,
): GetDocumentsFailureAction => ({
  type: 'DOCUMENT_GET_DOCUMENTS_FAILURE',
  error,
});

const createUploadDocumentFailureAction = (error: {
  error: Error;
  document: string;
}): UploadDocumentFailureAction => ({
  type: 'DOCUMENT_UPLOAD_FAILURE',
  error,
});

const createAddedDocumentAction = (
  document: DocumentResponse,
): AddedDocumentAction => ({
  type: 'DOCUMENT_ADDED_DOCUMENT',
  document,
});

const createUploadDocumentAction = (
  document: DocumentResponse,
): UploadedDocumentAction => ({
  type: 'DOCUMENT_UPLOADED_DOCUMENT',
  document,
});

const createConfirmedDocumentAction = (
  document: DocumentResponse,
): ConfirmedDocumentAction => ({
  type: 'DOCUMENT_CONFIRMED_DOCUMENT',
  document,
});

const createDeleteDocumentFailureAction = (
  error: Error,
): DeleteDocumentFailureAction => ({
  type: 'DOCUMENT_DELETE_FAILURE',
  error,
});

const createDeleteDocumentAction = (
  documentId: string,
): DeleteDocumentAction => ({
  type: 'DOCUMENT_DELETED',
  documentId,
});

export const createClearErrorDocumentAction = (
  documentName: string,
): ClearErrorDocumentAction => ({
  type: 'DOCUMENT_CLEAR_ERROR',
  documentName,
});

export const createClearErrorDocumentsAction =
  (): ClearErrorDocumentsAction => ({
    type: 'DOCUMENTS_CLEAR_ERROR',
  });

export const fetchDocuments =
  (): ThunkAction<void, AppState, any, any> => async (dispatch, getState) => {
    dispatch(loadStarted('DOCUMENT_UPLOAD'));

    const agentId = getAgentId(getState());
    const tokens = getCredentials(getState());

    if (!agentId) {
      dispatch(
        createGetDocumentFailureAction(new Error('basicInfo not initialized')),
      );
      dispatch(loadComplete('DOCUMENT_UPLOAD'));
      return;
    }

    try {
      const documents = await AgentService.getDocuments(agentId, tokens);
      dispatch(createGetDocumentsAction(documents));
    } catch (error) {
      dispatch(createGetDocumentFailureAction(error));
    }

    dispatch(loadComplete('DOCUMENT_UPLOAD'));
  };

export const uploadStagedDocument =
  (document: DocumentPendingUpload): ThunkAction<void, AppState, any, any> =>
  async (dispatch) => {
    dispatch(loadStarted('DOCUMENT_UPLOAD'));

    dispatch(createStagedAddDocumentAction(document));

    dispatch(loadComplete('DOCUMENT_UPLOAD'));
  };

export const deleteStagedDocument =
  (fileName: string): ThunkAction<void, AppState, any, any> =>
  async (dispatch) => {
    dispatch(loadStarted('DOCUMENT_UPLOAD'));

    dispatch(createDeleteStagedDocumentAction(fileName));

    dispatch(loadComplete('DOCUMENT_UPLOAD'));
  };

export const uploadDocument =
  (document: DocumentPendingUpload): ThunkAction<void, AppState, any, any> =>
  async (dispatch, getState) => {
    dispatch(loadStarted('DOCUMENT_UPLOAD'));
    const agentId = getAgentId(getState());
    const tokens = getCredentials(getState());
    const errors = getState().presentation.manageBusiness.documents.errors;
    const documents =
      getState().presentation.manageBusiness.documents.documents;

    if (!agentId) {
      dispatch(
        createUploadDocumentFailureAction({
          error: new Error('basicInfo not initialized'),
          document: document.file.name,
        }),
      );

      dispatch(loadComplete('DOCUMENT_UPLOAD'));
      return;
    }

    let addDocumentResponse: AddDocumentResponse | null = null;

    const errorExists = errors.some(
      (doc) => doc.document === document.file.name,
    );

    const docUploaded = documents.some(
      (doc) => doc.name === document.file.name,
    );

    try {
      if (docUploaded) {
        dispatch(loadComplete('DOCUMENT_UPLOAD'));
        return;
      }

      addDocumentResponse = await AgentService.addDocument(
        agentId,
        document.file.name,
        '',
        document.documentType,
        tokens,
      );

      dispatch(createAddedDocumentAction(addDocumentResponse.document));

      const formData = new FormData();
      formData.append(document.file.name, document.file);

      const uploadURL = addDocumentResponse.uploadURL;

      await fetch(uploadURL, {
        method: 'PUT',
        body: document.file,
      });

      dispatch(createUploadDocumentAction(addDocumentResponse.document));

      await AgentService.confirmDocument(
        agentId,
        addDocumentResponse.document.id,
        tokens,
      );

      dispatch(
        createConfirmedDocumentAction({
          ...addDocumentResponse.document,
          isConfirmed: true,
        }),
      );

      if (errorExists) {
        dispatch(createClearErrorDocumentAction(document.file.name));
      }
    } catch (error) {
      if (!errorExists) {
        dispatch(
          createUploadDocumentFailureAction({
            error: error,
            document: document.file.name,
          }),
        );
      }

      if (addDocumentResponse) {
        dispatch(deleteDocument(addDocumentResponse.document.id, false));
      }
    }

    dispatch(loadComplete('DOCUMENT_UPLOAD'));
  };

export const deleteDocument =
  (documentId: string, loading = true): ThunkAction<void, AppState, any, any> =>
  async (dispatch, getState) => {
    if (loading) {
      dispatch(loadStarted('DOCUMENT_DELETE'));
    }

    const agentId = getAgentId(getState());
    const tokens = getCredentials(getState());

    if (!agentId) {
      dispatch(
        createDeleteDocumentFailureAction(
          new Error('basicInfo not initialized'),
        ),
      );
      dispatch(loadComplete('DOCUMENT_DELETE'));
      return;
    }

    try {
      await AgentService.deleteDocument(agentId, documentId, tokens);
      dispatch(createDeleteDocumentAction(documentId));
    } catch (error) {
      dispatch(createDeleteDocumentFailureAction(error));
    }

    if (loading) {
      dispatch(loadComplete('DOCUMENT_DELETE'));
    }
  };
