import { Action } from 'redux';
import { DocumentResponse } from '../../../../service/service.types';
import {
  AddedDocumentAction,
  AddedStagedDocumentAction,
  ClearErrorDocumentAction,
  ConfirmedDocumentAction,
  DeleteDocumentAction,
  DeleteDocumentFailureAction,
  DeleteStagedDocumentAction,
  GetDocumentsAction,
  GetDocumentsFailureAction,
  UploadDocumentActionType,
  UploadDocumentFailureAction,
} from './documents.action';
import produce from 'immer';
import { DocumentPendingUpload } from '../../Contract/DocumentUpload/document.types';

export interface CustomError {
  error: Error;
  document: string;
}

export interface UploadDocumentInitState {
  stagedDocuments: DocumentPendingUpload[];
  documents: DocumentResponse[];
  errors: CustomError[];
}

export type UploadDocumentErrorState = {
  error: Error;
} & UploadDocumentInitState;

export type UploadSubPageState =
  | UploadDocumentInitState
  | UploadDocumentErrorState;

export const documentReducer = (
  state: UploadSubPageState = {
    documents: [],
    stagedDocuments: [],
    errors: [],
  },
  action: Action<UploadDocumentActionType>,
): UploadSubPageState => {
  return produce(state, (draftState: UploadSubPageState) => {
    switch (action.type) {
      case 'DOCUMENT_ADD_STAGED_DOCUMENTS':
        const stagedDocument = (action as AddedStagedDocumentAction)
          .stagedDocument;
        return {
          ...draftState,
          stagedDocuments: [...draftState.stagedDocuments, stagedDocument],
        };
      case 'DOCUMENT_DELETE_STAGED_DOCUMENTS':
        const fileName = (action as DeleteStagedDocumentAction).fileName;
        const newStagedDocuments = state.stagedDocuments.filter(
          (doc) => doc.file.name !== fileName,
        );
        return {
          ...draftState,
          stagedDocuments: newStagedDocuments,
        };
      case 'DOCUMENT_GET_DOCUMENTS':
        const documents = (action as GetDocumentsAction).documents;
        return {
          ...draftState,
          documents,
        };
      case 'DOCUMENT_ADDED_DOCUMENT':
        const document = (action as AddedDocumentAction).document;
        return {
          ...draftState,
          documents: [...draftState.documents, document],
        };
      case 'DOCUMENT_UPLOADED_DOCUMENT':
        return state;
      case 'DOCUMENT_CONFIRMED_DOCUMENT':
        const confirmedDocument = (action as ConfirmedDocumentAction).document;
        return {
          ...draftState,
          documents: [...state.documents].map((document) => {
            const doc = { ...document };
            if (doc.id === confirmedDocument.id) {
              doc.isConfirmed = true;
            }
            return doc;
          }),
        };
      case 'DOCUMENT_DELETED':
        const documentId = (action as DeleteDocumentAction).documentId;
        const newDocuments = state.documents.filter(
          (doc) => doc.id !== documentId,
        );
        return {
          ...draftState,
          documents: newDocuments,
        };
      case 'DOCUMENT_UPLOAD_FAILURE':
        const error = (action as UploadDocumentFailureAction).error;
        draftState = {
          ...draftState,
          errors: [...draftState.errors, error],
        };
        return draftState;
      case 'DOCUMENT_DELETE_FAILURE':
        draftState = {
          ...draftState,
          error: (action as DeleteDocumentFailureAction).error,
        };
        return draftState;
      case 'DOCUMENT_GET_DOCUMENTS_FAILURE':
        draftState = {
          ...draftState,
          error: (action as GetDocumentsFailureAction).error,
        };
        return draftState;
      case 'DOCUMENT_CLEAR_ERROR':
        const documentName = (action as ClearErrorDocumentAction).documentName;
        const newErrors = state.errors.filter(
          (item) => item.document !== documentName,
        );

        return {
          ...draftState,
          errors: newErrors,
        };
      case 'DOCUMENTS_CLEAR_ERROR':
        draftState = {
          ...draftState,
          errors: [],
        };
        return draftState;
      default:
        return state;
    }
  });
};
