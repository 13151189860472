import _ from 'lodash';
import { Middleware } from 'redux';
import { setGlobalError } from '../components/shared/error.action';
import { loadComplete, loadStarted } from '../components/shared/loading.action';
import { AppState } from '../rootReducer';
import { CredentialsService } from '../service/CredentialsService';
import { setCredentials } from '../components/shared/credentials.action';
import { JwtClient } from '../clients/JwtClient';
import { retryablePromise } from '../util/retryablePromise';
import { isResponse } from '../util/typeGuards';

const refreshAndSetTokens = (token: string, refreshToken: string, store) => {
  JwtClient.verifyAccessToken(token).then((idtoken) => {
    if (!idtoken) {
      CredentialsService.refreshTokens(refreshToken)
        .then((response) => {
          store.dispatch(
            setCredentials(
              response.accessToken,
              response.idToken,
              response.refreshToken,
            ),
          );
        })
        .catch(() => {
          store.dispatch(setCredentials('', '', ''));
        });
    }
  });
};

export const promiseMiddleware: Middleware<
  // eslint-disable-next-line @typescript-eslint/ban-types
  {},
  AppState
> =
  (store) =>
  (next) =>
  (
    action: {
      promise: () => Promise<any>;
      types: string[];
      type: string;
    } & Record<string, any>,
  ) => {
    const { promise, types } = action;
    if (!promise) {
      return next(action);
    }

    const updatedAction = removeFieldsFromAction(['types', 'promise'], action);

    const bypassGlobalError = _.get(action, 'bypassGlobalError', false);

    const [REQUEST, SUCCESS, FAILURE] = types;

    store.dispatch(loadStarted(REQUEST));

    next({ ...updatedAction, type: REQUEST });

    const { idToken, refreshToken } = store.getState().data.user.credentials;

    const runOnRetry = async (error): Promise<any> => {
      if (isResponse(error)) {
        if (error.status === 401 || error.status === 403) {
          await refreshAndSetTokens(idToken, refreshToken, store);
        }
      }
    };

    return retryablePromise({
      functionToRetry: promise,
      catchFunction: runOnRetry,
      shouldRetry: (error) =>
        (error as any).status === 401 || (error as any).status === 403,
    })
      .then((result) => {
        store.dispatch(loadComplete(REQUEST));
        next({ ...updatedAction, result, type: SUCCESS });
      })
      .catch((error) => {
        store.dispatch(loadComplete(REQUEST));
        if (!bypassGlobalError) {
          store.dispatch(setGlobalError(error));
        }
        next({ ...updatedAction, error, type: FAILURE });
      });
  };

const removeFieldsFromAction = (
  fields: string[],
  action: Record<string, any>,
) => {
  return Object.entries(action).reduce((acc, next) => {
    if (!fields.includes(next[0])) {
      return {
        ...acc,
        [next[0]]: next[1],
      };
    }
    return acc;
  }, {});
};
