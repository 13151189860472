import { Action } from 'redux';
import { Address, Agent, Phone } from '../../../service/service.types';
import { ThunkAction } from 'redux-thunk';
import { AppState } from '../../../rootReducer';
import { AgentService } from '../../../service/AgentService';
import { setGlobalError } from '../../shared/error.action';
import { loadComplete, loadStarted } from '../../shared/loading.action';
import { getCredentials } from '../../shared/selectors/getCredentials';
import { getUserInformation } from '../../shared/selectors/getUserInformation';

export type AddProfileActionTypes =
  | 'FETCH_USER_PROFILE_REQUEST'
  | 'FETCH_USER_PROFILE_SUCCESS'
  | 'FETCH_USER_PROFILE_FAILURE'
  | 'FETCH_USER_PROFILE_CLEAR'
  | 'EDIT_PROFILE_SUCCESS'
  | 'EDIT_PROFILE_FAILURE'
  | 'NONE';

export type EditProfileActionTypes =
  | 'EDIT_PROFILE_SUCCESS'
  | 'EDIT_PROFILE_CLEAR'
  | 'EDIT_PROFILE_FAILURE'
  | 'NONE';

export interface FetchUserProfileSuccessAction
  extends Action<AddProfileActionTypes> {
  type: 'FETCH_USER_PROFILE_SUCCESS';
  result: Agent;
}

export interface FetchUserProfileFailureAction
  extends Action<AddProfileActionTypes> {
  type: 'FETCH_USER_PROFILE_FAILURE';
  error: Error;
}

export interface EditProfileSuccessAction
  extends Action<EditProfileActionTypes> {
  result: { success: boolean };
  type: 'EDIT_PROFILE_SUCCESS';
}

export interface EditProfileFailureAction
  extends Action<EditProfileActionTypes> {
  type: 'EDIT_PROFILE_FAILURE';
  result: Error;
}

export interface EditProfileClearAction extends Action<EditProfileActionTypes> {
  type: 'EDIT_PROFILE_CLEAR';
}

export interface FetchUserProfileClearAction
  extends Action<AddProfileActionTypes> {
  type: 'FETCH_USER_PROFILE_CLEAR';
}

export const fetchUserProfile =
  (): ThunkAction<void, AppState, any, any> =>
  async (dispatch, getState: () => AppState) => {
    const tokens = getCredentials(getState());
    const userInfo = getUserInformation(getState());

    dispatch(loadStarted('FETCH_USER_PROFILE_REQUEST'));
    if (!userInfo) {
      dispatch(setGlobalError(new Error('basicInfo not initialized')));
      dispatch(loadComplete('FETCH_USER_PROFILE_REQUEST'));
      return;
    }

    const promise = async (): Promise<Agent> => {
      return await AgentService.getAgent(
        userInfo.agentId,
        userInfo.branchCode,
        tokens,
      );
    };

    dispatch({
      types: [
        'FETCH_USER_PROFILE_REQUEST',
        'FETCH_USER_PROFILE_SUCCESS',
        'FETCH_USER_PROFILE_FAILURE',
      ],
      promise,
      bypassGlobalError: true,
    });
    loadComplete('FETCH_USER_PROFILE_REQUEST');
  };

export const editProfile =
  (params: {
    addresses: Address[];
    phones: Phone[];
    removedPhones: Phone[];
  }): ThunkAction<void, AppState, any, any> =>
  async (dispatch, getState) => {
    dispatch(loadStarted('EDIT_PROFILE'));
    const tokens = getCredentials(getState());

    const userInfo = getUserInformation(getState());

    if (!userInfo) {
      dispatch(setGlobalError(new Error('basicInfo not initialized')));
      dispatch(loadComplete('EDIT_PROFILE'));
      return;
    }

    const { agentId, branchCode } = userInfo;

    const promise = async (): Promise<Partial<{ success: boolean }>> => {
      return await AgentService.editProfile(
        agentId,
        branchCode,
        params,
        tokens,
      );
    };

    dispatch({
      types: ['EDIT_PROFILE', 'EDIT_PROFILE_SUCCESS', 'EDIT_PROFILE_FAILURE'],
      promise: promise,
      bypassGlobalError: true,
    });

    loadComplete('EDIT_PROFILE');
  };
