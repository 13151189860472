import produce from 'immer';
import { Action } from 'redux';
import { AgentReserveFundBalance } from '../../../../../../service/service.types';
import {
  ReserveFundBalanceActionTypes,
  FetchReserveFundBalanceSuccessAction,
} from './reserveFundBalance.action';

export type ReserveFundBalanceState =
  | AgentReserveFundBalance[]
  | 'NOT_INITIALIZED';

export const reserveFundBalanceReducer = (
  state: ReserveFundBalanceState = 'NOT_INITIALIZED',
  action: Action<ReserveFundBalanceActionTypes>,
): ReserveFundBalanceState | 'NOT_INITIALIZED' => {
  return produce(state, (draftState) => {
    switch (action.type) {
      case 'FETCH_RESERVE_FUND_BALANCE_SUCCESS':
        draftState = (action as FetchReserveFundBalanceSuccessAction).result;
        return draftState;
      case 'FETCH_RESERVE_FUND_BALANCE_FAILURE':
        draftState = 'NOT_INITIALIZED';
        return draftState;
      default:
        return state;
    }
  });
};
