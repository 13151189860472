export enum ProductsEnum {
  PRECOA1 = 'Precoa 1',
  PRECOA4 = 'Precoa 4',
  PRECOA5 = 'Precoa 5',
  PRECOA6 = 'Precoa 6',
  PRECOA7 = 'Precoa 7',
  PRECOA8 = 'Precoa 8',
  PRECOA9 = 'Precoa 9',
  PRECOA10 = 'Precoa 10',
  PRECOA11 = 'Precoa 11',
  ASSETGUARD2 = 'Assetguard v2.0',
  CAPSTONEPREFERRED2 = 'Capstone Preferred 2',
  CAPSTONE010 = 'Casptone 0.1',
  CAPSTONE050 = 'Casptone 0.5',
  CAPSTONE10 = 'Casptone 1.0',
  CAPSTONE15 = 'Casptone 1.5',
  CAPSTONE20 = 'Casptone 2.0',
  NEWCOMER = 'Newcomer',
  SERIES4 = 'Series 4',
  SERIES7 = 'Series 7',
  SERIES7TRUST = 'Series 7 Trust',
  SERIES9 = 'Series 9',
  SERIES14 = 'Series 14',
  SERIES15 = 'Series 15',
  SERIES20 = 'Series 20',
  SERIES64 = 'Series 64',
  TFSERIES = 'TF Series',
  SPECIALCONSIDERATIONS = 'Special Considerations',
}
