import React from 'react';
import styles from './TableRow.module.scss';

interface TableRowProps {
  text?: string | undefined;
  label?: string;
}

const TableRow: React.FC<TableRowProps> = ({ text, label }: TableRowProps) => {
  return (
    <div data-testid="table-row">
      <div className={styles['row']}>
        <div className={styles['key-container']}>
          <div className={styles['key']}>{label}</div>
        </div>
        <p className={styles['value']}>{text}</p>
      </div>
      <div className={styles['divider']} />
    </div>
  );
};
export default TableRow;
