import React from 'react';
import classNames from 'classnames';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { SecondarySearchInput } from '@nglic/component-lib/build';
import styles from './HierarchyTableHeader.module.scss';
import { ProductDropdown } from '../../../modules/ProductDropdown/ProductDropdown';
import { Product } from '../../../../service/service.types';
import { Column } from '../../../modules/DrawerTable/DrawerTable';
import { DrawerTableHeader } from '../../../modules/DrawerTable/DrawerTableHeader/DrawerTableHeader';
import AddIcon from '@material-ui/icons/Add';

export const HierarchyTableHeader: React.FC<{
  onProductSelect: (product: Product) => void;
  onSearchTextChange: (val: string) => void;
  onSubmitSearch: (val: string) => void;
  columns: Column[];
  openModal: () => void;
  onProductClear: () => void;
  expandAll: boolean | undefined;
  showExpandButton: boolean;
  onExpandAllClick: () => void;
}> = ({
  onProductSelect,
  onSearchTextChange,
  onSubmitSearch,
  columns,
  openModal,
  onProductClear,
  onExpandAllClick,
  expandAll,
  showExpandButton,
}) => {
  return (
    <React.Fragment>
      <div className={styles['search-container']}>
        <div>
          <div
            className={styles['input-container']}
            aria-label={'agent-search'}
          >
            <SecondarySearchInput
              submitSearch={onSubmitSearch}
              onTextChange={onSearchTextChange}
            />
          </div>
          <div
            className={styles['dropdown-container']}
            aria-label={'product-dropdown'}
          >
            <ProductDropdown
              onProductSelect={onProductSelect}
              onProductClear={onProductClear}
            />
          </div>
        </div>
        <div onClick={openModal}>
          <AddIcon />
        </div>
      </div>
      <div className={styles['table-header-container']}>
        <div
          onClick={() => {
            if (onExpandAllClick) {
              onExpandAllClick();
            }
          }}
          className={classNames([
            styles['arrow'],
            { [styles['hidden']]: !showExpandButton },
            { [styles['open']]: expandAll },
          ])}
        >
          <KeyboardArrowUpIcon />
        </div>
        <div className={styles['drawer-table-header-container']}>
          <DrawerTableHeader columns={columns} />
        </div>
      </div>
    </React.Fragment>
  );
};
