import React from 'react';
import { SecondarySearchInput } from '@nglic/component-lib/build';
import styles from './PoliciesListHeader.module.scss';

export const PoliciesListHeader: React.FC<{
  onSearchTextChange: (val: string) => void;
  onSubmitSearch: (val: string) => void;
}> = ({ onSearchTextChange, onSubmitSearch }) => {
  return (
    <div className={styles['root']}>
      <div className={styles['search']}>
        <SecondarySearchInput
          submitSearch={onSubmitSearch}
          onTextChange={onSearchTextChange}
          placeHolder="Search for policy"
        />
      </div>
    </div>
  );
};
