import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBackIos';
import AddIcon from '@material-ui/icons/Add';
import { PageEnum } from '@nglic/component-lib/build';

// Components
import { AppPageLayoutWrapper } from '../../AppPageLayoutWrapper';
import { AgentList } from './AgentList/AgentList';
import { AgentSubHeader } from './SubHeader/AgentSubHeader';
import { AgentInformation } from './AgentInformation/AgentInformation';

// Styles
import styles from './AgentManagement.module.scss';
import { Agent } from 'service/service.types';
import { AddAgentPages } from '../AddAgent/AddAgent';
import { withStyles } from '@material-ui/core';

const StyledIconButton = withStyles({
  root: {
    padding: 0,
    color: 'white',
  },
})(IconButton);

export const AgentManagement: React.FC = () => {
  const history = useHistory();
  const [selectedAgent, setSelectedAgent] =
    useState<Agent | undefined>(undefined);

  const onAgentSelected = useCallback(
    (agent?: Agent) => {
      setSelectedAgent(agent);
    },
    [selectedAgent],
  );

  const subHeaderComponent = () => {
    if (selectedAgent) {
      return (
        <div
          onClick={() => {
            onAgentSelected(undefined);
          }}
          style={{ display: 'flex', paddingTop: '1px', cursor: 'pointer' }}
        >
          <ArrowBackIcon style={{ fontSize: 15, color: 'white' }} />{' '}
          <div>Manage Agents</div>
        </div>
      );
    } else {
      return (
        <div className={styles['title']}>
          <span>Manage Agents</span>
          <StyledIconButton
            onClick={() =>
              history.push(`/agent/add-agent/${AddAgentPages.GENERAL_INFO}`)
            }
          >
            <AddIcon />
          </StyledIconButton>
        </div>
      );
    }
  };

  return (
    <AppPageLayoutWrapper
      title={'AGENT MANAGEMENT'}
      page={PageEnum.AGENT}
      subHeaderMobileConfig={subHeaderComponent()}
    >
      <div className={classNames(styles['root'])}>
        <div
          className={classNames(styles['left-pane'], {
            [`${styles['agent-selected']}`]: selectedAgent !== undefined,
          })}
        >
          <AgentSubHeader />
          <AgentList
            selectedAgent={selectedAgent}
            onAgentSelected={onAgentSelected}
          />
        </div>
        <div
          className={classNames(styles['right-pane'], {
            [`${styles['agent-selected']}`]: selectedAgent !== undefined,
          })}
        >
          <AgentInformation
            selectedAgent={selectedAgent}
            onAgentInformationClose={() => {
              onAgentSelected(undefined);
            }}
          />
        </div>
      </div>
    </AppPageLayoutWrapper>
  );
};
