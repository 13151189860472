import { Action } from 'redux';
import produce from 'immer';

import {
  FetchReportDataErrorAction,
  FetchReportDataSuccessAction,
  ReportDataActionType,
} from './reportData.action';

export interface ReportDataInitState {
  categories: string[];
  chartData: { name: string; data: any[] }[];
  tableData: {
    title: string;
    headers: { field: string; display: string; visible: boolean }[];
    rows: Record<string, string | number>[];
  };
  timeRequested: number | null;
}

export type ReportDataErrorState = {
  error: Error;
} & ReportDataInitState;

export type ReportDataState = ReportDataInitState | ReportDataErrorState;

export const reportDataReducer = (
  state: ReportDataInitState = {
    categories: [],
    chartData: [],
    tableData: {
      title: '',
      headers: [],
      rows: [],
    },
    timeRequested: null,
  },
  action: Action<ReportDataActionType>,
): ReportDataState => {
  return produce(state, (draftState: ReportDataState) => {
    switch (action.type) {
      case 'REPORT_DATA_FETCH__SUCCESS':
        const currentAction = action as FetchReportDataSuccessAction;
        draftState = {
          ...draftState,
          ...currentAction.reportData,
          timeRequested: currentAction.timeRequested,
        };
        return draftState;
      case 'REPORT_DATA_FETCH_FAILURE':
        draftState = {
          ...draftState,
          error: (action as FetchReportDataErrorAction).error,
          categories: [],
          chartData: [],
        };
        return draftState;
      default:
        return state;
    }
  });
};
