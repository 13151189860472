import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import {
  loadComplete,
  loadStarted,
} from '../../../../../shared/loading.action';
import { AppState } from '../../../../../../rootReducer';
import { getCredentials } from '../../../../../shared/selectors/getCredentials';
import { setGlobalError } from '../../../../../shared/error.action';
import { Claim } from './claim.types';
import { PolicyService } from '../../../../../../service/PolicyService';
import { Policy } from '../../../../../../service/service.types';

export type CreateClaimActionType =
  | 'CREATE_CLAIM_SUCCESS'
  | 'CREATE_CLAIM_FAILURE'
  | 'CREATE_CLAIM_ERROR'
  | 'CLEAR_CREATE_CLAIM';

export interface CreateClaimAction extends Action<CreateClaimActionType> {
  type: 'CREATE_CLAIM_SUCCESS';
}

export interface CreateClaimFailureAction
  extends Action<CreateClaimActionType> {
  type: 'CREATE_CLAIM_FAILURE';
  error: Error;
}

export interface ClearCreateClaimErrorAction
  extends Action<CreateClaimActionType> {
  type: 'CREATE_CLAIM_ERROR';
}

export interface ClearClaimAction extends Action<CreateClaimActionType> {
  type: 'CLEAR_CREATE_CLAIM';
}

export const createClearCreateClaimErrorAction =
  (): ClearCreateClaimErrorAction => ({
    type: 'CREATE_CLAIM_ERROR',
  });

export const clearClaim = (): ClearClaimAction => ({
  type: 'CLEAR_CREATE_CLAIM',
});

export const createClaim =
  (claimData: Claim, policy: Policy): ThunkAction<void, AppState, any, any> =>
  async (dispatch, getState) => {
    dispatch(loadStarted('CREATE_CLAIM'));

    const tokens = getCredentials(getState());

    if (!policy.id) {
      dispatch(setGlobalError(new Error('Policy not provided!')));
      dispatch(loadComplete('CREATE_CLAIM'));
      return;
    }

    const promise = async (): Promise<{ success: boolean }> => {
      return await PolicyService.createPolicyClaim(claimData, policy, tokens);
    };

    dispatch({
      types: ['CREATE_CLAIM', 'CREATE_CLAIM_SUCCESS', 'CREATE_CLAIM_FAILURE'],
      promise,
      bypassGlobalError: false,
    });

    loadComplete('CREATE_CLAIM');
  };
