import { TextInput } from '@nglic/component-lib/build';
import React from 'react';
import { PhoneNumberMask } from '../../Pages/Contract/PersonalInformation/PersonalInformation';
import { isPhoneValid } from '@nglic/utilities-ts/build';
export const PhoneInput: React.FC<{
  onChange: (value: string) => void;
  currentValue: string;
  label?: string;
  validateOnLoad?: boolean;
}> = ({ onChange, label, currentValue, validateOnLoad }) => {
  const handleChange = (value: string) => {
    const strippedValue = value.replace(/[()_-\s]/g, '');
    onChange(strippedValue);
  };

  const phoneValidation = (phone: string) => {
    const isValid = isPhoneValid(phone);
    return {
      error: !isValid,
      message: !isValid
        ? `Phone number must contain 10 digits and contain a valid area code`
        : '',
    };
  };

  return (
    <TextInput
      label={label || ''}
      value={currentValue}
      onChange={handleChange}
      inputComponent={PhoneNumberMask}
      validator={phoneValidation}
      validateOnLoad={validateOnLoad}
    />
  );
};
