import { BaseService } from './BaseService';
import { AppqoreClient } from '../clients/AppQoreClient';
import { ENV } from '../env';
import { ThysseClient } from '../clients/ThysseClient';

const AgentServicePaths = {
  APPQORE_LOGIN: () =>
    `/auth/saml/consume?idp=${ENV.APPQORE_IPD}&destination=%23/display/123­`,
  THYSSE_LOGIN: () => `/DSF/signin.aspx­`,
};

export class SSOService extends BaseService {
  static getAppqoreClient(): AppqoreClient {
    return new AppqoreClient();
  }

  static getThysseeClient(): AppqoreClient {
    return new ThysseClient();
  }

  static async appqoreLogin(saml: string): Promise<void> {
    return await SSOService.getAppqoreClient().post(
      `${ENV.APPQORE_BASE_URL}${AgentServicePaths.APPQORE_LOGIN()}`,
      { SAMLResponse: saml },
      {
        'access-control-allow-origin': ENV.APP_ORIGIN,
      },
    );
  }

  static async thysseLogin(token: string): Promise<void> {
    return await SSOService.getThysseeClient().post(
      `${AgentServicePaths.THYSSE_LOGIN()}`,
      { FlexBasedLoginSessionID: token },
      {
        'access-control-allow-origin': ENV.APP_ORIGIN,
      },
    );
  }
}
