import React, { useEffect, useState, useCallback } from 'react';
import styles from './LoginAsAgent.module.scss';

import logo from '../../../../assets/img/logo/logo-ngl.png';
import logo2x from '../../../../assets/img/logo/logo-ngl@2x.png';
import logo3x from '../../../../assets/img/logo/logo-ngl@3x.png';
import {
  MenuItemData,
  PrimaryButton,
  SearchableDropdown,
} from '@nglic/component-lib';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../rootReducer';
import SearchIcon from '@material-ui/icons/Search';
import { debounce } from 'lodash';
import { AgentService } from '../../../../service/AgentService';
import { getCredentials } from '../../../shared/selectors/getCredentials';
import { getBranchCode } from '../../../shared/selectors/basicUserInfo.selector';
import { useHistory } from 'react-router-dom';

export const LoginAsAgent: React.FC = () => {
  const history = useHistory();

  const [options, setOptions] = useState<MenuItemData[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [canSearch, setCanSearch] = useState<boolean>(false);
  const [searchInput, setSearchInput] = useState<string>('');
  const [disabled, setDisabled] = useState<boolean>(true);
  const tokens = useSelector((state: AppState) => getCredentials(state));
  const branchCode = useSelector((state: AppState) => getBranchCode(state));

  const [page, setCurrentPage] = useState<number>(1);
  const pageLimit = 50;

  const onMenuItemClick = (value: MenuItemData) => {
    setSearchInput(value.display);
    setDisabled(false);
  };

  const onInputChange = (val: string) => {
    if (val === '') {
      setCanSearch(false);
    }
    setSearchInput(val);
  };

  const onEnter = () => {
    setCanSearch(true);
    setOptions([]);
  };

  const searchForAgents = (value?: string) => {
    if (!branchCode || value === '') {
      setOptions([]);
      return;
    }
    setLoading(true);
    AgentService.getAllAgents(branchCode, tokens, page, pageLimit, value)
      .then((res) => {
        const menuData = res.agents.map((agent) => {
          return {
            id: agent.id,
            display: agent.name,
          };
        });
        setOptions(menuData);
        setLoading(false);
      })
      .catch((err) => {
        setOptions([]);
        setLoading(false);
      });
  };

  const debouncedSearch = useCallback(
    debounce((nextValue: string) => {
      if (canSearch) {
        searchForAgents(nextValue);
      }
    }, 300),
    [searchForAgents, canSearch],
  );

  useEffect(() => {
    debouncedSearch(searchInput);
  }, [searchInput, canSearch]);

  return (
    <div className={styles['root']} data-testid={'login-as-agent-component'}>
      <div className={styles['top-bar']} />
      <div className={styles['input-container']}>
        <div className={styles['logo-container']}>
          <img
            className={styles['responsive-img']}
            srcSet={`${logo}, ${logo2x} 2x, ${logo3x} 3x`}
          />
        </div>
        <div className={styles['header']}>Welcome to NGLink!</div>
        <div className={styles['description']}>
          Please select the agent you would like to login as
        </div>
        <div className={styles['search']}>
          <SearchableDropdown
            loading={loading}
            title={'Search for Agent'}
            placeHolder={'Search for Agent'}
            frontIcon={<SearchIcon />}
            options={options}
            onMenuItemClick={onMenuItemClick}
            inputValue={searchInput}
            onInputChange={onInputChange}
            onEnter={onEnter}
          />
        </div>
        <PrimaryButton
          text="Login As Agent"
          disabled={disabled}
          onClick={() => {
            history.replace('/agent');
          }}
        />
        <div className={styles['logout']}>Logout</div>
      </div>
    </div>
  );
};
