import React from 'react';
import { SecondarySearchInput } from '@nglic/component-lib/build';
import styles from './AgentSupportSearch.module.scss';

export const AgentSupportSearch: React.FC<{
  onSearchTextChange: (val: string) => void;
  onSubmitSearch: (val: string) => void;
}> = ({ onSearchTextChange, onSubmitSearch }) => {
  return (
    <div className={styles['root']}>
      <div className={styles['search']}>
        <SecondarySearchInput
          submitSearch={onSubmitSearch}
          onTextChange={onSearchTextChange}
        />
      </div>
    </div>
  );
};
