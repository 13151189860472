import { Tooltip } from '@material-ui/core';
import classNames from 'classnames';
import React from 'react';
import styles from './PolicyInformationCell.module.scss';

export enum CellSize {
  Quarter,
  Half,
  ThreeQuarters,
  Standard,
  Double,
}
export enum ValueDisplayType {
  Numeric,
  Standard,
  Bold,
}
export enum CellColor {
  Standard,
  Grey,
}

export const PolicyInformationCell: React.FC<{
  header: string;
  value?: string;
  cellSize?: CellSize;
  toolTipValue?: string;
  toolTipTitle?: string;
  valueDisplayType?: ValueDisplayType;
  cellColor?: CellColor;
}> = ({
  header,
  value,
  cellSize = CellSize.Standard,
  toolTipValue,
  toolTipTitle,
  valueDisplayType = ValueDisplayType.Standard,
  cellColor = CellColor.Standard,
}) => {
  const getStyleForSize = (size: CellSize) => {
    switch (size) {
      case CellSize.Double:
        return 'size-double';
      case CellSize.Standard:
        return 'size-standard';
      case CellSize.ThreeQuarters:
        return 'size-three-quarters';
      case CellSize.Half:
        return 'size-half';
      case CellSize.Quarter:
        return 'size-quarter';
    }
  };

  const getStyleForDisplayType = (valueDisplayType: ValueDisplayType) => {
    switch (valueDisplayType) {
      case ValueDisplayType.Bold:
        return 'value-display-bold';
      default:
        return 'value-display-standard';
    }
  };

  const getStyleForCellColor = (cellColor: CellColor) => {
    switch (cellColor) {
      case CellColor.Grey:
        return 'color-grey';
      default:
        return 'color-standard';
    }
  };

  return (
    <div
      className={classNames(
        styles['table-cell'],
        styles[getStyleForSize(cellSize)],
        styles[getStyleForCellColor(cellColor)],
      )}
    >
      <span className={styles['cell-content-header']}>{header}</span>
      <span
        className={classNames(
          styles['cell-content-value'],
          styles[getStyleForDisplayType(valueDisplayType)],
        )}
      >
        {toolTipValue ? (
          <Tooltip title={toolTipTitle ?? ''}>
            <span>{toolTipValue}</span>
          </Tooltip>
        ) : (
          value
        )}
      </span>
    </div>
  );
};
