import React, { CSSProperties } from 'react';
import { Drawer } from './Drawer/Drawer';
import styles from './DrawerTable.module.scss';
import { DrawerTableHeader } from './DrawerTableHeader/DrawerTableHeader';
import getUuidByString from 'uuid-by-string';
import { Pagination } from '../Pagination/Pagination';
import { Row } from './Row/Row';
import { NGlicSpinner } from '@nglic/component-lib/build';
import { isUndefined } from 'lodash';

export interface Column {
  displayName: string;
  dataLocation: string;
  dataDisplayValueLocation?: string;
  linkText?: string;
  onClick?: () => void;
  isLink?: boolean;
  // textAlignment?: 'LEFT' | 'CENTER' | 'RIGHT'
  style?: CSSProperties;
}

interface PaginationProps {
  numberOfPages: number;
  onPageClick: (page: number) => void;
  currentPage: number;
}

export const DrawerTable = <T,>(props: {
  columns: Array<Column>;
  tableData: Array<{ header: T; rows?: T[] }>;
  loading?: boolean;
  paginationProps?: PaginationProps;
  renderHeader?: (columns: Column[]) => React.ReactElement;
  renderOnEmpty?: () => React.ReactElement;
  expandAll?: boolean;
  onExpandAllClear: () => void;
  onManualExpandCollapseAll: (allExpanded: boolean) => void;
}) => {
  const {
    renderHeader,
    columns,
    tableData,
    loading,
    paginationProps,
    renderOnEmpty,
    expandAll,
    onExpandAllClear,
    onManualExpandCollapseAll,
  } = props;

  const [rowExpandState, setRowExpandState] = React.useState<boolean[]>([]);

  const resetExpanded = (tableData: Array<{ header: T; rows?: T[] }>) => {
    setRowExpandState(new Array(tableData.length).fill(false));
  };

  React.useEffect(() => {
    if (tableData.length > 0 && rowExpandState.length === 0) {
      resetExpanded(tableData);
    }
  }, [tableData]);

  React.useEffect(() => {
    if (isUndefined(expandAll)) {
      return;
    } else {
      setRowExpandState(new Array(tableData.length).fill(expandAll));
    }
  }, [expandAll]);

  React.useEffect(() => {
    if (
      rowExpandState.length > 0 &&
      rowExpandState.every((item) => rowExpandState[0] === item)
    ) {
      onManualExpandCollapseAll(rowExpandState[0]);
    }
  }, [rowExpandState]);

  const emptyContent = renderOnEmpty ? renderOnEmpty() : null;
  const content =
    tableData.length > 0 ? (
      <div className={styles['content-container']}>
        {tableData.map((data, index) =>
          !data.rows ? (
            <Row
              key={getUuidByString(`${JSON.stringify(data)}${index}`)}
              columns={columns}
              rowData={data.header}
            />
          ) : (
            <Drawer
              key={getUuidByString(`${JSON.stringify(data)}${index}`)}
              index={index}
              columns={columns}
              headerData={data.header}
              rowData={data.rows}
              open={rowExpandState[index]}
              onOpen={(index: number) => {
                onExpandAllClear();
                const newRowExpandState = [...rowExpandState];
                newRowExpandState[index] = !rowExpandState[index];
                setRowExpandState(newRowExpandState);
              }}
            />
          ),
        )}
        {paginationProps && (
          <div className={styles['pagination-container']}>
            <Pagination
              numberOfPages={paginationProps.numberOfPages}
              onPageClick={paginationProps.onPageClick}
              currentPage={paginationProps.currentPage}
            />
          </div>
        )}
      </div>
    ) : (
      emptyContent
    );

  const loadingSpinner = (
    <div className={styles['loading-container']}>
      <NGlicSpinner />
    </div>
  );

  return (
    <div className={styles['root']}>
      <div className={styles['header-container']}>
        {renderHeader ? (
          renderHeader(columns)
        ) : (
          <DrawerTableHeader columns={columns} />
        )}
      </div>
      {loading ? loadingSpinner : content}
    </div>
  );
};
