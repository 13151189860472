import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Option,
  PageEnum,
  PrimaryButton,
  TextInput,
  NglicMultipleSelect,
} from '@nglic/component-lib/build';
import styles from './AddUser.module.scss';

import { useHistory } from 'react-router-dom';

import { AppPageLayoutWrapper } from '../../AppPageLayoutWrapper';
import { EmailInput } from '../../../modules/EmailInput/EmailInput';
import { containsEmptyFieldV2, isEmailValid } from '@nglic/utilities-ts/build';
import { useDispatch, useSelector } from 'react-redux';
import { addUser, deleteResponse } from './addUser.action';
import { AppState } from '../../../../rootReducer';
import { getUserInformation } from '../../../shared/selectors/getUserInformation';
import { Role } from '../../../../service/service.types';
import _ from 'lodash';

const allRolesOption: Option[] = [
  {
    id: 'admin',
    name: 'Admin',
  },
  {
    id: 'contract-managers',
    name: 'Contract Managers',
  },
  {
    id: 'funeral-home-manager',
    name: 'Funeral Home Manager',
  },
  {
    id: 'funeral-home-staff',
    name: 'Funeral Home Staff',
  },
  {
    id: 'invite-users',
    name: 'Invite Users',
  },
  {
    id: 'support',
    name: 'Support',
  },
];

export const AddUser: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [shouldShowSuccessMessage, setShouldShowSuccessMessage] =
    useState<boolean>(false);
  const [shouldShowFailureMessage, setShouldShowFailureMessage] =
    useState<boolean>(false);

  const basicInfo = useSelector((state: AppState) => getUserInformation(state));

  const rolesOption = useMemo(() => {
    const roles = basicInfo?.roles;

    if (!roles) {
      return [];
    }

    if (roles.includes(Role.SUPPORT)) {
      return allRolesOption;
    } else {
      return allRolesOption.filter((role) => role.id !== Role.SUPPORT);
    }
  }, [basicInfo]);

  const [addUserButtonDisabled, setAddUserButtonDisabled] = useState(true);
  const [addUserData, setAddUserData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    mfaEnabled: true,
  });
  const { firstName, lastName, email, mfaEnabled } = addUserData;

  const [selectedRoles, setSelectedRoles] = useState<string[]>([]);

  const addUserResponse: boolean | undefined = useSelector(
    (state: AppState) => state.presentation.users.addUser.success,
  );
  const handleOnChangeInput = useCallback(
    (inputName: string, newValue: string | Option) => {
      setAddUserData({
        ...addUserData,
        [inputName]: newValue,
      });
    },
    [addUserData],
  );

  useEffect(() => {
    if (!_.isUndefined(addUserResponse)) {
      if (addUserResponse) {
        setAddUserData({
          firstName: '',
          lastName: '',
          email: '',
          mfaEnabled: true,
        });
        setSelectedRoles([]);

        setShouldShowSuccessMessage(true);

        setTimeout(() => {
          setShouldShowSuccessMessage(false);
        }, 3000);
      } else {
        setShouldShowFailureMessage(true);
        setTimeout(() => {
          setShouldShowFailureMessage(false);
        }, 3000);
      }

      setTimeout(() => {
        dispatch(deleteResponse());
      }, 800);
    }
  }, [addUserResponse]);

  useEffect(() => {
    const isValid = isEmailValid(addUserData.email);
    const values = Object.values(addUserData);
    setAddUserButtonDisabled(
      containsEmptyFieldV2(values as string[]) ||
        !isValid ||
        selectedRoles.length <= 0,
    );
  }, [addUserData, selectedRoles]);
  const handleChangeState = useCallback(
    (value: string) => {
      let updatedRoles: string[] = [];
      if (selectedRoles.includes(value)) {
        updatedRoles = selectedRoles.reduce<string[]>((acc, next) => {
          if (next !== value) {
            acc.push(next);
          }
          return acc;
        }, []);
      } else {
        updatedRoles = [value, ...selectedRoles];
      }
      setSelectedRoles(updatedRoles);
    },
    [selectedRoles],
  );
  const options = rolesOption?.reduce<any[]>((acc, next) => {
    return [
      ...acc,
      {
        id: next.id,
        name: next.name,
        checked: selectedRoles.includes(next.id),
        onChange: handleChangeState,
      },
    ];
  }, []);

  return (
    <AppPageLayoutWrapper page={'' as PageEnum} title={''}>
      <div data-testid={'add-user-as-support-user'}>
        <div className={styles['root']}>
          <div className={styles['title']}>Add User</div>
          <div className={styles['content']}>
            <div className={styles['text-input']}>
              <TextInput
                value={addUserData.firstName}
                label={'First Name'}
                onChange={(value) => handleOnChangeInput('firstName', value)}
              />
            </div>
            <div className={styles['text-input']}>
              <TextInput
                value={addUserData.lastName}
                label={'Last Name'}
                onChange={(value) => handleOnChangeInput('lastName', value)}
              />
            </div>
            <div className={styles['text-input']}>
              <EmailInput
                email={addUserData.email}
                label={'Email Address'}
                onChange={(value) => handleOnChangeInput('email', value)}
              />
            </div>
            <div className={styles['role-selection']}>
              <NglicMultipleSelect
                options={options || []}
                selectedOption={selectedRoles}
                label="Select Role(s)"
              />
            </div>
            <div className={styles['add-user-button']}>
              <PrimaryButton
                text={'ADD USER'}
                disabled={addUserButtonDisabled}
                onClick={() => {
                  dispatch(
                    addUser(
                      firstName,
                      lastName,
                      email,
                      selectedRoles,
                      mfaEnabled,
                    ),
                  );
                }}
              />
              <div
                className={styles['link-back']}
                onClick={() => history.push('/support')}
              >
                Return to Support Home
              </div>
            </div>
            {shouldShowSuccessMessage && (
              <div className={styles['success-message']}>
                Invite Sent Successfully
              </div>
            )}
            {shouldShowFailureMessage && (
              <div className={styles['failure-message']}>
                Error: Please review user information and try again
              </div>
            )}
          </div>
        </div>
      </div>
    </AppPageLayoutWrapper>
  );
};
