import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import { AppState } from '../../../../../rootReducer';
import { getCredentials } from '../../../../shared/selectors/getCredentials';
import { ReportDataService } from '../../../../../service/ReportDataService';
import { IReportFilters, ISupportedReportFilters } from './Report.types';
import { loadComplete, loadStarted } from '../../../../shared/loading.action';
import { getUserInformation } from '../../../../shared/selectors/getUserInformation';

export type ReportFiltersActionType =
  | 'SET_REPORT_FILTERS'
  | 'REPORT_FILTERS_FETCH_REQUEST'
  | 'REPORT_FILTERS_FETCH_SUCCESS'
  | 'REPORT_FILTERS_FETCH_FAILURE'
  | 'RESET_REPORT_FILTERS'
  | 'REPORT_FILTERS_NONE';

export interface FetchReportFiltersSuccessAction
  extends Action<ReportFiltersActionType> {
  result: ISupportedReportFilters;
}

export interface FetchReportFiltersErrorAction
  extends Action<ReportFiltersActionType> {
  error: Error;
}

export interface SetReportFiltersAction
  extends Action<ReportFiltersActionType> {
  type: ReportFiltersActionType;
  currentFilters: IReportFilters;
}

export interface ResetReportFiltersAction
  extends Action<ReportFiltersActionType> {
  type: ReportFiltersActionType;
}

export const setReportFilters = ({
  dateGrouping,
  dateFrom,
  dateTo,
  funeralHomeIds,
  producerIds,
}: IReportFilters): SetReportFiltersAction => ({
  type: 'SET_REPORT_FILTERS',
  currentFilters: {
    dateGrouping,
    dateFrom,
    dateTo,
    funeralHomeIds,
    producerIds,
  },
});

export const resetReportFilters = (): ResetReportFiltersAction => ({
  type: 'RESET_REPORT_FILTERS',
});

export const fetchReportFilters =
  (): ThunkAction<void, AppState, any, any> =>
  async (dispatch, getState: () => AppState) => {
    const tokens = getCredentials(getState());
    const userInfo = getUserInformation(getState());

    if (!userInfo?.agentId) {
      dispatch({
        type: 'REPORT_FILTERS_FETCH_FAILURE',
        error: 'No agent ID present for current agent',
      });
      return;
    }

    dispatch(loadStarted('REPORT_FILTERS_FETCH_REQUEST'));

    const promise = async (): Promise<ISupportedReportFilters> => {
      dispatch(loadStarted('FETCH_REPORT_FILTERS'));
      const result = await ReportDataService.getReportFilters(
        tokens,
        userInfo.agentId,
        userInfo.branchCode,
      );
      dispatch(loadComplete('FETCH_REPORT_FILTERS'));
      return result;
    };

    dispatch({
      types: [
        'REPORT_FILTERS_FETCH_REQUEST',
        'REPORT_FILTERS_FETCH_SUCCESS',
        'REPORT_FILTERS_FETCH_FAILURE',
      ],
      promise,
      bypassGlobalError: true,
    });
  };
