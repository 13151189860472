import { BFFClient } from '../clients/BFFClient';
import { PartialPolicy, Policy } from './service.types';
import { BaseService } from './BaseService';

const PolicyServicePaths = {
  FUNERAL_HOME_POLICIES: (
    page = 1,
    limit = 50,
    agentId: string,
    query?: string,
  ) =>
    `/ao/funeral-homes/policies?agent${agentId}?page=${page}&limit=${limit}${
      query ? `&query=${query}` : ''
    }`,
};

interface GetAllFuneralHomePoliciesParams {
  credentials: { accessToken: string; idToken: string };
  page: number;
  limit: number;
  agentId: string;
  query?: string;
}

export class FuneralHomeService extends BaseService {
  static getBFFClient(): BFFClient {
    return new BFFClient();
  }

  static async getAllFuneralHomePolicies({
    credentials,
    page,
    limit,
    query,
    agentId,
  }: GetAllFuneralHomePoliciesParams): Promise<{
    funeralHomePolicies: boolean;
    policies: PartialPolicy[];
    totalPolicies: number;
  }> {
    return await FuneralHomeService.getBFFClient().get<{
      funeralHomePolicies: boolean;
      policies: Policy[];
      totalPolicies: number;
    }>(
      PolicyServicePaths.FUNERAL_HOME_POLICIES(page, limit, agentId, query),
      FuneralHomeService.getBaseHeaders(
        credentials.accessToken,
        credentials.idToken,
      ),
    );
  }
}
