import React, { useState } from 'react';
import { TextInput } from '@nglic/component-lib/build';
import styles from './AgentInformation.module.scss';
import { containsEmptyFieldV2, isEmailValid } from '@nglic/utilities-ts/build';

import { NglicSimpleSelect, Option, PrimaryButton } from '@nglic/component-lib';
import { useDispatch, useSelector } from 'react-redux';
import { addAgentInformation } from './agentInformation.action';
import { AppState } from '../../../../../rootReducer';
import { EmailInput } from '../../../../modules/EmailInput/EmailInput';
import { MailingPreference } from '../../../../../service/service.types';

export const mailingPreferenceOptions: Option[] = [
  {
    id: MailingPreference.AGENT,
    name: MailingPreference.AGENT,
  },
  {
    id: MailingPreference.FUNERAL_HOME,
    name: MailingPreference.FUNERAL_HOME,
  },
  {
    id: MailingPreference.POLICY_OWNER,
    name: MailingPreference.POLICY_OWNER,
  },
];

export const AgentInformation: React.FC<{ routeToNext?: () => void }> = ({
  routeToNext,
}) => {
  const dispatch = useDispatch();
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const reduxAgentInfo = useSelector(
    (state: AppState) => state.presentation.addAgent.generalInformation,
  );
  const [agentInformationData, setAgentInformationData] = React.useState({
    firstName: '',
    lastName: '',
    email: '',
    mailingPreference: '',
  });

  React.useEffect(() => {
    if (reduxAgentInfo !== 'INCOMPLETE') {
      setAgentInformationData({
        firstName: reduxAgentInfo.firstName,
        lastName: reduxAgentInfo.lastName,
        email: reduxAgentInfo.email,
        mailingPreference: reduxAgentInfo.mailingPreference,
      });
    }
  }, [reduxAgentInfo]);

  React.useEffect(() => {
    const isValid = isEmailValid(agentInformationData.email);
    const values = Object.values(agentInformationData);
    setSubmitDisabled(containsEmptyFieldV2(values) || !isValid);
  }, [agentInformationData]);

  const handleOnChangeInput = React.useCallback(
    (inputName: string, newValue: string) => {
      setAgentInformationData({
        ...agentInformationData,
        [inputName]: newValue,
      });
    },
    [agentInformationData],
  );

  const handleSubmit = () => {
    const { firstName, lastName, email, mailingPreference } =
      agentInformationData;
    dispatch(
      addAgentInformation(firstName, lastName, email, mailingPreference),
    );
    routeToNext && routeToNext();
  };

  return (
    <div className={styles['root']} data-testid={'agent-information'}>
      <div className={styles['inputs']}>
        <TextInput
          id={'first-name'}
          value={agentInformationData.firstName}
          onChange={(newValue: string) =>
            handleOnChangeInput('firstName', newValue)
          }
          label="First Name"
        />
        <TextInput
          id={'last-name'}
          value={agentInformationData.lastName}
          onChange={(newValue: string) =>
            handleOnChangeInput('lastName', newValue)
          }
          label="Last Name"
        />
        <EmailInput
          email={agentInformationData.email}
          onChange={(newValue: string) =>
            handleOnChangeInput('email', newValue)
          }
        />
        <div className={styles['singleSelect']}>
          <NglicSimpleSelect
            selectedOption={{
              id: agentInformationData.mailingPreference,
              name: agentInformationData.mailingPreference,
            }}
            onChange={(value) => {
              handleOnChangeInput('mailingPreference', value.name);
            }}
            label={'Mailing Preference'}
            options={mailingPreferenceOptions}
          />
        </div>
      </div>
      <div className={styles['buttonContainer']}>
        <PrimaryButton
          text="Next"
          onClick={handleSubmit}
          disabled={submitDisabled}
        />
      </div>
    </div>
  );
};
