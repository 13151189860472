import { Action } from 'redux';
import { Reserves } from '../../../../../service/service.types';
import { ThunkAction } from 'redux-thunk';
import { AppState } from '../../../../../rootReducer';
import { AgentService } from '../../../../../service/AgentService';
import { setGlobalError } from '../../../../shared/error.action';
import { loadComplete, loadStarted } from '../../../../shared/loading.action';
import { getCredentials } from '../../../../shared/selectors/getCredentials';
import { getUserInformation } from '../../../../shared/selectors/getUserInformation';

export type ReservesActionTypes =
  | 'FETCH_RESERVES_REQUEST'
  | 'FETCH_RESERVES_SUCCESS'
  | 'FETCH_RESERVES_FAILURE'
  | 'NONE';

export interface FetchReservesSuccessAction
  extends Action<ReservesActionTypes> {
  type: 'FETCH_RESERVES_SUCCESS';
  result: Reserves;
}

export interface FetchReservesFailureAction
  extends Action<ReservesActionTypes> {
  type: 'FETCH_RESERVES_FAILURE';
  error: Error;
}

export const fetchAgentReservePercentages =
  (): ThunkAction<void, AppState, any, any> =>
  async (dispatch, getState: () => AppState) => {
    const tokens = getCredentials(getState());
    const userInfo = getUserInformation(getState());
    dispatch(loadStarted('FETCH_RESERVES_REQUEST'));

    if (!userInfo || userInfo === 'NOT_INITIALIZED') {
      dispatch(setGlobalError(new Error('basicInfo not initialized')));
      dispatch(loadComplete('FETCH_RESERVES_REQUEST'));
      return;
    }

    const { agentId, branchCode } = userInfo;

    const promise = async (): Promise<Reserves> => {
      return await AgentService.getAgentReservePercentages(
        agentId,
        branchCode,
        tokens,
      );
    };

    dispatch({
      types: [
        'FETCH_RESERVES_REQUEST',
        'FETCH_RESERVES_SUCCESS',
        'FETCH_RESERVES_FAILURE',
      ],
      promise,
      bypassGlobalError: true,
    });
    loadComplete('FETCH_RESERVES_REQUEST');
  };
