import React, { useCallback, useState } from 'react';
import { NglicSimpleSelect, Option } from '@nglic/component-lib/build';
import { AllContactType, Phone } from '../../../../service/service.types';
import DeleteIcon from '@material-ui/icons/Delete';
import ClearIcon from '@material-ui/icons/Clear';
import styles from './EditProfile.module.scss';
import { PhoneInput } from '../../../modules/PhoneInput/PhoneInput';
import { isPhoneValid } from '@nglic/utilities-ts/build';

import { useNglScreenSize } from '@nglic/component-lib';

export const PhoneSection: React.FC<{
  isRemovable: boolean;
  phoneOptions: Option[];
  currentContact: Phone;
  onChange: (params: { phone: Phone; valid: boolean }) => void;
  onRemove?: (contact: Phone) => void;
}> = ({
  currentContact,
  onChange,
  phoneOptions,
  isRemovable = true,
  onRemove,
}) => {
  const [phone, setPhone] = useState<string>(currentContact.phone);
  const initSelection = phoneOptions.find(
    (opt) => opt.id === currentContact.type,
  ) || {
    id: '',
    name: '',
  };
  const [selectedPhoneType, setPhoneType] = useState<Option>(initSelection);

  const onTypeChange = useCallback(
    (option: Option) => {
      onChange({
        phone: {
          id: currentContact.id,
          phone,
          type: option.id as AllContactType,
        } as Phone,
        valid: isPhoneValid(phone),
      });
      setPhoneType(option);
    },
    [phone, onChange],
  );

  const onPhoneNumberChange = useCallback(
    (phone: string) => {
      onChange({
        phone: {
          id: currentContact.id,
          phone,
          type: selectedPhoneType.id as AllContactType,
        } as Phone,
        valid: isPhoneValid(phone),
      });
      setPhone(phone);
    },
    [selectedPhoneType, onChange],
  );

  const { isMobile } = useNglScreenSize();

  return (
    <div className={styles['phone-section']}>
      <div className={styles['controls']}>
        <div className={styles['checkbox-container']}>
          {isMobile && isRemovable && (
            <div
              className={styles['remove-icon']}
              onClick={() => onRemove && onRemove(currentContact)}
            >
              <ClearIcon />
            </div>
          )}
        </div>
        <div>
          <div className={styles['row-item']}>
            <PhoneInput
              label={'Enter a phone number'}
              validateOnLoad={currentContact.phone.length > 0}
              currentValue={phone}
              onChange={onPhoneNumberChange}
            />
          </div>
          <div className={styles['row-item']}>
            <NglicSimpleSelect
              selectedOption={selectedPhoneType}
              onChange={onTypeChange}
              label={'Choose phone type'}
              options={phoneOptions}
            />
          </div>
        </div>
        <div className={styles['checkbox-container']}>
          {!isMobile && isRemovable && (
            <div
              className={styles['remove-icon']}
              onClick={() => onRemove && onRemove(currentContact)}
            >
              <DeleteIcon />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
