import { Role } from '../../../../../../service/service.types';

export interface Claim {
  dateOfDeath: string;
  funeralCost: string | undefined;
  causeOfDeath: CauseOfDeath | undefined;
  userSubmittedClaim: string;
}

export enum CauseOfDeath {
  NATURAL = 'NATURAL',
  ACCIDENTAL = 'ACCIDENTAL',
  SUICIDE = 'SUICIDE',
  HOMICIDE = 'HOMICIDE',
  UNKNOWN = 'UNKNOWN',
}

export const CREATE_CLAIM_USER_ROLES = [
  Role.ADMIN,
  Role.FUNERAL_HOME_MANAGER,
  Role.FUNERAL_HOME_STAFF,
];
