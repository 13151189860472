import styles from './UsernameAndPasswordLogin.module.scss';
import { TextInput, Link, PrimaryButton } from '@nglic/component-lib/build';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearPasswordError, goToLoginSequence, login } from '../login.action';
import logo from '../../../../assets/img/logo/logo-ngl.png';
import logo2x from '../../../../assets/img/logo/logo-ngl@2x.png';
import logo3x from '../../../../assets/img/logo/logo-ngl@3x.png';
import { AppState } from '../../../../rootReducer';
import { isLoginErrorState } from '../login.reducer';
import { ENV } from '../../../../env';
import { EmailInput } from '../../../modules/EmailInput/EmailInput';
import _ from 'lodash';

export const UsernameAndPasswordLogin: React.FC = () => {
  const dispatch = useDispatch();
  const passwordError = useSelector((state: AppState) => {
    if (isLoginErrorState(state.presentation.login)) {
      let message =
        "Oops, we don't seem to recognize that username or password. Please try again";
      if (state.presentation.login.accountLocked) {
        message =
          'You have reached the maximum login attempts. Your account is now locked. Please click "Forgot your password"';
      }
      return {
        error: true,
        message,
      };
    }

    return {
      error: false,
      message: '',
    };
  });

  const [creds, setCreds] = React.useState({
    username: ENV.USERNAME_AUTOFILL,
    password: ENV.PASSWORD_AUTOFILL,
  });
  const [submitDisabled, setSubmitDisabled] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const usernameOnChange = React.useCallback(
    (val: string) => {
      setCreds({
        ...creds,
        username: val,
      });
    },
    [creds],
  );
  const passwordOnChange = React.useCallback(
    (val: string) => {
      if (passwordError.error) {
        dispatch(clearPasswordError());
      }
      setCreds({
        ...creds,
        password: _.trim(val),
      });
    },
    [creds],
  );

  React.useEffect(() => {
    setSubmitDisabled(
      !Boolean(creds.username.length) || !Boolean(creds.password.length),
    );
  }, [creds]);

  const sectionsLoading = useSelector(
    (state: AppState) => state.presentation.loading.sectionsLoading,
  );

  React.useEffect(() => {
    setLoading(sectionsLoading.includes('LOGIN_SUBMISSION'));
  }, [sectionsLoading]);

  const submitLogin = React.useCallback(() => {
    if (creds.password.length && creds.username.length) {
      setLoading(true);
      dispatch(login(creds));
    }
  }, [creds]);

  const handleKeyDown = React.useCallback(
    (e): void => {
      if (e.key === 'Enter') {
        submitLogin();
      }
    },
    [submitLogin],
  );

  const gotToForgotPasswordScreen = () => {
    dispatch(goToLoginSequence('FORGOT_PASSWORD'));
  };

  return (
    <div className={styles['root']} data-testid={'username-and-password-login'}>
      <div onKeyDown={handleKeyDown}>
        <div className={styles['logo-container']}>
          <img
            className={styles['responsive-img']}
            srcSet={`${logo}, ${logo2x} 2x, ${logo3x} 3x`}
          />
        </div>
        <div
          className={styles['input-container']}
          aria-label={`username-field-${
            creds.username.length ? 'filled' : 'empty'
          }`}
        >
          <EmailInput
            id={'username'}
            onChange={usernameOnChange}
            label={'Username'}
            email={creds.username}
            validator={() => ({ error: false, message: '' })}
          />
        </div>
        <div
          className={styles['input-container']}
          aria-label={`password-field-${
            creds.password.length ? 'filled' : 'empty'
          }`}
        >
          <TextInput
            hiddenText={true}
            onChange={passwordOnChange}
            label={'Password'}
            id={'password'}
            error={passwordError}
            initErrorState={passwordError}
            value={creds.password ? creds.password : undefined}
          >
            Password
          </TextInput>
        </div>
        <div className={styles['link-container']}>
          <PrimaryButton
            loading={loading}
            disabled={submitDisabled}
            text={'Login'}
            onClick={loading ? undefined : submitLogin}
            ariaLabel={`submit-login-button-${
              submitDisabled ? 'inactive' : 'active'
            }`}
            onKeyDown={handleKeyDown}
          />
          <Link
            arialLabel={'forgot-password-link'}
            text={'Forgot your password?'}
            onClick={gotToForgotPasswordScreen}
          />
        </div>
      </div>
    </div>
  );
};
