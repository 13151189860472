import React, { forwardRef } from 'react';
import styles from './PrintQuoteToPDF.module.scss';
import { formatUSD, RatePlanItem } from './QuoteTable/QuoteTable';
import NGLLogo from './../../../../../../assets/img/logo/logo-ngl.png';
import { formatDate } from '../../../Policies/PolicyDetail/PolicyInformation/PolicyInformationTransform';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  withStyles,
} from '@material-ui/core';
import { Product } from '../../../../../../service/service.types';
import { Option } from '@nglic/component-lib/build';

interface QuoteAgentInformationProps {
  fullName: string;
  email: string;
  phoneNumber: string;
  clientName: string;
}

export interface FiltersDataProps {
  product: Product | undefined;
  state: Option | undefined;
  age: string | undefined;
  funeralAmount: string | undefined;
  bump: string;
  paymentMethod: Option | undefined;
}

interface PrintQuotePdfProps {
  agentInformation: QuoteAgentInformationProps;
  ratePlans: RatePlanItem[];
  filtersData: FiltersDataProps;
}

const CustomLeftHeaderTableCell = withStyles({
  body: {
    fontWeight: 'bold',
    fontSize: '12px',
    padding: '1px 1px 1px 1px',
    border: 'none',
  },
})(TableCell);

const CustomTableCell = withStyles({
  root: {
    padding: '1px',
    fontSize: '12px',
    textAlign: 'center',
    border: 'none',
  },
  head: {
    fontSize: '12px',
    fontWeight: 'bold',
    border: 'none',
    letterSpacing: '1.5px',
  },
})(TableCell);

export const PrintQuoteToPDF = forwardRef(
  (props: PrintQuotePdfProps, ref: any) => {
    const { agentInformation, ratePlans, filtersData } = props;
    return (
      <div ref={ref} className={styles['root']}>
        <div className={styles['logo']}>
          <img src={NGLLogo} alt="pdf-ngl-logo" />
        </div>
        <div>Date: {formatDate(new Date().toDateString(), true)}</div>

        <div className={styles['agent-information']}>
          <div className={styles['data-fields']}>
            This quote is specially prepared for {agentInformation.clientName}{' '}
            by:
          </div>
          <div className={styles['data-fields']}>
            Agent Name: {agentInformation.fullName}
          </div>
          <div className={styles['data-fields']}>
            Agent Email: {agentInformation.email}
          </div>
          <div className={styles['data-fields']}>
            Agent Phone:{' '}
            {agentInformation.phoneNumber.replace(
              /^(\d{3})(\d{3})(\d{4}).*/,
              '($1) $2-$3',
            )}
          </div>
        </div>
        <div className={styles['quote-information']}>
          <div className={styles['data-fields']}>
            State: {filtersData?.state?.name}
          </div>
          <div className={styles['data-fields']}>
            Product: {filtersData?.product?.name}
          </div>
          <div className={styles['data-fields']}>Age: {filtersData?.age}</div>
          <div className={styles['data-fields']}>
            Funeral Amount: {filtersData?.funeralAmount}
          </div>
          <div className={styles['data-fields']}>Bump: {filtersData?.bump}</div>
          <div className={styles['data-fields']}>
            Payment Method: {filtersData?.paymentMethod?.name}
          </div>
        </div>
        <div className={styles['table']}>
          <div className={styles['table-header']}>EFT</div>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <CustomTableCell />
                  {ratePlans.map((test) => {
                    return <CustomTableCell>{test.plan}</CustomTableCell>;
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <CustomLeftHeaderTableCell>
                    Funeral Cost
                  </CustomLeftHeaderTableCell>
                  {ratePlans.map((test) => {
                    return (
                      <CustomTableCell>
                        {formatUSD(test.funeralAmount)}
                      </CustomTableCell>
                    );
                  })}
                </TableRow>
                <TableRow>
                  <CustomLeftHeaderTableCell>
                    Face Amount
                  </CustomLeftHeaderTableCell>
                  {ratePlans.map((test) => {
                    return (
                      <CustomTableCell>
                        {formatUSD(test.faceAmount)}
                      </CustomTableCell>
                    );
                  })}
                </TableRow>
                <TableRow>
                  <CustomLeftHeaderTableCell>Premium</CustomLeftHeaderTableCell>
                  {ratePlans.map((test) => {
                    return (
                      <CustomTableCell>
                        {formatUSD(test.multiPayPremium)}
                      </CustomTableCell>
                    );
                  })}
                </TableRow>
                <TableRow>
                  <CustomLeftHeaderTableCell>
                    Total Due With App
                  </CustomLeftHeaderTableCell>
                  {ratePlans.map((test) => {
                    return (
                      <CustomTableCell>
                        {formatUSD(test.totalDueWithApp)}
                      </CustomTableCell>
                    );
                  })}
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <div className={styles['footer']}>
          This insurance quote is based on the information supplied on the date
          provided above and is not guaranteed. This is not an actual contract
          for insurance and actual premiums will be determined at the time of
          application
        </div>
      </div>
    );
  },
);
