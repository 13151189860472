import { applyMiddleware, createStore, combineReducers, Store } from 'redux';
import thunkMiddleware from 'redux-thunk';
import {
  credentialsReducer,
  CredentialsState,
} from './components/shared/credentials.reducer';
import {
  loginReducer,
  LoginState,
} from './components/Pages/Login/login.reducer';
import {
  agentReducer,
  AgentState,
} from './components/Pages/Agent/agent.reducer';
import { composeWithDevTools } from 'redux-devtools-extension';
import {
  loadingReducer,
  LoadingState,
} from './components/shared/loading.reducer';
import {
  ContractState,
  contractReducer,
} from './components/Pages/Contract/contract.reducer';
import {
  addAgentReducer,
  AddAgentState,
} from './components/Pages/Agent/AddAgent/addagent.reducer';
import {
  productReducer,
  ProductState,
} from './components/shared/product.reducer';
import {
  BasicInfoState,
  basicUserInfoReducer,
  MasqueradeUserState,
  masqueradeUserReducer,
} from './components/shared/basicUserInfo.reducer';
import { promiseMiddleware } from './middleware/promiseMiddleware';
import { errorReducer, ErrorState } from './components/shared/error.reducer';
import { stateReducer, StateState } from './components/shared/state.reducer';
import {
  addProfileReducer,
  AddProfileState,
  editProfileReducer,
  EditProfileState,
} from './components/Pages/Profile/profile.reducer';
import {
  manageBusinessReducer,
  ManageBusinessState,
} from './components/Pages/ManageBusiness/manageBusiness.reducer';
import {
  policiesReducer,
  PoliciesState,
} from './components/Pages/ManageBusiness/Policies/policies.reducer';
import {
  AgentTerminationState,
  terminateAgentReducer,
} from './components/Pages/Agent/agentTermination.reducer';
import {
  featureViewableReducer,
  FeautureVisibilityState,
} from './components/shared/features.reducer';
import {
  demographicInformationReducer,
  DemographicInformationState,
} from './components/Pages/ManageBusiness/Policies/PolicyDetail/demographicInformation.reducer';
import {
  reserveFundBalanceReducer,
  ReserveFundBalanceState,
} from './components/Pages/ManageBusiness/Commissions/Drawer/CurrentBalance/reserveFundBalance.reducer';
import {
  usersReducer,
  UsersState,
} from './components/Pages/AgentSupport/usersListReducer';

import {
  commissionsStatementReducer,
  CommissionStatementsState,
} from './components/Pages/ManageBusiness/Commissions/StatementDownloadsTable/commissions-statements.reducer';
import {
  announcementReducer,
  AnnouncementState,
} from './components/Pages/ManageBusiness/Overview/announcement.reducer';

export interface AppState {
  data: {
    user: {
      credentials: CredentialsState;
      basicInfo: BasicInfoState;
    };
    masqueradeUser: {
      basicInfo: MasqueradeUserState;
    };
    product: ProductState;
    state: StateState;
  };
  presentation: {
    features: FeautureVisibilityState;
    login: LoginState;
    loading: LoadingState;
    agent: AgentState;
    contract: ContractState;
    addAgent: AddAgentState;
    addProfile: AddProfileState;
    editProfile: EditProfileState;
    manageBusiness: ManageBusinessState;
    policies: PoliciesState;
    terminateAgent: AgentTerminationState;
    demographicInformation: DemographicInformationState;
    reserveFundBalance: ReserveFundBalanceState;
    commissionsStatement: CommissionStatementsState;
    announcements: AnnouncementState;
    users: UsersState;
    error: ErrorState;
  };
}

export const rootReducer = combineReducers({
  data: combineReducers({
    user: combineReducers({
      credentials: credentialsReducer,
      basicInfo: basicUserInfoReducer,
    }),
    masqueradeUser: combineReducers({ basicInfo: masqueradeUserReducer }),
    product: productReducer,
    state: stateReducer,
  }),
  presentation: combineReducers({
    login: loginReducer,
    loading: loadingReducer,
    agent: agentReducer,
    contract: contractReducer,
    addProfile: addProfileReducer,
    editProfile: editProfileReducer,
    addAgent: addAgentReducer,
    policies: policiesReducer,
    manageBusiness: manageBusinessReducer,
    error: errorReducer,
    features: featureViewableReducer,
    terminateAgent: terminateAgentReducer,
    demographicInformation: demographicInformationReducer,
    reserveFundBalance: reserveFundBalanceReducer,
    users: usersReducer,
    commissionsStatement: commissionsStatementReducer,
    announcements: announcementReducer,
  }),
});

export const getStore = (preloadedState?: Partial<AppState>): Store => {
  const middlewareEnhancer = applyMiddleware(
    thunkMiddleware,
    promiseMiddleware,
  );
  const composedEnhancers = composeWithDevTools(middlewareEnhancer);

  return createStore(rootReducer, preloadedState, composedEnhancers);
};
