import React from 'react';
import {
  NGlicSpinner,
  PageEnum,
  PrimaryButton,
  useNglScreenSize,
} from '@nglic/component-lib/build';
import LandingImage from '../../../assets/img/landingAgent/landingAgent.png';
import styles from './LandingPage.module.scss';
import { useSelector } from 'react-redux';
import { AppState } from '../../../rootReducer';
import { contractRoutes } from '../Contract/ContractPage';
import { AppPageLayoutWrapper } from '../AppPageLayoutWrapper';
import { useHistory } from 'react-router-dom';
import NGLlogo from '../../../assets/img/logo/logo-ngl.png';
import { getUserInformation } from '../../shared/selectors/getUserInformation';

export const LandingPage: React.FC = () => {
  const history = useHistory();
  const agentData = useSelector((state: AppState) => getUserInformation(state));
  const { isMobile } = useNglScreenSize();
  const LandingPageComponent = () => (
    <AppPageLayoutWrapper
      page={'' as PageEnum}
      title={''}
      data-testid={'landing-page-component'}
    >
      {isMobile && (
        <div>
          <div className={styles['logo-header']}>
            <img src={NGLlogo} alt="image" />
          </div>
          <div className={styles['root']}>
            <div className={styles['image-container']}>
              <img src={LandingImage} alt="image" />
            </div>
            <div className={styles['text-container']}>
              <p> Welcome {agentData?.firstName}!</p>
              <p>
                You can start your contract by clicking the button below. Once
                started, you will need to complete your contract in one session.
                In order to run a background report, you must provide a Social
                Security number. A routing and account number will be needed if
                you choose to receive your commissions electronically. Make sure
                you have this information easily available before starting.
              </p>
            </div>
            <div className={styles['button-container']}>
              <PrimaryButton
                text={'START YOUR CONTRACT'}
                onClick={() =>
                  history.push(contractRoutes.PERSONAL_INFORMATION)
                }
              />
            </div>
          </div>
        </div>
      )}
      {!isMobile && (
        <div className={styles['root']}>
          <div className={styles['text-container']}>
            <p> Welcome {agentData?.firstName}!</p>
            <p>
              You can start your contract by clicking the button below. Once
              started, you will need to complete your contract in one session.
              In order to run a background report, you must provide a Social
              Security number. A routing and account number will be needed if
              you choose to receive your commissions electronically. Make sure
              you have this information easily available before starting.
            </p>
          </div>
          <div className={styles['button-container']}>
            <PrimaryButton
              text={'START YOUR CONTRACT'}
              onClick={() => history.push(contractRoutes.PERSONAL_INFORMATION)}
            />
          </div>
          <div className={styles['image-container']}>
            <img src={LandingImage} alt="image" />
          </div>
        </div>
      )}
    </AppPageLayoutWrapper>
  );

  return !agentData ? (
    <div className={styles['loading-container']}>
      <NGlicSpinner />
    </div>
  ) : (
    <LandingPageComponent />
  );
};
