import React, { useEffect, useState } from 'react';
import { NglicRadioGroup } from '@nglic/component-lib';
import styles from './Approval.module.scss';
import { useDispatch } from 'react-redux';
import { addApprover } from './approver.action';
import { Agent } from '../../../../../../service/service.types';
import { TitleWithButtonInParallel } from '../../../../Contract/Review/TitleWithButtonInParallel';
import {
  SupervisorSearch,
  SearchResultsDisplayType,
} from '../../../../../modules/SupervisorSearch/SupervisorSearch';

interface ApprovalProps {
  onClick?: (event, value) => void;
}

export const Approval: React.FC<ApprovalProps> = ({ onClick }) => {
  const iHaveAuthority =
    'I have the authority to contract this agent and will provide my signature in DocuSign';
  const iDoNotHaveAuthority =
    'I do not have the authority to contract this agent and the contract should be sent to an approver';
  const [visible, setVisible] = useState<boolean>(false);
  const [approverAgent, setApproverAgent] =
    useState<Partial<Agent> | undefined>();
  const dispatch = useDispatch();

  const handleOnChange = (event) => {
    setVisible(event.target.value === iDoNotHaveAuthority);
  };

  useEffect(() => {
    dispatch(
      addApprover(approverAgent?.id, approverAgent?.name, approverAgent?.email),
    );
  }, [approverAgent]);

  const radios = [
    {
      label: iHaveAuthority,
      onChange: (event) => {
        handleOnChange(event);
        onClick?.(event, 'Yes');
      },
    },
    {
      label: iDoNotHaveAuthority,
      onChange: (event) => {
        handleOnChange(event);
        onClick?.(event, 'No');
      },
    },
  ];

  return (
    <div className={styles['root']} data-testid={'approval-review-component'}>
      <TitleWithButtonInParallel title="Approval" removeButton={true} />
      <div className={styles['radios']}>
        <NglicRadioGroup radios={radios} />
      </div>
      {visible && (
        <div className={styles['search-supervisor']}>
          <SupervisorSearch
            type={SearchResultsDisplayType.EMAIL}
            onAgentSelect={setApproverAgent}
          />
        </div>
      )}
    </div>
  );
};
