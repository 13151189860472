import { Action } from 'redux';
import { PaymentData } from './PaymentMethod.types';

export type PaymentMethodAction = 'ADD_PAYMENT_METHOD' | 'CLEAR_PAYMENT_METHOD';

export interface AddPaymentMethodAction extends Action<PaymentMethodAction> {
  type: 'ADD_PAYMENT_METHOD';
  payload: PaymentData | { payByCheck: true };
}

export interface ClearPaymentMethodAction extends Action<PaymentMethodAction> {
  type: 'CLEAR_PAYMENT_METHOD';
}

export const addPersonalInformation = (
  payload: PaymentData | { payByCheck: true },
): AddPaymentMethodAction => ({
  type: 'ADD_PAYMENT_METHOD',
  payload,
});

export const clearPaymentMethod = (): ClearPaymentMethodAction => ({
  type: 'CLEAR_PAYMENT_METHOD',
});
