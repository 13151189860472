import { BackgroundData } from '../components/Pages/Contract/Background/Background.types';
import { PaymentData } from '../components/Pages/Contract/PaymentMethod/PaymentMethod.types';

export interface Credentials {
  username: string;
  password: string;
}

export type ConfirmForgotPasswordCreds = Credentials & {
  tempPassword: string;
};

export type TempPasswordCreds = Credentials & {
  session: string;
};

export type RequestMFAEmail = {
  session: string;
  username: string;
};

export type CustomChallengeCreds = {
  code: string;
  session: string;
  username: string;
  metaData?: {
    [key: string]: string;
  };
};

export interface SuccessfulAuthResponse {
  accessToken: string;
  refreshToken: string;
  idToken: string;
}

export type CustomChallengeResponse = {
  challenge: 'CUSTOM_CHALLENGE';
  session: string;
};

export type NewPasswordRequiredResponse = {
  challenge: 'NEW_PASSWORD_REQUIRED';
  session: string;
};

export type UnsuccessfulLoginResponse = {
  message: 'Credentials invalid';
};

export type UnsuccessfulTempPasswordResetResponse = {
  message: 'Unable to reset temp password';
};

export type AccountLockedResponse = {
  message: 'Account Locked';
};

export type UnknownErrorResponse = {
  message: 'Unknown error occurred';
};

export type LoginResponseTypes =
  | SuccessfulAuthResponse
  | CustomChallengeResponse
  | NewPasswordRequiredResponse
  | UnsuccessfulLoginResponse
  | AccountLockedResponse
  | UnknownErrorResponse;

export function isSuccessfulAuthResponse(
  res: LoginResponseTypes | UnsuccessfulTempPasswordResetResponse,
): res is SuccessfulAuthResponse {
  return (res as SuccessfulAuthResponse).accessToken !== undefined;
}

export function isCustomChallengeResponse(
  res: LoginResponseTypes,
): res is CustomChallengeResponse {
  return (res as CustomChallengeResponse).challenge === 'CUSTOM_CHALLENGE';
}

export function isNewPasswordRequiredResponse(
  res: LoginResponseTypes,
): res is NewPasswordRequiredResponse {
  return (
    (res as NewPasswordRequiredResponse).challenge === 'NEW_PASSWORD_REQUIRED'
  );
}

export function isAccountLockedResponse(
  res: LoginResponseTypes,
): res is AccountLockedResponse {
  return (res as AccountLockedResponse).message === 'Account Locked';
}

export interface Product {
  id: string;
  name: string;
  commissionLevel: string;
}

export interface Contact {
  id?: string;
  name: string;
  value: string;
  type?: phoneNumberType | addressType | emailType | AllContactType;
  rawContact?: ServiceAddress | ServicePhone;
  primary?: boolean;
}

export interface ServiceAddress {
  id: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  addressType: AllContactType.HOME | AllContactType.WORK;
  zip: string;
  agentId: string;
}

export interface ServicePhone {
  id: string;
  areaCode: string;
  dialNumber: string;
  countryCode: string;
  phoneType:
    | AllContactType.HOME_FAX
    | AllContactType.HOME_LANDLINE
    | AllContactType.WORK_LANDLINE
    | AllContactType.WORK_FAX
    | AllContactType.CELL;
  agentId: string;
}

export interface Phone {
  id?: string;
  phone: string;
  primary?: boolean;
  type?: 'Cell' | 'Landline' | 'Fax' | AllContactType;
}

export enum phoneNumberType {
  Work = 'work',
  Cell = 'cell',
  Home = 'home',
  Fax = 'fax',
}

export enum AllContactType {
  HOME_LANDLINE = 'HOME_LANDLINE',
  WORK_LANDLINE = 'WORK_LANDLINE',
  HOME_FAX = 'HOME_FAX',
  WORK_FAX = 'WORK_FAX',
  CELL = 'CELL',
  HOME = 'HOME',
  WORK = 'WORK',
}

export const allContactTypeString = (type: AllContactType): string => {
  switch (type) {
    case AllContactType.HOME_LANDLINE:
      return 'Home';
    case AllContactType.WORK_LANDLINE:
      return 'Work';
    case AllContactType.HOME_FAX:
      return 'Home Fax';
    case AllContactType.WORK_FAX:
      return 'Work Fax';
    case AllContactType.CELL:
      return 'Cell';
    case AllContactType.HOME:
      return 'Home';
    case AllContactType.WORK:
      return 'Work';
  }
};

export enum addressType {
  WorkAddress = 'Work Address',
  HomeAddress = 'Home Address',
}

export enum emailType {
  Primary = 'primary',
  Secondary = 'secondary',
}

export interface State {
  code: string;
  desc: string;
}

export enum OnboardingStatus {
  NOT_REQUIRED = 'Not Required',
  INVITED = 'Invited',
  NOT_STARTED = 'Not Started',
  PENDING = 'Pending',
  COMPLETE = 'Complete',
  FAILED = 'Failed',
}

export interface OnboardingStatusItem {
  name: string;
  value: OnboardingStatus;
}

export interface ApprovingAgent {
  id: string;
  firstName: string;
  lastName: string;
  id3SearchName: string;
  email: string;
}

export interface StateLicense {
  type: string;
  id: string;
  producerTypeDesc: string;
  state: string;
  status: string;
  number: string;
  statusDate: Date;
  expirationDate?: Date;
  producerId: string;
  sequenceNumber: number;
}

export interface Agent {
  id: string;
  name: string;
  producerIds: string[];
  email: string;
  approvingAgent: ApprovingAgent;
  products: Product[];
  onboardingStatuses: OnboardingStatusItem[];
  contacts: Contact[];
  status: AgentStatus;
  stateLicenses: StateLicense[];
  amlCertificate: AmlCertificate;
}

export interface AgentHierarchy {
  id: string;
  name: string;
  producerId: string;
  email: string;
  approvingAgent: ApprovingAgent;
  products: Product[];
  onboardingStatuses: OnboardingStatusItem[];
  contacts: Contact[];
  status: AgentStatus;
  stateLicenses: StateLicense[];
  amlCertificate: AmlCertificate;
}
export interface AmlCertificate {
  id: string;
  completionDate: string;
  trainingProvider: string;
  expirationDate: string;
}
export interface AgentProducer {
  id: string;
  name: string;
  producerId: string;
  email: string;
}

export interface UserInfo {
  firstName: string;
  lastName: string;
  email: string;
  branchCode: string;
  marketingPartnerId: string;
  agentId: string;
  contractingStatus?: ContractStatus;
  roles: Role[];
}

export interface ContractStatus {
  agentContractingStatus: OnboardingStatus;
  contractStatus: OnboardingStatus;
  backgroundCheckStatus: OnboardingStatus;
  creditCheckStatus: OnboardingStatus;
  stateAppointmentStatus: OnboardingStatus;
}

export interface AgentProduct {
  id?: string;
  producerId?: string;
  agentId?: string;
  agentName: string;
  productId: string;
  productName: string;
  upLineAgentId?: string;
  upLineAgentName?: string;
  commissionLevel: string;
  overrideFlag?: boolean;
}

export interface Hierarchy {
  id: string;
  productId?: string;
  productName?: string;
  agents: AgentProduct[];
}

export interface Reserves {
  singlePay: string;
  creditCard: string;
  directBill: string;
  renewal: string;
  eft: string;
  minAmount: string;
  maxAmount: string;
}
export interface AgentWorkingStates {
  state: string;
}

export enum DocumentType {
  ANTI_MONEY_LAUNDERING_TRAINING = 'ANTI_MONEY_LAUNDERING_TRAINING',
  STATE_LICENSE = 'STATE_LICENSE',
  TRAINING_DOCUMENT = 'TRAINING_DOCUMENT',
  VOIDED_CHECK = 'VOIDED_CHECK',
  OTHER = 'OTHER',
}

export interface DocumentResponse {
  id: string;
  name: string;
  md5: string;
  agentId: string;
  createdAt: Date;
  type: DocumentType;
  isConfirmed: boolean;
}

export interface AddDocumentResponse {
  document: DocumentResponse;
  uploadURL: string;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ConfirmDocumentResponse {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface DeleteDocumentResponse {}

export interface CCPDocument {
  createdAt: Date;
  documentType: string;
  id: string;
  legacyDocumentId: string;
  legacyMessageId: string;
  onbaseDocumentId: string;
  sourceSystem: string;
  updatedAt: Date;
  data?: string;
}
export interface AllCCPDocumentsResponse {
  documents: CCPDocument[];
}

export interface CCPDocumentResponse {
  documentId: string;
  data: {
    mimeType: string;
    docStream: string;
  };
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface SignContractResponse {
  success: boolean;
}

export interface UpdateProfileResponse {
  success: boolean;
}

export interface Address {
  addressLine: string;
  addressLine2?: string;
  unit?: string;
  city: string;
  state: string;
  zip: string;
  type: 'WORK' | 'HOME';
}

export enum CommunicationPreference {
  WORK_PHONE = 'Work Phone',
  HOME_PHONE = 'Home Phone',
  MOBILE_PHONE = 'Mobile Phone',
  EMAIL = 'Email',
  CONTRACT_UPLINE = 'Contract Upline',
  TEXT = 'Text',
}

export enum MailingPreference {
  AGENT = 'Agent',
  FUNERAL_HOME = 'Funeral Home',
  POLICY_OWNER = 'Policy Owner',
}
export interface UpdateAgentBody {
  agentId: string;
  firstName: string;
  middleName?: string;
  noMiddleName: boolean;
  lastName: string;
  dob: string;
  sex: string;
  ssn: string;
  state: string;
  email: string;
  branchCode: string;
  approvingAgentEmail: string | null;
  approvingAgentName: string | null;
  marketingPartnerId: string;
  addresses: Address[];
  phoneNumber: string;
  phoneNumberType: string;
  payment: PaymentData;
  backgroundData: BackgroundData[];
  reserves?: Reserves;
  agentWorkingStates?: string;
  willReceiveCopyOfBackgroundCheck: boolean;
  communicationPreference: string | undefined;
}

export interface IDemographicInformation {
  id: string;
  firstName: string;
  lastName: string;
  dob?: string;
  pin_surrogate?: string;
  gender?: string;
  demographicId?: string;
}

export type BasePerson = Pick<
  IDemographicInformation,
  | 'id'
  | 'firstName'
  | 'lastName'
  | 'dob'
  | 'pin_surrogate'
  | 'gender'
  | 'demographicId'
>;

export type BaseBusiness = {
  id: string;
  name: string;
};

export type PartialPolicy = Pick<
  Policy,
  | 'id'
  | 'insured'
  | 'productId'
  | 'agentId'
  | 'producerId'
  | 'status'
  | 'effectiveDate'
  | 'agentName'
  | 'productCode'
  | 'policyAmount'
  | 'policyNumber'
>;

export interface EarlyPayOff {
  insuranceCompanyId: string;
  dateTimeKey: Date;
  quoteDate: Date;
  epoEffectiveDate: Date;
  policyIssueDate: Date;
  epoTotalPremium: string;
  epoChangeFee: number;
}

export interface Policy {
  id: string;
  policyOwner: {
    id: string;
    demographic_information?: BasePerson;
    business_information?: BaseBusiness;
    displayName?: string;
  };
  insured: BasePerson;
  beneficiary: {
    id: string;
    demographic_information?: BasePerson;
    business_information?: BaseBusiness;
    displayName?: string;
  };
  trust?: {
    id: string;
    business_information?: BaseBusiness;
    displayName?: string;
  };
  payor: BasePerson;
  productId: string;
  agentId: string;
  producerId: string;
  surrenderCharge: number;
  baseCashValue: number;
  currentDeathBenefit: string;
  effectiveDate: string;
  nonforfeitureOption: string;
  trustRelationship: string;
  dividendOption: string;
  currentPolicyValue: number;
  dividendDeposit: number;
  impactRiderCashValue: number;
  issueAge: number;
  dateOfDeath: string;
  puaCashValue: number;
  netSurrenderValue: number;
  insuranceCompanyId: string;
  status: string;
  policyNumber: string;
  earlyPayOff: number;
  policyAmount: number;
  loanBalance: number;
  totalPolicies: string;
  costBasis: number;
  paidUpOnDate: string;
  matureDate: string;
  loanAvailable: number;
  productCode: string;
  categoryName: string;
  agentFirstName?: string;
  agentMiddleName?: string;
  agentLastName?: string;
  agentName?: string;
  insuredName?: string;
  faceValue: number;
  funeralHomeName: string;
  earlyPayOffs?: EarlyPayOff[];
}

export interface UpdateAgentProfile {
  addresses: Address[];
  phones: Phone[];
  removedPhones: Phone[];
}

export interface PolicyPayment {
  payor: BasePerson;
  paymentDate: string;
  paymentMethod: string;
  paymentAmount: number;
}

export enum ClaimStatus {
  CANCELLED_FOR_REINSTATEMENT = 'CANCELLED_FOR_REINSTATEMENT',
  VOID = 'VOID',
  CANCELLED = 'CANCELLED',
  REOPENED = 'REOPENED',
  DENIED = 'DENIED',
  PENDING = 'PENDING',
  PARTIALLY_PAID = 'PARTIALLY_PAID',
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
  CLAIM_REQUEST_SUBMITTED = 'CLAIM_REQUEST_SUBMITTED',
}

export enum ClaimReason {
  MATURITY = 'MATURITY',
  DEATH = 'DEATH',
  ACCIDENTAL_DEATH_BENEFIT = 'ACCIDENTAL_DEATH_BENEFIT',
  SURRENDER = 'SURRENDER',
  EXCHANGE = 'EXCHANGE',
  ANNUITIZE = 'ANNUITIZE',
  NOT_TAKEN = 'NOT_TAKEN',
  OTHER = 'OTHER',
  TRANSFER_FULL_SURRENDER = 'TRANSFER_FULL_SURRENDER',
}

export enum CauseOfDeath {
  NATURAL = 'NATURAL',
  ACCIDENTAL = 'ACCIDENTAL',
  SUICIDE = 'SUICIDE',
  HOMICIDE = 'HOMICIDE',
  UNKNOWN = 'UNKNOWN',
}

export interface PolicyClaim {
  claimId: string;
  policyDetailId: string;
  legacyClaimId: string;
  claimStatus: ClaimStatus;
  claimReason: ClaimReason;
  insuranceCompanyId: string;
  coverageNumber: string;
  userSubmittedClaim: string;
  dateOfDeath: string;
  causeOfDeath: CauseOfDeath;
  createdAt: string;
  funeralCost: string;
}

export interface CommissionsActivity {
  id: string;
  commissionsActivityDate: string;
  rate: string;
  faceValue: string;
  earned: string;
  commissionsActivityTypeCode: string;
  commissionsActivityTypeDescription: string;
  policyId: string;
  productName: string;
  insuredName: string;
  policyAmount: string;
  sellingAgentFullName: string;
}

export enum Role {
  ADMIN = 'admin',
  BASIC_USER = 'basic-user',
  CONTRACT_MANAGERS = 'contract-managers',
  FUNERAL_HOME_MANAGER = 'funeral-home-manager',
  FUNERAL_HOME_STAFF = 'funeral-home-staff',
  SUPPORT = 'support',
  INVITE_USER = 'invite-users',
}

export interface User {
  firstName: string;
  lastName: string;
  userId: string;
  email: string;
  cognitoUuid: string;
  roles: Role[];
  identifier: string;
  organization: string;
}

export interface CommissionDetailFilters {
  dateFrom?: string;
  dateTo?: string;
  dateGrouping?: CommissionDateFilters;
}

export type CommissionDateFilters = 'custom' | 'day' | 'month' | 'year';

export enum AgentStatus {
  ACTIVE = 'Active',
  PENDING = 'Pending',
  PENDING_TERMINATION = 'Pending Termination',
  TERMINATED = 'Terminated',
}

export interface IAddress {
  addressLine1: string;
  addressLine2?: string;
  city: string;
  state: string;
  zip: string;
}

export interface IPhone {
  areaCode: string;
  dialNumber: string;
  countryCode: string;
}
export interface DemographicInformation {
  addresses: IAddress[];
  phones: IPhone[];
}

export interface ProducerFundReservesResponse {
  singlePayPercent: string;
  creditCardPercent: string;
  directBillPercent: string;
  renewalPercent: string;
  eftPercent: string;
  minAmount: string;
  maxAmount: string;
}

export interface AgentReserveFundBalance {
  fund_type: string;
  fund_value: number;
  balance_date: Date;
  producer_id: string;
  reserves: ProducerFundReservesResponse;
}

export enum AnnouncementLocationEnum {
  OVERVIEW_HEADER = 'Overview - Header',
  OVERVIEW_RIGHT_SIDE = 'Overview - Right Side',
  AGENT_MANAGEMENT = 'Agent Management',
  AGENT_PROFILE = 'Agent Profile',
  POLICY_MANAGEMENT = 'Policy Management',
  COMMISSIONS = 'Commissions',
  PERFORMANCE = 'Performance',
  DOCUMENT_UPLOAD = 'Document Upload',
}

export enum AnnouncementStatusEnum {
  PUBLISHED = 'published',
  DRAFT = 'draft',
  ARCHIVED = 'archived',
}

export type Announcement = {
  start_date: Date;
  Announcement_Body: string;
  Announcement_Location: AnnouncementLocationEnum[];
  Announcement_Title: string;
  AppQore_User: string;
  End_Date: string;
  Environment: string[];
  Image: [];
  Product: string;
  Start_Date: string;
  State: string;
  TPM_Branch_Code: string;
  status: AnnouncementStatusEnum;
};
