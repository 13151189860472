import React, { useEffect, useState } from 'react';
import {
  DocumentResponse,
  DocumentType,
} from '../../../../../service/service.types';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../../../rootReducer';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  withStyles,
} from '@material-ui/core';
import { fetchDocuments } from '../documents.action';
import { getAgentId } from '../../../../shared/selectors/basicUserInfo.selector';
import { Pagination } from '../../../../modules/Pagination/Pagination';
import { chunk } from 'lodash';
import { useNglScreenSize, formatDate } from '@nglic/component-lib/build';
import styles from './Document.module.scss';

interface Option {
  id: string;
  name: string;
  type: DocumentType;
}
const documentOptions: Option[] = [
  {
    id: 'Anti-Money Laundering Training',
    name: 'Anti-Money Laundering Training',
    type: DocumentType.ANTI_MONEY_LAUNDERING_TRAINING,
  },
  {
    id: 'State License',
    name: 'State License',
    type: DocumentType.STATE_LICENSE,
  },
  {
    id: 'Training Documents',
    name: 'Training Documents',
    type: DocumentType.TRAINING_DOCUMENT,
  },
  {
    id: 'Voided Check',
    name: 'Voided Check',
    type: DocumentType.VOIDED_CHECK,
  },
  {
    id: 'Other',
    name: 'Other',
    type: DocumentType.OTHER,
  },
];
const getOptionByType = (type: DocumentType) => {
  const option = documentOptions.find((option) => option.type === type);
  return option ?? { id: 'Other', name: 'Other', type: DocumentType.OTHER };
};

export const Document: React.FC = () => {
  const [currentPage, setCurrentPage] = useState<number>(1);

  const agentId = useSelector((state: AppState) => getAgentId(state));
  const dispatch = useDispatch();

  const { isMobile } = useNglScreenSize();

  const documents: DocumentResponse[] = useSelector(
    (state: AppState) => state.presentation.manageBusiness.documents.documents,
  );

  const HeaderTableCell = withStyles({
    root: {
      borderBottom: '1px solid #757982;',
      fontFamily: 'Arial',
      fontStyle: 'normal',
      fontSize: '16px',
      fontWeight: 'bold',
      color: '#757982',
    },
  })(TableCell);

  const ContentTableCell = withStyles({
    root: {
      borderBottom: 'none',
    },
  })(TableCell);

  const ContentTableRow = withStyles({
    root: {
      '&:nth-child(odd)': {
        backgroundColor: '#F2F7F9',
      },
    },
  })(TableRow);

  useEffect(() => {
    if (agentId) {
      dispatch(fetchDocuments());
    }
  }, [agentId]);

  const sortedDocs = documents
    .slice()
    .sort(
      (a, b) =>
        new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf(),
    );

  const itemsPerPage = 12;

  const chunks = chunk(sortedDocs, itemsPerPage);
  const currentTableData = chunks[currentPage - 1] || [];

  return (
    <div data-testid={'documents-page'}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <HeaderTableCell>File Name</HeaderTableCell>
              {!isMobile && <HeaderTableCell>File Type</HeaderTableCell>}
              <HeaderTableCell>Uploaded</HeaderTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentTableData.map((document) => {
              return (
                <ContentTableRow key={document.name}>
                  <ContentTableCell>
                    {document.name}
                    {isMobile && (
                      <div className={styles['document-type']}>
                        {getOptionByType(document.type).name}
                      </div>
                    )}
                  </ContentTableCell>
                  {!isMobile && (
                    <ContentTableCell>
                      {getOptionByType(document.type).name}
                    </ContentTableCell>
                  )}
                  <ContentTableCell>
                    {formatDate(document.createdAt, 'MM/DD/YYYY')}
                  </ContentTableCell>
                </ContentTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Pagination
          numberOfPages={chunks.length}
          onPageClick={setCurrentPage}
          currentPage={currentPage}
        />
      </div>
    </div>
  );
};
