import classNames from 'classnames';
import React from 'react';
import { Policy } from '../../../../../../../service/service.types';
import styles from './AssetGuardPolicyInformation.module.scss';
import { calculateAge, substringDate } from '../PolicyInformationTransform';
import { PolicyInformationRow } from '../PolicyInformationRow/PolicyInformationRow';
import {
  CellColor,
  CellSize,
  PolicyInformationCell,
  ValueDisplayType,
} from '../PolicyInformationCell/PolicyInformationCell';
import { formatNumberUSD } from '@nglic/utilities-ts/build';

export const AssetGuardPolicyInformation: React.FC<{ policy?: Policy }> = ({
  policy,
}) => {
  return (
    <div className={classNames(styles['root'])}>
      <PolicyInformationRow>
        <PolicyInformationCell
          header="POLICY NUMBER"
          value={policy?.policyNumber}
        />
        <PolicyInformationCell
          header="EFFECTIVE DATE"
          value={substringDate(policy?.effectiveDate)}
        />

        <PolicyInformationCell
          header="PAID UP ON"
          value={substringDate(policy?.paidUpOnDate)}
        />
        <PolicyInformationCell
          header="EARLY PAY OFF"
          value={formatNumberUSD(policy?.earlyPayOff)}
        />
        <PolicyInformationCell
          header="COST BASIS"
          value={formatNumberUSD(policy?.costBasis)}
        />
      </PolicyInformationRow>
      <PolicyInformationRow>
        <PolicyInformationCell
          header="DATE OF BIRTH"
          value={substringDate(policy?.insured?.dob)}
          cellSize={CellSize.ThreeQuarters}
        />
        <PolicyInformationCell
          header="AGE"
          value={calculateAge(policy?.insured?.dob)}
          cellSize={CellSize.Quarter}
        />
        <PolicyInformationCell
          header="BASE CASH VALUE"
          value={formatNumberUSD(policy?.baseCashValue)}
        />
        <PolicyInformationCell
          header="LOAN BALANCE"
          value={formatNumberUSD(policy?.loanBalance)}
        />
        <PolicyInformationCell
          header="SURRENDER CHARGE"
          value={formatNumberUSD(policy?.surrenderCharge)}
        />
        <PolicyInformationCell
          header="NET SURRENDER VALUE"
          value={formatNumberUSD(policy?.netSurrenderValue)}
        />
      </PolicyInformationRow>
      <PolicyInformationRow>
        <PolicyInformationCell
          header="CURRENT VALUE"
          value={formatNumberUSD(policy?.currentPolicyValue)}
          cellColor={CellColor.Grey}
        />
        <PolicyInformationCell
          header="NONFORFEITURE OPTION"
          value={policy?.nonforfeitureOption}
        />
        <PolicyInformationCell
          header="LOAN AVAILABLE"
          value={formatNumberUSD(policy?.loanAvailable)}
        />
        <PolicyInformationCell
          header="PUA CASH VALUE"
          value={formatNumberUSD(policy?.puaCashValue)}
        />
        <PolicyInformationCell
          header="IMPACT RIDER CASH VALUE"
          value={formatNumberUSD(policy?.impactRiderCashValue)}
        />
      </PolicyInformationRow>
      <PolicyInformationRow>
        <PolicyInformationCell
          header="FACE VALUE"
          value={formatNumberUSD(policy?.faceValue)}
          cellColor={CellColor.Grey}
        />
        <PolicyInformationCell
          header="OWNER"
          value={policy?.policyOwner?.displayName}
          cellSize={CellSize.Double}
          valueDisplayType={ValueDisplayType.Bold}
        />

        <PolicyInformationCell
          header="FUNERAL PROVIDER"
          value={policy?.funeralHomeName ?? ''}
          cellSize={CellSize.Double}
          valueDisplayType={ValueDisplayType.Bold}
        />
      </PolicyInformationRow>
      <PolicyInformationRow>
        <PolicyInformationCell
          header="MATURES/EXPIRES ON"
          value={substringDate(policy?.matureDate)}
        />
        <PolicyInformationCell
          header="TRUST"
          value={policy?.trust?.displayName}
          cellSize={CellSize.Double}
          valueDisplayType={ValueDisplayType.Bold}
        />
        <PolicyInformationCell
          header="BENEFICIARY"
          value={policy?.beneficiary?.displayName}
          cellSize={CellSize.Double}
          valueDisplayType={ValueDisplayType.Bold}
        />
      </PolicyInformationRow>
    </div>
  );
};
