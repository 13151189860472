import React, { useCallback, useState } from 'react';
import styles from './HierarchyGrouping.module.scss';
import {
  AgentProducer,
  Hierarchy,
  Product,
} from '../../../../../../service/service.types';
import { HierarchyList } from './HierarchyList/HierarchyList';
import {
  SupervisorSearch,
  SearchResultsDisplayType,
} from '../../../../../modules/SupervisorSearch/SupervisorSearch';
import { ProductDropdown } from '../../../../../modules/ProductDropdown/ProductDropdown';
import { NGlicSpinner } from '@nglic/component-lib';
import { Option } from '@nglic/component-lib/build';
import { HierarchyUpdate } from '../hierarchy.reducer';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../../../rootReducer';

interface HierarchyGroupingProps {
  id: string;
  ariaLabel?: string;
  searchParams: {
    product?: Product;
    agent?: AgentProducer;
  };
  hierarchyList: Hierarchy[];
  newAgentHierarchies: Record<string, HierarchyUpdate>;
  newAgentHierarchyCommissionMap: Record<string, Option>;
  onSearchForHierarchiesForSection: (params: {
    id: string;
    product?: Product;
    agent?: AgentProducer;
  }) => void;
  onAddToHierarchy: (params: {
    id: string;
    searchParams: {
      agent?: AgentProducer;
      product?: Product;
    };
    hierarchyList: Hierarchy[];
    newAgentHierarchyCommissionMap: Record<string, Option>;
    newAgentHierarchies: Record<string, HierarchyUpdate>;
  }) => void;
  onRemoveFromHierarchy: (params: {
    id: string;
    hierarchyList: Hierarchy[];
    hierarchyId: string;
  }) => void;
}

export const HierarchyGrouping: React.FC<HierarchyGroupingProps> = ({
  ariaLabel,
  id,
  searchParams,
  onAddToHierarchy,
  onRemoveFromHierarchy,
  onSearchForHierarchiesForSection,
}) => {
  const [searchParamsState, setSearchParamsState] =
    useState<{
      product?: Product;
      agent?: AgentProducer;
    }>(searchParams);
  const [loading, setLoading] = useState<boolean>(false);
  const addAgentHierarchyState = useSelector(
    (state: AppState) => state.presentation.addAgent.hierarchy,
  );

  React.useEffect(() => {
    onSearchForHierarchiesForSection({
      ...searchParamsState,
      id,
    });
  }, [searchParamsState]);

  const handleOnAgentSelect = useCallback(
    (agent: AgentProducer) => {
      setSearchParamsState({
        ...searchParamsState,
        agent,
      });
    },
    [searchParamsState],
  );

  const handleOnSelectProduct = useCallback(
    (product: Product) => {
      setSearchParamsState({
        ...searchParamsState,
        product,
      });
    },
    [searchParamsState],
  );

  const handleAddToHierarchy = (params: {
    hierarchyList: Hierarchy[];
    newAgentHierarchyCommissionMap: Record<string, Option>;
    newAgentHierarchies: Record<string, HierarchyUpdate>;
  }) => {
    onAddToHierarchy({
      id,
      ...params,
      searchParams: searchParamsState,
    });
  };

  const handleRemoveFromHierarchy = (params: {
    hierarchyList: Hierarchy[];
    hierarchyId: string;
  }) => {
    onRemoveFromHierarchy({
      id,
      ...params,
    });
  };

  return (
    <div className={styles['root']} aria-label={ariaLabel}>
      <div className={styles['inputs']}>
        <div className={styles['input-section']}>
          <ProductDropdown
            onProductSelect={(product) => handleOnSelectProduct(product)}
            value={searchParamsState?.product}
          />
        </div>
        <div className={styles['input-section']}>
          <SupervisorSearch
            type={SearchResultsDisplayType.NAME}
            isDisabled={!searchParamsState?.product}
            initSearchValue={searchParamsState.agent?.name}
            product={searchParamsState.product}
            onAgentSelect={(agent) => handleOnAgentSelect(agent)}
          />
        </div>
      </div>
      {searchParamsState.agent &&
        searchParamsState.product &&
        !addAgentHierarchyState[id]?.loadingHierarchies && (
          <HierarchyList
            product={searchParamsState.product}
            upLineAgent={searchParamsState.agent}
            sectionId={id}
            hierarchies={addAgentHierarchyState[id]?.hierarchyList ?? []}
            newAgentHierarchies={
              addAgentHierarchyState[id]?.newAgentHierarchies ?? {}
            }
            newAgentHierarchyCommissionMap={
              addAgentHierarchyState[id]?.newAgentHierarchyCommissionMap ?? {}
            }
            onAddAgentToHierarchy={handleAddToHierarchy}
            onRemoveAgentFromHierarchy={handleRemoveFromHierarchy}
          />
        )}
      {addAgentHierarchyState[id]?.loadingHierarchies && (
        <div className={styles['loading-container']}>
          <NGlicSpinner />
        </div>
      )}
    </div>
  );
};
