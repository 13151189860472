import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from '../../../rootReducer';
import { AgentProducer, Product } from '../../../service/service.types';
import { AgentService } from '../../../service/AgentService';
import SearchIcon from '@material-ui/icons/Search';
import { debounce } from 'lodash';
import { MenuItemData, SearchableDropdown } from '@nglic/component-lib/build';
import { getCredentials } from '../../shared/selectors/getCredentials';
import { getBranchCode } from '../../shared/selectors/basicUserInfo.selector';
import getUuidByString from 'uuid-by-string';

export enum SearchResultsDisplayType {
  NAME,
  EMAIL,
}

export const SupervisorSearch: React.FC<{
  type: SearchResultsDisplayType;
  product?: Product;
  isDisabled?: boolean;
  onAgentSelect: (agent: AgentProducer) => void;
  placeHolderText?: string;
  initSearchValue?: string;
  includeInactive?: string;
}> = ({
  type,
  isDisabled,
  product,
  onAgentSelect,
  placeHolderText,
  initSearchValue,
  includeInactive,
}) => {
  const [options, setOptions] = useState<MenuItemData[]>([]);
  const [searchInput, setSearchInput] = useState<string>(
    initSearchValue ? initSearchValue : '',
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [canSearch, setCanSearch] = useState<boolean>(false);
  const tokens = useSelector((state: AppState) => getCredentials(state));
  const branchCode = useSelector((state: AppState) => getBranchCode(state));

  React.useEffect(() => {
    setSearchInput(initSearchValue || '');
  }, [initSearchValue]);

  const resultsReducer = (acc, next) => {
    const display =
      type === SearchResultsDisplayType.NAME
        ? `${next.name} (${next.producerId ? next.producerId : 'PENDING'})`
        : next.email;

    return [
      {
        id: getUuidByString(`${next.id}${next.producerId}`),
        display,
        extraInfo: next,
      },
      ...acc,
    ];
  };

  const searchForAgents = (value?: string) => {
    if (!branchCode || value === '') {
      setOptions([]);
      return;
    }
    setLoading(true);
    AgentService.searchForSupervisor(
      branchCode,
      tokens,
      product?.id,
      value,
      includeInactive,
    )
      .then((res) => {
        setOptions(res.reduce<MenuItemData[]>(resultsReducer, []));
        setLoading(false);
      })
      .catch(() => {
        setOptions([]);
        setLoading(false);
      });
  };

  const debouncedSearch = useCallback(
    debounce((nextValue: string) => {
      if (canSearch) {
        searchForAgents(nextValue);
      }
    }, 300),
    [searchForAgents, canSearch],
  );

  const onMenuItemClick = (value: MenuItemData) => {
    setSearchInput(value.display);
    onAgentSelect(value.extraInfo as AgentProducer);
  };

  const onInputChange = (val: string) => {
    if (val === '') {
      setCanSearch(false);
    }
    setSearchInput(val);
  };

  const onEnter = () => {
    setCanSearch(true);
    setOptions([]);
  };

  React.useEffect(() => {
    debouncedSearch(searchInput);
  }, [searchInput, canSearch]);

  React.useEffect(() => {
    if (searchInput === '') {
      setOptions([]);
    }
  }, [searchInput]);

  return (
    <SearchableDropdown
      loading={loading}
      title={'Supervisor Search'}
      placeHolder={placeHolderText || 'Search for Supervisor'}
      frontIcon={<SearchIcon />}
      options={options}
      onMenuItemClick={onMenuItemClick}
      inputValue={searchInput}
      disabled={isDisabled}
      onInputChange={onInputChange}
      onEnter={onEnter}
    />
  );
};
