import React from 'react';
import styles from './DrawerTableHeader.module.scss';
import classNames from 'classnames';
import { Column } from '../DrawerTable';
import getUuidByString from 'uuid-by-string';

export const DrawerTableHeader: React.FC<{ columns: Array<Column> }> = ({
  columns,
}) => {
  const renderColumn = (column: Column) => {
    return (
      <div
        key={getUuidByString(JSON.stringify(column))}
        onClick={column.onClick}
        className={classNames(styles['column'])}
        style={column.style}
      >
        {column.displayName}
      </div>
    );
  };

  return (
    <div className={styles['root']}>
      {columns.map((column) => renderColumn(column))}
    </div>
  );
};
