import { Action } from 'redux';

export type LoadingActionType = 'LOADING_STARTED' | 'LOADING_COMPLETE';

export type SectionName = string;

export interface LoadingAction extends Action<LoadingActionType> {
  section: SectionName;
  requestId?: string;
}

export const loadStarted = (
  section: SectionName,
  requestId?: string,
): LoadingAction => ({
  type: 'LOADING_STARTED',
  section,
  requestId,
});

export const loadComplete = (section: SectionName): LoadingAction => ({
  type: 'LOADING_COMPLETE',
  section,
});
