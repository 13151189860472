import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import {
  Agent,
  AgentHierarchy,
  AgentProduct,
  Hierarchy,
  Product,
} from '../../../../service/service.types';
import { Column, DrawerTable } from '../../../modules/DrawerTable/DrawerTable';
import { HierarchyService } from '../../../../service/HierarchyService';
import { HierarchyTableHeader } from './HierarchyTableHeader';
import { EditHierarchy } from '../EditHierarchyModal/EditHierarchy';
import { chunk } from 'lodash';
import { CreateHierarchy } from '../CreateHierarchyModal/CreateHierarchy';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../rootReducer';
import { getCredentials } from '../../../shared/selectors/getCredentials';
import { getAgentId } from '../../../shared/selectors/basicUserInfo.selector';
import { ReviewChangesModal } from '../ReviewChangesModal/ReviewChangesModal';
import { ReactComponent as EmptyStateImage } from 'assets/img/hierarchy_empty_state.svg';
import styles from './HierarchyTable.module.scss';
import { HierarchyTableMobileHeader } from './HierarchyTableMobileHeader';
import { getUserInformation } from '../../../shared/selectors/getUserInformation';
import { producerIdFilterPredicate } from '@nglic/utilities-ts/build';

const columns: Column[] = [
  {
    displayName: 'Agent',
    dataLocation: 'agentName',
  },
  {
    displayName: 'Producer ID',
    dataLocation: 'producerId',
  },
  {
    displayName: 'Product',
    dataLocation: 'productName',
  },
  {
    displayName: 'Level',
    dataLocation: 'commissionLevel',
    dataDisplayValueLocation: 'commissionLevelDisplay',
  },
  {
    displayName: '',
    dataLocation: 'edit',
    isLink: true,
    linkText: 'EDIT',
  },
];

const mobileColumns: Column[] = [
  {
    displayName: 'Agent',
    dataLocation: 'agentName',
  },
  {
    displayName: 'Producer ID',
    dataLocation: 'producerId',
  },
  {
    displayName: 'Level',
    dataLocation: 'commissionLevel',
    dataDisplayValueLocation: 'commissionLevelDisplay',
  },
];

type HierarchyTableType = Partial<AgentProduct> & { edit?: () => void };

export const HierarchyTable: React.FC = () => {
  const itemsPerPage = 5;
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [hierarchies, setHierarchies] = useState<Hierarchy[]>([]);
  const [tableData, setTableData] = useState<
    Array<{ header: Partial<AgentHierarchy>; rows?: Partial<AgentHierarchy>[] }>
  >([]);

  const [filterCurrentTableData, setFilterCurrentTableData] = useState<
    Array<{ header: Partial<AgentHierarchy>; rows?: Partial<AgentHierarchy>[] }>
  >([]);

  const [loading, setLoading] = useState<boolean>(false);
  const [searchInput, setSearchInput] = useState<string>('');
  const [product, setProduct] = useState<Product | undefined>();

  const [hierarchy, setHierarchy] = useState<Hierarchy | undefined>();
  const [updatedAgentProducts, setUpdatedAgentProducts] =
    useState<AgentProduct[]>();
  const userInfo = useSelector((state: AppState) => getUserInformation(state));
  const tokens = useSelector((state: AppState) => getCredentials(state));
  const currentAgentId = useSelector((state: AppState) => getAgentId(state));

  const [editHierarchyModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [newHierarchyModalOpen, setNewHierarchyModalOpen] =
    useState<boolean>(false);
  const [reviewChangesModalOpen, setReviewChangesModalOpen] =
    useState<boolean>(false);

  const [expandAll, setExpandAll] =
    React.useState<boolean | undefined>(undefined);

  const search = React.useCallback(() => {
    if (searchInput.length === 0 || !userInfo) {
      return;
    }
    setLoading(true);

    HierarchyService.findHierarchies(
      searchInput,
      userInfo.branchCode,
      tokens,
      product?.id,
      'false',
    )
      .then((res) => {
        setHierarchies(res);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [product, searchInput]);

  React.useEffect(() => {
    search();
  }, [product]);

  React.useEffect(() => {
    setTableData(
      hierarchies.reduce<
        Array<{
          header: Partial<AgentProduct> & {
            edit: () => void;
          };
          rows?: Partial<AgentProduct>[];
        }>
      >((acc, next) => {
        const agents = [...next.agents.reverse()];
        acc.push({
          header: {
            ...agents[0],
            commissionLevel: agents[0].overrideFlag
              ? `${agents[0].commissionLevel} (OVR)`
              : agents[0].commissionLevel,
            edit: () => {
              setHierarchy(next);
              setEditModalOpen(true);
            },
          },
          rows: agents.length > 1 ? agents.slice(1) : undefined,
        });
        return acc;
      }, []),
    );
  }, [hierarchies]);

  const handleEditModalClose = () => {
    setEditModalOpen(false);
    setHierarchy(undefined);
    if (searchInput.length === 0 || !userInfo) {
      return;
    }
    setLoading(true);
    HierarchyService.findHierarchies(
      searchInput,
      userInfo.branchCode,
      tokens,
      product?.id,
      'false',
    )
      .then((res) => {
        setHierarchies(res);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleEditModalSave = (updatedHierarchies: AgentProduct[]) => {
    setEditModalOpen(false);
    setReviewChangesModalOpen(true);
    setUpdatedAgentProducts(updatedHierarchies);
  };

  const handleReviewModalCloseOnSave = () => {
    setReviewChangesModalOpen(false);
    setHierarchy(undefined);
    if (searchInput.length === 0 || !userInfo) {
      return;
    }
    setLoading(true);
    HierarchyService.findHierarchies(
      searchInput,
      userInfo.branchCode,
      tokens,
      product?.id,
      'false',
    )
      .then((res) => {
        setHierarchies(res);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleReviewModalClose = () => {
    setReviewChangesModalOpen(false);
    setEditModalOpen(true);
  };

  const chunks = chunk(tableData, itemsPerPage);
  const currentTableData = chunks[currentPage - 1] || [];

  const renderHeader = () => (
    <HierarchyTableHeader
      onProductSelect={setProduct}
      onSearchTextChange={setSearchInput}
      onSubmitSearch={search}
      columns={columns}
      openModal={() => {
        setNewHierarchyModalOpen(true);
      }}
      onProductClear={() => {
        setProduct(undefined);
      }}
      onExpandAllClick={() => {
        if (_.isUndefined(expandAll)) {
          setExpandAll(true);
        } else {
          setExpandAll(!expandAll);
        }
      }}
      expandAll={expandAll}
      showExpandButton={currentTableData.length > 0}
    />
  );

  const renderHeaderMobile = () => (
    <HierarchyTableMobileHeader
      onProductSelect={setProduct}
      onSearchTextChange={setSearchInput}
      onSubmitSearch={search}
      columns={mobileColumns}
      openModal={() => {
        setNewHierarchyModalOpen(true);
      }}
      onProductClear={() => {
        setProduct(undefined);
      }}
      numberOfHierarchies={hierarchies.length}
    />
  );

  const renderOnEmpty = () => {
    return (
      <div className={styles['empty-state']}>
        <div>Search for an agent to see results</div>
        <div>
          <EmptyStateImage />
        </div>
      </div>
    );
  };

  useEffect(() => {
    const filteredTableData = currentTableData
      .filter((elem) => {
        return producerIdFilterPredicate(elem.header.producerId);
      })
      .reduce<
        Array<{
          header: Partial<AgentHierarchy>;
          rows?: Partial<AgentHierarchy>[];
        }>
      >((acc, next) => {
        acc.push({
          header: next.header,
          rows: next.rows?.filter(
            (elem) => !elem.producerId?.startsWith('BON'),
          ),
        });
        return acc;
      }, []);
    setFilterCurrentTableData(filteredTableData);
  }, [tableData]);

  return (
    <div className={styles['root']}>
      <div className={styles['desktop']}>
        <DrawerTable<HierarchyTableType>
          renderHeader={renderHeader}
          loading={loading}
          columns={columns}
          tableData={filterCurrentTableData}
          paginationProps={{
            numberOfPages: chunks.length,
            onPageClick: setCurrentPage,
            currentPage,
          }}
          renderOnEmpty={renderOnEmpty}
          expandAll={expandAll}
          onExpandAllClear={() => {
            setExpandAll(undefined);
          }}
          onManualExpandCollapseAll={(allExpanded) => {
            setExpandAll(allExpanded);
          }}
        />
        <EditHierarchy
          currentAgentId={currentAgentId}
          tokens={tokens}
          open={editHierarchyModalOpen}
          handleClose={handleEditModalClose}
          hierarchy={hierarchy}
          onSave={handleEditModalSave}
        />
        <CreateHierarchy
          open={newHierarchyModalOpen}
          handleClose={() => setNewHierarchyModalOpen(false)}
        />
        <ReviewChangesModal
          open={reviewChangesModalOpen}
          handleClose={handleReviewModalClose}
          handleCloseOnSave={handleReviewModalCloseOnSave}
          originalHierarchy={hierarchy}
          updatedAgentProducts={updatedAgentProducts}
          tokens={tokens}
        />
      </div>
      <div className={styles['mobile']}>
        <DrawerTable<HierarchyTableType>
          renderHeader={renderHeaderMobile}
          loading={loading}
          columns={mobileColumns}
          tableData={currentTableData}
          paginationProps={{
            numberOfPages: chunks.length,
            onPageClick: setCurrentPage,
            currentPage,
          }}
          renderOnEmpty={renderOnEmpty}
          expandAll={expandAll}
          onExpandAllClear={() => {
            setExpandAll(undefined);
          }}
          onManualExpandCollapseAll={(allExpanded) => {
            setExpandAll(allExpanded);
          }}
        />
        <EditHierarchy
          currentAgentId={currentAgentId}
          tokens={tokens}
          open={editHierarchyModalOpen}
          handleClose={handleEditModalClose}
          hierarchy={hierarchy}
          onSave={handleEditModalSave}
        />
        <CreateHierarchy
          open={newHierarchyModalOpen}
          handleClose={() => setNewHierarchyModalOpen(false)}
        />
        <ReviewChangesModal
          open={reviewChangesModalOpen}
          handleClose={handleReviewModalClose}
          handleCloseOnSave={handleReviewModalCloseOnSave}
          originalHierarchy={hierarchy}
          updatedAgentProducts={updatedAgentProducts}
          tokens={tokens}
        />
      </div>
    </div>
  );
};
