import React from 'react';

import { Policy, PolicyClaim } from '../../../../../../service/service.types';
import { NglicModal } from '@nglic/component-lib';
import styles from './ClaimDetailsModal.module.scss';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { formatDate } from '../PolicyInformation/PolicyInformationTransform';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../../../rootReducer';
import { getUserInformation } from '../../../../../shared/selectors/getUserInformation';
import { isUserFuneralHome } from '@nglic/utilities-ts/build';

interface ClaimDetailsModalProps {
  claim: PolicyClaim | undefined;
  policy: Policy;
  open: boolean;
  handleClose: () => void;
}

export const ClaimDetailsModal: React.FC<ClaimDetailsModalProps> = ({
  claim,
  policy,
  open,
  handleClose,
}) => {
  const userInfo = useSelector((state: AppState) => getUserInformation(state));

  const displayDate = (value: string | undefined) => {
    return value ? value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '';
  };

  return (
    <NglicModal isOpened={open}>
      <div className={styles['root']} data-testid="claim-details-modal">
        <div className={styles['header']}>
          <div className={styles['claim-details']}>
            <p className={styles['title']}>
              {isUserFuneralHome(userInfo?.roles)
                ? 'Submit Death Notice'
                : 'Claim Details'}
            </p>
            <p className={styles['submitted-by']}>
              Submitted by <i>{claim?.userSubmittedClaim}</i> on{' '}
              {formatDate(claim?.createdAt, true)}{' '}
            </p>
          </div>
          <IconButton onClick={handleClose} className={styles['icon-button']}>
            <CloseIcon className={styles['close-icon']} />
          </IconButton>
        </div>
        <div className={styles['content']}>
          <div className={styles['insured-date-of-death']}>
            <div className={styles['insured']}>
              <p className={styles['data']}>{policy?.insuredName}</p>
              <p className={styles['subtitle']}>Insured</p>
            </div>
            <div className={styles['date-of-death']}>
              <p className={styles['data']}>
                {formatDate(claim?.dateOfDeath, true)}
              </p>
              <p className={styles['subtitle']}>Date of Death</p>
            </div>
          </div>
          <div className={styles['beneficiary']}>
            <p className={styles['data']}>
              {policy.beneficiary?.displayName}{' '}
              {policy.beneficiary?.displayName}
            </p>
            <p className={styles['subtitle']}>Beneficiary</p>
          </div>
          <div className={styles['cause-of-death-funeral-cost']}>
            <div className={styles['cause-of-death']}>
              <p className={styles['data']}>{claim?.causeOfDeath}</p>
              <p className={styles['subtitle']}>Cause of Death</p>
            </div>
            <div className={styles['funeral-cost']}>
              <p className={styles['data']}>
                $ {displayDate(claim?.funeralCost)}
              </p>
              <p className={styles['subtitle']}>Funeral Cost</p>
            </div>
          </div>
        </div>
      </div>
    </NglicModal>
  );
};
