import React, { useState } from 'react';
import Close from '@material-ui/icons/Close';
import { IconButton } from '@material-ui/core';
import styles from './Announcement.module.scss';

export const Announcement: React.FC<{
  Announcement_Body: string;
  Announcement_Title: string;
  Start_Date: Date;
  End_Date: Date;
}> = ({ Announcement_Body, Announcement_Title, Start_Date, End_Date }) => {
  const now = new Date(Date.now());
  const [open, setOpen] = useState(true);
  if (now < Start_Date || now > End_Date || !open) {
    return null;
  }
  return (
    <div className={styles['root']}>
      <div className={styles['container']}>
        <div className={styles['left']}>
          <div className={styles['title']}>{Announcement_Title}</div>
          <div className={styles['body']}>
            {Announcement_Body?.replace(/(<([^>]+)>)/gi, '')}
          </div>
        </div>
        <div className={styles['right']}>
          <IconButton
            onClick={() => {
              setOpen(false);
            }}
          >
            <Close className={styles['icon']} />
          </IconButton>
        </div>
      </div>
    </div>
  );
};
