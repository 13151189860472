import React from 'react';
import styles from './Pagination.module.scss';
import classNames from 'classnames';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

export const Pagination: React.FC<{
  numberOfPages: number;
  onPageClick: (page: number) => void;
  currentPage: number;
  includePagerArrows?: boolean;
}> = ({ numberOfPages, onPageClick, currentPage }) => {
  if (numberOfPages < 2) {
    return <div />;
  }

  const mapNumbersToComponents = (pages: number[]) => {
    return pages.map((index) => {
      const pageNumber = index + 1;
      const active = currentPage === pageNumber;
      const label = `page_${pageNumber}_${active ? 'active' : 'inactive'}`;
      return (
        <PageLink
          pageNumber={index + 1}
          onPageClick={onPageClick}
          label={label}
          active={active}
          key={label}
        />
      );
    });
  };

  const pages = new Array(numberOfPages);
  let content;
  if (numberOfPages <= 7) {
    content = (
      <div aria-label={'pagination-container'} className={styles['root']}>
        {mapNumbersToComponents([...pages.keys()])}
      </div>
    );
  } else {
    const beginningNumbers =
      currentPage <= 4 ? [...pages.keys()].slice(0, 5) : [0];
    const middleNumbers =
      currentPage > 4 && currentPage + 4 < numberOfPages
        ? [...pages.keys()].slice(currentPage - 2, currentPage + 3)
        : [];
    const endingNumbers =
      currentPage > 4 && currentPage + 5 > numberOfPages
        ? [...pages.keys()].slice(numberOfPages - 6)
        : [numberOfPages - 1];

    const beginning = mapNumbersToComponents(beginningNumbers);
    const middle = mapNumbersToComponents(middleNumbers);
    const end = mapNumbersToComponents(endingNumbers);
    content = (
      <div aria-label={'pagination-container'} className={styles['root']}>
        {beginning}
        <span className={styles['ellipsis']}>{'... '}</span>
        {middle}
        {middle.length > 0 ? (
          <span className={styles['ellipsis']}>{'... '}</span>
        ) : (
          ''
        )}
        {end}
      </div>
    );
  }

  return (
    <div className={styles['baseContainer']}>
      <div className={styles['prevArrowContainer']}>
        {currentPage > 1 && (
          <ChevronLeftIcon
            aria-label={'prev_page'}
            onClick={() => onPageClick(currentPage - 1)}
            className={styles['arrow']}
          />
        )}
      </div>
      {content}
      <div className={styles['nextArrowContainer']}>
        {currentPage < numberOfPages && (
          <ChevronRightIcon
            aria-label={'next_page'}
            onClick={() => onPageClick(currentPage + 1)}
            className={styles['arrow']}
          />
        )}
      </div>
    </div>
  );
};

const PageLink: React.FC<{
  pageNumber: number;
  active: boolean;
  label: string;
  onPageClick: (page: number) => void;
}> = ({ pageNumber, label, onPageClick, active }) => {
  return (
    <a
      aria-label={label}
      tabIndex={0}
      className={classNames(styles['page'], {
        [styles['active']]: active,
      })}
      onClick={() => onPageClick(pageNumber)}
    >
      {pageNumber}
    </a>
  );
};
