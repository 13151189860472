import React, { useState } from 'react';
import styles from './Documents.module.scss';
import classNames from 'classnames';
import { Upload } from './Upload/Upload';
import { Document } from './Documents/Document';

export enum DocumentSubPages {
  UPLOAD = 'upload',
  DOCUMENTS = 'documents',
}

export const DocumentsNavItems: React.FC<{
  item: any;
}> = ({ item }) => {
  return (
    <div
      onClick={item.onClick}
      style={{ cursor: 'pointer' }}
      aria-label={`${item.id}-button`}
    >
      {item.name.toString().toUpperCase()}
      <div className={classNames({ [styles.underline]: item.selected })} />
    </div>
  );
};

export const Documents: React.FC<{ routeToNext?: () => void }> = ({}) => {
  const [selectedSubPage, setSelectedSubPage] = useState('upload');

  const subNavItems = [
    {
      name: 'Upload',
      id: 'upload',
      onClick: () => setSelectedSubPage('upload'),
      selected: selectedSubPage === 'upload',
    },
    {
      name: 'Documents',
      id: 'documents',
      onClick: () => setSelectedSubPage('documents'),
      selected: selectedSubPage === 'documents',
    },
  ];

  const renderComponent = (page: string) => {
    switch (page) {
      case DocumentSubPages.UPLOAD:
        return <Upload />;
      case DocumentSubPages.DOCUMENTS:
        return <Document />;
    }
  };

  return (
    <div className={styles['root']} data-testid="manage-business-documents">
      <div className={styles['title']}>Documents</div>
      <div className={styles['nav-title']}>
        {subNavItems.map((item) => (
          <DocumentsNavItems key={item.name} item={item} />
        ))}
      </div>
      <div className={styles['page-content']}>
        {renderComponent(selectedSubPage)}
      </div>
    </div>
  );
};
