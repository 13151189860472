import { AllContactType } from '../../../../service/service.types';

export type UserData = {
  firstName: string;
  middleName?: string;
  lastName: string;
  gender: string;
  birthday: string;
  ssn: string;
  phoneNumber: string;
  phoneNumberType: string;
  noMiddleName: boolean;
};

export type Address = {
  id?: string;
  city: string;
  state: string;
  zip: string;
  addressLine: string;
  unit?: string;
  type?: AllContactType.HOME | AllContactType.WORK;
};

export type AgentWorkingState = {
  state: string;
};

export interface ErrorMessages {
  SSN: ErrorMessage;
  Birthday: ErrorMessage;
}

export interface ErrorMessage {
  error: boolean;
  message: string;
}

export const InitErrorMessages = {
  SSN: {
    error: false,
    message: '',
  },
  Birthday: {
    error: false,
    message: '',
  },
};
