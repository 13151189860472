import { Action, combineReducers } from 'redux';
import produce from 'immer';

import { User } from '../../../service/service.types';
import {
  FetchAllUsersFailureAction,
  FetchAllUsersSuccessAction,
  UsersActionType,
} from './users.action';
import { addUserReducer, AddUserState } from './AddUser/addUser.reducer';

export interface UsersListInitState {
  users: User[] | undefined;
  totalUsers: number | undefined;
}

export type UsersErrorState = {
  error: Error;
} & UsersListInitState;

export type UsersListState = UsersListInitState | UsersErrorState;

export const usersListReducer = (
  state: UsersListInitState = {
    users: undefined,
    totalUsers: undefined,
  },
  action: Action<UsersActionType>,
): UsersListState => {
  return produce(state, (draftState: UsersListState) => {
    switch (action.type) {
      case 'USERS_FETCH_SUCCESS':
        draftState = {
          ...draftState,
          users: (action as FetchAllUsersSuccessAction).users,
          totalUsers: (action as FetchAllUsersSuccessAction).totalUsers,
        };
        return draftState;
      case 'USERS_FETCH_FAILURE':
        draftState = {
          ...draftState,
          error: (action as FetchAllUsersFailureAction).error,
          users: [],
        };
        return draftState;
      default:
        return state;
    }
  });
};

export interface UsersState {
  usersList: UsersListState;
  addUser: AddUserState;
}

export const usersReducer = combineReducers({
  usersList: usersListReducer,
  addUser: addUserReducer,
});
