import React from 'react';

import styles from './CommissionsActivityMobile.module.scss';
import { CommissionsActivity } from '../../../../../service/service.types';
import { ListItem, ListItemText } from '@material-ui/core';
import { convertToLocalString } from '@nglic/utilities-ts/build';
import { formatDate } from '../../Policies/PolicyDetail/PolicyInformation/PolicyInformationTransform';
import { NGlicSpinner } from '@nglic/component-lib';
import { useHistory } from 'react-router';

interface CommissionsActivityMobileProps {
  data: CommissionsActivity[] | undefined;
  loading: boolean;
}

export const CommissionsActivityMobile: React.FC<CommissionsActivityMobileProps> =
  ({ data, loading, children }) => {
    const history = useHistory();
    return (
      <div className={styles['root']}>
        {loading && (
          <div className={styles[loading ? 'loading-spinner' : '']}>
            <NGlicSpinner />
          </div>
        )}

        {!loading &&
          data?.map((commission) => {
            return (
              <div>
                <ListItem
                  className={styles['list-item']}
                  style={{ display: 'flex' }}
                  key={commission.id}
                  onClick={() =>
                    history.push(
                      `/manage-business/policies/${commission.policyId}`,
                    )
                  }
                  button
                  disableRipple
                  disableTouchRipple
                >
                  <div className={styles['left-item-text']}>
                    <ListItemText
                      primary={formatDate(commission.commissionsActivityDate)}
                    />
                    <ListItemText primary={commission.insuredName} />
                    <ListItemText primary={commission.productName} />
                  </div>
                  <div className={styles['right-item-text']}>
                    <ListItemText
                      primary={commission.commissionsActivityTypeCode}
                    />
                    <ListItemText
                      primary={'$' + convertToLocalString(commission.earned)}
                    />
                  </div>
                </ListItem>
              </div>
            );
          })}
        <div>{children}</div>
      </div>
    );
  };
