export enum BackgroundTitlesEnum {
  COMPLAINT_FILED = 'Complaint filed against you with an Insurance Department:',
  FILED_BANKRUPTCY = 'Filed Bankruptcy:',
  JUDGEMENT = 'Judgement in last seven years:',
  FELONY = 'Felony conviction or violation of U.S.C. § 1033:',
  BEEN_BONDED = 'Been bonded and had a claim against a bond due to your actions:',
  HAD_A_LICENSE = 'Had a license refused/suspended/revoked, currently restricted or under investigation:',
  INDEBTED = 'Indebted to any insurance Company/Agency/Manager:',
}

export interface BackgroundData {
  title: string;
  selected: string;
  explanation?: string;
  stateNames?: string[];
}

export interface ErrorMessage {
  error: boolean;
  message: string;
}

export const InitErrorMessage = {
  error: false,
  message: '',
};
