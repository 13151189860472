import { Action } from 'redux';
import produce from 'immer';

import {
  CommissionsStatementActionType,
  FetchAllCommissionsStatementDatesFailureAction,
  FetchAllCommissionStatementDatesSuccessAction,
} from './commissions-statements.action';

export interface CommissionsStatementInitState {
  statementDates: string[] | undefined;
  totalStatementDates: number | undefined;
}

export type CommissionActivitiesErrorState = {
  commissionStatementsError: Error;
} & CommissionsStatementInitState;

export type CommissionStatementsState =
  | CommissionsStatementInitState
  | CommissionActivitiesErrorState;

export const commissionsStatementReducer = (
  state: CommissionsStatementInitState = {
    statementDates: [],
    totalStatementDates: undefined,
  },
  action: Action<CommissionsStatementActionType>,
): CommissionStatementsState => {
  return produce(state, (draftState: CommissionStatementsState) => {
    switch (action.type) {
      case 'FETCH_COMMISSION_STATEMENT_DATES_SUCCESS':
        draftState = {
          ...draftState,
          statementDates: (
            action as FetchAllCommissionStatementDatesSuccessAction
          ).result.dates,
          totalStatementDates: (
            action as FetchAllCommissionStatementDatesSuccessAction
          ).result.totalStatementDates,
        };
        return draftState;
      case 'FETCH_COMMISSION_STATEMENT_DATES_FAILURE':
        draftState = {
          ...draftState,
          commissionStatementsError: (
            action as FetchAllCommissionsStatementDatesFailureAction
          ).error,
          statementDates: [],
        };
        return draftState;
      default:
        return state;
    }
  });
};
