import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { convertToLocalString } from '@nglic/utilities-ts/build';
import styles from './ReportTable.module.scss';

interface ReportTableProps {
  title: string;
  headers: { field: string; display: string; visible: boolean }[];
  rows: Record<string, string | number>[];
}

interface ShowMoreRowProps {
  reportData?: any;
  showMore?: () => void;
  show: boolean;
  showLess?: () => void;
}

export const ReportTableContent: React.FC<{
  headers: { field: string; display: string; visible: boolean }[];
  row: Record<string, string | number>;
}> = ({ headers, row }) => {
  return (
    <div className={classnames(styles['row'], styles['value-row'])}>
      {headers.map((header) => (
        <div>{row[header.field]}</div>
      ))}
    </div>
  );
};

export const ShowMoreRow: React.FC<ShowMoreRowProps> = ({
  reportData,
  showMore,
  show,
  showLess,
}) => {
  return (
    <div className={classnames(styles['row'], styles['showMore-content-row'])}>
      {!show && (
        <div className={styles['showMore']} onClick={showMore}>
          Show more
        </div>
      )}
      {show && (
        <div className={styles['showMore']} onClick={showLess}>
          Show less
        </div>
      )}
      <div>${convertToLocalString(reportData)}</div>
    </div>
  );
};

export const TotalRow: React.FC<{ total: number }> = ({ total }) => {
  return (
    <div className={styles['subtotal-row']}>
      <div className={styles['total']}>Total</div>
      <div className={styles['value']}>${convertToLocalString(total)}</div>
    </div>
  );
};

const calculateSum = (filteredReportData: any, valueKey: string) => {
  if (filteredReportData) {
    return filteredReportData?.reduce((acc, currentItem) => {
      if (currentItem[valueKey][0] === '$') {
        return acc + parseFloat(currentItem[valueKey].replace(/\$|,/g, ''));
      }
      return acc + parseFloat(currentItem[valueKey]);
    }, 0);
  }
  return 0;
};

export const ReportTable: React.FC<ReportTableProps> = ({
  title,
  headers,
  rows,
}) => {
  const [showMore, setShowMore] = useState(false);

  const [hiddenReportData, setHiddenReportData] = useState<
    Record<string, string | number>[]
  >([]);
  const [filteredReportData, setFilteredReportData] = useState<
    Record<string, string | number>[]
  >([]);

  useEffect(() => {
    if (rows?.length > 0) {
      setFilteredReportData([...rows.slice(0, 5)]);
      setHiddenReportData([...rows.slice(5)]);
    }
  }, [rows]);

  const handleShowMore = () => {
    setFilteredReportData([...rows]);
    setHiddenReportData([]);
    setShowMore(true);
  };

  const handleShowLess = () => {
    setFilteredReportData([...rows?.slice(0, 5)]);
    setHiddenReportData([...rows?.slice(5)]);
    setShowMore(false);
  };

  return (
    <div className={styles['root']} data-testid="main-element">
      <div className={styles['title']}>{title}</div>
      <div className={classnames(styles['row'], styles['header-row'])}>
        {headers.map((header) => (
          <div className={styles['header']}>{header.display}</div>
        ))}
      </div>
      {filteredReportData?.map((it) => {
        return <ReportTableContent headers={headers} row={it} />;
      })}
      <ShowMoreRow
        reportData={calculateSum(hiddenReportData, 'earned')}
        showMore={handleShowMore}
        show={showMore}
        showLess={handleShowLess}
      />
      <TotalRow total={calculateSum(rows, 'earned')} />
    </div>
  );
};
