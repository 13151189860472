import React, { useEffect, useState } from 'react';
import {
  GenericTable,
  NglicCheckbox,
  NGlicSpinner,
  PageEnum,
  PrimaryButton,
  TableFieldsProps,
} from '@nglic/component-lib/build';
import styles from './AgentSupport.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../rootReducer';
import { AppPageLayoutWrapper } from '../AppPageLayoutWrapper';
import { Pagination } from '../../modules/Pagination/Pagination';
import {
  OnboardingStatus,
  Role,
  User,
  UserInfo,
} from '../../../service/service.types';
import { SectionName } from 'components/shared/loading.action';
import { fetchUsers } from './users.action';
import { AgentSupportSearch } from './AgentSupportSearch';
import { useHistory } from 'react-router-dom';
import { getUserInformation } from '../../shared/selectors/getUserInformation';
import { setMasqueradeUserAction } from '../../shared/basicUserInfo.action';
import { getRouteForContractingStatus } from '../../../util/routing';
import _ from 'lodash';
import { UserService } from '../../../service/UserService';
import { getCredentials } from '../../shared/selectors/getCredentials';
import { StorageItemKey } from '../../../util/getItemInLocalStorageIfExists';
import AddUserIcon from '@material-ui/icons/PersonAdd';

export const AgentSupport: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const basicInfo = useSelector((state: AppState) => getUserInformation(state));
  const credentials = useSelector((state: AppState) => getCredentials(state));
  const [page, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(true);
  const [loggingIn, setLoggingIn] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [selectedUser, setSelectedUser] = useState<User | undefined>(undefined);

  const totalUsers: number | undefined = useSelector(
    (state: AppState) => state.presentation.users.usersList.totalUsers ?? 0,
  );
  const users: User[] | undefined = useSelector(
    (state: AppState) => state.presentation.users.usersList.users,
  );

  const sectionsLoading: SectionName[] = useSelector(
    (state: AppState) => state.presentation.loading.sectionsLoading,
  );

  useEffect(() => {
    if (basicInfo) {
      dispatch(fetchUsers({ query: searchQuery, limit: 10, page }));
    }
  }, [basicInfo, page]);

  const search = React.useCallback(() => {
    if (basicInfo === 'NOT_INITIALIZED') {
      return;
    }

    if (selectedUser) {
      setSelectedUser(undefined);
    }

    dispatch(fetchUsers({ query: searchQuery, limit: 10, page }));
  }, [searchQuery]);

  useEffect(() => {
    if (!totalUsers) {
      setTotalPages(1);
    } else {
      setTotalPages(Math.ceil(totalUsers / 10));
    }
  }, [totalUsers]);

  useEffect(() => {
    setLoading(!users || sectionsLoading.includes('USERS_FETCH_ALL'));
  }, [users, sectionsLoading]);
  const AgentSupportAction: React.FC<{
    row: User;
  }> = ({ row }) => {
    const updateSelectedUser = () => {
      if (selectedUser) {
        setSelectedUser(undefined);
      } else {
        setSelectedUser(row);
      }
    };
    return (
      <div className={styles['support-checkbox']}>
        {selectedUser && selectedUser !== row ? (
          ''
        ) : (
          <NglicCheckbox
            onChange={updateSelectedUser}
            checked={selectedUser !== undefined}
          />
        )}
      </div>
    );
  };

  const tableHeader: TableFieldsProps[] = [
    {
      titleName: '',
      dataField: '',
      type: 'action',
      actionComponent: AgentSupportAction,
    },
    {
      titleName: 'Name',
      dataField: 'fullName',
    },
    {
      titleName: 'Identifier',
      dataField: 'identifier',
    },
    {
      titleName: 'Organization',
      dataField: 'organization',
    },
    {
      titleName: 'Role',
      dataField: 'roles',
      displayFormat: (value: string[]): string => {
        return value?.join(',');
      },
    },
  ];

  const loginAsAUser = () => {
    if (selectedUser) {
      setLoggingIn(true);
      localStorage.setItem(
        StorageItemKey.MASQUERADE_UUID,
        selectedUser.cognitoUuid ?? '',
      );
      UserService.getUserInfoByRole(
        selectedUser.email,
        credentials,
        selectedUser.roles,
      )
        .then((res) => {
          // Ordering of these events matter. If we set masquerade user first we will rerender the protected
          // route with the wrong roles and hit the unauthorized page
          const agentContractingStatus = _.get(
            res,
            'contractingStatus.agentContractingStatus',
          ) as OnboardingStatus;
          const usersLandingPage = getRouteForContractingStatus(
            agentContractingStatus,
            selectedUser.roles ?? [],
          );
          history.push(usersLandingPage);
          dispatch(
            setMasqueradeUserAction({
              ...(res as UserInfo),
              roles: selectedUser.roles,
              cognitoUuid: selectedUser.cognitoUuid ?? '',
            }),
          );
        })
        .catch((e) => {
          setLoggingIn(false);
        });
    }
  };
  return basicInfo === 'NOT_INITIALIZED' ? (
    <div className={styles['loading-container']}>
      <NGlicSpinner />
    </div>
  ) : (
    <AppPageLayoutWrapper
      page={'' as PageEnum}
      title={''}
      data-testid={'landing-page-agent-support-component'}
    >
      <div>
        <div className={styles['support-header']}>NGLifeLink Users</div>
        <div className={styles['search-add-user']}>
          <div className={styles['support-search']}>
            <AgentSupportSearch
              onSubmitSearch={search}
              onSearchTextChange={setSearchQuery}
            />
          </div>
          {basicInfo?.roles.includes(Role.INVITE_USER) && (
            <div className={styles['add-user']}>
              <PrimaryButton
                text={'ADD USER'}
                startIcon={<AddUserIcon />}
                onClick={() => history.push('/support/add-user')}
              />
            </div>
          )}
        </div>

        <div className={styles['support-table']}>
          <GenericTable
            loading={loading}
            pagination={true}
            data={users}
            columns={tableHeader}
            overFlowHidden={loading}
          >
            <div className={styles['pagination-container']}>
              <Pagination
                onPageClick={setCurrentPage}
                numberOfPages={totalPages}
                currentPage={page}
              />
            </div>
          </GenericTable>
        </div>
        <div className={styles['support-button']}>
          <PrimaryButton
            loading={loggingIn}
            text="LOGIN AS USER"
            onClick={loginAsAUser}
            disabled={loggingIn}
          />
        </div>
      </div>
    </AppPageLayoutWrapper>
  );
};
