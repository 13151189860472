import { InputLabel } from '@material-ui/core';
import React from 'react';
import { Policy } from '../../../../../../service/service.types';
import {
  checkForBackgroundColor,
  convertStatus,
} from '../../PoliciesList/PoliciesList';
import styles from './PolicyInformationMobile.module.scss';
import {
  calculateAge,
  createFullName,
  substringDate,
} from './PolicyInformationTransform';
import Star from '../../../../../../assets/img/starIcon/Star.png';
import Icon from '../../../../../../assets/img/circleIcon/Icon.png';

interface PolicyInformationProps {
  policy?: Policy;
}

export const returnNumericValues = (
  value: string | number | undefined,
): string => {
  if (!value || value == 0) {
    return '$0.00';
  }
  return '$' + value;
};

const PolicyInformation: React.FC<PolicyInformationProps> = ({ policy }) => {
  return (
    <div className={styles['policy-information']}>
      <div className={styles['policy-information-header']}>
        <div className={styles['policy-information-title']}>
          Policy Information
        </div>
        <div className={styles['policy-information-mature']}>
          <div className={styles['policy-mature-label']}>Matures</div>
          <div className={styles['policy-mature']}>
            {substringDate(policy?.matureDate)}
          </div>
        </div>
      </div>
      <div className={styles['policy-information-content']}>
        <div className={styles['policy-information-content-row']}>
          <div>
            <img src={Star} />
          </div>
          <div className={styles['policy-information-label']}>
            Current Death Benefit
          </div>
          <div className={styles['policy-information-value']}>
            {returnNumericValues(policy?.currentDeathBenefit)}
          </div>
        </div>
        <div className={styles['policy-information-content-row']}>
          <div className={styles['icon']}>
            <img src={Icon} />
          </div>
          <div className={styles['policy-information-label-circle']}>
            Early Pay Off
          </div>
          <div className={styles['policy-information-value']}>
            {returnNumericValues(policy?.earlyPayOff)}
          </div>
        </div>
        <div className={styles['policy-information-content-row']}>
          <div>
            <img src={Star} />
          </div>
          <div className={styles['policy-information-label']}>
            Base Cash Value
          </div>
          <div className={styles['policy-information-value']}>
            {returnNumericValues(policy?.baseCashValue)}
          </div>
        </div>
        <div className={styles['policy-information-content-row']}>
          <div className={styles['icon']}>
            <img src={Icon} />
          </div>
          <div className={styles['policy-information-label-circle']}>
            Net Surrender Value
          </div>
          <div className={styles['policy-information-value']}>
            {returnNumericValues(policy?.netSurrenderValue)}
          </div>
        </div>
      </div>
    </div>
  );
};

export const PolicyInformationMobile: React.FC<PolicyInformationProps> = (
  props: PolicyInformationProps,
) => {
  const { policy } = props;
  return (
    <div className={styles['root']}>
      <div className={styles['header']}>
        <div className={styles['insured-information']}>
          <div className={styles['insured-name']}>
            {createFullName(
              policy?.insured?.firstName,
              policy?.insured?.lastName,
            )}
          </div>
          <div className={styles['insured-dob-information']}>
            <div className={styles['insured-dob-label']}>AGE</div>
            <div className={styles['insured-dob']}>
              {calculateAge(policy?.insured?.dob)}
            </div>
          </div>
        </div>
        <div className={styles['title']}>POLICY</div>
        <div className={styles['policy-number']}>{policy?.policyNumber}</div>
        <InputLabel
          className={styles[checkForBackgroundColor(policy?.status || '')]}
        >
          {convertStatus(policy?.status || '')}
        </InputLabel>
      </div>
      <div className={styles['first-content']}>
        <PolicyInformation policy={policy} />
      </div>
    </div>
  );
};
