import React, { useEffect, useState } from 'react';
import styles from './Quote.module.scss';
import { QuoteTable } from './QuoteTable/QuoteTable';
import { PrimaryButton } from '@nglic/component-lib';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../../../rootReducer';
import { QuoteDetailsModal } from './QuoteDetailsModal/QuoteDetailsModal';
import { FiltersDataProps } from './PrintQuoteToPDF';

interface QuoteProps {
  filtersData: FiltersDataProps;
}

export const Quote: React.FC<QuoteProps> = ({ filtersData }) => {
  const [showQuoteDetailsModal, setShowQuoteDetailsModal] = useState(false);
  const [printButtonDisabled, setPrintButtonDisabled] = useState(true);
  const rateCalculatorData = useSelector(
    (state: AppState) =>
      state.presentation.manageBusiness.rateCalculator.ratePlanItems,
  );

  useEffect(() => {
    setPrintButtonDisabled(!(rateCalculatorData.length > 0));
  }, [rateCalculatorData]);

  return (
    <div className={styles['root']}>
      <div className={styles['header']}>
        <div className={styles['re-calculate']}>RE-CALCULATE</div>
        <PrimaryButton
          disabled={printButtonDisabled}
          text="PRINT ALL"
          onClick={() => setShowQuoteDetailsModal(true)}
        />
      </div>
      <div className={styles['table-title']}>Quote</div>
      <div className={styles['quote-table']}>
        <QuoteTable data={rateCalculatorData} />
      </div>
      <QuoteDetailsModal
        open={showQuoteDetailsModal}
        handleClose={() => setShowQuoteDetailsModal(false)}
        filtersData={filtersData}
        tableData={rateCalculatorData}
      />
    </div>
  );
};
