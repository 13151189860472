import { ThunkAction } from 'redux-thunk';
import { AppState } from '../../../../rootReducer';
import { Action } from 'redux';
import { CommissionsActivity } from '../../../../service/service.types';
import { getCredentials } from '../../../shared/selectors/getCredentials';
import { CommissionActivityService } from '../../../../service/CommissionActivityService';
import { getUserInformation } from '../../../shared/selectors/getUserInformation';

export type CommissionActivitiesActionType =
  | 'FETCH_INITIAL_COMMISSION_ACTIVITIES_SUCCESS'
  | 'FETCH_INITIAL_COMMISSION_ACTIVITIES_FAILURE'
  | 'FETCH_COMMISSION_ACTIVITIES_SUCCESS'
  | 'FETCH_COMMISSION_ACTIVITIES_FAILURE'
  | 'FETCH_COMMISSION_RESERVES_ACTIVITIES_REQUEST'
  | 'FETCH_COMMISSION_RESERVES_ACTIVITIES_SUCCESS'
  | 'FETCH_COMMISSION_RESERVES_ACTIVITIES_FAILURE'
  | 'RESET_COMMISSIONS'
  | 'NONE';

export interface FetchAllCommissionActivitiesSuccessAction
  extends Action<CommissionActivitiesActionType> {
  result: {
    commissions: CommissionsActivity[];
    totalCommissions: number;
  };
}

export interface FetchAllCommissionActivitiesFailureAction
  extends Action<CommissionActivitiesActionType> {
  error: Error;
}

export interface FetchAllCommissionReservesActivitiesSuccessAction
  extends Action<CommissionActivitiesActionType> {
  result: {
    reserves: CommissionsActivity[];
    totalReserves: number;
  };
}

export interface FetchAllCommissionReservesActivitiesFailureAction
  extends Action<CommissionActivitiesActionType> {
  error: Error;
}

export interface FetchAllCommissionActivitiesStatementDatesSuccessAction
  extends Action<CommissionActivitiesActionType> {
  result: {
    dates: string[];
    totalStatementDates: number;
  };
}

export interface FetchAllCommissionActivitiesStatementDatesFailureAction
  extends Action<CommissionActivitiesActionType> {
  error: Error;
}

export const resetCommissions = (): Action<CommissionActivitiesActionType> => ({
  type: 'RESET_COMMISSIONS',
});

export const fetchCommissionActivities =
  (page = 1, limit = 50): ThunkAction<void, AppState, any, any> =>
  async (dispatch, getState: () => AppState) => {
    const tokens = getCredentials(getState());
    const userInfo = getUserInformation(getState());

    const promise = async (): Promise<{
      commissions: CommissionsActivity[];
      totalCommissions: number;
    }> => {
      if (!userInfo) {
        throw new Error('basicInfo not initialized');
      }

      return await CommissionActivityService.getAllCommissionActivities({
        credentials: tokens,
        branchCode: userInfo?.branchCode,
        agentId: userInfo?.agentId,
        page,
        limit,
      });
    };

    dispatch({
      types: [
        'FETCH_COMMISSION_ACTIVITIES_REQUEST',
        'FETCH_COMMISSION_ACTIVITIES_SUCCESS',
        'FETCH_COMMISSION_ACTIVITIES_FAILURE',
      ],
      promise,
      bypassGlobalError: true,
    });
  };

export const fetchInitialCommissionActivities =
  (limit: number): ThunkAction<void, AppState, any, any> =>
  async (dispatch, getState: () => AppState) => {
    const tokens = getCredentials(getState());
    const userInfo = getUserInformation(getState());

    const initCommissions =
      getState().presentation.manageBusiness.commissions.initialCommissions;

    if (initCommissions.length > 0) {
      return;
    }

    const promise = async (): Promise<{
      commissions: CommissionsActivity[];
      totalCommissions: number;
    }> => {
      if (!userInfo) {
        throw new Error('basicInfo not initialized');
      }

      return await CommissionActivityService.getAllCommissionActivities({
        credentials: tokens,
        branchCode: userInfo?.branchCode,
        agentId: userInfo?.agentId,
        page: 1,
        limit,
      });
    };

    dispatch({
      types: [
        'FETCH_INITIAL_COMMISSION_ACTIVITIES_REQUEST',
        'FETCH_INITIAL_COMMISSION_ACTIVITIES_SUCCESS',
        'FETCH_INITIAL_COMMISSION_ACTIVITIES_FAILURE',
      ],
      promise,
      bypassGlobalError: true,
    });
  };

export const fetchReserveCommissionActivities =
  (page = 1, limit = 50): ThunkAction<void, AppState, any, any> =>
  async (dispatch, getState: () => AppState) => {
    const tokens = getCredentials(getState());
    const userInfo = getUserInformation(getState());

    const promise = async (): Promise<{
      reserves: CommissionsActivity[];
      totalReserves: number;
    }> => {
      if (!userInfo) {
        throw new Error('basicInfo not initialized');
      }

      return await CommissionActivityService.getAllCommissionReservesActivities(
        {
          credentials: tokens,
          branchCode: userInfo?.branchCode,
          agentId: userInfo?.agentId,
          page,
          limit,
        },
      );
    };

    dispatch({
      types: [
        'FETCH_COMMISSION_RESERVES_ACTIVITIES_REQUEST',
        'FETCH_COMMISSION_RESERVES_ACTIVITIES_SUCCESS',
        'FETCH_COMMISSION_RESERVES_ACTIVITIES_FAILURE',
      ],
      promise,
      bypassGlobalError: false,
    });
  };
