import { Action } from 'redux';
import { BackgroundTitlesEnum } from './Background.types';

export type BackgroundAction = 'SELECT_ROW_INPUT' | 'SET_BACKGROUND_CHECK_COPY';

export interface SelectRowAction {
  title: string;
  selected: string;
  explanation?: string;
  states?: string[];
}

export interface WillReceiveCopyOfBackgroundCheckAction {
  willReceiveCopyOfBackgroundCheck: boolean;
}

export interface HandleSelectRowAction extends Action<BackgroundAction> {
  type: 'SELECT_ROW_INPUT';
  payload: SelectRowAction;
}

export interface HandleWillReceiveCopy extends Action<BackgroundAction> {
  type: 'SET_BACKGROUND_CHECK_COPY';
  payload: WillReceiveCopyOfBackgroundCheckAction;
}

export const selectRow = (
  title: BackgroundTitlesEnum,
  selected: string,
  explanation?: string,
  states?: string[],
): HandleSelectRowAction => {
  return {
    type: 'SELECT_ROW_INPUT',
    payload: {
      title,
      selected,
      explanation,
      states,
    },
  };
};

export const setReceiveCopyOfBackgroundCheck = (
  willReceiveCopyOfBackgroundCheck: boolean,
): HandleWillReceiveCopy => {
  return {
    type: 'SET_BACKGROUND_CHECK_COPY',
    payload: {
      willReceiveCopyOfBackgroundCheck,
    },
  };
};
