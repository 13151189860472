import React, { useEffect, useState } from 'react';
import styles from './RecentPoliciesMobile.module.scss';
import { useSelector } from 'react-redux';
import { AppState } from 'rootReducer';
import { PartialPolicy } from '../../../../../service/service.types';
import { SectionName } from '../../../../shared/loading.action';
import { useHistory } from 'react-router-dom';
import { policiesRoute } from './RecentPolicies';
import { ListItem, ListItemText } from '@material-ui/core';
import { formatDate } from '../../Policies/PolicyDetail/PolicyInformation/PolicyInformationTransform';
import { convertToLocalString } from '@nglic/utilities-ts/build';

interface RecentPoliciesMobileProps {
  policies?: PartialPolicy[];
}

const secondaryTypographyProps = {
  style: {
    fontSize: '10px',
    color: '#5E6168',
    letterSpacing: '1.5px',
  },
};

const secondaryTypographyPropsAgentName = {
  style: {
    color: '#5E6168',
    fontSize: '13px',
  },
};

const primaryTypographyProps = {
  style: {
    color: '#2F3034',
    fontSize: '22px',
  },
};
export const RecentPoliciesMobile: React.FC<RecentPoliciesMobileProps> = ({
  policies,
}) => {
  const [loading, setLoading] = useState<boolean>(true);
  const history = useHistory();

  const sectionsLoading: SectionName[] = useSelector(
    (state: AppState) => state.presentation.loading.sectionsLoading,
  );

  useEffect(() => {
    setLoading(!policies || sectionsLoading.includes('POLICIES_FETCH_ALL'));
  }, [policies, sectionsLoading]);

  const createRouteTo = (route: string) => () => {
    history.push(route);
  };
  return (
    <>
      <div className={styles['root']}>
        <div className={styles['header']}>Recent Policies Issued</div>
        <div className={styles['table']}>
          {policies?.map((policy) => {
            return (
              <ListItem
                className={styles['list-item']}
                style={{ display: 'flex' }}
                key={policy.id}
                button
                disableRipple
                disableTouchRipple
              >
                <div className={styles['left-item-text']}>
                  <ListItemText
                    secondary={policy.productCode}
                    secondaryTypographyProps={secondaryTypographyProps}
                  />
                  <ListItemText
                    primary={'$' + convertToLocalString(policy.policyAmount)}
                    secondary={policy.agentName}
                    secondaryTypographyProps={secondaryTypographyPropsAgentName}
                    primaryTypographyProps={primaryTypographyProps}
                  />
                </div>
                <div className={styles['right-item-text']}>
                  <ListItemText
                    className={styles['item-issuedDate']}
                    secondaryTypographyProps={secondaryTypographyProps}
                    secondary={formatDate(policy.effectiveDate)}
                  />
                </div>
              </ListItem>
            );
          })}
        </div>
      </div>
      <div
        className={styles['link']}
        onClick={createRouteTo(policiesRoute.POLICIES)}
      >
        View All Policies
      </div>
    </>
  );
};
