import React from 'react';
import { PolicyPayment } from '../../../../../../service/service.types';
import { returnNumericValues } from '../PolicyInformation/PolicyInformationMobile';
import {
  createFullName,
  substringDate,
} from '../PolicyInformation/PolicyInformationTransform';
import styles from './PaymentHistoryDetailMobile.module.scss';
import ArrowBackIcon from '@material-ui/icons/ArrowBackIos';

interface PaymentHistoryProps {
  policyPayment?: PolicyPayment;
  onPolicyPaymentSelected?: () => void;
}

export const PaymentHistoryDetailMobile: React.FC<PaymentHistoryProps> = ({
  policyPayment,
  onPolicyPaymentSelected,
}) => {
  return (
    <div className={styles['root']}>
      <div className={styles['header']}>
        <div className={styles['header-content']}>
          <div className={styles['go-back']} onClick={onPolicyPaymentSelected}>
            <ArrowBackIcon style={{ fontSize: 15, color: 'white' }} />
            <div className={styles['back']}>Back</div>
          </div>
          <div className={styles['payment-amount']}>
            {returnNumericValues(policyPayment?.paymentAmount)}
          </div>
        </div>
        <div className={styles['issued-date']}>
          Statement issued on {substringDate(policyPayment?.paymentDate)}
        </div>
      </div>
      <div className={styles['content']}>
        <div className={styles['list-item-header']}>Payment Info</div>
        <div className={styles['list-item-mobile']}>
          <div className={styles['list-item-value']}>
            {createFullName(
              policyPayment?.payor?.firstName,
              policyPayment?.payor?.lastName,
            )}
          </div>
          <div className={styles['list-item-label']}>Payor</div>
        </div>
        <div className={styles['list-item-mobile']}>
          <div className={styles['list-item-value']}>
            {substringDate(policyPayment?.paymentDate)}
          </div>
          <div className={styles['list-item-label']}>Date Paid</div>
        </div>
        <div className={styles['list-item-mobile']}>
          <div className={styles['list-item-value']}>
            {policyPayment?.paymentMethod}
          </div>
          <div className={styles['list-item-label']}>Payment Method</div>
        </div>
      </div>
      <div
        className={styles['return-to-policy']}
        onClick={onPolicyPaymentSelected}
      >
        Return to Policy
      </div>
    </div>
  );
};
