import styles from './ResetInitialPassword.module.scss';
import { PrimaryButton, TextInput } from '@nglic/component-lib/build';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetTempPassword } from '../login.action';
import { AppState } from '../../../../rootReducer';
import { isLoginTempPasswordUpdateState } from '../login.reducer';
import logo from '../../../../assets/img/logo/logo-ngl.png';
import logo2x from '../../../../assets/img/logo/logo-ngl@2x.png';
import logo3x from '../../../../assets/img/logo/logo-ngl@3x.png';
import {
  PasswordRequirements,
  PasswordValidity,
} from '../PasswordRequirements/PasswordRequirements';
import {
  isCapitalLetterRegx,
  isLowerCaseCharRegx,
  isNumberRegx,
  isSpecialCharacterRegx,
} from '../ResetPassword/ResetPassword';
import { useNglScreenSize } from '@nglic/component-lib';

export const ResetInitialPassword: React.FC = () => {
  const dispatch = useDispatch();
  const { isMobile } = useNglScreenSize();

  const [creds, setCreds] = React.useState({
    newPassword: '',
    confirmPassword: '',
  });
  const [submitDisabled, setSubmitDisabled] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [passwordValidity, setPasswordValidity] =
    React.useState<PasswordValidity>({
      minChars: undefined,
      capitalLetter: undefined,
      lowercaseLetter: undefined,
      number: undefined,
      specialChar: undefined,
    });

  React.useEffect(() => {
    setSubmitDisabled(
      !Boolean(creds.newPassword.length) ||
        !Boolean(creds.confirmPassword.length) ||
        creds.confirmPassword !== creds.newPassword,
    );
  }, [creds]);

  const { session, username } = useSelector((state: AppState) => {
    if (isLoginTempPasswordUpdateState(state.presentation.login)) {
      return {
        session: state.presentation.login.session,
        username: state.presentation.login.username,
      };
    }
    return { username: '', session: '' };
  });

  const newPasswordOnChange = React.useCallback(
    (val: string) => {
      setCreds({
        ...creds,
        newPassword: val,
      });

      setPasswordValidity({
        minChars: val.length >= 8,
        capitalLetter: isCapitalLetterRegx.test(val),
        lowercaseLetter: isLowerCaseCharRegx.test(val),
        number: isNumberRegx.test(val),
        specialChar: isSpecialCharacterRegx.test(val),
      });
    },
    [creds],
  );
  const confirmPassword = React.useCallback(
    (val: string) => {
      setCreds({
        ...creds,
        confirmPassword: val,
      });
    },
    [creds],
  );

  const confirmPasswordValidation = React.useCallback(
    (pass: string) => {
      const error = pass !== creds.newPassword;
      return {
        error,
        message: error ? 'Passwords do not match' : '',
      };
    },
    [creds],
  );

  const submitNewCredentials = (): void => {
    if (creds.newPassword === creds.confirmPassword) {
      setLoading(true);
      dispatch(
        resetTempPassword({
          session,
          username,
          password: creds.newPassword,
        }),
      );
    }
  };

  const handleKeyDown = React.useCallback(
    (e): void => {
      if (e.key === 'Enter') {
        submitNewCredentials();
      }
    },
    [submitNewCredentials],
  );

  return (
    <div className={styles['root']} data-testid={'reset-initial-password'}>
      <div onKeyDown={handleKeyDown}>
        <div className={styles['logo-container']}>
          <img
            className={styles['responsive-img']}
            srcSet={`${logo}, ${logo2x} 2x, ${logo3x} 3x`}
          />
        </div>
        <div className={styles['stars-container']}>
          <div className={styles['star']}>&#10022;</div>
          <div className={styles['star']}>&#10022;</div>
          <div className={styles['star']}>&#10022;</div>
        </div>
        <div className={styles['message-container']}>
          <p>Welcome! You look new here.</p>
          <p>Let's start by creating your account.</p>
        </div>
        <div
          className={styles['input-container']}
          aria-label={`new-password-input-${
            creds.newPassword.length ? 'filled' : 'empty'
          }`}
        >
          <TextInput
            id={'new-password'}
            hiddenText={true}
            onChange={newPasswordOnChange}
            label={'New Password'}
          />
        </div>
        <div
          className={styles['input-container']}
          aria-label={`confirm-password-input-${
            creds.confirmPassword.length ? 'filled' : 'empty'
          }`}
        >
          <TextInput
            hiddenText={true}
            onChange={confirmPassword}
            id={'confirm-password'}
            label={'Confirm Password'}
            validator={confirmPasswordValidation}
          />
        </div>
        {isMobile && (
          <div className={styles['validity']}>
            <PasswordRequirements passwordValidity={passwordValidity} />
          </div>
        )}
        <div className={styles['link-container']}>
          <PrimaryButton
            onKeyDown={handleKeyDown}
            ariaLabel={`create-account-button-${
              submitDisabled ? 'inactive' : 'active'
            }`}
            loading={loading}
            text={'Create Account'}
            onClick={submitNewCredentials}
          />
        </div>
      </div>
      {!isMobile && (
        <div className={styles['password-requirements']}>
          <PasswordRequirements passwordValidity={passwordValidity} />
        </div>
      )}
    </div>
  );
};
