import React, { useEffect, useState } from 'react';

import { NglicSimpleSelect, Option } from '@nglic/component-lib';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../rootReducer';
import { State } from '../../../service/service.types';
import { fetchStates } from '../state.action';

interface StatePickerProps {
  label?: string;
  routeToNext?: () => void;
  onSelectedState?: (state: Option) => void;
}

export const StatePicker: React.FC<StatePickerProps> = ({
  label,
  onSelectedState,
}) => {
  const dispatch = useDispatch();

  const displayLabel = label ? label : 'State';

  const [selectedState, setSelectedState] = useState<Option | undefined>();

  const states: State[] | undefined = useSelector(
    (state: AppState) => state.data.state.states,
  );

  const options = states?.reduce<any[]>((acc, next) => {
    return [
      ...acc,
      {
        id: next.code,
        name: next.desc,
      },
    ];
  }, []);

  useEffect(() => {
    dispatch(fetchStates());
  }, []);

  useEffect(() => {
    if (onSelectedState && selectedState) {
      onSelectedState(selectedState);
    }
  }, [selectedState]);

  return (
    <NglicSimpleSelect
      selectedOption={selectedState}
      onChange={(value) => {
        setSelectedState(value);
      }}
      label={displayLabel}
      options={options || []}
      loading={!states}
    />
  );
};
