export enum StorageItemKey {
  MASQUERADE_UUID = 'NGLIC_MASQUERADE_COGNITO_UUID',
  COGNITO_ID_TOKEN = 'NGLIC_COGNITO_ID_TOKEN',
  COGNITO_ACCESS_TOKEN = 'NGLIC_COGNITO_ACCESS_TOKEN',
  COGNITO_REFRESH_TOKEN = 'NGLIC_COGNITO_REFRESH_TOKEN',
  MFA_LOGIN_SESSION = 'NGLIC_COGNITO_MFA_LOGIN_SESSION',
  MFA_LOGIN_USERNAME = 'NGLIC_COGNITO_MFA_LOGIN_USERNAME',
  MFA_DEVICE_INFORMATION = 'NGLIC_COGNITO_MFA_DEVICE_INFORMATION',
}

export const getItemInLocalStorageIfExists = (
  itemName: StorageItemKey,
  defaultValue = '',
): string => {
  const itemInLocalStorage = localStorage.getItem(itemName);
  return itemInLocalStorage ?? defaultValue;
};
