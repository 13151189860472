import produce from 'immer';
import { Action } from 'redux';
import {
  CreateClaimActionType,
  CreateClaimFailureAction,
} from './claim.action';

export interface ClaimData {
  submitted: boolean;
  error?: Error;
}

export type ClaimState = 'INCOMPLETE' | ClaimData;

export const createClaimReducer = (
  state: ClaimState = 'INCOMPLETE',
  action: Action<CreateClaimActionType>,
): ClaimState => {
  return produce(state, () => {
    switch (action.type) {
      case 'CREATE_CLAIM_SUCCESS':
        return {
          submitted: true,
          error: undefined,
        };
      case 'CREATE_CLAIM_FAILURE':
        return {
          submitted: false,
          error: (action as CreateClaimFailureAction).error,
        };
      case 'CLEAR_CREATE_CLAIM':
        return 'INCOMPLETE';
    }
  });
};
