import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import { AppState } from '../../../../../rootReducer';
import { getCredentials } from '../../../../shared/selectors/getCredentials';
import { ReportDataService } from '../../../../../service/ReportDataService';
import { IReportFilters } from './Report.types';
import { loadComplete, loadStarted } from '../../../../shared/loading.action';
import { getUserInformation } from '../../../../shared/selectors/getUserInformation';

export type ReportDataActionType =
  | 'REPORT_DATA_FETCH__SUCCESS'
  | 'REPORT_DATA_FETCH_FAILURE'
  | 'NONE';

export interface FetchReportDataSuccessAction
  extends Action<ReportDataActionType> {
  reportData: any;
  timeRequested: number;
}

export interface FetchReportDataErrorAction
  extends Action<ReportDataActionType> {
  error: Error;
}

export const fetchReportData =
  (
    reportType: string,
    filters: IReportFilters,
  ): ThunkAction<void, AppState, any, any> =>
  async (dispatch, getState: () => AppState) => {
    const tokens = getCredentials(getState());

    const agentId = getUserInformation(getState())?.agentId;
    if (!agentId) {
      dispatch({
        type: 'REPORT_DATA_FETCH_FAILURE',
        error: 'No agent ID present for current agent',
      });
      return;
    }

    try {
      dispatch(loadStarted('FETCH_REPORT_DATA'));
      const reportData = await ReportDataService.getReportData(
        tokens,
        agentId,
        reportType,
        filters,
      );

      dispatch({
        type: 'REPORT_DATA_FETCH__SUCCESS',
        reportData,
        timeRequested: Date.now(),
      });
      dispatch(loadComplete('FETCH_REPORT_DATA'));
    } catch (error) {
      dispatch({
        type: 'REPORT_DATA_FETCH_FAILURE',
        error,
      });
      dispatch(loadComplete('FETCH_REPORT_DATA'));
    }
  };
