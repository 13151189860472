import { ThunkAction } from 'redux-thunk';
import { AppState } from '../../../../../rootReducer';
import { Action } from 'redux';
import { getCredentials } from '../../../../shared/selectors/getCredentials';
import { getUserInformation } from '../../../../shared/selectors/getUserInformation';
import { CommissionActivityService } from '../../../../../service/CommissionActivityService';
import { setGlobalError } from '../../../../shared/error.action';

export type CommissionsStatementActionType =
  | 'FETCH_COMMISSION_STATEMENT_DATES_REQUEST'
  | 'FETCH_COMMISSION_STATEMENT_DATES_SUCCESS'
  | 'FETCH_COMMISSION_STATEMENT_DATES_FAILURE'
  | 'NONE';

export interface FetchAllCommissionStatementDatesSuccessAction
  extends Action<CommissionsStatementActionType> {
  result: {
    dates: string[];
    totalStatementDates: number;
  };
}

export interface FetchAllCommissionsStatementDatesFailureAction
  extends Action<CommissionsStatementActionType> {
  error: Error;
}

export const fetchCommissionStatementDates =
  (page = 1, limit = 5): ThunkAction<void, AppState, any, any> =>
  async (dispatch, getState: () => AppState) => {
    const tokens = getCredentials(getState());
    const userInfo = getUserInformation(getState());

    if (!userInfo) {
      dispatch(setGlobalError(new Error('basicInfo not initialized')));
      return;
    }
    const promise = async (): Promise<{
      dates: string[];
      totalStatementDates: number;
    }> => {
      return await CommissionActivityService.getCommissionsStatementDates({
        credentials: tokens,
        agentId: userInfo?.agentId,
        page,
        limit,
      });
    };

    dispatch({
      types: [
        'FETCH_COMMISSION_STATEMENT_DATES_REQUEST',
        'FETCH_COMMISSION_STATEMENT_DATES_SUCCESS',
        'FETCH_COMMISSION_STATEMENT_DATES_FAILURE',
      ],
      promise,
      bypassGlobalError: true,
    });
  };
