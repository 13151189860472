import classnames from 'classnames';
import React from 'react';
import {
  Agent,
  AgentStatus,
  OnboardingStatus,
} from '../../../../../service/service.types';

import styles from './AgentLicenseAndTraining.module.scss';

interface AgentLicenseAndTrainingProps {
  selectedAgent: Agent;
}

export const AgentLicenseAndTraining: React.FC<AgentLicenseAndTrainingProps> =
  ({ selectedAgent }) => {
    const agentContractingStatus = selectedAgent.onboardingStatuses?.filter(
      (item) => item.name === 'Agent Contracting Status',
    )[0]?.value;

    const renderStatusLabel = (status: string) => {
      switch (status) {
        case OnboardingStatus.INVITED:
          return (
            <div
              className={classnames(
                styles['status'],
                styles['in-progress-status'],
              )}
            >
              CONTRACTING IN PROGRESS
            </div>
          );
        case OnboardingStatus.PENDING:
          return (
            <div
              className={classnames(
                styles['status'],
                styles['in-progress-status'],
              )}
            >
              CONTRACTING IN PROGRESS
            </div>
          );
        case OnboardingStatus.COMPLETE:
          return (
            <div
              className={classnames(
                styles['status'],
                styles['complete-status'],
              )}
            >
              {AgentStatus.ACTIVE.toUpperCase()}
            </div>
          );
        default:
          return <div></div>;
      }
    };
    return (
      <div className={styles['root']}>
        <div className={styles['content']}>
          {selectedAgent.status === AgentStatus.TERMINATED ||
          selectedAgent.status === AgentStatus.PENDING_TERMINATION ? (
            <div
              className={classnames(
                styles['status'],
                styles['terminated-status'],
              )}
            >
              {selectedAgent.status.toUpperCase()}
            </div>
          ) : (
            renderStatusLabel(agentContractingStatus)
          )}
        </div>
      </div>
    );
  };
