import produce from 'immer';
import { Action } from 'redux';

import { RatePlanItem } from './RightPane/Quote/QuoteTable/QuoteTable';
import {
  FetchRatePlansFailureAction,
  FetchRatePlansSuccessAction,
  RateCalculatorActionType,
} from './rateCalculator.action';

export interface RateCalculatorInitState {
  ratePlanItems: RatePlanItem[] | [];
}

export type RateCalculatorErrorState = {
  error: Error;
} & RateCalculatorInitState;

export type RateCalculatorState =
  | RateCalculatorInitState
  | RateCalculatorErrorState;

export const rateCalculatorReducer = (
  state: RateCalculatorState = { ratePlanItems: [] },
  action: Action<RateCalculatorActionType>,
): RateCalculatorState => {
  return produce(state, (draftState) => {
    switch (action.type) {
      case 'RATE_CALCULATOR_FETCH_SUCCESS':
        draftState.ratePlanItems = (
          action as FetchRatePlansSuccessAction
        ).result.ratePlanItems;
        return draftState;
      case 'RATE_CALCULATOR_FETCH_FAILURE':
        draftState = {
          ...draftState,
          error: (action as FetchRatePlansFailureAction).error,
          ratePlanItems: [],
        };
        return draftState;
      default:
        return state;
    }
  });
};
