import { ButtonSize, formatDate, SecondaryButton } from '@nglic/component-lib';
import React from 'react';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../../../rootReducer';
import { AgentService } from '../../../../../../service/AgentService';
import { CCPDocument } from '../../../../../../service/service.types';
import { getCredentials } from '../../../../../shared/selectors/getCredentials';
import { getUserInformation } from '../../../../../shared/selectors/getUserInformation';

import styles from './Correspondence.module.scss';
import { CorrespondenceTableHeader } from './CorrespondenceTableHeader';
import { DocumentClient } from '../../../../../../clients/DocumentClient';
interface CorrespondenceProps {
  documents: CCPDocument[];
}

export const Correspondence: React.FC<CorrespondenceProps> = ({
  documents = [],
}) => {
  const userInfo = useSelector((state: AppState) => getUserInformation(state));
  const tokens = useSelector((state: AppState) => getCredentials(state));

  const fetchDocument = (document: CCPDocument) => {
    try {
      if (userInfo) {
        AgentService.getCCPDocument(
          userInfo?.agentId,
          document.onbaseDocumentId,
          tokens,
        ).then((result) => {
          const {
            data: { docStream, mimeType },
          } = result;
          DocumentClient.showDocument(docStream, mimeType);
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className={styles['root']}>
      <div className={styles['header']}>Correspondence</div>
      <CorrespondenceTableHeader />
      <div className={styles['table']}>
        {documents.map((document, index) => {
          return (
            <div
              className={styles['row']}
              key={`${document.documentType}${index}`}
            >
              <div className={styles['date']}>
                {formatDate(document.createdAt || new Date(), 'MM/DD/YYYY')}
              </div>
              <div className={styles['name']}>{document.documentType}</div>
              <div className={styles['view-button']}>
                <SecondaryButton
                  text={'View'}
                  size={ButtonSize.XSMALL}
                  onClick={() => fetchDocument(document)}
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
