import produce from 'immer';
import { Action } from 'redux';
import {
  FetchProductsByBranchSuccessAction,
  ProductActionType,
} from './product.action';
import { Product } from '../../service/service.types';

export interface ProductState {
  products: Product[] | undefined;
}

export const productReducer = (
  state: ProductState = { products: undefined },
  action: Action<ProductActionType>,
): ProductState => {
  return produce(state, (draftState) => {
    switch (action.type) {
      case 'PRODUCT_FETCH_BY_BRANCH_SUCCESS':
        draftState.products = (
          action as FetchProductsByBranchSuccessAction
        ).result;
        return draftState;
      case 'PRODUCT_FETCH_BY_BRANCH_FAILURE':
        draftState.products = [];
        return draftState;
      default:
        return state;
    }
  });
};
