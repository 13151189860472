import dayjs from 'dayjs';

export type DateGroupingOption =
  | 'last_7_days'
  | 'last_14_days'
  | 'this_month'
  | 'last_month'
  | 'last_3_months'
  | 'last_6_months'
  | 'last_12_months'
  | 'this_year'
  | 'last_year'
  | 'all_time';

enum DateFormats {
  day = 'MMMM DD, YYYY',
  month = 'MMMM, YYYY',
  year = 'YYYY',
}

export function formatDateForDisplay(
  dateString: string,
  type: 'year' | 'month' | 'day',
): string {
  const splitString = dateString.split('T')[0];
  return dayjs(splitString).format(DateFormats[type]);
}

export function snakeCaseToKebabCase(snakeCaseString: string): string {
  const splitString = snakeCaseString?.split('_');
  return splitString
    ?.map((stringItem: string) => {
      const capitalizedString =
        stringItem.charAt(0).toUpperCase() + stringItem.slice(1);
      return capitalizedString;
    })
    ?.join(' ');
}

export function buildChartSubtitleString(type: DateGroupingOption): string {
  const dateFormat = DateFormats.day;
  const today = dayjs().format(dateFormat);
  let dateFrom = '';
  let dateTo = '';

  if (type === 'last_7_days') {
    dateFrom = dayjs().subtract(7, 'day').format(dateFormat);
    dateTo = today;
  } else if (type === 'last_14_days') {
    dateFrom = dayjs().subtract(14, 'day').format(dateFormat);
    dateTo = today;
  } else if (type === 'this_month') {
    dateFrom = dayjs().startOf('month').format(dateFormat);
    dateTo = dayjs().endOf('month').format(dateFormat);
  } else if (type === 'last_month') {
    dateFrom = dayjs().subtract(1, 'month').startOf('month').format(dateFormat);
    dateTo = dayjs().subtract(1, 'month').endOf('month').format(dateFormat);

    // NOTE: Last N Months always include the current month so we subtract by one less than the range
    // i.e. with last 3 months we subtract by 2 instead of 3
  } else if (type === 'last_3_months') {
    dateFrom = dayjs().subtract(2, 'month').startOf('month').format(dateFormat);
    dateTo = dayjs().endOf('month').format(dateFormat);
  } else if (type === 'last_6_months') {
    dateFrom = dayjs().subtract(5, 'month').startOf('month').format(dateFormat);
    dateTo = dayjs().endOf('month').format(dateFormat);
  } else if (type === 'last_12_months') {
    dateFrom = dayjs()
      .subtract(11, 'month')
      .startOf('month')
      .format(dateFormat);
    dateTo = dayjs().endOf('month').format(dateFormat);
  } else if (type === 'this_year') {
    dateFrom = dayjs().startOf('year').format(dateFormat);
    dateTo = dayjs().endOf('year').format(dateFormat);
  } else if (type === 'last_year') {
    dateFrom = dayjs().subtract(1, 'year').startOf('year').format(dateFormat);
    dateTo = dayjs().subtract(1, 'year').endOf('year').format(dateFormat);
  } else {
    return `All Time`;
  }
  return `From ${dateFrom} to ${dateTo}`;
}
