import React, { useCallback, useState } from 'react';
import {
  AgentProducer,
  Hierarchy,
  Product,
} from '../../../../../../../service/service.types';
import { HierarchySection } from '../HierarchySection/HierarchySection';
import styles from './HierarchyList.module.scss';
import { Pagination } from '../../../../../../modules/Pagination/Pagination';
import { chunk } from 'lodash';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../../../../rootReducer';
import { Option } from '@nglic/component-lib/build';
import { HierarchyUpdate } from '../../hierarchy.reducer';

export const HierarchyList: React.FC<{
  sectionId: string;
  product: Product;
  upLineAgent: Partial<AgentProducer>;
  hierarchies: Hierarchy[];
  newAgentHierarchyCommissionMap: Record<string, Option>;
  newAgentHierarchies: Record<string, HierarchyUpdate>;
  onAddAgentToHierarchy: (props: {
    hierarchyList: Hierarchy[];
    newAgentHierarchyCommissionMap: Record<string, Option>;
    newAgentHierarchies: Record<string, HierarchyUpdate>;
  }) => void;
  onRemoveAgentFromHierarchy: (props: {
    hierarchyList: Hierarchy[];
    hierarchyId: string;
  }) => void;
}> = ({
  hierarchies,
  upLineAgent,
  product,
  newAgentHierarchyCommissionMap,
  newAgentHierarchies,
  onAddAgentToHierarchy,
  onRemoveAgentFromHierarchy,
}) => {
  const [page, setPage] = useState<number>(1);
  const [newAgentName, setNewAgentName] = useState<string>('');
  const newAgentInfo = useSelector(
    (state: AppState) => state.presentation.addAgent.generalInformation,
  );
  React.useEffect(() => {
    if (newAgentInfo !== 'INCOMPLETE') {
      setNewAgentName(`${newAgentInfo.firstName} ${newAgentInfo.lastName}`);
    }
  }, [newAgentInfo]);

  const onUpdateHierarchy = useCallback(
    (value: { level?: Option; hierarchy: Hierarchy }) => {
      const { level, hierarchy } = value;
      if (level) {
        const hierarchyUpdate = {
          originalHierarchy: hierarchy,
          updatedHierarchy: [
            ...hierarchy.agents,
            {
              productId: product.id,
              productName: product.name,
              upLineAgentId: upLineAgent.id,
              upLineAgentName: upLineAgent?.name,
              commissionLevel: level.id,
              agentName: newAgentName,
            },
          ],
        };
        onAddAgentToHierarchy({
          hierarchyList: hierarchies,
          newAgentHierarchyCommissionMap: {
            ...newAgentHierarchyCommissionMap,
            [hierarchy.id]: level,
          },
          newAgentHierarchies: {
            ...newAgentHierarchies,
            [hierarchy.id]: hierarchyUpdate,
          },
        });
      } else {
        onRemoveAgentFromHierarchy({
          hierarchyList: hierarchies,
          hierarchyId: hierarchy.id,
        });
      }
    },
    [
      newAgentHierarchies,
      newAgentHierarchyCommissionMap,
      onAddAgentToHierarchy,
      onRemoveAgentFromHierarchy,
      newAgentName,
    ],
  );
  const currentHierarchies = chunk(hierarchies || [], 3);
  const totalPages = currentHierarchies.length;
  return (
    <div className={styles['root']}>
      <div className={styles['title']}>
        {upLineAgent &&
          `${upLineAgent.name} (${upLineAgent.producerId}) appears in (${hierarchies?.length}) hierarchies. Select which hierarchies to add the new agent to.`}
      </div>
      <div className={styles['list-container']}>
        <Pagination
          onPageClick={setPage}
          currentPage={page}
          numberOfPages={totalPages}
          includePagerArrows
        />
        <div className={styles['list']}>
          {currentHierarchies[page - 1]?.map((hierarchy, index) => {
            const uplineAgentCommissionLevel =
              hierarchy.agents[hierarchy.agents.length - 1]?.commissionLevel;
            return (
              <HierarchySection
                key={index}
                hierarchy={hierarchy}
                maxCommissionLevel={uplineAgentCommissionLevel}
                onUpdateHierarchy={onUpdateHierarchy}
                selectedCommissionLevel={
                  newAgentHierarchyCommissionMap[hierarchy.id]
                }
              />
            );
          })}
        </div>
        <Pagination
          onPageClick={setPage}
          currentPage={page}
          numberOfPages={totalPages}
          includePagerArrows
        />
      </div>
    </div>
  );
};
