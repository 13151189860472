import { EarlyPayOff } from './../../../../../../service/service.types';
import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import { AppState } from '../../../../../../rootReducer';
import { getCredentials } from '../../../../../shared/selectors/getCredentials';
import { PolicyPayment } from '../../../../../../service/service.types';
import { PolicyService } from 'service/PolicyService';

export type PaymentHistoryActionType =
  | 'PAYMENT_HISTORY_FETCH__SUCCESS'
  | 'PAYMENT_HISTORY_FETCH_FAILURE'
  | 'EARLY_PAYOFF_FETCH_SUCCESS'
  | 'EARLY_PAYOFF_FETCH_FAILURE'
  | 'NONE';

export interface FetchPaymentHistorySuccessAction
  extends Action<PaymentHistoryActionType> {
  type: 'PAYMENT_HISTORY_FETCH__SUCCESS';
  policyPayments: PolicyPayment[];
}

export interface FetchPaymentHistoryErrorAction
  extends Action<PaymentHistoryActionType> {
  type: 'PAYMENT_HISTORY_FETCH_FAILURE';
  error: Error;
}

export interface FetchEarlyPayOffSuccessAction
  extends Action<PaymentHistoryActionType> {
  type: 'EARLY_PAYOFF_FETCH_SUCCESS';
  earlyPayOffs: EarlyPayOff[];
}

export interface FetchEarlyPayOffErrorAction
  extends Action<PaymentHistoryActionType> {
  type: 'EARLY_PAYOFF_FETCH_FAILURE';
  error: Error;
}

export const fetchPolicyPayments =
  (policyId: string): ThunkAction<void, AppState, any, any> =>
  async (dispatch, getState: () => AppState) => {
    const tokens = getCredentials(getState());

    try {
      const { policyPayments } = await PolicyService.getPolicyPayments(
        tokens,
        policyId,
      );

      dispatch({
        type: 'PAYMENT_HISTORY_FETCH__SUCCESS',
        policyPayments,
      });
    } catch (error) {
      dispatch({
        type: 'PAYMENT_HISTORY_FETCH_FAILURE',
        error,
      });
    }
  };

export const fetchEarlyPayoffs =
  (policyId: string): ThunkAction<void, AppState, any, any> =>
  async (dispatch, getState: () => AppState) => {
    const tokens = getCredentials(getState());

    try {
      const { earlyPayOffs } = await PolicyService.getEarlyPayOffs(
        tokens,
        policyId,
      );

      dispatch({
        type: 'EARLY_PAYOFF_FETCH_SUCCESS',
        earlyPayOffs,
      });
    } catch (error) {
      dispatch({
        type: 'EARLY_PAYOFF_FETCH_FAILURE',
        error,
      });
    }
  };
