import { createSelector } from 'reselect';
import { UserInfo } from '../../../service/service.types';
import { getUserInformation } from './getUserInformation';

export const getAgentId = createSelector([getUserInformation], (userInfo) =>
  userInfo ? userInfo.agentId : undefined,
);

export const getBranchCode = createSelector([getUserInformation], (userInfo) =>
  userInfo ? userInfo.branchCode : undefined,
);

export const isUserInfo = (user: UserInfo | undefined): user is UserInfo => {
  return user !== undefined;
};
