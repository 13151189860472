import styles from './AgentInformation.module.scss';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CloseIcon from '@material-ui/icons/Close';
import { TerminateAgent } from './TerminateAgent/TerminateAgent';
import React from 'react';
import { Action } from './Action';
import { FeatureWrapper } from '../../../../modules/FeatureWrapper/FeatureWrapper';
import AuthContext from '../../../../context/AuthContext';

export const ActionsMenu = ({ handleModalClose: handlePaneClose, agent }) => {
  return (
    <div className={styles['actions-menu']}>
      <IconButton
        aria-label={'open-actions'}
        className={styles['open-actions']}
      >
        <MoreVertIcon className={styles['icon']} />
      </IconButton>
      <div className={styles['actions']}>
        <Action handleClick={handlePaneClose} ariaLabel={'close-pane'}>
          <div className={styles['close-button']}>
            <CloseIcon />
            <span>Close</span>
          </div>
        </Action>
        <AuthContext.Consumer>
          {(decodedAuthToken) => {
            return (
              <FeatureWrapper
                featureName={'terminate_agent'}
                params={{ roles: decodedAuthToken['cognito:groups'] }}
              >
                <TerminateAgent agent={agent} />
              </FeatureWrapper>
            );
          }}
        </AuthContext.Consumer>
      </div>
    </div>
  );
};
