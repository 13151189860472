import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { FourOhFour } from './components/Pages/FourOhFour/FourOhFour';
import { Login } from './components/Pages/Login/Login';
import { AddAgent } from './components/Pages/Agent/AddAgent/AddAgent';
import { Profile } from './components/Pages/Profile/Profile';
import { LandingPage } from './components/Pages/LandingPage/LandingPage';
import { ContractPage } from './components/Pages/Contract/ContractPage';
import { AgentManagement } from './components/Pages/Agent/AgentManagement/AgentManagement';
import { ManageHierarchies } from './components/Pages/ManageHierarchies/ManageHierarchies';
import { ProtectedRoute } from './components/Pages/ProtectedRoute';
import { NotAuthorizedPage } from './components/Pages/NotAuthorizedPage/NotAuthorizedPage';
import { SubmittedPage } from './components/Pages/Contract/Submitted/Submitted';
import { RESET_PASSWORD_ROUTE } from './util/routing';

import { ManageBusiness } from 'components/Pages/ManageBusiness/ManageBusiness';
import { Redirect } from './components/Pages/Login/MFA/Redirect';
import { TrackedRoute } from './components/Pages/TrackedRoute';
import { LoginAsAgent } from './components/Pages/Login/LoginAsAgent/LoginAsAgent';
import { AgentSupport } from './components/Pages/AgentSupport/AgentSupport';
import { AddUser } from './components/Pages/AgentSupport/AddUser/AddUser';
import KnowledgeCenter from './components/Pages/KnowledgeCenter/KnowledgeCenter';

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <Switch>
        <ProtectedRoute exact path={'/'} component={LandingPage} />
        <TrackedRoute exact path={'/login'} component={Login} />
        <TrackedRoute exact path={'/redirect'} component={Redirect} />
        <TrackedRoute exact path={RESET_PASSWORD_ROUTE} component={Login} />
        <ProtectedRoute exact path={'/support'} component={AgentSupport} />
        <ProtectedRoute
          exact
          path={'/contract/submitted'}
          component={SubmittedPage}
        />
        <ProtectedRoute
          exact
          path={'/contract/:subPage'}
          component={ContractPage}
        />
        <ProtectedRoute exact path={'/agent'} component={AgentManagement} />
        <ProtectedRoute
          exact
          path={'/agent/add-agent/:subPage'}
          component={AddAgent}
        />
        <ProtectedRoute exact path={'/support'} component={LoginAsAgent} />
        <ProtectedRoute exact path={'/support/add-user'} component={AddUser} />
        <ProtectedRoute
          exact
          path={'/manage-hierarchies'}
          component={ManageHierarchies}
        />
        <ProtectedRoute
          exact
          path={'/manage-business/:subPage/:id?'}
          component={ManageBusiness}
        />
        <ProtectedRoute
          exact
          path={'/knowledge-center/:subPage'}
          component={KnowledgeCenter}
        />
        <ProtectedRoute exact path={'/profile'} component={Profile} />
        <TrackedRoute path={'/not-authorized'} component={NotAuthorizedPage} />
        <TrackedRoute component={FourOhFour} />
      </Switch>
    </BrowserRouter>
  );
};

export default App;
