import React from 'react';
import { Product, State } from '../../../../../../service/service.types';
import {
  ErrorMessage,
  FlexDirection,
  NglicRadioGroup,
  NglicSimpleSelect,
  Option,
  TextInput,
} from '@nglic/component-lib';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../../../rootReducer';
import styles from './InsuredInformation.module.scss';

interface AgeProps {
  validateAgeInput: (value: string) => void;
  age: string | undefined;
  error?: ErrorMessage;
}
interface GenderProps {
  onClick: (value: 'male' | 'female' | undefined) => void;
  gender: 'male' | 'female' | undefined;
}

interface ResidentStateProps {
  state: Option | undefined;
  onChange: (value: Option) => void;
}

interface HealthQuestionProps {
  onClick: (value: 'Yes' | 'No' | undefined) => void;
  question: 'Yes' | 'No' | undefined;
}

export const healthQuestionVisibility = (
  product: Product | undefined,
): boolean => {
  const productsNotAllowed = [
    'Series 9',
    'Assetguard v2.0',
    'Special Considerations',
  ];
  if (product) {
    if (!productsNotAllowed.includes(product.name)) {
      return true;
    }
  }

  return false;
};

export interface InsuredInformationProps {
  product?: Product;
  residentState: ResidentStateProps;
  gender: GenderProps;
  healthQuestion: HealthQuestionProps;
  age: AgeProps;
}

export const InsuredInformation: React.FC<InsuredInformationProps> = ({
  product,
  residentState,
  gender,
  healthQuestion,
  age,
}) => {
  const states: State[] | undefined = useSelector(
    (state: AppState) => state.data.state.states,
  );

  const options = states?.reduce<any[]>((acc, next) => {
    return [
      ...acc,
      {
        id: next.code,
        name: next.desc,
      },
    ];
  }, []);

  const genderOptions = [
    {
      label: 'Male',
      onClick: () => gender.onClick('male'),
      selected: gender.gender === 'male',
    },
    {
      label: 'Female',
      onClick: () => gender.onClick('female'),
      selected: gender.gender === 'female',
    },
  ];

  const healthQuestionOptions = [
    {
      label: 'Yes',
      onClick: () => healthQuestion.onClick('Yes'),
      selected: healthQuestion.question === 'Yes',
    },
    {
      label: 'No',
      onClick: () => healthQuestion.onClick('No'),
      selected: healthQuestion.question === 'No',
    },
  ];
  return (
    <div className={styles['root']}>
      <div className={styles['title']}> Insured Information</div>
      <NglicSimpleSelect
        selectedOption={residentState.state}
        onChange={(value) => {
          residentState.onChange(value);
        }}
        label={'State of Residence'}
        options={options || []}
        loading={!states}
      />
      <div className={styles['age-input']}>
        <TextInput
          onChange={(newValue: string) => {
            age.validateAgeInput(newValue);
          }}
          error={age.error}
          label="Age"
        />
      </div>
      <div className={styles['radio-button']}>
        <NglicRadioGroup
          radios={genderOptions}
          title="Gender"
          radiosFlexDirection={FlexDirection.COLUMN}
        />
      </div>
      <div className={styles['radio-button']}>
        {healthQuestionVisibility(product) && (
          <NglicRadioGroup
            radios={healthQuestionOptions}
            title="Health Question"
            radiosFlexDirection={FlexDirection.COLUMN}
          />
        )}
      </div>
    </div>
  );
};
