import { setGlobalError } from '../../../../shared/error.action';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { Reserves } from 'service/service.types';
import { AppState } from '../../../../../rootReducer';
import { AgentService } from '../../../../../service/AgentService';
import { loadComplete, loadStarted } from '../../../../shared/loading.action';
import { getCredentials } from '../../../../shared/selectors/getCredentials';
import { GeneralAgentInformation } from '../AgentInformation/agentInformation.reducer';
import { HierarchyUpdate } from '../Hierarchy/hierarchy.reducer';
import { getUserInformation } from '../../../../shared/selectors/getUserInformation';

export type ReviewAddAgentActionType =
  | 'REVIEW_ADD_AGENT'
  | 'REVIEW_ADD_AGENT_FAILURE'
  | 'REVIEW_ADD_AGENT_ERROR'
  | 'CLEAR_REVIEW_ADD_AGENT';

export interface ReviewAddAgentAction extends Action<ReviewAddAgentActionType> {
  type: 'REVIEW_ADD_AGENT';
}

export interface ReviewAddAgentFailureAction
  extends Action<ReviewAddAgentActionType> {
  type: 'REVIEW_ADD_AGENT_FAILURE';
  error: Error;
}

export interface ClearReviewAddAgentErrorAction
  extends Action<ReviewAddAgentActionType> {
  type: 'REVIEW_ADD_AGENT_ERROR';
}

export interface ClearReviewAction extends Action<ReviewAddAgentActionType> {
  type: 'CLEAR_REVIEW_ADD_AGENT';
}

export const createClearReviewAddAgentErrorAction =
  (): ClearReviewAddAgentErrorAction => ({
    type: 'REVIEW_ADD_AGENT_ERROR',
  });

export const clearReview = (): ClearReviewAction => ({
  type: 'CLEAR_REVIEW_ADD_AGENT',
});

export const addAgent =
  (
    agentInfo: GeneralAgentInformation,
    hierarchyUpdates: HierarchyUpdate[],
    reserves: Reserves,
    approverId: string,
    comment: string,
  ): ThunkAction<void, AppState, any, any> =>
  async (dispatch, getState) => {
    dispatch(loadStarted('ADD_AGENT'));

    const tokens = getCredentials(getState());

    const userInfo = getUserInformation(getState());

    if (!userInfo) {
      dispatch(setGlobalError(new Error('basicInfo not initialized')));
      dispatch(loadComplete('ADD_AGENT'));
      return;
    }

    const { agentId, branchCode } = userInfo;
    const promise = async (): Promise<Partial<{ success: boolean }>> => {
      return await AgentService.createAgent(
        {
          firstName: agentInfo.firstName,
          lastName: agentInfo.lastName,
          email: agentInfo.email,
          mailingPreference: agentInfo.mailingPreference,
        },
        hierarchyUpdates,
        reserves,
        branchCode,
        tokens,
        approverId || agentId,
        comment,
      );
    };

    dispatch({
      types: ['ADD_AGENT', 'REVIEW_ADD_AGENT', 'REVIEW_ADD_AGENT_FAILURE'],
      promise,
      bypassGlobalError: false,
    });

    loadComplete('ADD_AGENT');
  };
