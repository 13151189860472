import { Action } from 'redux';
import produce from 'immer';

import { CommissionsActivity } from 'service/service.types';
import {
  CommissionsDetailByPeriodActionType,
  FetchCommissionsDetailByPeriodErrorAction,
  FetchCommissionsDetailByPeriodSuccessAction,
} from './commissionsDetailByPeriod.action';

export interface CommissionDetailByPeriodInitState {
  commissions: CommissionsActivity[] | undefined;
  totalCommissions: number | undefined;
}

export type CommissionDetailByPeriodErrorState = {
  error: Error;
} & CommissionDetailByPeriodInitState;

export type CommissionDetailByPeriodState =
  | CommissionDetailByPeriodInitState
  | CommissionDetailByPeriodErrorState;

export const commissionsDetailByPeriodReducer = (
  state: CommissionDetailByPeriodInitState = {
    commissions: undefined,
    totalCommissions: undefined,
  },
  action: Action<CommissionsDetailByPeriodActionType>,
): CommissionDetailByPeriodState => {
  return produce(state, (draftState: CommissionDetailByPeriodState) => {
    switch (action.type) {
      case 'COMMISSIONS_DETAIL_BY_PERIOD_FETCH__SUCCESS':
        draftState = {
          ...draftState,
          commissions: (action as FetchCommissionsDetailByPeriodSuccessAction)
            .result.commissions,
          totalCommissions: (
            action as FetchCommissionsDetailByPeriodSuccessAction
          ).result.totalCommissions,
        };
        return draftState;
      case 'COMMISSIONS_DETAIL_BY_PERIOD_FETCH_FAILURE':
        draftState = {
          ...draftState,
          error: (action as FetchCommissionsDetailByPeriodErrorAction).error,
          commissions: [],
        };
        return draftState;
      default:
        return state;
    }
  });
};
