import { CognitoClient } from '../clients/CognitoClient';
import {
  ConfirmForgotPasswordCreds,
  Credentials,
  CustomChallengeCreds,
  LoginResponseTypes,
  SuccessfulAuthResponse,
  TempPasswordCreds,
  UnsuccessfulTempPasswordResetResponse,
  RequestMFAEmail,
} from './service.types';
import { BFFClient } from '../clients/BFFClient';
import { Auth } from 'aws-amplify';
import { BaseService } from './BaseService';

enum CredentialsServicePaths {
  UNLOCK_ACCOUNT = '/ao/user/unlock-account',
  REGISTER_DEVICE = '/ao/user/register-device',
}

export class CredentialsService extends BaseService {
  static async login(
    creds: Credentials,
    metaData?: Record<string, any>,
  ): Promise<LoginResponseTypes> {
    let res;
    try {
      res = await Auth.signIn(creds.username, creds.password, metaData);
    } catch (e) {
      if (e.message.toLowerCase().includes('incorrect username or password')) {
        return {
          message: 'Credentials invalid',
        };
      } else if (e.message.toLowerCase().includes('account is locked')) {
        return {
          message: 'Account Locked',
        };
      } else {
        return {
          message: 'Unknown error occurred',
        };
      }
    }

    if (res.signInUserSession) {
      const { accessToken, refreshToken, idToken } = res.signInUserSession;
      return {
        accessToken: accessToken.jwtToken,
        refreshToken: refreshToken.token,
        idToken: idToken.jwtToken,
      };
    }

    if (
      res.challengeName === 'NEW_PASSWORD_REQUIRED' ||
      res.challengeName === 'CUSTOM_CHALLENGE'
    ) {
      return {
        challenge: res.challengeName,
        session: res.Session as string,
      };
    }

    return {
      message: 'Credentials invalid',
    };
  }

  static async requestMFAEmail(
    creds: RequestMFAEmail,
  ): Promise<LoginResponseTypes> {
    let res;
    try {
      res = await CredentialsService.getCognitoClient().respondToChallenge(
        creds.session,
        'CUSTOM_CHALLENGE',
        {
          ANSWER: 'REQUEST_EMAIL',
          USERNAME: creds.username,
        },
      );
    } catch (e) {
      throw e;
    }

    if (res.ChallengeName === 'CUSTOM_CHALLENGE') {
      return {
        challenge: res.ChallengeName,
        session: res.Session as string,
      };
    }

    return {
      message: 'Credentials invalid',
    };
  }

  static async respondToCustomChallenge(
    creds: CustomChallengeCreds,
    metaData?: Record<string, any>,
  ): Promise<LoginResponseTypes> {
    let res;
    try {
      res = await CredentialsService.getCognitoClient().respondToChallenge(
        creds.session,
        'CUSTOM_CHALLENGE',
        {
          ANSWER: creds.code,
          USERNAME: creds.username,
        },
        metaData,
      );
    } catch (e) {
      throw e;
    }

    if (res.AuthenticationResult) {
      const { AccessToken, RefreshToken, IdToken } = res.AuthenticationResult;
      return {
        accessToken: AccessToken,
        refreshToken: RefreshToken,
        idToken: IdToken,
      };
    }

    return {
      message: 'Credentials invalid',
    };
  }

  static async refreshTokens(
    refreshToken: string,
  ): Promise<SuccessfulAuthResponse> {
    let res;
    try {
      res = await CredentialsService.getCognitoClient().refreshTokens(
        refreshToken,
      );
    } catch (e) {
      throw new Error('Credentials invalid');
    }

    if (res.AuthenticationResult) {
      const { AccessToken, RefreshToken, IdToken } = res.AuthenticationResult;
      return {
        accessToken: AccessToken as string,
        refreshToken: RefreshToken as string,
        idToken: IdToken as string,
      };
    }

    throw new Error('Credentials invalid');
  }

  static async generatePasswordResetCode(email: string): Promise<void> {
    await CredentialsService.getCognitoClient().generatePasswordResetCode(
      email,
    );
  }

  static async resetPassword(creds: ConfirmForgotPasswordCreds): Promise<void> {
    await CredentialsService.getCognitoClient().confirmForgotPassword(creds);
  }

  static async resetTempPassword(
    creds: TempPasswordCreds,
  ): Promise<SuccessfulAuthResponse | UnsuccessfulTempPasswordResetResponse> {
    let res;
    try {
      res = await CredentialsService.getCognitoClient().respondToChallenge(
        creds.session,
        'NEW_PASSWORD_REQUIRED',
        {
          NEW_PASSWORD: creds.password,
          USERNAME: creds.username,
        },
      );
    } catch (e) {
      return {
        message: 'Unable to reset temp password',
      };
    }

    if (res.AuthenticationResult) {
      const { AccessToken, RefreshToken, IdToken } = res.AuthenticationResult;
      return {
        accessToken: AccessToken as string,
        refreshToken: RefreshToken as string,
        idToken: IdToken as string,
      };
    }

    return {
      message: 'Unable to reset temp password',
    };
  }

  static async unlockAccount(email: string): Promise<void> {
    await CredentialsService.getBFFClient().post<{ success: boolean }>(
      CredentialsServicePaths.UNLOCK_ACCOUNT,
      { email },
    );
  }
  static getCognitoClient(): CognitoClient {
    return new CognitoClient();
  }

  static getBFFClient(): BFFClient {
    return new BFFClient();
  }
}
