import React from 'react';
import styles from './ReviewChangesModal.module.scss';

interface HierarchyChange {
  agentName: string;
  oldCommissionLevel: string;
  newCommissionLevel: string;
}

export const ChangeSection: React.FC<{
  description: string;
  changes: HierarchyChange[];
  sectionLabel?: string;
}> = ({ description, changes, sectionLabel }) => {
  if (changes.length === 0) {
    return null;
  }

  const changeRows = changes.map((change, index) => (
    <div
      className={styles['change-row']}
      key={index}
      aria-label={`${change.agentName} change row`}
    >
      <div>{change.agentName}</div>
      <div>{change.oldCommissionLevel}</div>
      <div>{change.newCommissionLevel}</div>
    </div>
  ));

  return (
    <div className={styles['change-section']} aria-label={sectionLabel}>
      <div className={styles['description']}>{description}</div>
      <div className={styles['header-row']}>
        <div>Agent</div>
        <div>Original</div>
        <div>New</div>
      </div>
      <div className={styles['change-row-container']}>{changeRows}</div>
    </div>
  );
};
