import { BFFClient } from '../clients/BFFClient';
import { CommissionsActivity } from './service.types';
import { BaseService } from './BaseService';

const CommissionsAPIPaths = {
  COMMISSION_ACTIVITIES: ({
    branchCode,
    agentId,
    limit,
    page,
  }: Partial<GetCommissionsActivitiesParams>) =>
    `/ao/commission-activity/branch/${branchCode}/agent/${agentId}?limit=${limit}&page=${page}`,
  RESERVES_ACTIVITIES: ({
    branchCode,
    agentId,
    limit,
    page,
  }: Partial<GetCommissionsActivitiesParams>) =>
    `/ao/commission-activity/reserves/branch/${branchCode}/agent/${agentId}?limit=${limit}&page=${page}`,
  COMMISSION_STATEMENT_DATES: ({
    agentId,
    page,
    limit,
  }: Partial<GetCommissionsActivitiesParams>) =>
    `/ao/commission-activity/statement-dates/agent/${agentId}?limit=${limit}&page=${page}`,
  COMMISSION_STATEMENT: (statementDate) =>
    `/ao/commission-activity/commission-statement/${statementDate}`,
};

interface GetCommissionsActivitiesParams {
  branchCode: string;
  agentId: string;
  credentials: { accessToken: string; idToken: string };
  page?: number;
  limit?: number;
}

interface GetCommissionStatementPDF {
  statementDate: string;
  credentials: { accessToken: string; idToken: string };
}

export class CommissionActivityService extends BaseService {
  static getBFFClient(): BFFClient {
    return new BFFClient();
  }

  static async getAllCommissionActivities({
    branchCode,
    agentId,
    credentials,
    page = 1,
    limit = 50,
  }: GetCommissionsActivitiesParams): Promise<{
    commissions: CommissionsActivity[];
    totalCommissions: number;
  }> {
    return await CommissionActivityService.getBFFClient().get<{
      commissions: CommissionsActivity[];
      totalCommissions: number;
    }>(
      CommissionsAPIPaths.COMMISSION_ACTIVITIES({
        branchCode,
        agentId,
        limit,
        page,
      }),
      CommissionActivityService.getBaseHeaders(
        credentials.accessToken,
        credentials.idToken,
      ),
    );
  }

  static async getAllCommissionReservesActivities({
    branchCode,
    agentId,
    credentials,
    page = 1,
    limit = 50,
  }: GetCommissionsActivitiesParams): Promise<{
    reserves: CommissionsActivity[];
    totalReserves: number;
  }> {
    return await CommissionActivityService.getBFFClient().get<{
      reserves: CommissionsActivity[];
      totalReserves: number;
    }>(
      CommissionsAPIPaths.RESERVES_ACTIVITIES({
        branchCode,
        agentId,
        limit,
        page,
      }),
      this.getBaseHeaders(credentials.accessToken, credentials.idToken),
    );
  }

  static async getCommissionsStatementDates({
    agentId,
    credentials,
    page = 1,
    limit = 50,
  }: Pick<
    GetCommissionsActivitiesParams,
    'agentId' | 'credentials' | 'limit' | 'page'
  >): Promise<{
    dates: string[];
    totalStatementDates: number;
  }> {
    console.info('here');
    return await this.getBFFClient().get<{
      dates: string[];
      totalStatementDates: number;
    }>(
      CommissionsAPIPaths.COMMISSION_STATEMENT_DATES({ agentId, page, limit }),
      this.getBaseHeaders(credentials.accessToken, credentials.idToken),
    );
  }

  static async getCommissionStatementPdf({
    statementDate,
    credentials,
  }: GetCommissionStatementPDF): Promise<{
    objectUrl: string;
  }> {
    return await this.getBFFClient().get<{
      objectUrl: string;
    }>(
      CommissionsAPIPaths.COMMISSION_STATEMENT(statementDate),
      this.getBaseHeaders(credentials.accessToken, credentials.idToken),
    );
  }
}
