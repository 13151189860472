import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { AppState } from '../../../../rootReducer';
import { clearAgentInformation } from './AgentInformation/agentInformation.action';
import { clearAllHierarchies } from './Hierarchy/hierarchy.action';
import { clearReserves } from './Reserves/reserves.action';
import { clearReview } from './Review/review.action';

export type AddAgentActionType = 'CLEAR_ADD_AGENT_DATA';

export interface ClearAddAgentAction extends Action<AddAgentActionType> {
  type: 'CLEAR_ADD_AGENT_DATA';
}

export const clearAddAgentData =
  (): ThunkAction<void, AppState, any, any> => async (dispatch) => {
    dispatch(clearAgentInformation());
    dispatch(clearAllHierarchies());
    dispatch(clearReserves());
    dispatch(clearReview());
  };
