import { setGlobalError } from '../../shared/error.action';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { AppState } from '../../../rootReducer';
import { AgentService } from '../../../service/AgentService';
import { loadComplete, loadStarted } from '../../shared/loading.action';
import { getCredentials } from '../../shared/selectors/getCredentials';
import { getUserInformation } from '../../shared/selectors/getUserInformation';

export type TerminateAgentActionType =
  | 'TERMINATE_AGENT'
  | 'TERMINATE_AGENT_FAILURE';

export interface TerminateAgentAction extends Action<TerminateAgentActionType> {
  type: 'TERMINATE_AGENT';
}

export interface TerminateAgentFailureAction
  extends Action<TerminateAgentActionType> {
  type: 'TERMINATE_AGENT_FAILURE';
  error: Error;
}

export const terminateAgent =
  (
    agentId: string,
    producerIds: string[],
  ): ThunkAction<void, AppState, any, any> =>
  async (dispatch, getState) => {
    dispatch(loadStarted('TERMINATE_AGENT'));

    const tokens = getCredentials(getState());
    const userInfo = getUserInformation(getState());

    if (!userInfo) {
      dispatch(setGlobalError(new Error('basicInfo not initialized')));
      dispatch(loadComplete('TERMINATE_AGENT'));
      return;
    }

    const { branchCode, firstName, lastName } = userInfo;
    const terminatedBy = firstName + ' ' + lastName;
    const promise = async (): Promise<Partial<{ success: boolean }>> => {
      return await AgentService.terminateAgent(
        branchCode,
        agentId,
        tokens,
        producerIds,
        terminatedBy,
      );
    };

    dispatch({
      types: ['TERMINATE_AGENT', 'TERMINATE_AGENT_FAILURE'],
      promise,
      bypassGlobalError: false,
    });

    loadComplete('TERMINATE_AGENT');
  };
