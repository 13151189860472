import { v4 as uuidv4 } from 'uuid';

export abstract class BaseService {
  static getBaseHeaders(accessToken = '', idToken = ''): Record<string, any> {
    const masqueradeCognitoUUID = localStorage.getItem(
      'NGLIC_MASQUERADE_COGNITO_UUID',
    );

    return masqueradeCognitoUUID
      ? {
          Authorization: accessToken,
          'x-id-token': idToken,
          'request-id': uuidv4(),
          'x-masquerade-cognito-uuid': masqueradeCognitoUUID || '',
        }
      : {
          Authorization: accessToken,
          'x-id-token': idToken,
          'request-id': uuidv4(),
        };
  }
}
