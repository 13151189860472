import React from 'react';
import { PageEnum, PrimaryButton } from '@nglic/component-lib/build';

import styles from './AgentSubHeader.module.scss';
import { AddAgentPages } from '../../AddAgent/AddAgent';
import { useHistory } from 'react-router-dom';

export const AgentSubHeader: React.FC = () => {
  const linkMap = {
    [PageEnum.AGENT]: '/agent',
  };

  const history = useHistory();

  return (
    <div className={styles['root']}>
      <div className={styles['header-text']}>Agents</div>
      <PrimaryButton
        text={'Add Agent'}
        onClick={() =>
          history.push(
            `${linkMap[PageEnum.AGENT]}/add-agent/${
              AddAgentPages.GENERAL_INFO
            }`,
          )
        }
      />
    </div>
  );
};
