import React from 'react';
import { IconButton, ListItemText, Tooltip } from '@material-ui/core';
import styles from './HierarchyModal.module.scss';
import DeleteIcon from '@material-ui/icons/Delete';
import { NglicSimpleSelect, Option } from '@nglic/component-lib/build';
import { AgentProduct } from '../../../service/service.types';
import { createLevelArray } from '@nglic/utilities-ts/build';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Info from '@material-ui/icons/Info';

export interface HierarchyItemProps {
  agent: AgentProduct;
  onRemoveItem: (agent: AgentProduct) => void;
  onLevelSelect: (agent: AgentProduct, selectedLevel: Option) => void;
  levelRange?: { min: number; max: number };
  isEditable?: boolean;
  onLevelZeroPositionMoveUp?: (agentProduct: AgentProduct) => void;
  onLevelZeroPositionMoveDown?: (agentProduct: AgentProduct) => void;
}

export const AgentHierarchyRow: React.FC<HierarchyItemProps> = ({
  onRemoveItem,
  onLevelSelect,
  agent,
  levelRange,
  isEditable,
  onLevelZeroPositionMoveDown,
  onLevelZeroPositionMoveUp,
}) => {
  const handleChange = (option: Option) => {
    onLevelSelect(agent, option);
  };
  const isCommissionLevelZero = agent.commissionLevel === '0';
  const levelOptions = levelRange
    ? createLevelArray(levelRange).reduce<Option[]>((acc, next) => {
        return [
          ...acc,
          {
            id: `${next}`,
            name: `Level ${next}`,
          },
        ];
      }, [])
    : [];

  const LevelZeroPositionToggle: React.FC<{
    agent: AgentProduct;
    isCommissionLevelZero: boolean;
    onDownArrowClick?: (agentProduct: AgentProduct) => void;
    onUpArrowClick?: (agentProduct: AgentProduct) => void;
  }> = ({ agent, onDownArrowClick, onUpArrowClick, isCommissionLevelZero }) => {
    const onDownClick = () => {
      onDownArrowClick && onDownArrowClick(agent);
    };
    const onUpClick = () => {
      onUpArrowClick && onUpArrowClick(agent);
    };
    if (isCommissionLevelZero && isEditable) {
      return (
        <div className={styles['arrow-controls']}>
          <ArrowDropUpIcon aria-label={'move-level-0-up'} onClick={onUpClick} />
          <ArrowDropDownIcon
            aria-label={'move-level-0-down'}
            onClick={onDownClick}
          />
        </div>
      );
    } else {
      return <div className={styles['row-item-small']} />;
    }
  };

  const CommissionLevelDisplay: React.FC<{ isEditable: boolean }> = ({
    isEditable,
  }) => {
    if (!isEditable) {
      return (
        <div className={styles['level']}>
          <span>{`Level ${agent.commissionLevel}`}</span>
        </div>
      );
    }
    return (
      <div className={styles['commission-dropdown']}>
        <NglicSimpleSelect
          options={levelOptions}
          disabled={isCommissionLevelZero}
          selectedOption={{
            id: agent.commissionLevel,
            name: `Level ${agent.commissionLevel}`,
          }}
          onChange={handleChange}
        />
        {isCommissionLevelZero && (
          <Tooltip title="To edit remove agent, then re-add">
            <Info />
          </Tooltip>
        )}
      </div>
    );
  };

  return (
    <div
      className={styles['agent-hierarchy-row']}
      aria-label={'agent-hierarchy-row'}
    >
      <LevelZeroPositionToggle
        isCommissionLevelZero={isCommissionLevelZero}
        agent={agent}
        onDownArrowClick={onLevelZeroPositionMoveDown}
        onUpArrowClick={onLevelZeroPositionMoveUp}
      />
      <div className={styles['main-content']}>
        <div className={styles['row-item-large']}>
          <ListItemText primary={`${agent.agentName} (${agent.producerId})`} />
        </div>
        <div className={styles['row-item']}>
          <CommissionLevelDisplay isEditable={isEditable ?? false} />
        </div>
        <div className={styles['row-item']}>
          {isEditable ? (
            <Tooltip title="Remove from hierarchy">
              <IconButton
                aria-label="delete"
                className={styles['remove-icon']}
                onClick={() => onRemoveItem(agent)}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <div />
          )}
        </div>
      </div>
    </div>
  );
};
