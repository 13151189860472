import React, { useEffect, useState } from 'react';
import styles from './RecentPolicies.module.scss';
import { GenericTable, useNglScreenSize } from '@nglic/component-lib/build';
import { TitleWithButtonInParallel } from '../../../Contract/Review/TitleWithButtonInParallel';
import { formatDate } from '../../Policies/PolicyDetail/PolicyInformation/PolicyInformationTransform';
import { useSelector } from 'react-redux';
import { AppState } from 'rootReducer';
import { PartialPolicy, Policy } from '../../../../../service/service.types';
import { SectionName } from '../../../../shared/loading.action';
import { useHistory } from 'react-router-dom';
import { RecentPoliciesMobile } from './RecentPoliciesMobile';

export const tableHeader = [
  {
    titleName: 'Effective Date',
    dataField: 'effectiveDate',
    displayFormat: (value: string): string => {
      return value && formatDate(value);
    },
  },
  {
    titleName: 'Insured Name',
    dataField: 'insuredName',
  },
  {
    titleName: 'Product',
    dataField: 'productCode',
  },
  {
    titleName: 'Agent',
    dataField: 'agentName',
  },
];

export const policiesRoute = {
  POLICIES: '/manage-business/policies',
};

export const RecentPolicies: React.FC<{ routeToNext?: () => void }> = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const history = useHistory();

  const policies: PartialPolicy[] | undefined = useSelector(
    (state: AppState) => state.presentation.policies.initialPolicies,
  );

  const sectionsLoading: SectionName[] = useSelector(
    (state: AppState) => state.presentation.loading.sectionsLoading,
  );

  useEffect(() => {
    setLoading(sectionsLoading.includes('POLICIES_FETCH_INITIAL'));
  }, [sectionsLoading]);

  const { isMobile } = useNglScreenSize();

  const createRouteTo = (route: string) => () => {
    history.push(route);
  };

  const footer = (
    <tr>
      <td colSpan={tableHeader.length + 1}>
        <div
          className={styles['link']}
          onClick={createRouteTo(policiesRoute.POLICIES)}
        >
          View All Policies
        </div>
      </td>
    </tr>
  );
  return (
    <>
      {isMobile ? (
        <RecentPoliciesMobile policies={policies} />
      ) : (
        <div className={styles['root']}>
          <TitleWithButtonInParallel
            title="Recent Policies Issued"
            removeBorder
            decreaseSpaceBetween
            helpIcon
            toolTipMessage="Only policies that have been issued will appear here. To view applications, please visit AppQore."
            customStyle={true}
          />
          <div className={styles['table']}>
            <GenericTable
              loading={loading}
              pagination={false}
              tableTitle={undefined}
              data={[...policies].slice(0, 3)}
              columns={tableHeader}
              renderFooter={() => footer}
            />
          </div>
        </div>
      )}
    </>
  );
};
