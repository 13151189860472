import { BFFClient } from '../clients/BFFClient';
import { IReportFilters } from '../components/Pages/ManageBusiness/Performance/ReportData/Report.types';
import { BaseService } from './BaseService';
import { CommissionDetailFilters, CommissionsActivity } from './service.types';

const ReportDataPaths = {
  REPORT_FILTERS: (agentId: string, branchCode: string) =>
    `/ao/reports/filters/branch/${branchCode}/agent/${agentId}`,
  REPORT_DATA: (agentId: string, reportType: string, filters) =>
    `/ao/reports/${reportType}/agent/${agentId}${formatQueryParams(filters)}`,
  POLICY_DETAILS_BY_COMMISSIONS_PERIOD: (
    agentId: string,
    limit?: number,
    page?: number,
    dateFrom?: string,
    dateGrouping?: string,
    dateTo?: string,
  ) =>
    `/ao/reports/agent/${agentId}?limit=${limit ? limit : 50}&page=${
      page ? page : 1
    }${dateGrouping ? `&dateGrouping=${dateGrouping}` : ''}${
      dateFrom ? `&dateFrom=${dateFrom}` : ''
    }${dateTo ? `&dateTo=${dateTo}` : ''}`,
};

const formatQueryParams = (projectFilters: Record<string, any>) => {
  const filters = _formatParams(projectFilters);
  const queryParams = [filters].filter((value) => value.length > 0).join('&');

  return queryParams.length > 0 ? `?${queryParams}` : '';
};

const _formatParams = (paramsObj) => {
  if (!paramsObj || Object.entries(paramsObj).length === 0) {
    return '';
  }

  return Object.entries(paramsObj).reduce((acc, next, index) => {
    if (index === 0 && next[1] !== undefined) {
      return `${next[0]}=${encodeURIComponent(next[1] as any)}`;
    }

    return `${acc}&${next[0]}=${JSON.stringify(next[1])}`;
  }, '');
};

export class ReportDataService extends BaseService {
  static getBFFClient(): BFFClient {
    return new BFFClient();
  }

  static async getReportFilters(
    credentials: {
      accessToken: string;
      idToken: string;
    },
    agentId: string,
    branchCode: string,
  ): Promise<any> {
    return await ReportDataService.getBFFClient().get<any>(
      ReportDataPaths.REPORT_FILTERS(agentId, branchCode),
      ReportDataService.getBaseHeaders(
        credentials.accessToken,
        credentials.idToken,
      ),
    );
  }

  static async getReportData(
    credentials: { accessToken: string; idToken: string },
    agentId: string,
    reportType: string,
    filters: IReportFilters,
  ): Promise<any> {
    return await ReportDataService.getBFFClient().get<any>(
      ReportDataPaths.REPORT_DATA(agentId, reportType, filters),
      ReportDataService.getBaseHeaders(
        credentials.accessToken,
        credentials.idToken,
      ),
    );
  }

  static async getCommissionsActivityByPeriod(
    credentials: { accessToken: string; idToken: string },
    agentId: string,
    page = 1,
    limit = 50,
    commissionDetailFilters: CommissionDetailFilters,
  ): Promise<{ commissions: CommissionsActivity[]; totalCommissions: number }> {
    return await ReportDataService.getBFFClient().get<any>(
      ReportDataPaths.POLICY_DETAILS_BY_COMMISSIONS_PERIOD(
        agentId,
        limit,
        page,
        commissionDetailFilters?.dateFrom,
        commissionDetailFilters?.dateGrouping,
        commissionDetailFilters?.dateTo,
      ),
      ReportDataService.getBaseHeaders(
        credentials.accessToken,
        credentials.idToken,
      ),
    );
  }
}
