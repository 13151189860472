import React, { useState } from 'react';
import {
  Agent,
  AgentStatus,
  Role,
} from '../../../../../../service/service.types';
import { AgentTerminationConfirmModal } from './TerminationConfirmationModal/AgentTerminationConfirmModal';
import { Action } from '../Action';
import styles from './TerminateAgent.module.scss';
import Supervisor from '../../../../../../assets/img/supervisorIcon/Supervisor.png';
import AuthContext from '../../../../../context/AuthContext';

export const TerminateAgent: React.FC<{ agent?: Agent }> = ({ agent }) => {
  const [show, setShow] = useState(false);

  if (agent?.status === AgentStatus.PENDING_TERMINATION || !agent) {
    return null;
  }
  const showModal = () => {
    setShow(true);
  };
  const onClose = () => {
    setShow(false);
  };

  return (
    <AuthContext.Consumer>
      {(decodedAuthToken) => {
        const roles: Role[] = decodedAuthToken['cognito:groups'] ?? [];
        return (
          (roles.includes(Role.CONTRACT_MANAGERS) ||
            roles.includes(Role.ADMIN)) && (
            <React.Fragment>
              <Action handleClick={showModal}>
                <img src={Supervisor} />
                <span className={styles['text']}>Terminate Agent</span>
              </Action>
              <AgentTerminationConfirmModal
                open={show}
                handleClose={onClose}
                selectedAgent={agent}
              />
            </React.Fragment>
          )
        );
      }}
    </AuthContext.Consumer>
  );
};
