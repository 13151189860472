import { Action } from 'redux';
import produce from 'immer';

import { DemographicInformation } from 'service/service.types';
import {
  DemographicInformationActionType,
  FetchDemographicInformationErrorAction,
  FetchDemographicInformationSuccessAction,
} from './demographicInformation.action';

export interface DemographicInformationInitState {
  demographicInformation?: DemographicInformation;
}

export type DemographicInformationErrorState = {
  error: Error;
} & DemographicInformationInitState;

export type DemographicInformationState =
  | DemographicInformationInitState
  | DemographicInformationErrorState;

export const demographicInformationReducer = (
  state: DemographicInformationInitState = {
    demographicInformation: undefined,
  },
  action: Action<DemographicInformationActionType>,
): DemographicInformationState => {
  return produce(state, (draftState: DemographicInformationState) => {
    switch (action.type) {
      case 'DEMOGRAPHIC_INFORMATION_FETCH__SUCCESS':
        draftState = {
          ...draftState,
          demographicInformation: (
            action as FetchDemographicInformationSuccessAction
          ).result.demographicInformation,
        };
        return draftState;
      case 'DEMOGRAPHIC_INFORMATION_FETCH_FAILURE':
        draftState = {
          ...draftState,
          error: (action as FetchDemographicInformationErrorAction).error,
          demographicInformation: undefined,
        };
        return draftState;
      default:
        return state;
    }
  });
};
