import { Action } from 'redux';

// BASE TYPE DEFS FOR ACTIONS
export type GlobalErrorActionType =
  | 'SET_GLOBAL_ERROR'
  | 'CLEAR_GLOBAL_ERROR'
  | 'NONE';

export interface SetGlobalErrorAction extends Action<GlobalErrorActionType> {
  type: 'SET_GLOBAL_ERROR';
  error: Error;
}

export interface ClearGlobalErrorAction extends Action<GlobalErrorActionType> {
  type: 'CLEAR_GLOBAL_ERROR';
}

export const setGlobalError = (error: Error): SetGlobalErrorAction => ({
  type: 'SET_GLOBAL_ERROR',
  error,
});

export const clearGlobalError = (): ClearGlobalErrorAction => ({
  type: 'CLEAR_GLOBAL_ERROR',
});
