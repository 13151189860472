import React from 'react';
import { Product } from '../../../../../../service/service.types';
import { NglicSimpleSelect, Option } from '@nglic/component-lib';
import { ProductsEnum } from '../../products.enum';

const series14GrowthRateOptions: Option[] = [
  {
    id: '0.0085',
    name: 'A: 0.85%',
  },
  {
    id: '0.0135',
    name: 'B: 1.35%',
  },
  {
    id: '0.0185',
    name: 'C: 1.85%',
  },
];

const series64GrowthRateOptions: Option[] = [
  {
    id: '0.0080',
    name: 'A: 0.80%',
  },
  {
    id: '0.0130',
    name: 'B: 1.30%',
  },
  {
    id: '0.0180',
    name: 'C: 1.80%',
  },
];
const otherGrowthRateOptions: Option[] = [
  {
    id: '0.0025',
    name: 'B: 0.25%',
  },
  {
    id: '0.0075',
    name: 'C: 0.75%',
  },
  {
    id: '0.0125',
    name: 'D: 1.25%',
  },
  {
    id: '0.0175',
    name: 'E: 1.75%',
  },
  {
    id: '0.0225',
    name: 'F: 2.25%',
  },
  {
    id: '0.025',
    name: 'G: 2.5%',
  },
];

export const growthRateVisibility = (product: Product | undefined): boolean => {
  const productsAllowed = ['Series 4', 'Series 14', 'Series 64'];
  if (product) {
    if (productsAllowed.includes(product.name)) {
      return true;
    }
  }
  return false;
};

export interface GrowthRateProps {
  product?: Product;
  onChange: (value: Option) => void;
  rate?: Option | undefined;
}

export const GrowthRate: React.FC<GrowthRateProps> = ({
  product,
  onChange,
  rate,
}) => {
  const growthRateOptions = (product: Product | undefined): Option[] => {
    if (product) {
      if (product.name === ProductsEnum.SERIES14)
        return series14GrowthRateOptions;
      else if (product.name === ProductsEnum.SERIES64)
        return series64GrowthRateOptions;
      else return otherGrowthRateOptions;
    }
    return [] as Option[];
  };

  return (
    <div data-testid={'rate-calculator-growth-rate'}>
      {growthRateVisibility(product) && (
        <NglicSimpleSelect
          selectedOption={rate}
          onChange={(value) => {
            onChange(value);
          }}
          options={growthRateOptions(product) || []}
          label={'Growth Rate'}
        />
      )}
    </div>
  );
};
