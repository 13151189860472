import React, { useEffect, useState } from 'react';
import styles from './RecentCommissionActivity.module.scss';
import { GenericTable, useNglScreenSize } from '@nglic/component-lib';
import { formatDate } from '../../Policies/PolicyDetail/PolicyInformation/PolicyInformationTransform';
import { useSelector } from 'react-redux';
import { AppState } from 'rootReducer';
import { CommissionsActivity } from '../../../../../service/service.types';
import { SectionName } from '../../../../shared/loading.action';
import { useHistory } from 'react-router-dom';
import { RecentCommissionActivityMobile } from './RecentCommissionActivityMobile';

export const tableHeader = [
  {
    titleName: 'Date',
    dataField: 'commissionsActivityDate',
    displayFormat: (value: string | undefined): string => {
      return (value && formatDate(value, true)) || '';
    },
  },
  {
    titleName: 'Insured Name',
    dataField: 'insuredName',
  },
  {
    titleName: 'Activity Type',
    dataField: 'commissionsActivityTypeDescription',
  },
  {
    titleName: 'Agent',
    dataField: 'sellingAgentFullName',
  },
  {
    titleName: 'Amount',
    dataField: 'earned',
    displayFormat: (value: string | undefined): string => {
      if (Number(value) < 0) {
        const newValue = value?.replace('-', '');
        return (
          (newValue &&
            '-$' + newValue?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')) ||
          ''
        );
      } else {
        return (
          (value && '$' + value.replace(/\B(?=(\d{3})+(?!\d))/g, ',')) || ''
        );
      }
    },
  },
];

export const commissionsRoute = {
  COMMISSIONS: '/manage-business/commissions',
};

export const RecentCommissionActivity: React.FC<{ routeToNext?: () => void }> =
  ({ routeToNext }) => {
    const [loading, setLoading] = useState<boolean>(true);
    const history = useHistory();

    const commissions: CommissionsActivity[] | undefined = useSelector(
      (state: AppState) =>
        state.presentation.manageBusiness.commissions.initialCommissions,
    );

    const sectionsLoading: SectionName[] = useSelector(
      (state: AppState) => state.presentation.loading.sectionsLoading,
    );

    useEffect(() => {
      setLoading(
        sectionsLoading.includes('FETCH_INITIAL_COMMISSION_ACTIVITIES_REQUEST'),
      );
    }, [commissions, sectionsLoading]);

    const { isMobile } = useNglScreenSize();

    const createRouteTo = (route: string) => () => {
      history.push(route);
    };
    const footer = (
      <tr>
        <td colSpan={tableHeader.length + 1}>
          <div
            className={styles['link']}
            onClick={createRouteTo(commissionsRoute.COMMISSIONS)}
          >
            View All Commission Activity
          </div>
        </td>
      </tr>
    );

    const displayedCommissions = [...commissions].slice(0, 3);

    return (
      <>
        {isMobile ? (
          <RecentCommissionActivityMobile commissions={displayedCommissions} />
        ) : (
          <div className={styles['root']}>
            <div className={styles['title']}>Recent Commission Activity</div>
            <div className={styles['table']}>
              <GenericTable
                loading={loading}
                pagination={false}
                tableTitle={undefined}
                data={displayedCommissions}
                columns={tableHeader}
                renderFooter={() => footer}
              />
            </div>
          </div>
        )}
      </>
    );
  };
