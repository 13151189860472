import React, { useMemo } from 'react';
import { Agent } from 'service/service.types';

import styles from './AgentInformation.module.scss';

import { ReactComponent as NoAgentSelectedImage } from 'assets/img/agentManagement/no-agent-selected.svg';

import { AgentContactsTable } from '../ContactsTable/AgentContactsTable';
import DoneIcon from '@material-ui/icons/Done';
import { useDispatch, useSelector } from 'react-redux';
import { agentInformationResendInvite } from '../../agent.action';
import { AppState } from '../../../../../rootReducer';
import agentSelectedPicture from '../../../../../assets/img/agentManagement/agent-selected.png';
import { AgentLicenseAndTraining } from '../LicenseAndTraining/AgentLicenseAndTraining';
import { ActionsMenu } from './ActionsMenu';
import { filterProducerIds } from '@nglic/utilities-ts/build';

interface AgentInformationProps {
  selectedAgent?: Agent;
  onAgentInformationClose?: () => void;
}

interface AgentSelectedProps {
  selectedAgent: Agent;
  onAgentInformationClose?: () => void;
}

const NoAgentSelected: React.FC = () => {
  return (
    <div
      className={styles['no-agent-selected']}
      aria-label={'no agent selected pane'}
    >
      <NoAgentSelectedImage className={styles['image']} />
      <div className={styles['text']}>
        No agent selected. Choose an agent <br /> to view more details about
        them.
      </div>
    </div>
  );
};

const AgentSelected: React.FC<AgentSelectedProps> = ({
  selectedAgent,
  onAgentInformationClose,
}) => {
  const { onboardingStatuses, contacts, name } = selectedAgent;
  const dispatch = useDispatch();
  const resendInviteRedux = useSelector(
    (state: AppState) => state.presentation.agent.agentInformationResendInvite,
  );

  const handleResendInvitation = (email: string) => {
    dispatch(agentInformationResendInvite(email));
  };

  const producerIds = useMemo(() => {
    return filterProducerIds(selectedAgent?.producerIds);
  }, [selectedAgent]);

  return (
    <div className={styles['agent-selected']}>
      <div className={styles['header']}>
        <div className={styles['text']}>{name}</div>
        <ActionsMenu
          handleModalClose={onAgentInformationClose}
          agent={selectedAgent}
        />
      </div>
      <div className={styles['content']}>
        <AgentLicenseAndTraining selectedAgent={selectedAgent} />
        {resendInviteRedux?.success ? (
          <div>
            <div className={styles['done-resend-invitation']}>
              <DoneIcon />
              <div>Invitation sent successfully!</div>
            </div>
          </div>
        ) : (
          <div
            className={styles['resend-invitation']}
            onClick={() => {
              handleResendInvitation(selectedAgent.email);
            }}
          >
            Resend Invite
          </div>
        )}
        <div className={styles['producer']}>
          Producer ID(s)#: {producerIds.join(', ')}
        </div>
        <AgentContactsTable contacts={contacts} />
        <div className={styles['selected-agent-image']}>
          <img src={agentSelectedPicture} />
        </div>
      </div>
    </div>
  );
};

export const AgentInformation: React.FC<AgentInformationProps> = (
  props: AgentInformationProps,
) => {
  const { selectedAgent, onAgentInformationClose } = props;
  return (
    <div className={styles['root']}>
      {selectedAgent ? (
        <AgentSelected
          selectedAgent={selectedAgent}
          onAgentInformationClose={onAgentInformationClose}
        />
      ) : (
        <NoAgentSelected />
      )}
    </div>
  );
};
