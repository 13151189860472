import { ThunkAction } from 'redux-thunk';
import { AppState } from '../../../../../../rootReducer';
import { getAgentId } from '../../../../../shared/selectors/basicUserInfo.selector';
import { getCredentials } from '../../../../../shared/selectors/getCredentials';
import { AgentService } from '../../../../../../service/AgentService';
import { SSOService } from '../../../../../../service/SSOService';

export const ssoIntoAppqore =
  (): ThunkAction<void, AppState, any, any> => async (dispatch, getState) => {
    const agentId = getAgentId(getState());
    const tokens = getCredentials(getState());
    if (!agentId || !tokens) {
      return;
    }

    const promise = async (): Promise<void> => {
      return AgentService.getAgentAppQoreSAML(agentId, tokens)
        .then((res) => res.saml)
        .then((userSaml) => SSOService.appqoreLogin(userSaml));
    };

    dispatch({
      types: ['APPQORE_SSO', 'APPQORE_SSO_SUCCESS', 'APPQORE_SSO_FAILURE'],
      promise,
      bypassGlobalError: true,
    });
  };
