import {
  DatePicker,
  FlexDirection,
  NglicModal,
  NglicRadioGroup,
  PrimaryButton,
  TextInput,
} from '@nglic/component-lib';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styles from './ClaimsModal.module.scss';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { Policy } from '../../../../../../service/service.types';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import { isUserFuneralHome, isNumber } from '@nglic/utilities-ts/build';

import { Claim, CREATE_CLAIM_USER_ROLES } from './claim.types';
import { createClaim } from './claim.action';
import { AppState } from '../../../../../../rootReducer';
import {
  getUserInformation,
  userHasPrivileges,
} from '../../../../../shared/selectors/getUserInformation';

interface NotSubmittedClaimProps {
  open: boolean;
  handleClose: () => void;
  policy: Policy;
  submitClicked: boolean;
  setClaimsData: (claimsData) => void;
  setSubmitClicked: (clicked) => void;
  handleOnChangeInput: (name, value) => void;
  causeOfDeathRadioGroups: any;
  claimsData: Claim;
  submitDisabled: boolean;
}

interface SecondClaimModalHeaderProps {
  policy: Policy;
}

interface SecondClaimModalFooterProps {
  policy: Policy;
  setSubmitClicked: (clicked) => void;
  claimsData: Claim;
}

export const SecondClaimModalHeader: React.FC<SecondClaimModalHeaderProps> = ({
  policy,
}) => {
  const userInfo = useSelector((state: AppState) => getUserInformation(state));
  return (
    <div className={styles['content']}>
      <p>
        {isUserFuneralHome(userInfo?.roles)
          ? 'A claim will be submitted for policy'
          : 'A death notification will be submitted for Policy'}
        <div style={{ fontWeight: 'bold' }}>#{policy?.policyNumber}</div> for
        {policy?.insuredName} and will be paid to {policy?.funeralHomeName}
      </p>
    </div>
  );
};

export const SecondClaimModalFooter: React.FC<SecondClaimModalFooterProps> = ({
  policy,
  setSubmitClicked,
  claimsData,
}) => {
  const dispatch = useDispatch();

  return (
    <div className={styles['edit-submit-button']}>
      <div
        className={styles['edit-button']}
        onClick={() => {
          setSubmitClicked(false);
        }}
      >
        EDIT
      </div>
      <div
        onClick={() => {
          dispatch(createClaim(claimsData, policy));
        }}
        className={styles['submit-button']}
      >
        <PrimaryButton text={'SUBMIT'} />
      </div>
    </div>
  );
};
export const NotSubmittedClaim: React.FC<NotSubmittedClaimProps> = ({
  open,
  handleClose,
  policy,
  submitClicked,
  setClaimsData,
  setSubmitClicked,
  handleOnChangeInput,
  causeOfDeathRadioGroups,
  claimsData,
  submitDisabled,
}) => {
  const userInfo = useSelector((state: AppState) => getUserInformation(state));

  const canCreateClaim = useSelector((state: AppState) =>
    userHasPrivileges(CREATE_CLAIM_USER_ROLES)(state),
  );

  const [funeralCostInputErrorMessage, setFuneralCostInputErrorMessage] =
    useState({
      error: false,
      message: '',
    });

  const processNumberInput = (value: string) => {
    const newStr = value.replace(/,/g, '');
    return newStr.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const funeralCostInputValidator = (value: string) => {
    const parsedValue = value != '' ? value.replace(/,/g, '') : 0;
    const notValidNumber = !isNumber(parsedValue);
    if (notValidNumber) {
      setFuneralCostInputErrorMessage &&
        setFuneralCostInputErrorMessage({
          error: notValidNumber,
          message: notValidNumber ? `Value must be numeric` : '',
        });
    } else {
      setFuneralCostInputErrorMessage &&
        setFuneralCostInputErrorMessage({
          error: false,
          message: '',
        });
    }
  };

  return (
    <NglicModal isOpened={open}>
      <div className={styles['root']}>
        <div className={styles['header']}>
          <div className={styles['title']}>
            <p>
              {isUserFuneralHome(userInfo?.roles)
                ? 'Submit Claim'
                : 'Submit Death Notice'}
            </p>
          </div>
          {!submitClicked && (
            <IconButton
              onClick={() => {
                handleClose();
                setClaimsData({
                  dateOfDeath: '',
                  funeralCost: '',
                  causeOfDeath: undefined,
                  userSubmittedClaim: '',
                });
              }}
              className={styles['icon-button']}
            >
              <CloseIcon
                className={styles['close-icon']}
                onClick={() => {
                  setSubmitClicked(false);
                }}
              />
            </IconButton>
          )}
        </div>
        {submitClicked ? (
          <SecondClaimModalHeader policy={policy} />
        ) : (
          <div className={styles['content']}>
            <div className={styles['text']}>
              <p>
                {isUserFuneralHome(userInfo?.roles)
                  ? 'Any instructions about how to start a claim should be input here.'
                  : ''}
              </p>
            </div>
            <div className={styles['date-picker']}>
              <DatePicker
                onChange={(newValue: string) =>
                  handleOnChangeInput('dateOfDeath', newValue)
                }
                label={'Date of Death'}
              />
            </div>

            {canCreateClaim && (
              <>
                <div className={styles['funeral-cost']}>
                  <TextInput
                    value={
                      processNumberInput(claimsData.funeralCost || '') || ''
                    }
                    onChange={(newValue: string) => {
                      handleOnChangeInput(
                        'funeralCost',
                        newValue.replace(/,/g, ''),
                      );
                      funeralCostInputValidator(newValue);
                    }}
                    error={funeralCostInputErrorMessage}
                    frontIcon={<AttachMoneyIcon />}
                    label="Funeral Cost"
                  />
                </div>

                <div className={styles['cause-of-death']}>
                  <NglicRadioGroup
                    title="Cause Of Death"
                    radios={causeOfDeathRadioGroups}
                    radiosFlexDirection={FlexDirection.COLUMN}
                  />
                </div>
              </>
            )}
          </div>
        )}

        <div className={styles['footer']}>
          {submitClicked ? (
            <SecondClaimModalFooter
              policy={policy}
              setSubmitClicked={setSubmitClicked}
              claimsData={claimsData}
            />
          ) : (
            <div className={styles['submit-claim-button']}>
              <PrimaryButton
                disabled={submitDisabled || funeralCostInputErrorMessage.error}
                text={'SUBMIT'}
                onClick={() => {
                  setSubmitClicked(true);
                }}
              />
            </div>
          )}
        </div>
      </div>
    </NglicModal>
  );
};
