import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../../../../rootReducer';
import { ssoIntoThysse } from './ThysseActionLink.action';
import { ActionLink } from '../../ActionLink/ActionLink';
import Vector from '../../../../../../assets/img/orderSuppliesIcon/Vector.png';

export const ThysseActionLink: React.FC = () => {
  const sectionsLoading = useSelector(
    (state: AppState) => state.presentation.loading.sectionsLoading,
  );
  const isLoading = sectionsLoading.includes('THYSSE_SSO');
  const dispatch = useDispatch();

  const onClick = () => {
    dispatch(ssoIntoThysse());
  };
  return (
    <ActionLink
      text="Order Supplies"
      image={Vector}
      ariaLabel={'thysse-sso-button'}
      actionOnClick={onClick}
      loading={isLoading}
    />
  );
};
