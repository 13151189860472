import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import { AppState } from '../../../../../rootReducer';
import { getCredentials } from '../../../../shared/selectors/getCredentials';
import { DemographicInformation } from 'service/service.types';
import { PolicyService } from '../../../../../service/PolicyService';

export type DemographicInformationActionType =
  | 'DEMOGRAPHIC_INFORMATION_FETCH__SUCCESS'
  | 'DEMOGRAPHIC_INFORMATION_FETCH_FAILURE'
  | 'NONE';

export interface FetchDemographicInformationSuccessAction
  extends Action<DemographicInformationActionType> {
  result: {
    demographicInformation: DemographicInformation;
  };
}

export interface FetchDemographicInformationErrorAction
  extends Action<DemographicInformationActionType> {
  error: Error;
}

export const fetchBasePersonAddressesAndPhones =
  (demographicId: string): ThunkAction<void, AppState, any, any> =>
  async (dispatch, getState: () => AppState) => {
    const tokens = getCredentials(getState());

    try {
      const promise = async (): Promise<{
        demographicInformation: DemographicInformation;
      }> => {
        return await PolicyService.getBasePersonAddressesAndPhones(
          tokens,
          demographicId,
        );
      };

      dispatch({
        types: [
          'DEMOGRAPHIC_INFORMATION_FETCH__REQUEST',
          'DEMOGRAPHIC_INFORMATION_FETCH__SUCCESS',
          'DEMOGRAPHIC_INFORMATION_FETCH_FAILURE',
        ],
        promise,
        bypassGlobalError: true,
      });
    } catch (e) {
      return {
        demographicInformation: undefined,
      };
    }
  };
