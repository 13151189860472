import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import { loadComplete, loadStarted } from '../../../shared/loading.action';
import { AppState } from '../../../../rootReducer';
import { UserService } from '../../../../service/UserService';
import { getCredentials } from '../../../shared/selectors/getCredentials';

export type AddUserActionType =
  | 'ADD_USER_SUCCESS'
  | 'ADD_USER_FAILURE'
  | 'ADD_USER_DELETE_RESPONSE'
  | 'NONE';

export interface AddUserSuccessAction extends Action<AddUserActionType> {
  type: 'ADD_USER_SUCCESS';
  result: {
    success: boolean | undefined;
  };
}
export interface AddUserDeleteResponseAction extends Action<AddUserActionType> {
  type: 'ADD_USER_DELETE_RESPONSE';
}

export interface AddUserFailureAction extends Action<AddUserActionType> {
  error: Error;
}

export interface AddUserErrorAction extends Action<AddUserActionType> {
  type: 'ADD_USER_FAILURE';
  error: Error;
}

const createDeleteStagedDocumentAction = (): AddUserDeleteResponseAction => ({
  type: 'ADD_USER_DELETE_RESPONSE',
});

export const addUser =
  (
    firstName: string,
    lastName: string,
    email: string,
    roles: string[],
    mfaEnabled: boolean,
  ): ThunkAction<void, AppState, any, any> =>
  async (dispatch, getState: () => AppState) => {
    dispatch(loadStarted('ADD_USER'));
    const tokens = getCredentials(getState());

    const promise = async (): Promise<{ success: boolean }> => {
      return await UserService.createUserAsSupportUser(
        {
          firstName,
          lastName,
          email,
          roles,
          mfaEnabled: mfaEnabled ? 'true' : 'false',
        },
        tokens,
      );
    };

    dispatch({
      types: ['ADD_USER', 'ADD_USER_SUCCESS', 'ADD_USER_FAILURE'],
      promise,
      bypassGlobalError: false,
    });
    loadComplete('ADD_USER');
  };

export const deleteResponse =
  (): ThunkAction<void, AppState, any, any> => async (dispatch) => {
    dispatch(loadStarted('ADD_USER'));
    dispatch(createDeleteStagedDocumentAction());
    dispatch(loadComplete('ADD_USER'));
  };
