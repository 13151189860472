import React, { ReactElement, useEffect } from 'react';
import { AppPageLayoutWrapper } from '../AppPageLayoutWrapper';
import { Policies } from './Policies/Policies';
import { PageEnum } from '@nglic/component-lib';
import { Overview } from './Overview/Overview';
import { Commissions } from './Commissions/Commissions';
import { Performance } from './Performance/Performance';
import { Documents } from './Documents/Documents';
import { RouteComponentProps } from 'react-router-dom';
import {
  Home as HomeIcon,
  Search as SearchIcon,
  AttachMoney as DollarIcon,
  Equalizer as ChartIcon,
  Backup as UploadIcon,
} from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { fetchInitialCommissionActivities } from './Commissions/commissions.action';
import { fetchInitialPolicies } from './Policies/policies.action';
import { withTracker } from 'ga-4-react';
import { AppState } from '../../../rootReducer';
import { getUserInformation } from '../../shared/selectors/getUserInformation';
import { isUserFuneralHome } from '@nglic/utilities-ts/build';

import { fetchAllFeaturesVisibility } from '../../shared/features.action';

export interface SubNavigationItemConfig {
  name: string;
  selected: boolean;
  onClick: (...args) => void;
  altText?: string;
  id: string;
  icon?: ReactElement;
  visible?: boolean;
}

export enum BusinessPages {
  OVERVIEW = 'overview',
  COMMISSIONS = 'commissions',
  POLICIES = 'policies',
  PERFORMANCE = 'performance',
  DOCUMENTS = 'documents',
}

const routePrefix = '/manage-business';
export const manageBusinessRoutes = {
  OVERVIEW: `${routePrefix}/${BusinessPages.OVERVIEW}`,
  COMMISSIONS: `${routePrefix}/${BusinessPages.COMMISSIONS}`,
  POLICIES: `${routePrefix}/${BusinessPages.POLICIES}`,
  PERFORMANCE: `${routePrefix}/${BusinessPages.PERFORMANCE}`,
  DOCUMENTS: `${routePrefix}/${BusinessPages.DOCUMENTS}`,
};

export const ManageBusiness: React.FC<
  Pick<RouteComponentProps<{ subPage: string }>, 'match' | 'history'>
> = (props) => {
  const { match, history } = props;
  const subPage = match.params.subPage;
  const dispatch = useDispatch();
  const basicInfo = useSelector((state: AppState) => getUserInformation(state));

  const featureVisibilityState = useSelector(
    (state: AppState) => state.presentation.features,
  );

  dispatch(fetchInitialCommissionActivities(5));
  dispatch(fetchInitialPolicies());
  useEffect(() => {
    dispatch(fetchAllFeaturesVisibility());
  }, []);

  const createRouteTo = (route: string) => () => {
    history.push(route);
  };

  const subNavConfig: SubNavigationItemConfig[] = [
    {
      name: 'Overview',
      id: 'overview',
      icon: <HomeIcon />,
      onClick: createRouteTo(manageBusinessRoutes.OVERVIEW),
      selected: subPage === BusinessPages.OVERVIEW,
      visible: featureVisibilityState.overviewVisible,
    },
    {
      name: 'Commissions',
      id: 'commissions',
      icon: <DollarIcon />,
      onClick: createRouteTo(manageBusinessRoutes.COMMISSIONS),
      selected: subPage === BusinessPages.COMMISSIONS,
      visible:
        !isUserFuneralHome(basicInfo?.roles) &&
        featureVisibilityState.commissionsVisible,
    },
    {
      name: 'Policies',
      id: 'policies',
      icon: <SearchIcon />,
      onClick: createRouteTo(manageBusinessRoutes.POLICIES),
      selected: subPage === BusinessPages.POLICIES,
      visible: featureVisibilityState.policyManagementVisible,
    },
    {
      name: 'Performance',
      id: 'performance',
      icon: <ChartIcon />,
      onClick: createRouteTo(manageBusinessRoutes.PERFORMANCE),
      selected: subPage === BusinessPages.PERFORMANCE,
      visible: !isUserFuneralHome(basicInfo?.roles),
    },
    {
      name: 'Documents',
      id: 'documents',
      icon: <UploadIcon />,
      onClick: createRouteTo(manageBusinessRoutes.DOCUMENTS),
      selected: subPage === BusinessPages.DOCUMENTS,
    },
  ];

  const renderComponent = (page: string) => {
    let Component;
    switch (page) {
      case BusinessPages.OVERVIEW:
        Component = withTracker(Overview);
        return (
          <Component
            routeToNext={createRouteTo(manageBusinessRoutes.OVERVIEW)}
          />
        );
      case BusinessPages.COMMISSIONS:
        Component = withTracker(Commissions);
        return (
          <Component
            routeToNext={createRouteTo(manageBusinessRoutes.COMMISSIONS)}
          />
        );
      case BusinessPages.POLICIES:
        Component = withTracker(Policies);
        return (
          <Component
            routeToNext={createRouteTo(manageBusinessRoutes.POLICIES)}
          />
        );
      case BusinessPages.PERFORMANCE:
        Component = withTracker(Performance);
        return (
          <Component
            routeToNext={createRouteTo(manageBusinessRoutes.PERFORMANCE)}
          />
        );
      case BusinessPages.DOCUMENTS:
        Component = withTracker(Documents);
        return (
          <Component
            routeToNext={createRouteTo(manageBusinessRoutes.DOCUMENTS)}
          />
        );
    }
  };

  const subHeaderComponent = () => {
    if (subPage === BusinessPages.POLICIES) return <div>Manage Policies</div>;
  };

  return (
    <AppPageLayoutWrapper
      title={'Business Management'}
      page={PageEnum.BUSINESS_MANAGEMENT}
      subnavConfig={subNavConfig}
      subHeaderMobileConfig={subHeaderComponent()}
    >
      {renderComponent(subPage)}
    </AppPageLayoutWrapper>
  );
};

export default ManageBusiness;
