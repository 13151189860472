import { TextInput } from '@nglic/component-lib/build';
import React from 'react';
import { isEmailValid } from '@nglic/utilities-ts/build';

interface EmailInputProps {
  id?: string;
  email: string;
  label?: string;
  onChange: (value: string) => void;
  validator?: (val: string) => {
    error: boolean;
    message: string;
  };
}

export const EmailInput: React.FC<EmailInputProps> = ({
  id = 'email',
  label = 'Email',
  email,
  onChange,
  validator,
}) => {
  const emailValidation = (email: string) => {
    const notValid = !isEmailValid(email);
    return {
      error: notValid,
      message: notValid ? `${email} is not a valid email` : '',
    };
  };

  const handleOnChange = (value) => {
    if (onChange) {
      onChange(value.toLowerCase());
    }
  };

  return (
    <TextInput
      id={id}
      value={email}
      onChange={handleOnChange}
      label={label}
      validator={validator ? validator : emailValidation}
    />
  );
};
