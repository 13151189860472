import React, { useEffect, useState } from 'react';
import { Pagination } from '../../../../modules/Pagination/Pagination';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../../../rootReducer';
import styles from './StatementDownloadsTable.module.scss';
import { SectionName } from '../../../../shared/loading.action';
import { CommissionRow } from './CommissionRow/CommissionRow';
import { fetchCommissionStatementDates } from './commissions-statements.action';
import { getUserInformation } from '../../../../shared/selectors/getUserInformation';
import { NGlicSpinner } from '@nglic/component-lib/build';

export const StatementDownloadsTable: React.FC = () => {
  const ITEMS_PER_PAGE = 5;
  const dispatch = useDispatch();

  const basicInfo = useSelector((state: AppState) => getUserInformation(state));
  const sectionsLoading: SectionName[] = useSelector(
    (state: AppState) => state.presentation.loading.sectionsLoading,
  );

  const totalCommissionsStatements = useSelector(
    (state: AppState) =>
      state.presentation.commissionsStatement.totalStatementDates,
  );
  const commissionsStatement = useSelector(
    (state: AppState) => state.presentation.commissionsStatement.statementDates,
  );

  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    if (basicInfo !== 'NOT_INITIALIZED') {
      dispatch(fetchCommissionStatementDates(page, ITEMS_PER_PAGE));
    }
  }, [basicInfo]);

  useEffect(() => {
    if (!totalCommissionsStatements) {
      setTotalPages(1);
    } else {
      setTotalPages(Math.ceil(totalCommissionsStatements / ITEMS_PER_PAGE));
    }
  }, [totalCommissionsStatements]);

  useEffect(() => {
    setLoading(
      !commissionsStatement ||
        sectionsLoading.includes('FETCH_COMMISSION_STATEMENT_DATES_REQUEST'),
    );
  }, [commissionsStatement, sectionsLoading]);

  useEffect(() => {
    dispatch(fetchCommissionStatementDates(page, ITEMS_PER_PAGE));
  }, [page]);

  return (
    <div className={styles['root']} data-testid="commission-statement-table">
      <div className={styles['top-bar']} />
      <h5 className={styles['statement-title']}>Commission Statements</h5>
      {!loading && (
        <span>
          {commissionsStatement?.map((item, index) => {
            return <CommissionRow key={`${item}-${index}`} date={item} />;
          })}
          <div className={styles['pagination-container']}>
            <Pagination
              onPageClick={setPage}
              numberOfPages={totalPages}
              currentPage={page}
            />
          </div>
        </span>
      )}
      {loading && (
        <div className={styles['loading-container']}>
          <NGlicSpinner />
        </div>
      )}
    </div>
  );
};
