import React from 'react';
import styles from './Upload.module.scss';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { DocumentPendingUpload } from '../../../Contract/DocumentUpload/document.types';

export const DocumentErrors: React.FC<{
  errors: { error: Error; document: string }[];
  handleRetry: (index: number) => void;
  handleOnFileDelete: (index: number) => void;
  index: number;
  document: DocumentPendingUpload;
}> = ({ errors, handleRetry, index, handleOnFileDelete, document }) => {
  return (
    <div className={styles['retry-delete']}>
      {errors.length > 0 &&
        errors.map((error) => {
          if (error.document === document.file.name) {
            return (
              <IconButton
                className={styles['icon-container']}
                onClick={() => {
                  handleRetry(index);
                }}
              >
                <div className={styles['retry']}>Retry</div>
              </IconButton>
            );
          }
        })}
      <IconButton
        className={styles['icon-container']}
        onClick={() => {
          handleOnFileDelete(index);
        }}
      >
        <DeleteIcon className={styles['icon']} />
      </IconButton>
    </div>
  );
};
