import { Action } from 'redux';

export type AgentInformationAction =
  | 'ADD_AGENT_INFORMATION'
  | 'CLEAR_AGENT_INFORMATION';

export interface AddAgentInformationAction
  extends Action<AgentInformationAction> {
  type: 'ADD_AGENT_INFORMATION';
  firstName: string;
  lastName: string;
  email: string;
  mailingPreference: string;
}

export interface ClearAgentInformationAction
  extends Action<AgentInformationAction> {
  type: 'CLEAR_AGENT_INFORMATION';
}

export const addAgentInformation = (
  firstName: string,
  lastName: string,
  email: string,
  mailingPreference: string,
): AddAgentInformationAction => ({
  type: 'ADD_AGENT_INFORMATION',
  firstName,
  lastName,
  email,
  mailingPreference,
});

export const clearAgentInformation = (): ClearAgentInformationAction => ({
  type: 'CLEAR_AGENT_INFORMATION',
});
