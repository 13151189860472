export const substringDate = (date?: string): string => {
  const onlyDate = date?.substring(0, 10) || '';
  if (onlyDate) {
    const parts = onlyDate.split('-');
    const convertedDate = `${parts[1]}/${parts[2]}/${parts[0]}`;
    return convertedDate;
  }
  return '';
};

export const createFullName = (
  firstName?: string,
  lastName?: string,
): string => {
  if (firstName || lastName) {
    return firstName + ' ' + lastName;
  }
  return '';
};

export const calculateAge = (date?: string): string => {
  let yearOfBirth = date?.substring(0, 4);
  if (yearOfBirth) {
    yearOfBirth = (new Date().getFullYear() - parseInt(yearOfBirth)).toString();
  }
  return yearOfBirth || '';
};

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const formatDate = (date?: string, includeYear = false): string => {
  if (!date) {
    return '';
  }

  const dateObj = new Date(date);

  const year = dateObj.getUTCFullYear();
  const month = dateObj.getUTCMonth();
  const day = dateObj.getUTCDate();

  return `${monthNames[month]} ${day}${includeYear ? `, ${year}` : ''}`;
};
