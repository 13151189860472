import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { PoliciesListHeader } from './PoliciesListHeader';
import styles from './PoliciesList.module.scss';
import { Pagination } from '../../../../modules/Pagination/Pagination';
import {
  NGlicSpinner,
  SearchInput,
  useNglScreenSize,
} from '@nglic/component-lib/build/';
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import classNames from 'classnames';
import { InputLabel } from '@material-ui/core';
import { PartialPolicy } from '../../../../../service/service.types';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'rootReducer';
import { fetchPolicies } from '../policies.action';
import { SectionName } from '../../../../shared/loading.action';
import {
  createFullName,
  substringDate,
} from '../PolicyDetail/PolicyInformation/PolicyInformationTransform';
import FilterListIcon from '@material-ui/icons/FilterList';
import { getUserInformation } from '../../../../shared/selectors/getUserInformation';

const secondaryTypographyProps = {
  style: {
    fontSize: '11px',
    color: '#757982',
  },
};

const secondaryTypographyPropsPolicyNumber = {
  style: {
    color: 'rgba(0, 0, 0, 0.87)',
    letterSpacing: '0.4px',
    fontSize: '11px',
  },
};

const primaryTypographyPropsInsured = {
  style: {
    color: '#000',
    letterSpacing: '0.1px',
    fontSize: '14px',
    fontWeight: 700,
  },
};

export enum PolicyStatusEnum {
  PENDING = 'P',
  ACTIVE = 'A',
  TERMINATED = 'T',
  NOT_TAKEN = 'N',
}

interface PoliciesListProps {
  selectedPolicyId?: string;
  onPolicySelected: (policy?: PartialPolicy) => void;
}

export const checkForBackgroundColor = (policyStatus?: string): string => {
  switch (policyStatus) {
    case PolicyStatusEnum.ACTIVE:
      return 'policyStatus-preneedGreen';
    case PolicyStatusEnum.PENDING:
      return 'policyStatus-marketsGold';
    default:
      return 'policyStatus-nglGrey';
  }
};

export const convertStatus = (
  policyStatus?: string,
): 'ACTIVE' | 'PENDING' | 'TERMINATED' | 'NOT TAKEN' => {
  switch (policyStatus) {
    case PolicyStatusEnum.ACTIVE:
      return 'ACTIVE';
    case PolicyStatusEnum.PENDING:
      return 'PENDING';
    case PolicyStatusEnum.TERMINATED:
      return 'TERMINATED';
    default:
      return 'NOT TAKEN';
  }
};

export const PoliciesList: React.FC<PoliciesListProps> = (
  props: PoliciesListProps,
) => {
  const { selectedPolicyId, onPolicySelected } = props;

  const POLICIES_PER_PAGE = 50;

  // Hooks
  const params = useParams<{ subPage: string; id?: string }>();
  const dispatch = useDispatch();
  const { isMobile } = useNglScreenSize();

  // State
  const [loading, setLoading] = useState<boolean>(true);
  const [page, setCurrentPage] = useState<number>(1);
  const [policyQuery, setPolicyQuery] = useState<string>('');
  const [clearSearchInputMobile, setClearSearchInputMobile] =
    useState<boolean>(false);

  // Selectors
  const userInfo = useSelector((state: AppState) => getUserInformation(state));
  const policies: PartialPolicy[] | undefined = useSelector(
    (state: AppState) => state.presentation.policies.policies,
  );
  const initialPolicies: PartialPolicy[] | undefined = useSelector(
    (state: AppState) => state.presentation.policies.initialPolicies,
  );
  const totalPolicies: number | undefined = useSelector(
    (state: AppState) => state.presentation.policies.totalPolicies || 0,
  );

  const sectionsLoading: SectionName[] = useSelector(
    (state: AppState) => state.presentation.loading.sectionsLoading,
  );

  useEffect(() => {
    setLoading(
      (!policies && initialPolicies.length === 0) ||
        sectionsLoading.includes('POLICIES_FETCH_ALL'),
    );
  }, [policies, sectionsLoading, initialPolicies]);

  useEffect(() => {
    if (policies && policies.length === 1) {
      onPolicySelected(policies[0]);
    } else {
      onPolicySelected();
    }
  }, [policies]);

  useEffect(() => {
    // This Is setup to fetch the selected polocu
    if (
      params?.id ||
      (!params?.id &&
        policies &&
        policies.length === 1 &&
        initialPolicies.length &&
        userInfo &&
        !sectionsLoading.includes('POLICIES_FETCH_INITIAL'))
    ) {
      onPolicySelected && onPolicySelected();
      // dispatch(
      //   fetchPolicies({
      //     page,
      //     limit: POLICIES_PER_PAGE,
      //     query: policyQuery,
      //     policyId: params.id,
      //   }),
      // );
    } else {
      return;
    }
  }, [userInfo, policyQuery]);

  useEffect(() => {
    if (page === 1) {
      return;
    }
    dispatch(
      fetchPolicies({
        page,
        limit: POLICIES_PER_PAGE,
        query: policyQuery,
        policyId: params.id,
      }),
    );
  }, [page]);

  const search = React.useCallback(() => {
    if (userInfo === 'NOT_INITIALIZED') {
      return;
    }
    dispatch(
      fetchPolicies({
        page,
        limit: 50,
        query: policyQuery,
        policyId: params.id,
      }),
    );
  }, [policyQuery]);

  const renderRow = (props: ListChildComponentProps) => {
    const { style, index } = props;
    const policy = policyDataToRender ? policyDataToRender[index] : undefined;
    return (
      <ListItem
        className={classNames(styles['list-item'], {
          [`${styles['list-item-selected']}`]: policy?.id === selectedPolicyId,
        })}
        style={{
          ...style,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
        key={policy?.id}
        button
        onClick={() => {
          onPolicySelected(policy);
        }}
        disableRipple
        disableTouchRipple
      >
        <div>
          <ListItemText
            secondary={policy?.productCode}
            secondaryTypographyProps={secondaryTypographyProps}
          />
          <ListItemText
            primary={createFullName(
              policy?.insured?.firstName,
              policy?.insured?.lastName,
            )}
            secondary={policy?.policyNumber}
            secondaryTypographyProps={secondaryTypographyPropsPolicyNumber}
            primaryTypographyProps={primaryTypographyPropsInsured}
          />
        </div>
        <div>
          <ListItemText
            secondaryTypographyProps={secondaryTypographyProps}
            secondary={substringDate(policy?.effectiveDate)}
          />
          <InputLabel
            className={classNames(
              styles[checkForBackgroundColor(policy?.status)],
              styles['status-label'],
            )}
          >
            {convertStatus(policy?.status)}
          </InputLabel>
        </div>
      </ListItem>
    );
  };

  const handleClear = () => {
    setClearSearchInputMobile(true);
    setPolicyQuery('');
    setTimeout(() => setClearSearchInputMobile(false), 1);
    if (userInfo) {
      dispatch(
        fetchPolicies({
          page,
          limit: 50,
          query: '',
          policyId: params.id,
        }),
      );
    }
  };

  const searchForMobile = (value: string) => {
    setPolicyQuery(value);
    if (userInfo) {
      dispatch(
        fetchPolicies({
          page,
          limit: 50,
          query: value,
        }),
      );
    }
  };

  const policyDataToRender =
    page === 1 && policyQuery === '' && !params?.id
      ? initialPolicies
      : policies;

  const totalPages = Math.ceil((totalPolicies ?? 0) / POLICIES_PER_PAGE);
  return (
    <div className={styles['root']} data-testid="main-element">
      <PoliciesListHeader
        onSearchTextChange={setPolicyQuery}
        onSubmitSearch={search}
      />
      <div className={styles['mobile-header']}>
        <div className={styles['mobile-search']} data-testid="search-input">
          <SearchInput
            value={clearSearchInputMobile ? policyQuery : ''}
            placeHolder={'Search'}
            onChange={searchForMobile}
            id={'policy-search'}
          />
          <div
            onClick={() => {
              handleClear();
            }}
            className={styles['cancel-search']}
          >
            Cancel
          </div>
        </div>
        <div className={styles['filterIcon']}>
          <div>All Products</div>
          <FilterListIcon />
        </div>
        <div className={styles['divider']}>POLICIES</div>
      </div>
      <div className={styles['list-container']}>
        {loading && (
          <div className={styles['loading-container']}>
            <NGlicSpinner />
          </div>
        )}
        {!loading && (
          <FixedSizeList
            height={560}
            width={!isMobile ? 400 : ''}
            itemSize={72}
            itemCount={policyDataToRender?.length ?? 0}
            className={styles['list']}
          >
            {renderRow}
          </FixedSizeList>
        )}
        <div className={styles['pagination-container']}>
          <Pagination
            onPageClick={setCurrentPage}
            numberOfPages={totalPages}
            currentPage={page}
          />
        </div>
      </div>
    </div>
  );
};
