import produce from 'immer';
import {
  FetchHierarchiesForSection,
  ClearHierarchySectionAction,
  HierarchyAction,
  AddToHierarchyAction,
  RemoveFromHierarchyAction,
} from './hierarchy.action';
import {
  AgentProducer,
  AgentProduct,
  Hierarchy,
  Product,
} from '../../../../../service/service.types';
import { Action } from 'redux';
import { Option } from '@nglic/component-lib/build';

export type HierarchyUpdate = {
  originalHierarchy: Hierarchy;
  updatedHierarchy: AgentProduct[];
};

export interface HierarchyGroupingParams {
  searchParams: {
    product?: Product;
    agent?: AgentProducer;
  };
  loadingHierarchies?: boolean;
  hierarchyList: Hierarchy[];
  newAgentHierarchies: Record<string, HierarchyUpdate>;
  newAgentHierarchyCommissionMap: Record<string, Option>;
}

export type AddAgentHierarchyState = Record<string, HierarchyGroupingParams>;

export const initialState: AddAgentHierarchyState = {};

export const hierarchyReducer = (
  state = initialState,
  action: Action<HierarchyAction>,
): AddAgentHierarchyState => {
  return produce(state, (draftState) => {
    switch (action.type) {
      case 'REMOVE_FROM_HIERARCHY':
        const removeFromHierarchyAction = action as RemoveFromHierarchyAction;
        const section = state[removeFromHierarchyAction.id];

        const newAgentHierarchies = Object.entries(
          section.newAgentHierarchies ?? {},
        ).reduce((acc, next) => {
          if (next[0] !== removeFromHierarchyAction.hierarchyId) {
            return {
              ...acc,
              [`${next[0]}`]: next[1],
            };
          }
          return acc;
        }, {});

        const newAgentHierarchyCommissionMap = Object.entries(
          section.newAgentHierarchyCommissionMap ?? {},
        ).reduce((acc, next) => {
          if (next[0] !== removeFromHierarchyAction.hierarchyId) {
            return {
              ...acc,
              [`${next[0]}`]: next[1],
            };
          }
          return acc;
        }, {});

        draftState[removeFromHierarchyAction.id] = {
          searchParams: section.searchParams,
          hierarchyList: section.hierarchyList,
          newAgentHierarchies,
          newAgentHierarchyCommissionMap,
        };
        return draftState;
      case 'ADD_TO_HIERARCHY':
        const addToHierarchyAction = action as AddToHierarchyAction;
        draftState = {
          ...draftState,
          [addToHierarchyAction.id]: {
            searchParams: addToHierarchyAction.searchParams,
            hierarchyList: addToHierarchyAction.hierarchyList,
            newAgentHierarchies: addToHierarchyAction.newAgentHierarchies,
            newAgentHierarchyCommissionMap:
              addToHierarchyAction.newAgentHierarchyCommissionMap,
          },
        };
        return draftState;
      case 'CLEAR_HIERARCHY_SECTION':
        const clearHierarchySectionAction =
          action as ClearHierarchySectionAction;
        const previousSection =
          draftState[clearHierarchySectionAction.sectionId];
        draftState = {
          ...draftState,
          [clearHierarchySectionAction.sectionId]: {
            ...previousSection,
            hierarchyList: [],
          },
        };
        return draftState;

      case 'CLEAR_HIERARCHIES':
        return initialState;

      case 'FETCH_HIERARCHIES_FOR_NEW_AGENT_SUCCESS':
        const fetchHierarchySuccessAction =
          action as FetchHierarchiesForSection;
        draftState = {
          ...draftState,
          [fetchHierarchySuccessAction.sectionId]: {
            searchParams: fetchHierarchySuccessAction.searchParams,
            hierarchyList: fetchHierarchySuccessAction.result,
            loadingHierarchies: false,
            newAgentHierarchies: {},
            newAgentHierarchyCommissionMap: {},
          },
        };
        return draftState;

      case 'FETCH_HIERARCHIES_FOR_NEW_AGENT_REQUEST':
        const requestStartAction = action as FetchHierarchiesForSection;
        draftState[requestStartAction.sectionId] = {
          searchParams: requestStartAction.searchParams,
          hierarchyList: requestStartAction.result,
          loadingHierarchies: true,
          newAgentHierarchies: {},
          newAgentHierarchyCommissionMap: {},
        };
        return draftState;
      default:
        return state;
    }
  });
};
