import produce from 'immer';
import { Action } from 'redux';
import { Announcement } from '../../../../service/service.types';
import {
  FetchAnnouncementsSuccessAction,
  AnnouncementsActionType,
} from './announcement.action';

export interface AnnouncementState {
  announcements: Announcement[] | undefined;
}

export const announcementReducer = (
  state: AnnouncementState = {
    announcements: undefined,
  },
  action: Action<AnnouncementsActionType>,
): AnnouncementState => {
  return produce(state, (draftState) => {
    switch (action.type) {
      case 'ANNOUNCEMENTS_FETCH__SUCCESS':
        draftState.announcements = (
          action as FetchAnnouncementsSuccessAction
        ).announcements;
        return draftState;
      case 'ANNOUNCEMENTS_FETCH_FAILURE':
        draftState.announcements = [];
        return draftState;
      default:
        return state;
    }
  });
};
