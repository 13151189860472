enum ContractPageStepsEnum {
  PERSONAL_INFORMATION = 'PERSONAL INFORMATION',
  BACKGROUND = 'BACKGROUND',
  PAYMENT_METHOD = 'PAYMENT METHOD',
  DOCUMENT_UPLOAD = 'DOCUMENT UPLOAD',
  REVIEW = 'REVIEW',
}

enum PhoneNumberTypesEnum {
  CELL = 'Cell',
  WORK = 'Work',
  FAX = 'Fax',
  HOME = 'Home',
}

enum GenderEnum {
  MALE = 'Male',
  FEMALE = 'Female',
}

enum AccountTypeEnum {
  CHECKING = 'Checking',
  SAVINGS = 'Savings',
}

enum PaymentFrequencyEnum {
  DAILY = 'Daily',
  WEEKLY = 'Weekly',
  BI_WEEKLY = 'Bi-Weekly',
  MONTHLY = 'Monthly',
}

enum PaymentErrorsEnum {
  ROUTING_NUMBER = 'RoutingNumber',
  ACCOUNT_NUMBER_CONFIRMATION = 'AccountNumberConfirmation',
}

enum DemographicErrorsEnum {
  SSN = 'Ssn',
  BIRTHDAY = 'Birthday',
}

interface ContractBreadcrumbItem {
  label: ContractPageStepsEnum;
  active: boolean;
  onClick?: () => void;
}

interface ContractSubPageNavigationItem {
  currentPage: string;
  nextPage?: string;
  active: boolean;
}

export {
  ContractPageStepsEnum,
  PhoneNumberTypesEnum,
  GenderEnum,
  AccountTypeEnum,
  PaymentFrequencyEnum,
  PaymentErrorsEnum,
  DemographicErrorsEnum,
};

export type { ContractBreadcrumbItem, ContractSubPageNavigationItem };
