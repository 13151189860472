import produce from 'immer';
import { LoadingAction, SectionName } from './loading.action';

export interface LoadingState {
  sectionsLoading: SectionName[];
}

export const loadingReducer = (
  state: LoadingState = { sectionsLoading: [] },
  action: LoadingAction,
): LoadingState => {
  return produce(state, (draftState) => {
    switch (action.type) {
      case 'LOADING_STARTED':
        draftState.sectionsLoading = [
          ...draftState.sectionsLoading,
          action.section,
        ];
        return draftState;
      case 'LOADING_COMPLETE':
        draftState.sectionsLoading = draftState.sectionsLoading.reduce<
          SectionName[]
        >((acc, section) => {
          if (section !== action.section) {
            acc.push(section);
          }
          return acc;
        }, []);
        return draftState;
      default:
        return state;
    }
  });
};
