import React from 'react';
import { Product } from '../../../../../../service/service.types';
import styles from './PriceVariables.module.scss';
import { ErrorMessage, TextInput } from '@nglic/component-lib';
import { DownPayment } from './DownPayment/DownPayment';
import {
  PaymentMethod,
  PaymentMethodProps,
} from './PaymentMethod/PaymentMethod';
import {
  FuneralAmountOrAffordability,
  FuneralAmountOrAffordabilityProps,
} from './FuneralAmountOrAffordability/FuneralAmntOrAffordability';
import { Bump } from './Bump/Bump';

interface FaceAmountProps {
  onChange: (val: string, label: string) => void;
  error?: ErrorMessage;
}

export interface InsuredInformationProps {
  product?: Product;
  faceAmount: FaceAmountProps;
  downPayment: (val: string) => void;
  paymentMethod: PaymentMethodProps;
  funeralAmountOrAffordability: FuneralAmountOrAffordabilityProps;
  bump: () => void;
}

export const PriceVariables: React.FC<InsuredInformationProps> = ({
  product,
  faceAmount,
  downPayment,
  paymentMethod,
  funeralAmountOrAffordability,
  bump,
}) => {
  const faceAmountInputName = (product: Product | undefined): string => {
    const productsForFaceAmount = ['Assetguard v2.0', 'Special Considerations'];
    if (product) {
      if (productsForFaceAmount.includes(product.name)) {
        return 'Face Amount';
      }
    }

    return funeralAmountOrAffordability.option as
      | 'Funeral Amount'
      | 'Affordability';
  };

  return (
    <div
      className={styles['root']}
      data-testid={'rate-calculator-price-variables'}
    >
      <div className={styles['title']}>Price Variables</div>
      <div className={styles['face-amount']}>
        <TextInput
          onChange={(newValue: string) => {
            faceAmount.onChange(newValue, faceAmountInputName(product));
          }}
          error={faceAmount.error}
          label={faceAmountInputName(product)}
        />
      </div>
      <div className={styles['down-payment']}>
        <DownPayment onChange={downPayment} product={product} />
      </div>
      <div className={styles['payment-method']}>
        <PaymentMethod
          onChange={paymentMethod.onChange}
          method={paymentMethod.method}
        />
      </div>
      <div className={styles['radio-button']}>
        <FuneralAmountOrAffordability
          product={product}
          onClick={(val) => funeralAmountOrAffordability.onClick(val)}
          option={funeralAmountOrAffordability.option}
        />
      </div>
      <Bump onClick={bump} />
    </div>
  );
};
