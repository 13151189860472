import React from 'react';
import { NglicSimpleSelect, Option } from '@nglic/component-lib';

export interface PaymentMethodProps {
  onChange: (val: Option) => void;
  method: Option | undefined;
}

const paymentMethodOptions: Option[] = [
  {
    id: 'monthly-eft',
    name: 'Monthly EFT',
  },
  {
    id: 'annual',
    name: 'Annual',
  },
  {
    id: 'semi-annual',
    name: 'Semi Annual',
  },
  {
    id: 'quarterly',
    name: 'Quarterly',
  },
  {
    id: 'monthly-direct',
    name: 'Monthly Direct',
  },
  {
    id: 'monthly-credit-card',
    name: 'Monthly Credit Card',
  },
];

export const PaymentMethod: React.FC<PaymentMethodProps> = ({
  onChange,
  method,
}) => {
  return (
    <div>
      <NglicSimpleSelect
        selectedOption={method}
        onChange={(value) => {
          onChange(value);
        }}
        options={paymentMethodOptions || []}
        label={'Payment Method'}
      />
    </div>
  );
};
