import produce from 'immer';
import {
  Address,
  UserData,
  AgentWorkingState,
} from './PersonalInformation.types';
import { AddPersonalInformationAction } from './personalInformation.action';

export type ContractUserDemographics = UserData & {
  homeAddress?: Address;
  workAddress?: Address;
  agentWorkingStates?: AgentWorkingState[];
  workAddressSameAsHomeAddress?: boolean;
  communicationPreference?: string;
};
export type PersonalInformationState = 'INCOMPLETE' | ContractUserDemographics;

export const personalInformationReducer = (
  state: PersonalInformationState = 'INCOMPLETE',
  action: AddPersonalInformationAction,
): PersonalInformationState => {
  return produce(state, () => {
    switch (action.type) {
      case 'ADD_PERSONAL_INFORMATION':
        return {
          ...action.payload,
        };

      default:
        return state;
    }
  });
};
