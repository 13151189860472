import React from 'react';
import _ from 'lodash';
import { PageEnum } from '@nglic/component-lib/build';
import styles from './Submitted.module.scss';
import { ReactComponent as MailSent } from '../../../../assets/img/mailSent/mail_sent.svg';
import { AppState } from '../../../../rootReducer';
import { useSelector } from 'react-redux';
import { OnboardingStatus } from '../../../../service/service.types';
import { getRouteForContractingStatus } from '../../../../util/routing';
import { RouteComponentProps } from 'react-router-dom';
import { AppPageLayoutWrapper } from '../../AppPageLayoutWrapper';
import { getUserInformation } from '../../../shared/selectors/getUserInformation';

export const SubmittedPage: React.FC<
  Pick<RouteComponentProps<{ subPage: string }>, 'match' | 'history'>
> = ({ history }) => {
  const navTo = (route: string) => {
    history.push(route);
  };

  const agentData = useSelector((state: AppState) => getUserInformation(state));
  const submitted: boolean = useSelector((data: AppState) => {
    return _.get(data, 'presentation.contract.review.submitted', false);
  });

  React.useEffect(() => {
    if (agentData && agentData.contractingStatus) {
      const { agentContractingStatus } = agentData.contractingStatus;

      if (agentContractingStatus !== OnboardingStatus.PENDING && !submitted) {
        navTo(getRouteForContractingStatus(agentContractingStatus));
      }
    }
  }, [agentData, submitted]);

  return (
    <AppPageLayoutWrapper page={'' as PageEnum} title={''}>
      <div className={styles['root']}>
        <div className={styles['icon-container']}>
          <MailSent className={styles['icon']} />
        </div>
        <div className={styles['header']}>Your contract was submitted!</div>
        <div className={styles['details']}>
          Check your email for a message from contracting@nglic.com and follow
          the instructions to sign your contract using DocuSign.
          <br />
          <br /> Thank you for contracting with NGL!
        </div>
      </div>
    </AppPageLayoutWrapper>
  );
};
