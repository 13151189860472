import React from 'react';
import { TrackedPaymentMethod } from './PaymentMethod/PaymentMethod';
import { TrackedPersonalInformation } from './PersonalInformation/PersonalInformation';
import {
  MobileStepperHeader,
  NglicBreadcrumbs,
  PageEnum,
  SectionTitle,
  useNglScreenSize,
} from '@nglic/component-lib/build';
import styles from './ContractPage.module.scss';
import {
  ContractPageStepsEnum,
  ContractBreadcrumbItem,
  ContractSubPageNavigationItem,
} from './contract.types';
import {
  isValidBackgroundData,
  TrackedBackground,
} from './Background/Background';
import { TrackedDocumentUpload } from './DocumentUpload/DocumentUpload';
import { TrackedReview } from './Review/Review';
import { ContractState } from './contract.reducer';
import { RouteComponentProps } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AppState } from '../../../rootReducer';
import { AppPageLayoutWrapper } from '../AppPageLayoutWrapper';
import classNames from 'classnames';

export enum ContractPages {
  PERSONAL_INFORMATION = 'personal-information',
  BACKGROUND = 'background',
  PAYMENT_METHOD = 'payment-method',
  DOCUMENT_UPLOAD = 'document-upload',
  REVIEW = 'review',
}
const routePrefix = '/contract';
export const contractRoutes = {
  PERSONAL_INFORMATION: `${routePrefix}/${ContractPages.PERSONAL_INFORMATION}`,
  BACKGROUND: `${routePrefix}/${ContractPages.BACKGROUND}`,
  PAYMENT_METHOD: `${routePrefix}/${ContractPages.PAYMENT_METHOD}`,
  DOCUMENT_UPLOAD: `${routePrefix}/${ContractPages.DOCUMENT_UPLOAD}`,
  REVIEW: `${routePrefix}/${ContractPages.REVIEW}`,
};

const pageOrder: Record<ContractPages, number> = {
  [ContractPages.PERSONAL_INFORMATION]: 1,
  [ContractPages.BACKGROUND]: 2,
  [ContractPages.PAYMENT_METHOD]: 3,
  [ContractPages.DOCUMENT_UPLOAD]: 4,
  [ContractPages.REVIEW]: 5,
};

const getPageCompletionStatuses = (state: ContractState) => {
  const personalInformationComplete =
    state.personalInformation !== 'INCOMPLETE';
  const backgroundComplete = isValidBackgroundData(
    state.background.backgroundData,
  );
  const paymentMethodComplete = state.paymentMethod !== 'INCOMPLETE';
  const documentUploadComplete = true;

  return {
    personalInformationComplete,
    backgroundComplete,
    paymentMethodComplete,
    documentUploadComplete,
  };
};

const getLastCompletedPage = (state: ContractState) => {
  const {
    personalInformationComplete,
    backgroundComplete,
    paymentMethodComplete,
    documentUploadComplete,
  } = getPageCompletionStatuses(state);

  if (
    personalInformationComplete &&
    backgroundComplete &&
    paymentMethodComplete &&
    documentUploadComplete
  ) {
    return ContractPages.REVIEW;
  }

  if (
    personalInformationComplete &&
    backgroundComplete &&
    paymentMethodComplete
  ) {
    return ContractPages.DOCUMENT_UPLOAD;
  }

  if (personalInformationComplete && backgroundComplete) {
    return ContractPages.PAYMENT_METHOD;
  }

  if (personalInformationComplete) {
    return ContractPages.BACKGROUND;
  }

  return ContractPages.PERSONAL_INFORMATION;
};

export const ContractPage: React.FC<
  Pick<RouteComponentProps<{ subPage: string }>, 'match' | 'history'>
> = (props) => {
  const { match, history } = props;
  const subPage = match.params.subPage;
  const createRouteTo = (route: string) => () => {
    history.push(route);
  };

  const contractState = useSelector(
    (state: AppState) => state.presentation.contract,
  );

  const { isMobile } = useNglScreenSize();

  React.useEffect(() => {
    const lastCompletedPage = getLastCompletedPage(contractState);
    const lastCompletedPageNumber = pageOrder[lastCompletedPage];
    const currentPageNumber = pageOrder[subPage];

    if (currentPageNumber > lastCompletedPageNumber) {
      history.push(`${routePrefix}/${lastCompletedPage}`);
    }
  }, []);

  const {
    personalInformationComplete,
    backgroundComplete,
    paymentMethodComplete,
    documentUploadComplete,
  } = getPageCompletionStatuses(contractState);

  const subNavPageHrefMap = {
    [ContractPageStepsEnum.PERSONAL_INFORMATION]: 'Personal Information',
    [ContractPageStepsEnum.BACKGROUND]: 'Background Questions',
    [ContractPageStepsEnum.PAYMENT_METHOD]: 'Payment Method',
    [ContractPageStepsEnum.DOCUMENT_UPLOAD]: 'Document Upload',
    [ContractPageStepsEnum.REVIEW]: 'Review Contract',
  };

  const subNavItems: ContractSubPageNavigationItem[] = [
    {
      currentPage:
        subNavPageHrefMap[ContractPageStepsEnum.PERSONAL_INFORMATION],
      nextPage: subNavPageHrefMap[ContractPageStepsEnum.BACKGROUND],
      active: subPage === ContractPages.PERSONAL_INFORMATION,
    },
    {
      currentPage: subNavPageHrefMap[ContractPageStepsEnum.BACKGROUND],
      nextPage: subNavPageHrefMap[ContractPageStepsEnum.PAYMENT_METHOD],
      active: subPage === ContractPages.BACKGROUND,
    },
    {
      currentPage: subNavPageHrefMap[ContractPageStepsEnum.PAYMENT_METHOD],
      nextPage: subNavPageHrefMap[ContractPageStepsEnum.DOCUMENT_UPLOAD],
      active: subPage === ContractPages.PAYMENT_METHOD,
    },
    {
      currentPage: subNavPageHrefMap[ContractPageStepsEnum.DOCUMENT_UPLOAD],
      nextPage: subNavPageHrefMap[ContractPageStepsEnum.REVIEW],
      active: subPage === ContractPages.DOCUMENT_UPLOAD,
    },
    {
      currentPage: subNavPageHrefMap[ContractPageStepsEnum.REVIEW],
      active: subPage === ContractPages.REVIEW,
    },
  ];

  const items: ContractBreadcrumbItem[] = [
    {
      label: ContractPageStepsEnum.PERSONAL_INFORMATION,
      active: subPage === ContractPages.PERSONAL_INFORMATION,
      onClick: createRouteTo(contractRoutes.PERSONAL_INFORMATION),
    },
    {
      label: ContractPageStepsEnum.BACKGROUND,
      active: subPage === ContractPages.BACKGROUND,
      onClick: personalInformationComplete
        ? createRouteTo(contractRoutes.BACKGROUND)
        : undefined,
    },
    {
      label: ContractPageStepsEnum.PAYMENT_METHOD,
      active: subPage === ContractPages.PAYMENT_METHOD,
      onClick: backgroundComplete
        ? createRouteTo(contractRoutes.PAYMENT_METHOD)
        : undefined,
    },
    {
      label: ContractPageStepsEnum.DOCUMENT_UPLOAD,
      active: subPage === ContractPages.DOCUMENT_UPLOAD,
      onClick: paymentMethodComplete
        ? createRouteTo(contractRoutes.DOCUMENT_UPLOAD)
        : undefined,
    },
    {
      label: ContractPageStepsEnum.REVIEW,
      active: subPage === ContractPages.REVIEW,
      onClick:
        personalInformationComplete &&
        backgroundComplete &&
        paymentMethodComplete &&
        documentUploadComplete
          ? createRouteTo(contractRoutes.REVIEW)
          : undefined,
    },
  ];

  const renderCurrentSubPage = (page: string) => {
    switch (page) {
      case ContractPages.PERSONAL_INFORMATION:
        return (
          <TrackedPersonalInformation
            routeToNext={createRouteTo(contractRoutes.BACKGROUND)}
          />
        );
      case ContractPages.BACKGROUND:
        return (
          <TrackedBackground
            routeToNext={createRouteTo(contractRoutes.PAYMENT_METHOD)}
          />
        );
      case ContractPages.PAYMENT_METHOD:
        return (
          <TrackedPaymentMethod
            routeToNext={createRouteTo(contractRoutes.DOCUMENT_UPLOAD)}
          />
        );
      case ContractPages.DOCUMENT_UPLOAD:
        return (
          <TrackedDocumentUpload
            routeToNext={createRouteTo(contractRoutes.REVIEW)}
          />
        );
      case ContractPages.REVIEW:
        return <TrackedReview routeTo={history.push} />;
    }
  };

  const renderSectionTitle = (page: string) => {
    switch (page) {
      case ContractPages.PERSONAL_INFORMATION:
        return (
          <SectionTitle>
            {ContractPageStepsEnum.PERSONAL_INFORMATION}
          </SectionTitle>
        );
      case ContractPages.BACKGROUND:
        return <SectionTitle>{ContractPageStepsEnum.BACKGROUND}</SectionTitle>;
      case ContractPages.PAYMENT_METHOD:
        return (
          <SectionTitle>{ContractPageStepsEnum.PAYMENT_METHOD}</SectionTitle>
        );
      case ContractPages.REVIEW:
        return <SectionTitle>{ContractPageStepsEnum.REVIEW}</SectionTitle>;
    }
  };
  return (
    <AppPageLayoutWrapper
      title="Contract"
      page={PageEnum.AGENT}
      data-testid="contract-page-component"
    >
      {isMobile && (
        <div>
          <div className={styles['mobile-contract-navigation']}>
            <MobileStepperHeader items={subNavItems} />
          </div>

          <div
            className={classNames(styles['container'], {
              [styles['review-page-container']]: subPage === 'review',
            })}
          >
            {renderCurrentSubPage(subPage)}
          </div>
        </div>
      )}
      {!isMobile && (
        <div>
          <div className={styles['bread-crumb-container']}>
            <NglicBreadcrumbs items={items} />
          </div>
          <div className={styles['container']}>
            {renderSectionTitle(subPage)}
            {renderCurrentSubPage(subPage)}
          </div>
        </div>
      )}
    </AppPageLayoutWrapper>
  );
};
