import React from 'react';
import styles from './Drawer.module.scss';
import classNames from 'classnames';
import { Link } from '@nglic/component-lib/build';
import { Column } from '../DrawerTable';
import getUuidByString from 'uuid-by-string';

interface DrawerRow<T> {
  columns: Array<Column>;
  rowData: T;
}

export const DrawerRow = <T,>(props: DrawerRow<T>) => {
  const { columns, rowData } = props;
  const renderColumn = (column: Column, data: T, index: number) => {
    const { isLink, dataLocation, linkText, style } = column;
    const renderRenderLink = isLink && data[dataLocation];
    return renderRenderLink ? (
      <div
        key={getUuidByString(`${index}`)}
        onClick={data[dataLocation]}
        className={classNames(styles['column'], {
          [styles['link']]: column.isLink,
        })}
        style={style}
      >
        <Link text={linkText || ''} onClick={data[dataLocation]} />
      </div>
    ) : (
      <div
        key={getUuidByString(`${index}`)}
        onClick={column.onClick}
        className={classNames(styles['column'], {
          [styles['link']]: column.isLink,
        })}
        style={style}
      >
        {data[dataLocation]}
      </div>
    );
  };

  return (
    <div className={styles['content-row']}>
      {columns.map((column, index) => renderColumn(column, rowData, index))}
    </div>
  );
};
