import styles from './ForgotPassword.module.scss';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import logo from '../../../../assets/img/logo/logo-ngl.png';
import logo2x from '../../../../assets/img/logo/logo-ngl@2x.png';
import logo3x from '../../../../assets/img/logo/logo-ngl@3x.png';
import { Link, PrimaryButton } from '@nglic/component-lib/build';
import { goToLoginSequence, genPasswordResetCode } from '../login.action';
import { AppState } from '../../../../rootReducer';
import { EmailInput } from '../../../modules/EmailInput/EmailInput';

export const ForgotPassword: React.FC = () => {
  const dispatch = useDispatch();

  const [emailAddress, setEmailAddress] = React.useState('');
  const [submitDisabled, setSubmitDisabled] = React.useState(true);
  const [loading, setLoading] = React.useState(false);

  const sectionsLoading = useSelector(
    (state: AppState) => state.presentation.loading.sectionsLoading,
  );

  React.useEffect(() => {
    setLoading(sectionsLoading.includes('GEN_PASSWORD_RESET_CODE'));
  }, [sectionsLoading]);

  const emailOnChange = React.useCallback(
    (val: string) => {
      setEmailAddress(val);
    },
    [emailAddress],
  );

  React.useEffect(() => {
    setSubmitDisabled(!Boolean(emailAddress.length));
  }, [emailAddress]);

  const submit = React.useCallback(() => {
    if (emailAddress.length) {
      setLoading(true);
      dispatch(genPasswordResetCode(emailAddress));
    }
  }, [emailAddress]);

  const handleKeyDown = React.useCallback(
    (e): void => {
      if (e.key === 'Enter') {
        submit();
      }
    },
    [submit],
  );

  const goToLogin = (): void => {
    dispatch(goToLoginSequence('USERNAME_AND_PASSWORD'));
  };

  return (
    <div className={styles['root']} data-testid={'forgot-password'}>
      <div onKeyDown={handleKeyDown}>
        <div className={styles['logo-container']}>
          <img
            className={styles['responsive-img']}
            srcSet={`${logo}, ${logo2x} 2x, ${logo3x} 3x`}
          />
          <div className={styles['text-content']}>
            Forgot your password? Request a reset.
          </div>
        </div>
        <div className={styles['input-container']} aria-label={'email-field'}>
          <EmailInput
            id={'forgot-password-email'}
            onChange={emailOnChange}
            email={emailAddress}
          />
        </div>
        <div className={styles['link-container']}>
          <PrimaryButton
            onKeyDown={handleKeyDown}
            ariaLabel={`submit-email-button-${
              submitDisabled ? 'inactive' : 'active'
            }`}
            disabled={submitDisabled}
            loading={loading}
            onClick={submit}
            text={'REQUEST RESET'}
          />
          <Link onClick={goToLogin} text={'Back to Login'} />
        </div>
      </div>
    </div>
  );
};
