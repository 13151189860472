import React from 'react';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import styles from './Drawer.module.scss';
import classNames from 'classnames';
import { DrawerRow } from './DrawerRow';
import { Column } from '../DrawerTable';
import getUuidByString from 'uuid-by-string';

interface DrawerProps<T> {
  columns: Array<Column>;
  headerData: T;
  rowData: T[];
  index: number;
  open: boolean;
  onOpen: (index: number) => void;
}

export const Drawer = <T,>(props: DrawerProps<T>): React.ReactElement => {
  const { columns, headerData, rowData, index, open, onOpen } = props;

  return (
    <div className={styles['root']}>
      <div
        onClick={() => {
          onOpen(index);
        }}
        className={classNames(styles['arrow'], {
          [styles['open']]: open,
        })}
      >
        <KeyboardArrowUpIcon />
      </div>
      <div
        className={classNames(styles['row-container'], {
          [styles['open']]: open,
        })}
      >
        <DrawerRow rowData={headerData} columns={columns} />
        {rowData.map((row) => (
          <DrawerRow
            key={getUuidByString(JSON.stringify(row))}
            rowData={row}
            columns={columns}
          />
        ))}
      </div>
    </div>
  );
};
