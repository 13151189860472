import React, { useEffect, useState } from 'react';
import styles from './HierarchySection.module.scss';
import {
  AgentProduct,
  Hierarchy,
} from '../../../../../../../service/service.types';
import { ConditionalLevelDropdown } from '../ConditionalLevelDropdown/ConditionalLevelDropdown';
import { Option } from '@nglic/component-lib/build';

export interface HierarchySectionProps {
  maxCommissionLevel: string;
  hierarchy: Hierarchy;
  selectedCommissionLevel?: Option;
  onUpdateHierarchy: (value: { level?: Option; hierarchy: Hierarchy }) => void;
}

export const HierarchySection: React.FC<HierarchySectionProps> = ({
  maxCommissionLevel,
  hierarchy,
  selectedCommissionLevel,
  onUpdateHierarchy,
}) => {
  const [filteredAgentHierarchy, setFilteredAgentHierarchy] =
    useState<AgentProduct[]>();
  const [selectedValue, setSelectedValue] =
    React.useState<Option | undefined>();

  const onOptionSelect = (level?: Option) => {
    onUpdateHierarchy({
      level,
      hierarchy,
    });
  };

  React.useEffect(() => {
    setSelectedValue(selectedCommissionLevel);
  }, [selectedCommissionLevel]);

  useEffect(() => {
    const filtered = hierarchy.agents.filter(
      (agent) => !agent.producerId?.startsWith('BON'),
    );
    setFilteredAgentHierarchy(filtered);
  }, []);

  return (
    <div className={styles['root']}>
      <div className={styles['agent-rows']}>
        {filteredAgentHierarchy?.reduce<React.ReactElement[]>(
          (acc, agent, index) => {
            const agentName =
              index === filteredAgentHierarchy.length - 1
                ? `${agent.agentName}   LVL(${maxCommissionLevel})`
                : agent.agentName;
            return [
              ...acc,
              <div key={agent.producerId} className={styles['agent-row']}>
                <span>{agentName}</span> <span>{agent.producerId}</span>
              </div>,
            ];
          },
          [],
        )}
      </div>
      <div className={styles['radio-section']}>
        <ConditionalLevelDropdown
          selectedValue={selectedValue}
          onOptionSelect={onOptionSelect}
          minLevel={0}
          maxLevel={
            parseFloat(maxCommissionLevel) === 0
              ? 15
              : parseFloat(maxCommissionLevel) - 0.5
          }
        />
      </div>
    </div>
  );
};
