import React, { useEffect, useState } from 'react';
import styles from './ReportData.module.scss';
import { ReportTable } from './ReportTable/ReportTable';
import { HighchartsChart } from '@nglic/component-lib/build';
import { buildChartSubtitleString, DateGroupingOption } from './report.helpers';
import { ReportFilters } from './ReportFilters/ReportFilters';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../../../rootReducer';
import { fetchReportData } from './reportData.action';
import { CommissionsDetailModal } from './CommissionsDetailModal/CommissionsDetailModal';
import dayjs from 'dayjs';
import { CommissionsCategoriesEnum } from '../Reporting/Reporting';
import { useNglScreenSize } from '@nglic/component-lib';
import { cloneDeep } from 'lodash';
import { resetReportFilters } from './reportFilters.action';

interface ReportDataProps {
  reportCategory: string;
}

export enum DateFilterGroupBy {
  last_7_days = 'day',
  last_14_days = 'day',
  this_month = 'day',
  last_month = 'day',
  last_3_months = 'month',
  last_6_months = 'month',
  last_12_months = 'month',
  this_year = 'month',
  last_year = 'month',
  all_time = 'year',
}

export const ReportData: React.FC<ReportDataProps> = ({ reportCategory }) => {
  const dispatch = useDispatch();

  // Local State
  const [show, setShow] = useState(false);
  const [date, setDate] = useState('');
  const [chartData, setChartData] = useState<{
    categories: any[];
    series: any[];
  }>({ categories: [], series: [] });
  const [tableData, setTableData] = useState<{
    title: string;
    headers: { field: string; display: string; visible: boolean }[];
    rows: Record<string, string | number>[];
  }>({ title: '', headers: [], rows: [] });

  // Redux State
  const sectionsLoading = useSelector(
    (state: AppState) => state.presentation.loading.sectionsLoading,
  );
  const reportData = useSelector(
    (state: AppState) => state.presentation.manageBusiness.reportData,
  );
  const filterOptions = useSelector(
    (state: AppState) =>
      state.presentation.manageBusiness.reportFilters.filterOptions,
  );
  const currentFilters = useSelector(
    (state: AppState) =>
      state.presentation.manageBusiness.reportFilters.currentFilters,
  );

  useEffect(() => {
    dispatch(resetReportFilters());
  }, [reportCategory]);

  useEffect(() => {
    // Need to do a deep clone here, otherwise it still maintains a reference to the redux state
    const newChartData = cloneDeep({
      categories: reportData.categories,
      series: reportData.chartData,
    });

    const newTableData = cloneDeep({
      title: reportData.tableData.title,
      headers: reportData.tableData.headers,
      rows: reportData.tableData.rows,
    });

    setTableData(newTableData);
    setChartData(newChartData);
  }, [reportData]);

  useEffect(() => {
    dispatch(fetchReportData(reportCategory, currentFilters));
  }, [currentFilters]);

  const handleShowCommissionsDetail = (event: any) => {
    setShow(true);
    setDate(event?.grouped_date);
  };

  const handleCloseCommissionsDetail = () => {
    setShow(false);
    setDate('');
  };
  const { isMobile } = useNglScreenSize();

  return (
    <div className={styles['root']} data-testid="main-element">
      {!isMobile && (
        <>
          <div className={styles['title-block']}>
            <div className={styles['title']}>
              Commissions
              {reportCategory ===
                CommissionsCategoriesEnum.COMMISSIONS_BY_PERIOD && ` by Period`}
              {reportCategory ===
                CommissionsCategoriesEnum.COMMISSIONS_BY_PRODUCT &&
                ` by Product`}
              {reportCategory ===
                CommissionsCategoriesEnum.COMMISSIONS_OVER_TIME && ` Over Time`}
            </div>
            <div className={styles['subtitle']}>
              <span>
                {buildChartSubtitleString(
                  currentFilters.dateGrouping as DateGroupingOption,
                )}
              </span>
              {reportData.timeRequested ? (
                <span>
                  values as of{' '}
                  {dayjs(reportData.timeRequested).format('MM/DD/YYYY')}
                </span>
              ) : null}
            </div>
          </div>
          <ReportFilters
            isLoading={sectionsLoading.includes('FETCH_REPORT_FILTERS')}
            currentFilterOptions={filterOptions}
            currentSelectedFilters={currentFilters}
            reportCategory={reportCategory}
          />
          <div className={styles['chart-wrapper']}>
            <HighchartsChart
              type="column"
              categories={chartData.categories}
              series={chartData.series}
              isLoading={sectionsLoading.includes('FETCH_REPORT_DATA')}
              onSeriesClick={handleShowCommissionsDetail}
              allowNegatives={false}
            />
          </div>
          {reportCategory !==
            CommissionsCategoriesEnum.COMMISSIONS_OVER_TIME && (
            <ReportTable
              title={tableData.title}
              headers={tableData.headers}
              rows={tableData.rows}
            />
          )}
          <CommissionsDetailModal
            open={show}
            date={date}
            handleClose={handleCloseCommissionsDetail}
          />
        </>
      )}

      {isMobile && (
        <>
          <ReportFilters
            isLoading={sectionsLoading.includes('FETCH_REPORT_FILTERS')}
            currentFilterOptions={filterOptions}
            currentSelectedFilters={currentFilters}
            reportCategory={reportCategory}
          />
          <div className={styles['title-block']}>
            <div className={styles['title']}>Commissions</div>
          </div>
          <div className={styles['chart-wrapper']}>
            <HighchartsChart
              type="column"
              categories={chartData.categories}
              series={chartData.series}
              isLoading={sectionsLoading.includes('FETCH_REPORT_DATA')}
              allowNegatives={false}
            />
          </div>
          <ReportTable
            title={tableData.title}
            headers={tableData.headers}
            rows={tableData.rows}
          />
          <div className={styles['modal']}>
            <CommissionsDetailModal
              open={show}
              date={date}
              handleClose={handleCloseCommissionsDetail}
            />
          </div>
        </>
      )}
    </div>
  );
};
