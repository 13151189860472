import React, { useState } from 'react';
import {
  ConditionalRow,
  Platforms,
  TextInput,
  useNglScreenSize,
} from '@nglic/component-lib';
import styles from './TextInputRows.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
  BackgroundTitlesEnum,
  ErrorMessage,
  InitErrorMessage,
} from '../Background.types';
import { isTextBetweenZeroAndFiveHundred } from '@nglic/utilities-ts/build';
import { selectRow } from '../background.action';
import { AppState } from '../../../../../rootReducer';

interface GlobalTextInputRowsProps {
  title: string;
  index: number;
}

export const TextInputRow: React.FC<GlobalTextInputRowsProps> = ({
  title,
  index,
}) => {
  const [remainingCharacters, setRemainingCharacters] = useState(500);
  const [errorMessage, setErrorMessage] =
    React.useState<ErrorMessage>(InitErrorMessage);
  const dispatch = useDispatch();
  const { platform } = useNglScreenSize();
  const backgroundData = useSelector(
    (data: AppState) => data.presentation.contract.background.backgroundData,
  );

  const handleRemainingCharacters = React.useCallback(
    (newValue: string) => {
      const newCharacters = 500 - newValue.length;
      if (newCharacters >= 0) {
        setRemainingCharacters(newCharacters);
      }
    },
    [remainingCharacters],
  );

  const handleOnClick = React.useCallback(
    (titleName: string, value: string) => {
      if (value === 'No') {
        setRemainingCharacters(500);
      }
    },
    [remainingCharacters],
  );

  const handleErrorMessage = React.useCallback(
    (value: string) => {
      setErrorMessage({
        ...errorMessage,
        error: !isTextBetweenZeroAndFiveHundred(value),
        message: !isTextBetweenZeroAndFiveHundred(value)
          ? 'Must not exceed 500 characters'
          : '',
      });
    },
    [errorMessage],
  );

  const handleOnClickConditionalRow = (
    titleName: string,
    conditionalRowValue: string,
  ) => {
    switch (titleName) {
      case BackgroundTitlesEnum.FILED_BANKRUPTCY:
        dispatch(
          selectRow(BackgroundTitlesEnum.FILED_BANKRUPTCY, conditionalRowValue),
        );
        break;
      case BackgroundTitlesEnum.JUDGEMENT:
        dispatch(
          selectRow(BackgroundTitlesEnum.JUDGEMENT, conditionalRowValue),
        );
        break;
      case BackgroundTitlesEnum.FELONY:
        dispatch(selectRow(BackgroundTitlesEnum.FELONY, conditionalRowValue));
        break;
      case BackgroundTitlesEnum.BEEN_BONDED:
        dispatch(
          selectRow(BackgroundTitlesEnum.BEEN_BONDED, conditionalRowValue),
        );
        break;
      case BackgroundTitlesEnum.HAD_A_LICENSE:
        dispatch(
          selectRow(BackgroundTitlesEnum.HAD_A_LICENSE, conditionalRowValue),
        );
        break;
      case BackgroundTitlesEnum.INDEBTED:
        dispatch(selectRow(BackgroundTitlesEnum.INDEBTED, conditionalRowValue));
        break;
    }
  };

  const handleOnChangeInput = (titleName: string, inputValue: string) => {
    switch (titleName) {
      case BackgroundTitlesEnum.FILED_BANKRUPTCY:
        dispatch(
          selectRow(BackgroundTitlesEnum.FILED_BANKRUPTCY, 'Yes', inputValue),
        );
        break;
      case BackgroundTitlesEnum.JUDGEMENT:
        dispatch(selectRow(BackgroundTitlesEnum.JUDGEMENT, 'Yes', inputValue));
        break;
      case BackgroundTitlesEnum.FELONY:
        dispatch(selectRow(BackgroundTitlesEnum.FELONY, 'Yes', inputValue));
        break;
      case BackgroundTitlesEnum.BEEN_BONDED:
        dispatch(
          selectRow(BackgroundTitlesEnum.BEEN_BONDED, 'Yes', inputValue),
        );
        break;
      case BackgroundTitlesEnum.HAD_A_LICENSE:
        dispatch(
          selectRow(BackgroundTitlesEnum.HAD_A_LICENSE, 'Yes', inputValue),
        );
        break;
      case BackgroundTitlesEnum.INDEBTED:
        dispatch(selectRow(BackgroundTitlesEnum.INDEBTED, 'Yes', inputValue));
        break;
    }
  };

  return (
    <div className={styles['global-text-input-row-container']}>
      <ConditionalRow
        onClick={(titleName: string, value: string) => {
          handleOnClickConditionalRow(titleName, value);
          handleOnClick(titleName, value);
        }}
        value={backgroundData[index].selected as 'Yes' | 'No'}
        title={title}
        mobileLayout={platform === Platforms.PHONE}
      >
        <div className={styles['text-input']}>
          <TextInput
            error={errorMessage}
            onChange={(value: string) => {
              handleOnChangeInput(title, value);
              handleRemainingCharacters(value);
              handleErrorMessage(value);
            }}
            value={backgroundData[index].explanation}
            label="Explanation"
            multiline={true}
          />
          <p>{remainingCharacters} characters remaining</p>
        </div>
      </ConditionalRow>
    </div>
  );
};
