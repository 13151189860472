import React from 'react';
import {
  FlexDirection,
  NglicCheckbox,
  NglicRadioGroup,
  PrimaryButton,
  TextInput,
  useNglScreenSize,
} from '@nglic/component-lib/build';
import styles from './PaymentMethod.module.scss';
import {
  AccountTypeEnum,
  PaymentErrorsEnum,
  PaymentFrequencyEnum,
} from '../contract.types';
import {
  ErrorMessages,
  InitErrorMessages,
  PaymentData,
  PaymentEmptyObject,
} from './PaymentMethod.types';
import card from '../../../../assets/img/card/card.png';
import card2x from '../../../../assets/img/card/card@2x.png';
import card3x from '../../../../assets/img/card/card@3x.png';
import {
  checkIfStringDoNotMatch,
  containsEmptyFieldV2,
  isNineDigits,
} from '@nglic/utilities-ts/build';
import { useDispatch, useSelector } from 'react-redux';
import {
  addPersonalInformation,
  clearPaymentMethod,
} from './paymentMethod.action';
import { AppState } from '../../../../rootReducer';
import { Platforms } from '@nglic/component-lib';
import { withTracker } from 'ga-4-react';

export const PaymentMethod: React.FC<{ routeToNext?: () => void }> = ({
  routeToNext,
}) => {
  const dispatch = useDispatch();
  const { platform } = useNglScreenSize();
  const [submitDisabled, setSubmitDisabled] = React.useState(true);
  const [payBiWeekly, setPayBiWeekly] = React.useState(false);
  const [digits, setDigits] = React.useState<number>(9);

  const [errorMessage, setErrorMessage] =
    React.useState<ErrorMessages>(InitErrorMessages);
  const [paymentData, setPaymentData] = React.useState<PaymentData>({
    bankName: '',
    accountType: '',
    routingNumber: '',
    accountNumber: '',
    confirmAccountNumber: '',
    paymentFrequency: '',
    paymentType: 'EFT',
  });

  const paymentMethodDataRedux = useSelector(
    (state: AppState) => state.presentation.contract.paymentMethod,
  );

  const payByCheck = (data: any) => {
    if (data === 'INCOMPLETE') {
      Object.assign(paymentData, PaymentEmptyObject);
    }
  };

  const payByEft = (data: any) => {
    if (data !== 'INCOMPLETE') {
      setPaymentData({
        bankName: data.bankName,
        accountType: data.accountType,
        routingNumber: data.routingNumber,
        accountNumber: data.accountNumber,
        confirmAccountNumber: data.confirmAccountNumber,
        paymentFrequency: data.paymentFrequency,
        paymentType: data.paymentType,
      });
    }
  };

  React.useEffect(() => {
    payBiWeekly
      ? payByCheck(paymentMethodDataRedux)
      : payByEft(paymentMethodDataRedux);
  }, [payBiWeekly]);

  const handleErrorMessage = (
    input: PaymentErrorsEnum,
    newValue: string,
  ): void => {
    switch (input) {
      case PaymentErrorsEnum.ROUTING_NUMBER:
        setErrorMessage({
          ...errorMessage,
          RoutingNumber: {
            error: !isNineDigits(newValue),
            message: !isNineDigits(newValue)
              ? 'Must be 9 digits and only contain numbers'
              : '',
          },
        });
        break;
      case PaymentErrorsEnum.ACCOUNT_NUMBER_CONFIRMATION:
        const error = checkIfStringDoNotMatch(
          newValue,
          paymentData.accountNumber,
        );
        const reverseCheck = checkIfStringDoNotMatch(
          newValue,
          paymentData.confirmAccountNumber,
        );
        setErrorMessage({
          ...errorMessage,
          AccountNumberConfirmation: {
            error: error && reverseCheck,
            message:
              error && reverseCheck ? 'Account number does not match' : '',
          },
        });
        break;
      default:
    }
  };

  const handleOnChangeInput = React.useCallback(
    (inputName: string, newValue: string) => {
      if (
        inputName === 'paymentFrequency' &&
        paymentData.paymentFrequency !== ''
      ) {
        paymentData.paymentFrequency = newValue;
      }

      if (
        paymentData.paymentType === 'CHECK' &&
        paymentData.paymentFrequency !== ''
      ) {
        paymentData.paymentType = 'EFT';
      }

      if (inputName === 'routingNumber') {
        const newDigits = 9 - newValue.length;
        if (newDigits >= 0) {
          setDigits(newDigits);
        }
      }

      setPaymentData({
        ...paymentData,
        [inputName]: newValue,
      });
    },
    [paymentData],
  );

  const accountTypes = [
    {
      label: AccountTypeEnum.CHECKING,
      disabled: payBiWeekly,
      onClick: () =>
        handleOnChangeInput('accountType', AccountTypeEnum.CHECKING),
      selected: paymentData.accountType === AccountTypeEnum.CHECKING,
    },
    {
      disabled: payBiWeekly,
      label: AccountTypeEnum.SAVINGS,
      onClick: () =>
        handleOnChangeInput('accountType', AccountTypeEnum.SAVINGS),
      selected: paymentData.accountType === AccountTypeEnum.SAVINGS,
    },
  ];

  const paymentFrequency = [
    {
      disabled: payBiWeekly,
      label: PaymentFrequencyEnum.DAILY,
      onClick: () =>
        handleOnChangeInput('paymentFrequency', PaymentFrequencyEnum.DAILY),
      selected: paymentData.paymentFrequency === PaymentFrequencyEnum.DAILY,
    },
    {
      disabled: payBiWeekly,
      label: PaymentFrequencyEnum.WEEKLY,
      onClick: () =>
        handleOnChangeInput('paymentFrequency', PaymentFrequencyEnum.WEEKLY),
      selected: paymentData.paymentFrequency === PaymentFrequencyEnum.WEEKLY,
    },
    {
      disabled: payBiWeekly,
      label: PaymentFrequencyEnum.BI_WEEKLY,
      onClick: () =>
        handleOnChangeInput('paymentFrequency', PaymentFrequencyEnum.BI_WEEKLY),
      selected: paymentData.paymentFrequency === PaymentFrequencyEnum.BI_WEEKLY,
    },
    {
      disabled: payBiWeekly,
      label: PaymentFrequencyEnum.MONTHLY,
      onClick: () =>
        handleOnChangeInput('paymentFrequency', PaymentFrequencyEnum.MONTHLY),
      selected: paymentData.paymentFrequency === PaymentFrequencyEnum.MONTHLY,
    },
  ];

  const handleSubmit = () => {
    if (!submitDisabled && routeToNext) {
      if (payBiWeekly) {
        dispatch(addPersonalInformation({ payByCheck: true }));
      } else {
        dispatch(addPersonalInformation(paymentData));
      }
      routeToNext();
    }
  };

  const handleOnPaste = (event: any) => {
    event.preventDefault();
  };

  React.useEffect(() => {
    const validRoutingNumber = isNineDigits(paymentData.routingNumber);
    const accountNumberNotMatching = checkIfStringDoNotMatch(
      paymentData.accountNumber,
      paymentData.confirmAccountNumber,
    );
    const values = Object.values(paymentData);

    setSubmitDisabled(
      (containsEmptyFieldV2(values) ||
        !validRoutingNumber ||
        accountNumberNotMatching) &&
        !payBiWeekly,
    );
  }, [paymentData, payBiWeekly]);

  return (
    <div data-testid="payment-method" className={styles['payment-method-root']}>
      <div className={styles['desktop-container']}>
        <div className={styles['checkbox-container']}>
          <NglicCheckbox
            onChange={React.useCallback(() => {
              dispatch(clearPaymentMethod());
              setPayBiWeekly(!payBiWeekly);
            }, [payBiWeekly])}
            checked={payBiWeekly}
          />
          <label className={styles['label']}>
            I prefer to be paid bi-weekly by check
          </label>
        </div>
        <div className={styles['input-container']}>
          <div className={styles['col']}>
            <div className={styles['item']}>
              <TextInput
                label="Bank Name"
                id={'bank_name'}
                disabled={payBiWeekly}
                onChange={(newValue: string) =>
                  handleOnChangeInput('bankName', newValue)
                }
                value={paymentData.bankName}
              />
            </div>
            <div className={styles['radio-group-item']}>
              <NglicRadioGroup radios={accountTypes} title="Account Types" />
            </div>
            <div className={styles['item']}>
              <TextInput
                label="Routing Number"
                id={'routing_number'}
                disabled={payBiWeekly}
                onChange={(newValue: string) => {
                  handleOnChangeInput('routingNumber', newValue);
                  handleErrorMessage(
                    PaymentErrorsEnum.ROUTING_NUMBER,
                    newValue,
                  );
                }}
                value={paymentData.routingNumber}
                error={errorMessage.RoutingNumber}
              />
              <p className={styles['digits']}>{digits} digits remaining</p>
            </div>
          </div>
          <div className={styles['col']}>
            <img
              className={styles['payment-card']}
              srcSet={`${card}, ${card2x} 2x, ${card3x} 3x`}
              alt={'Payment Card'}
            />
          </div>
        </div>
        <div className={styles['account-number-container']}>
          <div className={styles['col']}>
            <TextInput
              label="Account Number"
              id={'account_number'}
              disabled={payBiWeekly}
              onChange={(newValue: string) => {
                handleOnChangeInput('accountNumber', newValue);
                handleErrorMessage(
                  PaymentErrorsEnum.ACCOUNT_NUMBER_CONFIRMATION,
                  newValue,
                );
              }}
              value={paymentData.accountNumber}
            />
          </div>

          <div className={styles['col']}>
            <TextInput
              label="Re-Enter Account Number"
              id={'confirmation_account_number'}
              disabled={payBiWeekly}
              value={paymentData.confirmAccountNumber}
              onChange={(newValue: string) => {
                handleOnChangeInput('confirmAccountNumber', newValue);
                handleErrorMessage(
                  PaymentErrorsEnum.ACCOUNT_NUMBER_CONFIRMATION,
                  newValue,
                );
              }}
              onPaste={handleOnPaste}
              error={errorMessage.AccountNumberConfirmation}
            />
          </div>
        </div>
        <NglicRadioGroup radios={paymentFrequency} title="Payment Frequency" />
        <div className={styles['buttonContainer']}>
          <PrimaryButton
            text="Next"
            onClick={handleSubmit}
            disabled={submitDisabled}
          />
        </div>
      </div>
      <div className={styles['mobile-container']}>
        <div className={styles['checkbox-container']}>
          <NglicCheckbox
            onChange={React.useCallback(() => {
              dispatch(clearPaymentMethod());
              setPayBiWeekly(!payBiWeekly);
            }, [payBiWeekly])}
            checked={payBiWeekly}
          />
          <label className={styles['label']}>
            I prefer to be paid bi-weekly by check
          </label>
        </div>
        <div className={styles['col']}>
          <img
            className={styles['payment-card']}
            srcSet={`${card}, ${card2x} 2x, ${card3x} 3x`}
            alt={'Payment Card'}
          />
        </div>
        {!payBiWeekly && (
          <div>
            <div className={styles['input-container']}>
              <div className={styles['col']}>
                <div className={styles['item']}>
                  <TextInput
                    label="Bank Name"
                    id={'bank_name'}
                    disabled={payBiWeekly}
                    onChange={(newValue: string) =>
                      handleOnChangeInput('bankName', newValue)
                    }
                    value={paymentData.bankName}
                  />
                </div>
                <div className={styles['item']} style={{ width: '75%' }}>
                  <TextInput
                    label="Routing Number"
                    id={'routing_number'}
                    disabled={payBiWeekly}
                    onChange={(newValue: string) => {
                      handleOnChangeInput('routingNumber', newValue);
                      handleErrorMessage(
                        PaymentErrorsEnum.ROUTING_NUMBER,
                        newValue,
                      );
                    }}
                    value={paymentData.routingNumber}
                    error={errorMessage.RoutingNumber}
                  />
                  <p className={styles['digits']}>{digits} digits remaining</p>
                </div>
              </div>
            </div>
            <div className={styles['account-number-container']}>
              <div className={styles['col']}>
                <TextInput
                  label="Account Number"
                  id={'account_number'}
                  disabled={payBiWeekly}
                  onChange={(newValue: string) => {
                    handleOnChangeInput('accountNumber', newValue);
                    handleErrorMessage(
                      PaymentErrorsEnum.ACCOUNT_NUMBER_CONFIRMATION,
                      newValue,
                    );
                  }}
                  value={paymentData.accountNumber}
                />
              </div>
              <div className={styles['col']}>
                <TextInput
                  label="Re-Enter Account Number"
                  id={'confirmation_account_number'}
                  disabled={payBiWeekly}
                  value={paymentData.confirmAccountNumber}
                  onChange={(newValue: string) => {
                    handleOnChangeInput('confirmAccountNumber', newValue);
                    handleErrorMessage(
                      PaymentErrorsEnum.ACCOUNT_NUMBER_CONFIRMATION,
                      newValue,
                    );
                  }}
                  onPaste={handleOnPaste}
                  error={errorMessage.AccountNumberConfirmation}
                />
              </div>
            </div>
            <div className={styles['radio-group-item']}>
              <NglicRadioGroup
                radios={accountTypes}
                title="Account Types"
                radiosFlexDirection={
                  platform === Platforms.PHONE
                    ? FlexDirection.COLUMN
                    : FlexDirection.ROW
                }
              />
            </div>
            <div className={styles['radio-group-item']}>
              <NglicRadioGroup
                radios={paymentFrequency}
                title="Payment Frequency"
                radiosFlexDirection={
                  platform === Platforms.PHONE
                    ? FlexDirection.COLUMN
                    : FlexDirection.ROW
                }
              />
            </div>
          </div>
        )}

        <div className={styles['buttonContainer']}>
          <PrimaryButton
            text="Next"
            onClick={handleSubmit}
            disabled={submitDisabled}
          />
        </div>
      </div>
    </div>
  );
};

export const TrackedPaymentMethod: React.FC<any> = withTracker(PaymentMethod);
