import { BFFClient } from '../clients/BFFClient';
import { State } from './service.types';
import { BaseService } from './BaseService';

const StateServicePaths = {
  STATES: () => `/ao/states`,
};

export class StateService extends BaseService {
  static getBFFClient(): BFFClient {
    return new BFFClient();
  }

  static async getStates(credentials: {
    accessToken: string;
    idToken: string;
  }): Promise<State[]> {
    const { states } = await StateService.getBFFClient().get<{
      states: State[];
    }>(
      `${StateServicePaths.STATES()}`,
      StateService.getBaseHeaders(credentials.accessToken, credentials.idToken),
    );
    return states;
  }
}
