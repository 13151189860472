import React, { useState } from 'react';
import styles from './CommissionRow.module.scss';
import DownloadIcon from '../../../../../../assets/img/downloadCommission/Vector.png';
import { CircularProgress } from '@material-ui/core';

import { CommissionActivityService } from '../../../../../../service/CommissionActivityService';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../../../rootReducer';
import { getCredentials } from '../../../../../shared/selectors/getCredentials';

export const CommissionRow: React.FC<{
  date: string;
}> = ({ date }) => {
  const [loading, setLoading] = useState(false);
  const tokens = useSelector((state: AppState) => getCredentials(state));

  const handleCommissionSelection = (statementDate: string) => {
    setLoading(true);
    CommissionActivityService.getCommissionStatementPdf({
      statementDate,
      credentials: tokens,
    })
      .then((res) => {
        window.open(res.objectUrl, '_blank');
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  };
  return (
    <div
      className={styles['row']}
      data-testid="commission-statement-row"
      onClick={() => handleCommissionSelection(date)}
    >
      <div className={styles['date']}>{date}</div>
      <div>
        {!loading && <img src={DownloadIcon} />}
        {loading && <CircularProgress size={20} />}
      </div>
    </div>
  );
};
