import {
  AgentProduct,
  Hierarchy,
  Product,
} from '../../../../service/service.types';
import React, { useState } from 'react';
import { Option } from '@nglic/component-lib/build';
import { HierarchyModal } from '../../../modules/HierarchyModal/HierarchyModal';
import { createLevelArray } from '@nglic/utilities-ts/build';

export const EditHierarchy: React.FC<{
  open: boolean;
  currentAgentId?: string;
  handleClose: () => void;
  hierarchy?: Hierarchy;
  tokens: { accessToken: string; idToken: string };
  onSave: (
    updatedHierarchies: AgentProduct[],
    originalHierarchy: Hierarchy,
  ) => void;
}> = ({ hierarchy, open, handleClose, currentAgentId, onSave }) => {
  const [availableLevels, setAvailableLevels] = useState<Option[]>([]);

  React.useEffect(() => {
    const currentAgent = findAgentInHierarchy(
      hierarchy?.agents,
      currentAgentId,
    );
    const maxLevel =
      currentAgent?.commissionLevel === '0'
        ? 15
        : parseFloat(currentAgent?.commissionLevel ?? '-1') - 0.5;

    if (maxLevel < 0) {
      setAvailableLevels([]);
      return;
    }

    setAvailableLevels(
      createLevelArray({ min: 0, max: maxLevel }).map((level) => ({
        id: `${level}`,
        name: `Level ${level}`,
      })),
    );
  }, [hierarchy, currentAgentId]);

  if (!hierarchy) {
    return <div />;
  }

  const { productName } = hierarchy.agents[0];

  const saveEdits = (updates: AgentProduct[]) => {
    onSave(updates, hierarchy);
  };

  return (
    <HierarchyModal
      isOpen={open}
      onClose={handleClose}
      agentProducts={hierarchy.agents}
      newAgentLevels={availableLevels}
      onCancel={handleClose}
      onSave={saveEdits}
      description={
        'Any changes made below will only apply to this specific hierarchy.'
      }
      title={`${productName} Hierarchy Change`}
      product={
        { id: hierarchy.productId, name: hierarchy.productName } as Product
      }
    />
  );
};

const findAgentInHierarchy = (
  agents: AgentProduct[] = [],
  agentId?: string,
): AgentProduct | undefined => {
  // Since agents can appear in the hierarchy more than once, we reverse the list
  // in order to ensure we find the hierarchy with the largest comission level first
  return [...agents].reverse().find((agent) => agent.agentId === agentId);
};
