import React, { useCallback, useState } from 'react';
import { ActionLink } from '../../ActionLink/ActionLink';
import Submit from '../../../../../../assets/img/submitIcon/Submit.png';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../../../../rootReducer';
import { ssoIntoAppqore } from './AppQoreActionLink.action';

export const AppQoreActionLink: React.FC = () => {
  const sectionsLoading = useSelector(
    (state: AppState) => state.presentation.loading.sectionsLoading,
  );
  const isLoading = sectionsLoading.includes('APPQORE_SSO');
  const dispatch = useDispatch();

  const onClick = () => {
    dispatch(ssoIntoAppqore());
  };
  return (
    <ActionLink
      ariaLabel={'appqore-sso-button'}
      text="SubmitPolicy (AppQore)"
      image={Submit}
      actionOnClick={onClick}
      loading={isLoading}
    />
  );
};
