import {
  GenericTable,
  NglicModal,
  Platforms,
  TableFieldsProps,
  useNglScreenSize,
} from '@nglic/component-lib';
import { Pagination } from '../../../../../modules/Pagination/Pagination';
import { formatDate } from '../../../Policies/PolicyDetail/PolicyInformation/PolicyInformationTransform';
import { SectionName } from '../../../../../shared/loading.action';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'rootReducer';
import {
  CommissionDateFilters,
  CommissionsActivity,
} from 'service/service.types';
import { fetchCommissionsDetailByPeriod } from './commissionsDetailByPeriod.action';
import styles from './CommissionsDetailModal.module.scss';
import CloseIcon from '../../../../../../assets/img/closeIcon/CloseIcon.png';
import { convertToLocalString } from '@nglic/utilities-ts/build';
import { CommissionsTableViewAction } from '../../../../../modules/CommissionsTableViewAction/CommissionsTableViewAction';
import { getUserInformation } from '../../../../../shared/selectors/getUserInformation';
interface CommissionsDetailModalProps {
  date: string;
  open: boolean;
  handleClose: () => void;
}

export const tableHeader: TableFieldsProps[] = [
  {
    titleName: 'Date',
    dataField: 'commissionsActivityDate',
    displayFormat: (value: string): string => {
      return formatDate(value);
    },
  },
  {
    titleName: 'Insured Name',
    dataField: 'insuredName',
  },
  {
    titleName: 'Policy ID',
    dataField: 'policyId',
  },
  {
    titleName: 'Product',
    dataField: 'productName',
  },
  {
    titleName: 'Rate',
    dataField: 'rate',
    displayFormat: (value: string): string => {
      return `${parseFloat(value)?.toString()}%`;
    },
  },
  {
    titleName: 'Face Value',
    dataField: 'faceValue',
    displayFormat: (value: string): string => {
      return `$${convertToLocalString(value)}`;
    },
  },
  {
    titleName: 'Earned',
    dataField: 'earned',
    displayFormat: (value: string): string => {
      return `$${convertToLocalString(value)}`;
    },
  },
  {
    titleName: '',
    dataField: '',
    type: 'action',
    actionComponent: CommissionsTableViewAction,
  },
];

export const mobileTableHeader = [
  {
    titleName: 'Date',
    dataField: 'commissionsActivityDate',
    displayFormat: (value: string): string => {
      return formatDate(value);
    },
  },
  {
    titleName: 'Earned',
    dataField: 'earned',
    displayFormat: (value: string): string => {
      return `$${convertToLocalString(value)}`;
    },
  },
];

const filter_on_month_array = [
  'last_3_months',
  'last_6_months',
  'last_12_months',
  'this_year',
  'last_year',
];
const filter_on_day_array = [
  'last_7_days',
  'last_14_days',
  'this_month',
  'last_month',
];

const checkForCustomDateFilter = (
  dateGrouping: string,
): CommissionDateFilters => {
  if (filter_on_month_array.includes(dateGrouping)) {
    return 'month';
  } else if (filter_on_day_array.includes(dateGrouping)) {
    return 'day';
  } else if (dateGrouping === 'all_time') {
    return 'year';
  } else {
    return 'custom';
  }
};
export const CommissionsDetailModal: React.FC<CommissionsDetailModalProps> = ({
  date,
  open,
  handleClose,
}) => {
  const dispatch = useDispatch();
  const userInfo = useSelector((state: AppState) => getUserInformation(state));
  const [page, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(6);
  const pageLimit = 6;
  const currentFilters = useSelector(
    (state: AppState) =>
      state.presentation.manageBusiness.reportFilters.currentFilters,
  );
  const [loading, setLoading] = useState<boolean>(true);

  const commissionDetailByPeriod: CommissionsActivity[] | undefined =
    useSelector(
      (state: AppState) =>
        state.presentation.manageBusiness.commissionDetailByPeriod.commissions,
    );
  const [
    filteredCommissionDetailByPeriod,
    setFilteredCommissionDetailByPeriod,
  ] = useState<CommissionsActivity[]>();
  const totalCommissions: number | undefined = useSelector(
    (state: AppState) =>
      state.presentation.manageBusiness.commissionDetailByPeriod
        .totalCommissions || 0,
  );

  const sectionsLoading: SectionName[] = useSelector(
    (state: AppState) => state.presentation.loading.sectionsLoading,
  );

  const { isMobile, platform } = useNglScreenSize();

  useEffect(() => {
    if (userInfo && date) {
      dispatch(
        fetchCommissionsDetailByPeriod(
          userInfo.agentId,
          {
            dateFrom: date,
            dateGrouping: checkForCustomDateFilter(
              currentFilters?.dateGrouping,
            ),
          },
          page,
          pageLimit,
        ),
      );
    }
  }, [userInfo, date, page]);

  useEffect(() => {
    if (!totalCommissions) {
      setTotalPages(6);
    } else {
      setTotalPages(Math.ceil(totalCommissions / pageLimit));
    }
  }, [totalCommissions]);
  useEffect(() => {
    if (commissionDetailByPeriod) {
      setFilteredCommissionDetailByPeriod(commissionDetailByPeriod);
    }
  }, [commissionDetailByPeriod]);

  useEffect(() => {
    setLoading(
      !commissionDetailByPeriod ||
        sectionsLoading.includes('COMMISSIONS_DETAIL_BY_PERIOD_FETCH_REQUEST'),
    );
  }, [commissionDetailByPeriod, sectionsLoading]);
  return (
    <NglicModal isOpened={open}>
      <div className={styles['root']}>
        <div className={styles['header']}>
          <div className={styles['title']}>MATCHING POLICIES</div>
          <div className={styles['icon']} onClick={handleClose}>
            <img src={CloseIcon} />
          </div>
        </div>

        {isMobile && platform === Platforms.PHONE ? (
          <GenericTable
            loading={loading}
            pagination={true}
            tableTitle={{ tableName: '', tablePeriod: '' }}
            data={filteredCommissionDetailByPeriod || []}
            columns={mobileTableHeader}
          >
            <div className={styles['pagination-container']}>
              <Pagination
                onPageClick={setCurrentPage}
                numberOfPages={totalPages}
                currentPage={page}
              />
            </div>
          </GenericTable>
        ) : (
          <GenericTable
            loading={loading}
            pagination={true}
            tableTitle={{ tableName: '', tablePeriod: '' }}
            data={filteredCommissionDetailByPeriod || []}
            columns={tableHeader}
          >
            <div className={styles['pagination-container']}>
              <Pagination
                onPageClick={setCurrentPage}
                numberOfPages={totalPages}
                currentPage={page}
              />
            </div>
          </GenericTable>
        )}
      </div>
    </NglicModal>
  );
};
