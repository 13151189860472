import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { AppState } from '../../../../rootReducer';
import { AgentService } from '../../../../service/AgentService';
import {
  AddDocumentResponse,
  DocumentResponse,
} from '../../../../service/service.types';
import { getAgentId } from '../../../shared/selectors/basicUserInfo.selector';
import { loadComplete, loadStarted } from '../../../shared/loading.action';

import { DocumentPendingUpload } from './document.types';
import { getCredentials } from '../../../shared/selectors/getCredentials';

export type DocumentActionType =
  | 'DOCUMENT_GET_DOCUMENTS'
  | 'DOCUMENT_GET_DOCUMENTS_FAILURE'
  | 'DOCUMENT_ADDED_DOCUMENT'
  | 'DOCUMENT_UPLOADED_DOCUMENT'
  | 'DOCUMENT_CONFIRMED_DOCUMENT'
  | 'DOCUMENT_DELETED'
  | 'DOCUMENT_UPLOAD_FAILURE'
  | 'DOCUMENT_DELETE_FAILURE'
  | 'DOCUMENT_CLEAR_ERROR'
  | 'DOCUMENT_NONE';

export interface GetDocumentsAction extends Action<DocumentActionType> {
  type: 'DOCUMENT_GET_DOCUMENTS';
  documents: DocumentResponse[];
}

export interface GetDocumentsFailureAction extends Action<DocumentActionType> {
  type: 'DOCUMENT_GET_DOCUMENTS_FAILURE';
  error: Error;
}

export interface AddedDocumentAction extends Action<DocumentActionType> {
  type: 'DOCUMENT_ADDED_DOCUMENT';
  document: DocumentResponse;
}

export interface UploadedDocumentAction extends Action<DocumentActionType> {
  type: 'DOCUMENT_UPLOADED_DOCUMENT';
  document: DocumentResponse;
}

export interface ConfirmedDocumentAction extends Action<DocumentActionType> {
  type: 'DOCUMENT_CONFIRMED_DOCUMENT';
  document: DocumentResponse;
}

export interface DeleteDocumentAction extends Action<DocumentActionType> {
  type: 'DOCUMENT_DELETED';
  documentId: string;
}

export interface UploadDocumentFailureAction
  extends Action<DocumentActionType> {
  type: 'DOCUMENT_UPLOAD_FAILURE';
  error: Error;
}

export interface DeleteDocumentFailureAction
  extends Action<DocumentActionType> {
  type: 'DOCUMENT_DELETE_FAILURE';
  error: Error;
}

export interface ClearErrorDocumentAction extends Action<DocumentActionType> {
  type: 'DOCUMENT_CLEAR_ERROR';
}

const createGetDocumentsAction = (
  documents: DocumentResponse[],
): GetDocumentsAction => ({
  type: 'DOCUMENT_GET_DOCUMENTS',
  documents,
});

const createGetDocumentFailureAction = (
  error: Error,
): GetDocumentsFailureAction => ({
  type: 'DOCUMENT_GET_DOCUMENTS_FAILURE',
  error,
});

const createUploadDocumentFailureAction = (
  error: Error,
): UploadDocumentFailureAction => ({
  type: 'DOCUMENT_UPLOAD_FAILURE',
  error,
});

const createAddedDocumentAction = (
  document: DocumentResponse,
): AddedDocumentAction => ({
  type: 'DOCUMENT_ADDED_DOCUMENT',
  document,
});

const createUploadDocumentAction = (
  document: DocumentResponse,
): UploadedDocumentAction => ({
  type: 'DOCUMENT_UPLOADED_DOCUMENT',
  document,
});

const createConfirmedDocumentAction = (
  document: DocumentResponse,
): ConfirmedDocumentAction => ({
  type: 'DOCUMENT_CONFIRMED_DOCUMENT',
  document,
});

const createDeleteDocumentFailureAction = (
  error: Error,
): DeleteDocumentFailureAction => ({
  type: 'DOCUMENT_DELETE_FAILURE',
  error,
});

const createDeleteDocumentAction = (
  documentId: string,
): DeleteDocumentAction => ({
  type: 'DOCUMENT_DELETED',
  documentId,
});

export const createClearErrorDocumentAction = (): ClearErrorDocumentAction => ({
  type: 'DOCUMENT_CLEAR_ERROR',
});

export const fetchDocuments =
  (): ThunkAction<void, AppState, any, any> => async (dispatch, getState) => {
    dispatch(loadStarted('DOCUMENT_UPLOAD'));

    const agentId = getAgentId(getState());
    const tokens = getCredentials(getState());

    if (!agentId) {
      dispatch(
        createGetDocumentFailureAction(new Error('basicInfo not initialized')),
      );
      dispatch(loadComplete('DOCUMENT_UPLOAD'));
      return;
    }

    try {
      const documents = await AgentService.getDocuments(agentId, tokens);
      dispatch(createGetDocumentsAction(documents));
    } catch (error) {
      dispatch(createGetDocumentFailureAction(error));
    }

    dispatch(loadComplete('DOCUMENT_UPLOAD'));
  };

export const uploadDocument =
  (document: DocumentPendingUpload): ThunkAction<void, AppState, any, any> =>
  async (dispatch, getState) => {
    dispatch(loadStarted('DOCUMENT_UPLOAD'));

    const agentId = getAgentId(getState());
    const tokens = getCredentials(getState());

    if (!agentId) {
      dispatch(
        createUploadDocumentFailureAction(
          new Error('basicInfo not initialized'),
        ),
      );
      dispatch(loadComplete('DOCUMENT_UPLOAD'));
      return;
    }

    let addDocumentResponse: AddDocumentResponse | null = null;

    try {
      addDocumentResponse = await AgentService.addDocument(
        agentId,
        document.file.name,
        '',
        document.documentType,
        tokens,
      );

      dispatch(createAddedDocumentAction(addDocumentResponse.document));

      const formData = new FormData();
      formData.append(document.file.name, document.file);

      const uploadURL = addDocumentResponse.uploadURL;

      await fetch(uploadURL, {
        method: 'PUT',
        body: document.file,
      });

      dispatch(createUploadDocumentAction(addDocumentResponse.document));

      await AgentService.confirmDocument(
        agentId,
        addDocumentResponse.document.id,
        tokens,
      );

      dispatch(
        createConfirmedDocumentAction({
          ...addDocumentResponse.document,
          isConfirmed: true,
        }),
      );
    } catch (error) {
      if (addDocumentResponse) {
        dispatch(deleteDocument(addDocumentResponse.document.id, false));
      }

      dispatch(createUploadDocumentFailureAction(error));
    }

    dispatch(loadComplete('DOCUMENT_UPLOAD'));
  };

export const deleteDocument =
  (documentId: string, loading = true): ThunkAction<void, AppState, any, any> =>
  async (dispatch, getState) => {
    if (loading) {
      dispatch(loadStarted('DOCUMENT_DELETE'));
    }

    const agentId = getAgentId(getState());
    const tokens = getCredentials(getState());

    if (!agentId) {
      dispatch(
        createDeleteDocumentFailureAction(
          new Error('basicInfo not initialized'),
        ),
      );
      dispatch(loadComplete('DOCUMENT_DELETE'));
      return;
    }

    try {
      await AgentService.deleteDocument(agentId, documentId, tokens);
      dispatch(createDeleteDocumentAction(documentId));
    } catch (error) {
      dispatch(createDeleteDocumentFailureAction(error));
    }

    if (loading) {
      dispatch(loadComplete('DOCUMENT_DELETE'));
    }
  };
