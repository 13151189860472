import { createSelector } from 'reselect';
import { AppState } from '../../../../rootReducer';
import {
  ContractUserDemographics,
  PersonalInformationState,
} from './personalInformation.reducer';

const getPersonalInformation = (state: AppState) =>
  state.presentation.contract.personalInformation;

export const getContractUserDemographics = createSelector<
  AppState,
  PersonalInformationState,
  ContractUserDemographics | undefined
>([getPersonalInformation], (personalInformation) => {
  if (personalInformation !== 'INCOMPLETE') {
    return personalInformation as ContractUserDemographics;
  } else {
    return undefined;
  }
});
