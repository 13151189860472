import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { AppState } from '../../rootReducer';
import { getCredentials } from './selectors/getCredentials';
import { JwtClient } from '../../clients/JwtClient';
import { OnboardingStatus, Role } from '../../service/service.types';
import { UserService } from '../../service/UserService';
import { get } from 'lodash';
import { getUserInformation } from './selectors/getUserInformation';

export enum APP_FEATURES {
  EDIT_PROFILE = 'edit_profile',
  BUSINESS_MANAGEMENT_PAGE = 'business_management_page',
  HIERARCHY_MANAGEMENT = 'hierarchy_management',
  KNOWLEDGE_CENTER = 'knowledge_center',
}

export enum APP_CHILD_FEATURES {
  COMMISSIONS = 'commissions',
  OVERVIEW = 'overview',
  POLICY_MANAGEMENT = 'policy_management',
}

export type FeatureActionTypes =
  | 'FETCH_FEATURE_VIEWABLE_REQUEST'
  | 'FETCH_FEATURE_VIEWABLE_SUCCESS'
  | 'FETCH_FEATURE_VIEWABLE_FAILURE'
  | 'NONE';

export interface FetchFeatureViewableSuccessAction
  extends Action<FeatureActionTypes> {
  type: 'FETCH_FEATURE_VIEWABLE_SUCCESS';
  result: {
    manageBusinessVisible: boolean;
    editProfileVisible: boolean;
    manageHierarchiesVisible: boolean;
    knowledgeCenterVisible: boolean;
    commissionsVisible: boolean;
    overviewVisible: boolean;
    policyManagementVisible: boolean;
  };
}

export interface FetchFeatureViewableFailureAction
  extends Action<FeatureActionTypes> {
  type: 'FETCH_FEATURE_VIEWABLE_FAILURE';
  error: Error;
}

// At this point we don't know if we have the users roles or the logged in users roles so
// we pass it in
export const fetchAllFeaturesVisibility =
  (): ThunkAction<void, AppState, any, any> =>
  async (dispatch, getState: () => AppState) => {
    const tokens = getCredentials(getState());
    const basicInfo = getUserInformation(getState());
    const decodedToken = await JwtClient.decodeToken(tokens.idToken);

    if (!basicInfo) {
      return;
    }

    const promise = async (): Promise<{
      manageBusinessVisible: boolean;
      manageHierarchiesVisible: boolean;
      knowledgeCenterVisible: boolean;
      commissionsVisible: boolean;
      overviewVisible: boolean;
      policyManagementVisible: boolean;
    }> => {
      const agentContractingStatus = get(
        basicInfo,
        'contractingStatus.agentContractingStatus',
        {},
      );

      const [
        manageBusinessVisible,
        manageHierarchiesVisible,
        knowledgeCenterVisible,
        commissionsVisible,
        overviewVisible,
        policyManagementVisible,
      ] = await Promise.all([
        fetchIsManageBusinessViewable(
          basicInfo.roles,
          decodedToken['custom:existing_agent'] === 'true',
          agentContractingStatus,
          tokens,
        ),
        fetchIsManageHierarchiesViewable(basicInfo.roles, tokens),
        fetchIsKnowledgeCenterViewable(basicInfo.roles, tokens),
        fetchIsCommissionsViewable(
          basicInfo.roles,
          decodedToken['custom:existing_agent'] === 'true',
          agentContractingStatus,
          tokens,
        ),
        fetchIsOverviewViewable(
          basicInfo.roles,
          decodedToken['custom:existing_agent'] === 'true',
          agentContractingStatus,
          tokens,
        ),
        fetchIsPolicyManagementViewable(
          basicInfo.roles,
          decodedToken['custom:existing_agent'] === 'true',
          agentContractingStatus,
          tokens,
        ),
      ]);
      return {
        manageBusinessVisible,
        manageHierarchiesVisible,
        knowledgeCenterVisible,
        commissionsVisible,
        overviewVisible,
        policyManagementVisible,
      };
    };

    dispatch({
      types: [
        'FETCH_FEATURE_VIEWABLE_REQUEST',
        'FETCH_FEATURE_VIEWABLE_SUCCESS',
        'FETCH_FEATURE_VIEWABLE_FAILURE',
      ],
      promise,
      bypassGlobalError: false,
    });
  };

const fetchIsManageBusinessViewable = async (
  roles: Role[],
  existingAgent: boolean,
  agentContractingStatus: string,
  credentials: { accessToken: string; idToken: string },
) => {
  const manageBusiness = await UserService.isFeatureViewable(
    APP_FEATURES.BUSINESS_MANAGEMENT_PAGE,
    'ao',
    { roles },
    credentials,
  );

  return (
    manageBusiness.visible &&
    (agentContractingStatus === OnboardingStatus.COMPLETE || existingAgent)
  );
};

const fetchIsManageHierarchiesViewable = async (
  roles: Role[],
  credentials: { accessToken: string; idToken: string },
) => {
  const res = await UserService.isFeatureViewable(
    APP_FEATURES.HIERARCHY_MANAGEMENT,
    'ao',
    { roles },
    credentials,
  );

  return res.visible;
};

const fetchIsKnowledgeCenterViewable = async (
  roles: Role[],
  credentials: { accessToken: string; idToken: string },
) => {
  const res = await UserService.isFeatureViewable(
    APP_FEATURES.KNOWLEDGE_CENTER,
    'ao',
    { roles },
    credentials,
  );

  return res.visible;
};

const fetchIsCommissionsViewable = async (
  roles: Role[],
  existingAgent: boolean,
  agentContractingStatus: string,
  credentials: { accessToken: string; idToken: string },
) => {
  const commissions = await UserService.isChildFeatureViewable(
    APP_FEATURES.BUSINESS_MANAGEMENT_PAGE,
    APP_CHILD_FEATURES.COMMISSIONS,
    'ao',
    { roles },
    credentials,
  );

  return (
    commissions.visible &&
    (agentContractingStatus === OnboardingStatus.COMPLETE || existingAgent)
  );
};

const fetchIsOverviewViewable = async (
  roles: Role[],
  existingAgent: boolean,
  agentContractingStatus: string,
  credentials: { accessToken: string; idToken: string },
) => {
  const overview = await UserService.isChildFeatureViewable(
    APP_FEATURES.BUSINESS_MANAGEMENT_PAGE,
    APP_CHILD_FEATURES.OVERVIEW,
    'ao',
    { roles },
    credentials,
  );

  return (
    overview.visible &&
    (agentContractingStatus === OnboardingStatus.COMPLETE || existingAgent)
  );
};

const fetchIsPolicyManagementViewable = async (
  roles: Role[],
  existingAgent: boolean,
  agentContractingStatus: string,
  credentials: { accessToken: string; idToken: string },
) => {
  const policyManagement = await UserService.isChildFeatureViewable(
    APP_FEATURES.BUSINESS_MANAGEMENT_PAGE,
    APP_CHILD_FEATURES.POLICY_MANAGEMENT,
    'ao',
    { roles },
    credentials,
  );

  return (
    policyManagement.visible &&
    (agentContractingStatus === OnboardingStatus.COMPLETE || existingAgent)
  );
};
