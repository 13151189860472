import React, { useState } from 'react';
import { PoliciesList } from './PoliciesList/PoliciesList';
import styles from './Policies.module.scss';
import { NoPolicySelected, PolicyDetail } from './PolicyDetail/PolicyDetail';
import { PartialPolicy, Policy } from '../../../../service/service.types';
import { useNglScreenSize } from '@nglic/component-lib';

export const Policies: React.FC<{ routeToNext?: () => void }> = () => {
  const [selectedPolicy, setSelectedPolicy] = useState<string | undefined>();

  const onPolicySelected = (policy?: PartialPolicy) => {
    setSelectedPolicy(policy?.id);
  };

  const { isMobile } = useNglScreenSize();

  return (
    <>
      {!isMobile && (
        <div className={styles['root']}>
          <div className={styles['left-pane']}>
            <PoliciesList
              selectedPolicyId={selectedPolicy}
              onPolicySelected={onPolicySelected}
            />
          </div>
          <div className={styles['right-pane']}>
            {selectedPolicy && <PolicyDetail policyId={selectedPolicy} />}
            {!selectedPolicy && <NoPolicySelected />}
          </div>
        </div>
      )}
      <div className={styles['mobile-root']}>
        <div className={styles['agent-list']}>
          {isMobile && !selectedPolicy && (
            <PoliciesList
              selectedPolicyId={selectedPolicy}
              onPolicySelected={onPolicySelected}
            />
          )}
          {isMobile && selectedPolicy && (
            <PolicyDetail policyId={selectedPolicy} />
          )}
        </div>
      </div>
    </>
  );
};
