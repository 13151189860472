import produce from 'immer';
import { Action } from 'redux';
import { UserInfo } from '../../service/service.types';
import {
  FetchUserBasicInfoSuccessAction,
  SetMasqueradeUserAction,
  UserActionType,
} from './basicUserInfo.action';

export type BasicInfoState = UserInfo | 'NOT_INITIALIZED';

export type MasqueradeUserInfo = UserInfo & {
  cognitoUuid?: string;
};
export type MasqueradeUserState = MasqueradeUserInfo | 'NOT_INITIALIZED';

export const basicUserInfoReducer = (
  state: BasicInfoState = 'NOT_INITIALIZED',
  action: Action<UserActionType>,
): UserInfo | 'NOT_INITIALIZED' => {
  return produce(state, (draftState) => {
    switch (action.type) {
      case 'USER_FETCH_BASIC_INFO_SUCCESS':
        draftState = (action as FetchUserBasicInfoSuccessAction).result;
        return draftState;
      case 'USER_FETCH_BASIC_INFO_FAILURE':
        draftState = 'NOT_INITIALIZED';
        return draftState;
      default:
        return state;
    }
  });
};

export const masqueradeUserReducer = (
  state: MasqueradeUserState = 'NOT_INITIALIZED',
  action: Action<UserActionType>,
): MasqueradeUserState => {
  return produce(state, (draftState) => {
    switch (action.type) {
      case 'SET_MASQUERADE_USER_INFO':
        draftState = (action as SetMasqueradeUserAction).result;
        return draftState;
      case 'REMOVE_MASQUERADE_USER_INFO':
        draftState = 'NOT_INITIALIZED';
        return draftState;
      case 'USER_FETCH_BASIC_INFO_FAILURE':
        draftState = 'NOT_INITIALIZED';
        return draftState;
      default:
        return state;
    }
  });
};
