import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import { AppState } from '../../rootReducer';
import { StateService } from '../../service/StateService';
import { State } from '../../service/service.types';
import { getCredentials } from './selectors/getCredentials';

export type StateActionType =
  | 'STATE_FETCH_SUCCESS'
  | 'STATE_FETCH_FAILURE'
  | 'NONE';

export interface FetchStateSuccessAction extends Action<StateActionType> {
  result: State[];
}

export interface FetchStateErrorAction extends Action<StateActionType> {
  error: Error;
}

export const fetchStates =
  (): ThunkAction<void, AppState, any, any> =>
  async (dispatch, getState: () => AppState) => {
    const tokens = getCredentials(getState());

    const promise = async (): Promise<State[]> => {
      return await StateService.getStates(tokens);
    };

    dispatch({
      types: [
        'STATE_FETCH_REQUEST',
        'STATE_FETCH_SUCCESS',
        'STATE_FETCH_FAILURE',
      ],
      promise,
      bypassGlobalError: true,
    });
  };
