import React from 'react';
import ReactDOM from 'react-dom';
import GA4React, { useGA4React } from 'ga-4-react';
import App from './App';
import Amplify from 'aws-amplify';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { getStore } from './rootReducer';
import { enableMapSet } from 'immer';
import { setupLogger } from './util/logger';
import { ENV } from './env';
import './index.scss';
enableMapSet();

setupLogger();

const ga4react = new GA4React(ENV.GOOGLE_ANALYTICS_PROPERTY_ID);

Amplify.configure({
  aws_cognito_region: ENV.REGION,
  aws_user_pools_id: ENV.POOL_ID,
  aws_user_pools_web_client_id: ENV.COGNITO_CLIENT_ID,
  aws_mandatory_sign_in: 'enable',
  authenticationFlowType: 'CUSTOM_AUTH',
});

export const store = getStore();

(async () => {
  try {
    await ga4react.initialize();
  } catch (err) {
    console.error('Adblock is activated');
  }

  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <App />
      </Provider>
    </React.StrictMode>,
    document.getElementById('root'),
  );
})();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
