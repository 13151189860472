import produce from 'immer';
import _ from 'lodash';
import { PaymentData, PaymentPayByCheck } from './PaymentMethod.types';
import {
  AddPaymentMethodAction,
  ClearPaymentMethodAction,
} from './paymentMethod.action';

export const initialState: PaymentData = {
  bankName: '',
  accountType: '',
  routingNumber: '',
  accountNumber: '',
  confirmAccountNumber: '',
  paymentFrequency: '',
  paymentType: 'EFT',
};

export type PaymentInformationState = 'INCOMPLETE' | PaymentData;

export const paymentMethodReducer = (
  state: PaymentInformationState = 'INCOMPLETE',
  action: AddPaymentMethodAction | ClearPaymentMethodAction,
): PaymentInformationState => {
  return produce(state, () => {
    switch (action.type) {
      case 'ADD_PAYMENT_METHOD':
        if (_.get(action, 'payload.payByCheck', false)) {
          return {
            ...PaymentPayByCheck,
          };
        } else {
          return {
            ...(action.payload as PaymentData),
          };
        }
      case 'CLEAR_PAYMENT_METHOD':
        return 'INCOMPLETE';

      default:
        return state;
    }
  });
};
