import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import { AppState } from '../../../../../../rootReducer';
import { getCredentials } from '../../../../../shared/selectors/getCredentials';
import { ReportDataService } from '../../../../../../service/ReportDataService';
import {
  CommissionDetailFilters,
  CommissionsActivity,
} from 'service/service.types';

export type CommissionsDetailByPeriodActionType =
  | 'COMMISSIONS_DETAIL_BY_PERIOD_FETCH__SUCCESS'
  | 'COMMISSIONS_DETAIL_BY_PERIOD_FETCH_FAILURE'
  | 'NONE';

export interface FetchCommissionsDetailByPeriodSuccessAction
  extends Action<CommissionsDetailByPeriodActionType> {
  result: {
    commissions: CommissionsActivity[];
    totalCommissions: number;
  };
}

export interface FetchCommissionsDetailByPeriodErrorAction
  extends Action<CommissionsDetailByPeriodActionType> {
  error: Error;
}

export const fetchCommissionsDetailByPeriod =
  (
    agentId: string,
    commissionDetailFilters: CommissionDetailFilters,
    page = 1,
    limit = 50,
  ): ThunkAction<void, AppState, any, any> =>
  async (dispatch, getState: () => AppState) => {
    const tokens = getCredentials(getState());

    const promise = async (): Promise<{
      commissions: CommissionsActivity[];
      totalCommissions: number;
    }> => {
      return await ReportDataService.getCommissionsActivityByPeriod(
        tokens,
        agentId,
        page,
        limit,
        commissionDetailFilters,
      );
    };

    dispatch({
      types: [
        'COMMISSIONS_DETAIL_BY_PERIOD_FETCH_REQUEST',
        'COMMISSIONS_DETAIL_BY_PERIOD_FETCH__SUCCESS',
        'COMMISSIONS_DETAIL_BY_PERIOD_FETCH_FAILURE',
      ],
      promise,
      bypassGlobalError: true,
    });
  };
