import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import styles from './Upload.module.scss';
import {
  NglicSimpleSelect,
  NGlicSpinner,
  PrimaryButton,
} from '@nglic/component-lib';
import { DocumentPendingUpload } from '../../../Contract/DocumentUpload/document.types';
import {
  deleteStagedDocument,
  uploadDocument,
  uploadStagedDocument,
} from '../documents.action';
import { useDispatch, useSelector } from 'react-redux';
import {
  DocumentResponse,
  DocumentType,
} from '../../../../../service/service.types';
import { AppState } from '../../../../../rootReducer';
import { getAgentId } from '../../../../shared/selectors/basicUserInfo.selector';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { createClearErrorDocumentAction } from '../documents.action';
import { get } from 'lodash';
import { fetchDocuments } from '../documents.action';
import { setGlobalError } from '../../../../shared/error.action';
import SubmittedImage from '../../../../../assets/img/documentUpload/submittedDocument.png';
import UploadingDocumentImage from '../../../../../assets/img/documentUpload/uploadingDocument.png';
import FailedUploadDocumentImage from '../../../../../assets/img/documentUpload/failedDocumentUpload.png';
import getUuidByString from 'uuid-by-string';
import { DocumentErrors } from './DocumentErrors';

interface Option {
  id: string;
  name: string;
  type: DocumentType;
}

const documentOptions: Option[] = [
  {
    id: 'Anti-Money Laundering Training',
    name: 'Anti-Money Laundering Training',
    type: DocumentType.ANTI_MONEY_LAUNDERING_TRAINING,
  },
  {
    id: 'State License',
    name: 'State License',
    type: DocumentType.STATE_LICENSE,
  },
  {
    id: 'Training Documents',
    name: 'Training Documents',
    type: DocumentType.TRAINING_DOCUMENT,
  },
  {
    id: 'Voided Check',
    name: 'Voided Check',
    type: DocumentType.VOIDED_CHECK,
  },
  {
    id: 'Other',
    name: 'Other',
    type: DocumentType.OTHER,
  },
];

const getOptionByName = (name: string) => {
  const option = documentOptions.find((type) => type.name === name);
  return option ?? { id: 'Other', name: 'Other', type: DocumentType.OTHER };
};

const getOptionByType = (type: DocumentType) => {
  const option = documentOptions.find((option) => option.type === type);
  return option ?? { id: 'Other', name: 'Other', type: DocumentType.OTHER };
};

export const Upload: React.FC = () => {
  const fileSelector = useRef<HTMLInputElement | null>(null);
  const dispatch = useDispatch();
  const [submit, setSubmit] = useState(true);
  const [selectedDocumentType, setSelectedDocumentType] = useState<Option>({
    id: '',
    name: '',
    type: '' as DocumentType,
  });

  const stagedDocuments: DocumentPendingUpload[] = useSelector(
    (state: AppState) =>
      state.presentation.manageBusiness.documents.stagedDocuments,
  );

  const documents: DocumentResponse[] = useSelector(
    (state: AppState) => state.presentation.manageBusiness.documents.documents,
  );
  const agentId = useSelector((state: AppState) => getAgentId(state));
  const error = useSelector((state: AppState) =>
    get(state, 'presentation.manageBusiness.documents.errors'),
  ) as [{ error: Error; document: string }];

  useEffect(() => {
    if (agentId) {
      dispatch(fetchDocuments());
    }
  }, [agentId]);

  const handleOnDocumentTypeChanged = useCallback(
    (newValue: Option) => {
      setSelectedDocumentType({
        id: newValue.id,
        name: newValue.name,
        type: newValue.type,
      });
    },
    [selectedDocumentType],
  );

  const handleOnSelectedFile = useCallback(
    (newValue: File) => {
      const selectedOption = getOptionByName(selectedDocumentType.name);
      const documentPendingUpload: DocumentPendingUpload = {
        documentType: selectedOption.type,
        file: newValue,
      };
      setSubmit(true);
      const documentStagedExist = stagedDocuments.some(
        (doc) => doc.file.name === documentPendingUpload.file.name,
      );

      const documentDBExist = documents.some(
        (doc) => doc.name === documentPendingUpload.file.name,
      );

      if (stagedDocuments.length === 0 && !documentDBExist) {
        dispatch(uploadStagedDocument(documentPendingUpload));
      } else if (documentDBExist) {
        dispatch(setGlobalError(new Error('Document already uploaded')));
      }

      if (stagedDocuments.length !== 0) {
        if (documentStagedExist || documentDBExist) {
          dispatch(setGlobalError(new Error('Document already uploaded')));
        } else {
          dispatch(uploadStagedDocument(documentPendingUpload));
        }
      }
      setSelectedDocumentType({
        id: '',
        name: '',
        type: '' as DocumentType,
      });
    },
    [stagedDocuments, selectedDocumentType, documents],
  );

  const handleSaveDocuments = () => {
    stagedDocuments.map((document) => {
      const selectedOption = getOptionByType(document.documentType);
      const documentPendingUpload: DocumentPendingUpload = {
        documentType: selectedOption.type,
        file: document.file,
      };
      setSubmit(false);

      dispatch(uploadDocument(documentPendingUpload));

      setSelectedDocumentType({
        id: '',
        name: '',
        type: '' as DocumentType,
      });
    });
  };

  const handleFileSelect = () => {
    fileSelector.current?.click();
  };

  const handleOnFileDelete = useCallback(
    (index: number) => {
      dispatch(
        createClearErrorDocumentAction(stagedDocuments[index].file.name),
      );
      dispatch(deleteStagedDocument(stagedDocuments[index].file.name));
    },
    [stagedDocuments],
  );

  const handleRetry = useCallback(
    (index: number) => {
      const documentPendingUpload: DocumentPendingUpload = {
        documentType: stagedDocuments[index].documentType,
        file: stagedDocuments[index].file,
      };
      dispatch(uploadDocument(documentPendingUpload));
    },
    [stagedDocuments],
  );
  const checkIfDocumentHasError = (documentName: string): boolean => {
    return error.some((doc) => doc.document === documentName);
  };

  const checkIfDocumentExistsInDB = (documentName: string): boolean => {
    return documents.some((doc) => doc.name === documentName);
  };

  const deleteAfter800ms = (docName: string) => {
    setTimeout(() => {
      dispatch(deleteStagedDocument(docName));
    }, 800);
  };

  const renderList = () => {
    return (
      <div className={styles['list']}>
        <div className={styles['header']}>
          <div className={styles['name']}>Document Name</div>
          <div className={styles['type']}>Document Type</div>
          <div className={styles['empty-div']} />
        </div>
        <div className={styles['divider']} />
        <div className={styles['content']}>
          {stagedDocuments.map((document, index) => {
            return (
              <div
                className={styles['row']}
                key={getUuidByString(JSON.stringify(document))}
              >
                <div className={styles['name-container']}>
                  <div className={styles['name']}>{document.file.name}</div>
                </div>
                <div className={styles['type']}>
                  {getOptionByType(document.documentType).name}
                </div>
                {!checkIfDocumentHasError(document.file.name) ? (
                  documents.map((dbDocument) => {
                    if (
                      dbDocument.name === document.file.name &&
                      dbDocument.isConfirmed
                    ) {
                      return (
                        <IconButton className={styles['icon-container']}>
                          <img
                            className={styles['icon']}
                            src={SubmittedImage}
                            alt="submitted-image"
                          />
                          {deleteAfter800ms(dbDocument.name)}
                        </IconButton>
                      );
                    } else if (
                      dbDocument.name === document.file.name &&
                      !dbDocument.isConfirmed
                    ) {
                      return (
                        <IconButton className={styles['icon-container']}>
                          <img
                            className={styles['rotating-icon']}
                            src={UploadingDocumentImage}
                            alt="submitted-image"
                          />
                        </IconButton>
                      );
                    }
                  })
                ) : (
                  <DocumentErrors
                    handleOnFileDelete={handleOnFileDelete}
                    handleRetry={handleRetry}
                    index={index}
                    errors={error}
                    document={document}
                  />
                )}
                {submit && !checkIfDocumentExistsInDB(document.file.name) && (
                  <IconButton
                    className={styles['icon-container']}
                    onClick={() => {
                      handleOnFileDelete(index);
                    }}
                  >
                    <DeleteIcon className={styles['icon']} />
                  </IconButton>
                )}
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  if (!agentId) {
    return (
      <div className={styles['loading-container']}>
        <NGlicSpinner />
      </div>
    );
  }

  return (
    <div className={styles['root']} data-testid="document-upload">
      <div className={styles['icon-container']}>
        <div className={styles['title']}>Upload a file</div>
      </div>
      {stagedDocuments.length > 0 && renderList()}
      <div className={styles['container']}>
        {stagedDocuments.length <= 0 && (
          <div className={styles['select-text']}>
            Select file from your computer
          </div>
        )}
        <div className={styles['upload-container']}>
          <div className={styles['dropdown']}>
            <NglicSimpleSelect
              selectedOption={selectedDocumentType}
              onChange={(value) => handleOnDocumentTypeChanged(value as Option)}
              options={documentOptions}
              label={'Document Type'}
            />
          </div>
          <div className={styles['choose-file-container']}>
            <PrimaryButton
              text="Choose File"
              onClick={handleFileSelect}
              disabled={selectedDocumentType.name === ''}
            />
          </div>
          <input
            id="myInput"
            type="file"
            ref={fileSelector}
            style={{ display: 'none' }}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              const { files } = event.target;
              if (files) {
                const file = files[0];
                handleOnSelectedFile(file);
              }
              if (fileSelector.current) {
                fileSelector.current.value = '';
              }
            }}
          />
        </div>
        <div>
          {error.length > 0 && (
            <div className={styles['failed-upload']}>
              <img
                className={styles['failed-icon']}
                src={FailedUploadDocumentImage}
                alt="failed-image"
              />
              <div>
                There was an error uploading on or more of your documents, Retry
                or refresh the page to try again
              </div>
            </div>
          )}
        </div>
        <div className={styles['save-button']}>
          <PrimaryButton
            text="Save"
            onClick={handleSaveDocuments}
            disabled={stagedDocuments.length === 0}
          />
        </div>
      </div>
    </div>
  );
};
