import React from 'react';
import {
  AllContactType,
  allContactTypeString,
  Contact,
} from 'service/service.types';

import styles from './AgentContactsTable.module.scss';

interface AgentContactsTableProps {
  contacts: Contact[];
}

export const AgentContactsTable: React.FC<AgentContactsTableProps> = (
  props: AgentContactsTableProps,
) => {
  const { contacts } = props;

  const renderKeyContainer = (contact: Contact) => {
    return (
      <div className={styles['key-container']}>
        <div className={styles['key']}>
          {(() => {
            if (contact.name === 'Phone Number') {
              return allContactTypeString(contact.type as AllContactType);
            } else {
              return contact.name;
            }
          })()}
        </div>
      </div>
    );
  };

  return (
    <div className={styles['root']}>
      {contacts.map((contact, index) => {
        return (
          <div key={index}>
            <div className={styles['row']}>
              {renderKeyContainer(contact)}
              <div className={styles['value']}>
                {contact.value.split('\n').map((item, key) => {
                  return (
                    <span key={key}>
                      {item}
                      <br />
                    </span>
                  );
                })}
              </div>
            </div>
            <div className={styles['divider']}></div>
          </div>
        );
      })}
    </div>
  );
};
