import { AgentProduct, Hierarchy } from '../../../../service/service.types';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../rootReducer';
import { HierarchyService } from '../../../../service/HierarchyService';
import { getCredentials } from '../../../shared/selectors/getCredentials';
import { getBranchCode } from '../../../shared/selectors/basicUserInfo.selector';
import { HierarchyModal } from '../../../modules/HierarchyModal/HierarchyModal';
import { HierarchyUtil } from '../../../../service/transformers/Hierarchy';

export const CreateHierarchy: React.FC<{
  open: boolean;
  handleClose: () => void;
}> = ({ open, handleClose }) => {
  const [saving, setSaving] = useState<boolean>(false);
  const tokens = useSelector((state: AppState) => getCredentials(state));
  const branchCode = useSelector((state: AppState) => getBranchCode(state));

  const handleSave = (
    updatedHierarchies: AgentProduct[],
    originalHierarchies: AgentProduct[],
  ) => {
    setSaving(true);
    const newHierarchy = HierarchyUtil.transformHierarchy(
      {
        id: '',
        productId: updatedHierarchies[0].productId,
        productName: updatedHierarchies[0].productName,
        agents: originalHierarchies,
      } as Hierarchy,
      updatedHierarchies,
    );

    HierarchyService.createHierarchy(newHierarchy, branchCode || '', tokens)
      .then(() => {
        setSaving(false);
        handleClose();
      })
      .catch(() => {
        setSaving(false);
      });
  };

  const newOptionLevels = [...new Array(14).keys()].map((index) => ({
    id: `${index + 1}`,
    name: `Level ${index + 1}`,
  }));

  return (
    <HierarchyModal
      saving={saving}
      isOpen={open}
      onClose={handleClose}
      newAgentLevels={newOptionLevels}
      onCancel={handleClose}
      onSave={handleSave}
      description={'Instructions for how to build a new hierarchy'}
      title={`Build a new hierarchy`}
      showProductDropdown={true}
    />
  );
};
