import React, { useState } from 'react';
import styles from './Hierarchy.module.scss';
import { HierarchyGrouping } from './HierarchyGrouping/HierarchyGrouping';
import { Link, Option, TextInput } from '@nglic/component-lib/build';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../../../rootReducer';
import { PrimaryButton } from '@nglic/component-lib';
import { AddAgentHierarchyState, HierarchyUpdate } from './hierarchy.reducer';
import {
  AgentProducer,
  Hierarchy,
  Product,
} from '../../../../../service/service.types';
import {
  addComment,
  addToHierarchy,
  fetchHierarchiesForNewAgentSection,
  removeFromHierarchy,
} from './hierarchy.action';

export const NewAgentHierarchy: React.FC<{ routeToNext?: () => void }> = ({
  routeToNext,
}) => {
  const dispatch = useDispatch();
  const hierarchyGroupingProps: AddAgentHierarchyState = useSelector(
    (state: AppState) => state.presentation.addAgent.hierarchy,
  );
  const [nextDisabled, setNextIsDisabled] = useState<boolean>(true);
  const [comment, setComment] = useState<string>('');

  const onSearchForHierarchiesForSection = (params: {
    id: string;
    product?: Product;
    agent?: AgentProducer;
  }) => {
    const { product, agent, id } = params;
    const currentSearchParams = hierarchyGroupingProps[id]?.searchParams;
    const searchParamsEqual =
      currentSearchParams?.agent === agent &&
      currentSearchParams?.product === product;
    if (product && agent && !searchParamsEqual) {
      dispatch(fetchHierarchiesForNewAgentSection(agent, product, id));
    }
  };

  const onAddToHierarchy = (params: {
    id: string;
    searchParams: {
      agent?: AgentProducer;
      product?: Product;
    };
    hierarchyList: Hierarchy[];
    newAgentHierarchyCommissionMap: Record<string, Option>;
    newAgentHierarchies: Record<string, HierarchyUpdate>;
  }) => {
    const {
      id,
      searchParams,
      hierarchyList,
      newAgentHierarchyCommissionMap,
      newAgentHierarchies,
    } = params;
    dispatch(
      addToHierarchy(
        id,
        searchParams,
        hierarchyList,
        newAgentHierarchyCommissionMap,
        newAgentHierarchies,
      ),
    );
  };

  const onRemoveFromHierarchy = (params: {
    id: string;
    hierarchyList: Hierarchy[];
    hierarchyId: string;
  }) => {
    const { id, hierarchyList, hierarchyId } = params;
    dispatch(removeFromHierarchy(id, hierarchyList, hierarchyId));
  };

  const [hierarchyGroupings, setHierarchyGroupings] = useState<
    Record<string, React.ReactElement>
  >({
    hierarchy_1: (
      <HierarchyGrouping
        id={'hierarchy_1'}
        searchParams={hierarchyGroupingProps['hierarchy_1']?.searchParams ?? {}}
        hierarchyList={
          hierarchyGroupingProps['hierarchy_1']?.hierarchyList ?? []
        }
        newAgentHierarchies={{}}
        newAgentHierarchyCommissionMap={{}}
        onAddToHierarchy={onAddToHierarchy}
        onRemoveFromHierarchy={onRemoveFromHierarchy}
        onSearchForHierarchiesForSection={onSearchForHierarchiesForSection}
      />
    ),
  });
  const hierarchyGroupingValues = Object.values(hierarchyGroupingProps);

  React.useEffect(() => {
    const disabled = Object.values(hierarchyGroupingProps).reduce<boolean>(
      (acc, next) => {
        return (
          acc && Object.values(next?.newAgentHierarchies ?? {}).length === 0
        );
      },
      true,
    );

    setNextIsDisabled(disabled);
  }, [hierarchyGroupingProps]);

  React.useEffect(() => {
    if (hierarchyGroupingValues.length > 1) {
      const grouping = hierarchyGroupingValues?.reduce<
        Record<string, React.ReactElement>
      >((acc, params, index) => {
        if (index < 1) {
          return acc;
        }
        const key = index + 1;
        return {
          ...acc,
          [`hierarchy_${key}`]: (
            <HierarchyGrouping
              id={`hierarchy_${key}`}
              ariaLabel={`hierarchy_${key}`}
              searchParams={params.searchParams}
              hierarchyList={params.hierarchyList}
              newAgentHierarchies={params.newAgentHierarchies || {}}
              newAgentHierarchyCommissionMap={
                params.newAgentHierarchyCommissionMap || {}
              }
              onAddToHierarchy={onAddToHierarchy}
              onRemoveFromHierarchy={onRemoveFromHierarchy}
              onSearchForHierarchiesForSection={
                onSearchForHierarchiesForSection
              }
            />
          ),
        };
      }, hierarchyGroupings);
      setHierarchyGroupings(grouping);
    }
  }, []);

  const addNewHierarchyGroup = React.useCallback(() => {
    const position = Object.entries(hierarchyGroupings).length + 1;
    setHierarchyGroupings({
      ...hierarchyGroupings,
      [`hierarchy_${position}`]: (
        <HierarchyGrouping
          id={`hierarchy_${position}`}
          ariaLabel={`hierarchy_${position}`}
          searchParams={{}}
          hierarchyList={[]}
          newAgentHierarchies={{}}
          newAgentHierarchyCommissionMap={{}}
          onAddToHierarchy={onAddToHierarchy}
          onRemoveFromHierarchy={onRemoveFromHierarchy}
          onSearchForHierarchiesForSection={onSearchForHierarchiesForSection}
        />
      ),
    });
  }, [hierarchyGroupings]);

  const handleSubmit = () => {
    dispatch(addComment(comment));
    routeToNext && routeToNext();
  };

  const showAddMore =
    hierarchyGroupingValues.length === Object.keys(hierarchyGroupings).length;

  return (
    <div className={styles['root']} data-testid={'hierarchy'}>
      {Object.values(hierarchyGroupings).map((hierarchy, index) => (
        <div key={index}>{hierarchy}</div>
      ))}
      <div className={styles['comment-container']}>
        {hierarchyGroupingValues.length > 0 && (
          <TextInput
            value={comment}
            onChange={(value) => setComment(value)}
            label="Comment"
            multiline={true}
          />
        )}
      </div>
      <div className={styles['link-container']}>
        {showAddMore && (
          <Link
            text={'+ Add Agent to additional Upline or Product'}
            onClick={addNewHierarchyGroup}
          />
        )}
      </div>
      <div className={styles['button-container']}>
        <PrimaryButton
          text="Next"
          onClick={handleSubmit}
          disabled={nextDisabled}
        />
      </div>
    </div>
  );
};
