import React from 'react';
import { Policy } from '../../../../../../service/service.types';
import styles from './PolicyInformation.module.scss';
import { NGlicSpinner } from '@nglic/component-lib/build';
import { AssetGuardPolicyInformation } from './AssetGuardPolicyDetails/AssetGuardPolicyInformation';
import { PreneedPolicyInformation } from './PreneedPolicyInformation/PreneedPolicyInformation';

export interface PolicyInformationProps {
  policy?: Policy;
  loading?: boolean;
}

export const PolicyInformation: React.FC<PolicyInformationProps> = (
  props: PolicyInformationProps,
) => {
  const { policy, loading } = props;

  const getPolicyDisplayForCategory = (categoryName?: string) => {
    switch (categoryName) {
      case 'AssetGuard':
        return <AssetGuardPolicyInformation policy={policy} />;
      default:
        return <PreneedPolicyInformation policy={policy} />;
    }
  };
  const baseComponent = (
    <>
      <div className={styles['header']}>Policy Information</div>
      {getPolicyDisplayForCategory(policy?.categoryName)}
    </>
  );

  const loadingComponent = (
    <div className={styles['loading-container']}>
      <NGlicSpinner />
    </div>
  );

  return (
    <div className={styles['root']}>
      {loading ? loadingComponent : baseComponent}
    </div>
  );
};
