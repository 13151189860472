import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import { AppState } from '../../rootReducer';
import { Role, UserInfo } from '../../service/service.types';
import { UserService } from '../../service/UserService';
import { MasqueradeUserInfo } from './basicUserInfo.reducer';
import {
  getItemInLocalStorageIfExists,
  StorageItemKey,
} from '../../util/getItemInLocalStorageIfExists';
import { getCredentials } from './selectors/getCredentials';

export type UserActionType =
  | 'USER_FETCH_BASIC_INFO_REQUEST'
  | 'USER_FETCH_BASIC_INFO_SUCCESS'
  | 'USER_FETCH_BASIC_INFO_FAILURE'
  | 'SET_MASQUERADE_USER_INFO'
  | 'REMOVE_MASQUERADE_USER_INFO'
  | 'NONE';

export interface FetchUserBasicInfoSuccessAction
  extends Action<UserActionType> {
  type: 'USER_FETCH_BASIC_INFO_SUCCESS';
  result: UserInfo;
}

export interface FetchUserBasicInfoFailureAction
  extends Action<UserActionType> {
  type: 'USER_FETCH_BASIC_INFO_FAILURE';
  error: Error;
}

export interface SetMasqueradeUserAction extends Action<UserActionType> {
  type: 'SET_MASQUERADE_USER_INFO';
  result: MasqueradeUserInfo;
}

export const setMasqueradeUserAction = (
  user: MasqueradeUserInfo,
): SetMasqueradeUserAction => ({
  type: 'SET_MASQUERADE_USER_INFO',
  result: user,
});

export const removeMasqueradeUserAction = (): Action<UserActionType> => ({
  type: 'REMOVE_MASQUERADE_USER_INFO',
});

// Here we pass in roles vs get them from state and decoding the token because
// at this point we don't know if the user is a masquerade user or the logged in user
export const fetchBasicUserInfo =
  (roles: Role[], email: string): ThunkAction<void, AppState, any, any> =>
  async (dispatch, getState: () => AppState) => {
    const { accessToken, idToken } = getState().data.user.credentials;
    const promise = async (): Promise<Partial<UserInfo>> => {
      return await UserService.getUserInfoByRole(
        email,
        {
          idToken,
          accessToken,
        },
        roles,
      );
    };

    dispatch({
      types: [
        'USER_FETCH_BASIC_INFO_REQUEST',
        'USER_FETCH_BASIC_INFO_SUCCESS',
        'USER_FETCH_BASIC_INFO_FAILURE',
      ],
      promise,
      bypassGlobalError: true,
    });
  };

export const initializeUserInfo =
  (
    loggedInUserRoles: Role[],
    loggedInUserEmail: string,
  ): ThunkAction<void, AppState, any, any> =>
  async (dispatch, getState: () => AppState) => {
    const { accessToken, idToken } = getCredentials(getState());

    const cognitoUuid = getItemInLocalStorageIfExists(
      StorageItemKey.MASQUERADE_UUID,
    );
    if (loggedInUserRoles.includes(Role.SUPPORT) && cognitoUuid !== '') {
      const masqueradeUser = await UserService.getMasqueradeUser(cognitoUuid, {
        accessToken,
        idToken,
      });
      dispatch(
        setMasqueradeUserAction({
          ...(masqueradeUser as UserInfo),
          cognitoUuid,
        }),
      );
    }

    dispatch(fetchBasicUserInfo(loggedInUserRoles, loggedInUserEmail));
  };
