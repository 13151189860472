import React, { FC, useState, useEffect, useCallback } from 'react';
import { Box } from '@material-ui/core';
import {
  NglicMultipleSelect,
  NglicSimpleSelect,
  Option,
  useNglScreenSize,
} from '@nglic/component-lib/build';
import styles from './ReportFilters.module.scss';
import { snakeCaseToKebabCase } from '../report.helpers';
import { IProducerOptions, IReportFilters } from '../Report.types';
import { useDispatch } from 'react-redux';
import { setReportFilters } from '../reportFilters.action';
import FilterListIcon from '@material-ui/icons/FilterList';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { DatePickerModal } from './DatePickerModal/DatePickerModal';
import { CommissionsCategoriesEnum } from '../../Reporting/Reporting';
interface ReportFiltersProps {
  isLoading: boolean;
  currentFilterOptions: {
    dateFilters: string[];
    funeralHomeIds: { id: string; name: string }[];
    producerIds: IProducerOptions[];
  };
  currentSelectedFilters: IReportFilters;
  reportCategory: string;
}

export const ReportFilters: FC<ReportFiltersProps> = React.memo(
  ({
    isLoading,
    currentFilterOptions,
    currentSelectedFilters,
    reportCategory,
  }) => {
    // Local State
    const [loading, setLoading] = useState<boolean>(true);
    const [isDatePickerOpen, setIsDatePickerOpen] = useState<boolean>(false);

    // Mobile
    const [filtersOpen, setFiltersOpen] = useState<boolean>(false);

    // Filter Options
    const [dateOptions, setDateOptions] = useState<Option[]>([]);
    const [producerIdOptions, setProducerIdOptions] = useState<Option[]>([]);
    const [funeralHomeOptions, setFuneralHomeOptions] = useState<Option[]>([]);

    // Selected Filters
    const [selectedProducerIds, setSelectedProducerIds] = useState<string[]>(
      [],
    );
    const [selectedFuneralHomes, setSelectedFuneralHomes] = useState<Option[]>(
      [],
    );

    const { isMobile } = useNglScreenSize();
    const dispatch = useDispatch();

    useEffect(() => {
      if (isLoading) {
        setLoading(true);
      } else {
        setLoading(false);
      }
    }, [isLoading]);
    useEffect(() => {
      if (currentFilterOptions) {
        const newDateOptions = currentFilterOptions.dateFilters.map(
          (filter) => {
            return {
              id: filter,
              name: snakeCaseToKebabCase(filter),
            };
          },
        );
        const newProducerIdOptions = currentFilterOptions.producerIds.map(
          (filter) => {
            return {
              id: filter?.id,
              name: filter?.name,
            };
          },
        );
        setDateOptions([...newDateOptions]);
        setProducerIdOptions([...newProducerIdOptions]);
        setFuneralHomeOptions([...currentFilterOptions.funeralHomeIds]);
      }
    }, [currentFilterOptions]);

    //ANCHOR: Date Filter
    const handleDateGroupingSelected = (value) => {
      if (value.id === 'custom') {
        setIsDatePickerOpen(true);
      } else {
        dispatch(
          setReportFilters({
            ...currentSelectedFilters,
            dateGrouping: value.id,
            dateFrom: '',
            dateTo: '',
          }),
        );
      }
    };

    const handleDatepickerClose = (
      range: { from: string; to: string } | undefined,
    ): void => {
      setIsDatePickerOpen(false);
      if (range) {
        dispatch(
          setReportFilters({
            ...currentSelectedFilters,
            dateGrouping: 'custom',
            dateFrom: range.from,
            dateTo: range.to,
          }),
        );
      }
    };

    const handleDatePickerCancel = () => {
      setIsDatePickerOpen(false);
    };

    //ANCHOR: ProducerId Filter
    const handleProducerIdStateChange = useCallback(
      (value: string) => {
        let updatedProducerIds: string[] = [];
        if (selectedProducerIds.includes(value)) {
          updatedProducerIds = selectedProducerIds.reduce<string[]>(
            (acc, next) => {
              if (next !== value) {
                acc.push(next);
              }
              return acc;
            },
            [],
          );
        } else {
          updatedProducerIds = [value, ...selectedProducerIds];
        }
        setSelectedProducerIds(updatedProducerIds);
        dispatch(
          setReportFilters({
            ...currentSelectedFilters,
            producerIds: updatedProducerIds,
          }),
        );
      },
      [selectedProducerIds],
    );

    const producerIdMultiSelectOptions = producerIdOptions?.reduce<any[]>(
      (acc, next) => {
        return [
          ...acc,
          {
            id: next.id,
            name: next.name + ` (${next.id})`,
            checked: selectedProducerIds.includes(next.id),
            onChange: handleProducerIdStateChange,
          },
        ];
      },
      [],
    );

    //ANCHOR: FuneralHomeId Filter

    const handleFuneralHomeStateChange = useCallback(
      (value: string) => {
        let updatedFuneralHomeIds: Option[];
        const selectedOption =
          funeralHomeOptions.find((option) => option.id === value) ??
          ({ id: value } as Option);
        const isCurrentlySelected = !!selectedFuneralHomes.find(
          (selectedValue) => selectedValue.id === value,
        );
        if (isCurrentlySelected) {
          updatedFuneralHomeIds = selectedFuneralHomes.reduce<Option[]>(
            (acc, next) => {
              if (next.id !== value) {
                acc.push(next);
              }
              return acc;
            },
            [],
          );
        } else {
          updatedFuneralHomeIds = [selectedOption, ...selectedFuneralHomes];
        }
        setSelectedFuneralHomes(updatedFuneralHomeIds);
        dispatch(
          setReportFilters({
            ...currentSelectedFilters,
            funeralHomeIds: updatedFuneralHomeIds.map((update) => update.id),
          }),
        );
      },
      [selectedFuneralHomes, funeralHomeOptions],
    );

    const funeralHomeIdMultiSelectOptions = funeralHomeOptions?.reduce<any[]>(
      (acc, next) => {
        return [
          ...acc,
          {
            id: next.id,
            name: next.name ?? next.id,
            checked: selectedFuneralHomes.find(
              (selected) => selected.id === next.id,
            ),
            onChange: handleFuneralHomeStateChange,
          },
        ];
      },
      [],
    );
    const filterOptions = (
      options: Option[],
      reportCategory: string,
    ): Option[] => {
      if (reportCategory !== CommissionsCategoriesEnum.COMMISSIONS_OVER_TIME) {
        return options;
      }

      return options.filter(
        (option) => option.id !== 'custom' && option.id !== 'last_month',
      );
    };

    return (
      <div className={styles['root']}>
        {isMobile && !filtersOpen ? (
          <div
            onClick={() => {
              setFiltersOpen(true);
            }}
            className={styles['not-collapsed']}
          >
            <div>Filter</div>
            <FilterListIcon />
          </div>
        ) : (
          <Box
            className={styles['root-box']}
            display="flex"
            flexDirection={!isMobile ? 'row' : 'column'}
            justifyContent="between"
            alignItems="center"
          >
            <div className={styles['select-container']}>
              <NglicSimpleSelect
                label="Time Period"
                selectedOption={{
                  id: currentSelectedFilters.dateGrouping,
                  name: snakeCaseToKebabCase(
                    currentSelectedFilters.dateGrouping,
                  ),
                }}
                options={filterOptions(dateOptions, reportCategory)}
                onChange={handleDateGroupingSelected}
                loading={loading}
              />
            </div>
            <div className={styles['select-container']}>
              <NglicMultipleSelect
                options={funeralHomeIdMultiSelectOptions || []}
                selectedOption={selectedFuneralHomes.map((fh) => fh.name)}
                label="Funeral Home"
              />
            </div>
            <div className={styles['select-container']}>
              <NglicMultipleSelect
                options={producerIdMultiSelectOptions || []}
                selectedOption={selectedProducerIds}
                label="Producer"
              />
            </div>
            {isMobile && (
              <div
                onClick={() => {
                  setFiltersOpen(false);
                }}
                className={styles['collapse']}
              >
                <div>Collapse</div>
                <KeyboardArrowUpIcon />
              </div>
            )}
            <DatePickerModal
              isOpen={isDatePickerOpen}
              onClose={handleDatepickerClose}
              onCancel={handleDatePickerCancel}
            />
          </Box>
        )}
      </div>
    );
  },
);
