import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import {
  AgentProducer,
  Hierarchy,
  Product,
} from '../../../../../service/service.types';
import { AppState } from '../../../../../rootReducer';
import { getCredentials } from '../../../../shared/selectors/getCredentials';
import { setGlobalError } from '../../../../shared/error.action';
import { HierarchyService } from '../../../../../service/HierarchyService';
import { Option } from '@nglic/component-lib/build';
import { HierarchyUpdate } from './hierarchy.reducer';
import { getUserInformation } from '../../../../shared/selectors/getUserInformation';

export type HierarchyAction =
  | 'REMOVE_FROM_HIERARCHY'
  | 'ADD_TO_HIERARCHY'
  | 'ADD_COMMENT'
  | 'SET_ORIGINAL_HIERARCHY'
  | 'REMOVE_ORIGINAL_HIERARCHY'
  | 'CLEAR_HIERARCHY_SECTION'
  | 'CLEAR_HIERARCHIES'
  | 'ADD_HIERARCHY_PARAMS'
  | 'FETCH_HIERARCHIES_FOR_NEW_AGENT_REQUEST'
  | 'FETCH_HIERARCHIES_FOR_NEW_AGENT_SUCCESS'
  | 'FETCH_HIERARCHIES_FOR_NEW_AGENT_FAILURE'
  | 'SET_COMMISSION_LEVEL'
  | 'REMOVE_COMMISSION_LEVEL'
  | 'SET_HIERARCHIES_WITH_SECTION';

export interface AddToHierarchyAction extends Action<HierarchyAction> {
  type: HierarchyAction;
  id: string;
  searchParams: {
    agent?: AgentProducer;
    product?: Product;
  };
  hierarchyList: Hierarchy[];
  newAgentHierarchyCommissionMap: Record<string, Option>;
  newAgentHierarchies: Record<string, HierarchyUpdate>;
}

export interface RemoveFromHierarchyAction extends Action<HierarchyAction> {
  type: HierarchyAction;
  id: string;
  hierarchyList: Hierarchy[];
  hierarchyId: string;
}

export interface OriginalHierarchyAction extends Action<HierarchyAction> {
  type: HierarchyAction;
  id: string;
  hierarchy: Hierarchy;
}

export interface ClearHierarchySectionAction extends Action<HierarchyAction> {
  sectionId: string;
}

export interface HierarchySearchParamsAction extends Action<HierarchyAction> {
  type: HierarchyAction;
  id: string;
  product: Product;
  upLineAgent: AgentProducer;
}

export interface AddCommentAction extends Action<HierarchyAction> {
  type: 'ADD_COMMENT';
  comment: string;
}

export interface HierarchyAgentCommissionLevelAction
  extends Action<HierarchyAction> {
  type: HierarchyAction;
  sectionId: string;
  commissionLevel: string;
  hierarchyId: string;
  selected: string;
}
export interface FetchHierarchiesForSection extends Action<HierarchyAction> {
  type: HierarchyAction;
  result: Hierarchy[];
  sectionId: string;
  searchParams: {
    agent?: AgentProducer;
    product?: Product;
  };
}
export interface AddHierarchiesForNewAgentAction
  extends Action<HierarchyAction> {
  type: HierarchyAction;
  result: Hierarchy[];
}

export const addToHierarchy = (
  id: string,
  searchParams: {
    agent?: AgentProducer;
    product?: Product;
  },
  hierarchyList: Hierarchy[],
  newAgentHierarchyCommissionMap: Record<string, Option>,
  newAgentHierarchies: Record<string, HierarchyUpdate>,
): AddToHierarchyAction => ({
  type: 'ADD_TO_HIERARCHY',
  id,
  searchParams,
  hierarchyList,
  newAgentHierarchyCommissionMap,
  newAgentHierarchies,
});

export const addComment = (comment: string): AddCommentAction => ({
  type: 'ADD_COMMENT',
  comment,
});

export const removeFromHierarchy = (
  id: string,
  hierarchyList: Hierarchy[],
  hierarchyId: string,
): RemoveFromHierarchyAction => ({
  type: 'REMOVE_FROM_HIERARCHY',
  id,
  hierarchyList,
  hierarchyId,
});

export const clearHierarchySection = (
  sectionId: string,
): ClearHierarchySectionAction => ({
  type: 'CLEAR_HIERARCHY_SECTION',
  sectionId,
});

export const clearAllHierarchies = (): Action<HierarchyAction> => ({
  type: 'CLEAR_HIERARCHIES',
});

export const fetchHierarchiesForNewAgentSection =
  (
    agent: AgentProducer,
    product: Product,
    sectionId: string,
  ): ThunkAction<void, AppState, any, any> =>
  async (dispatch, getState: () => AppState) => {
    const tokens = getCredentials(getState());
    const userInfo = getUserInformation(getState());

    if (!userInfo) {
      dispatch(setGlobalError(new Error('basicInfo not initialized')));
      return;
    }

    dispatch(clearHierarchySection(sectionId));

    const promise = async (): Promise<Hierarchy[]> => {
      return await HierarchyService.getHierarchiesForNewAgent(
        agent.producerId || '',
        userInfo.branchCode,
        product.id,
        tokens,
      );
    };

    dispatch({
      types: [
        'FETCH_HIERARCHIES_FOR_NEW_AGENT_REQUEST',
        'FETCH_HIERARCHIES_FOR_NEW_AGENT_SUCCESS',
        'FETCH_HIERARCHIES_FOR_NEW_AGENT_FAILURE',
      ],
      promise,
      bypassGlobalError: true,
      sectionId: sectionId,
      searchParams: {
        agent,
        product,
      },
    });
  };
