import React, { useState } from 'react';
import {
  NglicSimpleSelect,
  Option,
  NglicSimpleSelectType,
} from '@nglic/component-lib/build';
import { useDispatch, useSelector } from 'react-redux';
import { Product } from '../../../service/service.types';
import { AppState } from '../../../rootReducer';
import styles from './ProductDropdown.module.scss';
import { fetchProductsByBranchCode } from '../../shared/product.action';
import { getUserInformation } from '../../shared/selectors/getUserInformation';

export const ProductDropdown: React.FC<{
  onProductSelect: (product: Product) => void;
  onProductClear?: () => void;
  value?: Product;
}> = ({ onProductSelect, onProductClear, value }) => {
  const dispatch = useDispatch();
  const [selectedOption, setSelectedOption] = useState<
    Option | string | undefined
  >(value as Product);
  const [loading, setLoading] = useState<boolean>(true);
  const products: Product[] | undefined = useSelector(
    (state: AppState) => state.data.product.products,
  );
  const userInfo = useSelector((state: AppState) => getUserInformation(state));
  const sectionsLoading = useSelector(
    (state: AppState) => state.presentation.loading.sectionsLoading,
  );

  React.useEffect(() => {
    if (products) {
      setLoading(false);
    }
  }, [sectionsLoading]);

  React.useEffect(() => {
    if (userInfo && (!products || products?.length === 0)) {
      const { branchCode } = userInfo;
      dispatch(fetchProductsByBranchCode(branchCode));
    }
  }, [userInfo]);

  const options = products?.reduce<Option[]>((acc, next) => {
    return [
      ...acc,
      {
        id: next.id + next.name,
        name: next.name,
        extraInfo: next,
      },
    ];
  }, []);

  const handleChange = (option: Option) => {
    setSelectedOption(option);
    onProductSelect(option.extraInfo as Product);
    value = option.extraInfo as Product;
  };

  const handleClear = () => {
    setSelectedOption(undefined);
    if (onProductClear) {
      onProductClear();
    }
  };

  return (
    <div className={styles['root']}>
      <NglicSimpleSelect
        loading={loading}
        onChange={handleChange}
        options={options || []}
        selectedOption={selectedOption || value || ''}
        type={NglicSimpleSelectType?.MAINTAIN_VALUE}
        label={'Select a Product'}
        clearIcon={onProductClear ? handleClear : undefined}
      />
    </div>
  );
};
