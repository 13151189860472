import React from 'react';
import { PageEnum } from '@nglic/component-lib/build';
import { HierarchyTable } from './HierarchyTable/HierarchyTable';
import { AppPageLayoutWrapper } from '../AppPageLayoutWrapper';
import styles from '../Agent/AgentManagement/AgentManagement.module.scss';

export const ManageHierarchies: React.FC = () => {
  const subHeaderComponent = () => {
    return <div className={styles['title']}>Manage Hierarchies</div>;
  };
  return (
    <AppPageLayoutWrapper
      title={'Manage Hierarchies'}
      page={PageEnum.MANAGE_HIERARCHIES}
      subHeaderMobileConfig={subHeaderComponent()}
    >
      <HierarchyTable />
    </AppPageLayoutWrapper>
  );
};

export default ManageHierarchies;
