import React, { useCallback, useState } from 'react';
import { Option } from '@nglic/component-lib/build';
import { useDispatch, useSelector } from 'react-redux';
import { Product } from '../../../service/service.types';
import { AppState } from '../../../rootReducer';
import styles from './ProductSlidingDrawer.module.scss';
import { fetchProductsByBranchCode } from '../../shared/product.action';
import { CircularProgress, Drawer, withStyles } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import classNames from 'classnames';
import { getUserInformation } from '../../shared/selectors/getUserInformation';

const StyledDrawer = withStyles({
  paper: {
    borderRadius: `16px 16px 0 0`,
  },
})(Drawer);

export const ProductSlidingDrawer: React.FC<{
  onProductSelect: (product: Product) => void;
  onProductClear?: () => void;
}> = ({ onProductSelect, onProductClear }) => {
  const dispatch = useDispatch();
  const [selectedOption, setSelectedOption] = useState<Option | undefined>();
  const [loading, setLoading] = useState<boolean>(true);
  const products: Product[] | undefined = useSelector(
    (state: AppState) => state.data.product.products,
  );
  const userInfo = useSelector((state: AppState) => getUserInformation(state));
  const sectionsLoading = useSelector(
    (state: AppState) => state.presentation.loading.sectionsLoading,
  );

  React.useEffect(() => {
    if (products && !sectionsLoading.includes('PRODUCT_FETCH_BY_BRANCH')) {
      setLoading(false);
    }
  }, [sectionsLoading, products]);

  React.useEffect(() => {
    if (userInfo) {
      const { branchCode } = userInfo;
      dispatch(fetchProductsByBranchCode(branchCode));
    }
  }, [userInfo]);

  const options = products?.reduce<Option[]>((acc, next) => {
    return [
      ...acc,
      {
        id: next.id + next.name,
        name: next.name,
        extraInfo: next,
      },
    ];
  }, []);

  const [drawerIsOpen, setDrawerOpen] = useState<boolean>(false);

  const handleChange = (option: Option) => {
    setDrawerOpen(false);
    setSelectedOption(option);
    onProductSelect(option.extraInfo as Product);
  };

  return (
    <React.Fragment>
      <div
        className={styles['root']}
        aria-label={'product-drawer-toggle'}
        onClick={
          !loading
            ? () => {
                setDrawerOpen(true);
              }
            : undefined
        }
      >
        <span className={styles['product-drawer-toggle']}>
          {selectedOption ? selectedOption.name : 'All Products'}
        </span>
        {!loading && <FilterListIcon />}
        {loading && (
          <CircularProgress size={20} className={styles['loading-spinner']} />
        )}
      </div>
      <StyledDrawer
        anchor={'bottom'}
        open={drawerIsOpen}
        onBackdropClick={() => setDrawerOpen(false)}
        className={styles['drawer-root']}
      >
        <div>
          <div
            className={classNames(
              styles['product-drawer-header'],
              styles['product-drawer-item'],
            )}
          >
            Filter by
          </div>
          {options?.map((option) => {
            return (
              <div
                className={styles['product-drawer-item']}
                key={option.id}
                onClick={() => handleChange(option)}
              >
                {option.name}
              </div>
            );
          })}
        </div>
      </StyledDrawer>
    </React.Fragment>
  );
};
