import produce from 'immer';
import { Action } from 'redux';
import {
  ReviewAddAgentActionType,
  ReviewAddAgentFailureAction,
} from './review.action';
import { ReviewData } from '../../../Contract/Review/Review.types';

export type ReviewState = 'INCOMPLETE' | ReviewData;

export const reviewAddAgentReducer = (
  state: ReviewState = 'INCOMPLETE',
  action: Action<ReviewAddAgentActionType>,
): ReviewState => {
  return produce(state, () => {
    switch (action.type) {
      case 'REVIEW_ADD_AGENT':
        return {
          submitted: true,
          error: undefined,
        };
      case 'REVIEW_ADD_AGENT_FAILURE':
        return {
          submitted: true,
          error: (action as ReviewAddAgentFailureAction).error,
        };
      case 'CLEAR_REVIEW_ADD_AGENT':
        return 'INCOMPLETE';
    }
  });
};
