import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import { AutoSizer } from 'react-virtualized';

import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ChevronRight from '@material-ui/icons/ChevronRight';
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import { NGlicSpinner, SearchInput } from '@nglic/component-lib/build/';
import styles from './AgentList.module.scss';
import { AppState } from '../../../../../rootReducer';
import {
  agentInformationResendInviteClear,
  agentListResendInvite,
  agentListResendInviteClear,
  fetchAgents,
} from '../../agent.action';
import { Agent } from 'service/service.types';
import { Pagination } from '../../../../modules/Pagination/Pagination';
import { SectionName } from '../../../../shared/loading.action';
import DoneIcon from '@material-ui/icons/Done';
import { Search } from '../../../../modules/Search/Search';
import { getUserInformation } from '../../../../shared/selectors/getUserInformation';

interface AgentListProps {
  selectedAgent?: Agent;
  onAgentSelected?: (agent: Agent) => void;
}

export const AgentList: React.FC<AgentListProps> = (props: AgentListProps) => {
  const { selectedAgent, onAgentSelected } = props;
  const [loading, setLoading] = useState<boolean>(true);
  const [filteredText, setFilteredText] = useState<string>('');
  const [filteredAgents, setFilteredAgents] = useState<Agent[]>([]);
  const [page, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const pageLimit = 50;
  const agents: Agent[] | undefined = useSelector(
    (state: AppState) => state.presentation.agent.agents,
  );
  const totalAgents: number | undefined = useSelector(
    (state: AppState) => state.presentation.agent.totalAgents,
  );
  const sectionsLoading: SectionName[] = useSelector(
    (state: AppState) => state.presentation.loading.sectionsLoading,
  );

  const resendInviteRedux = useSelector(
    (state: AppState) => state.presentation.agent.agentListResendInvite,
  );

  React.useEffect(() => {
    setLoading(!agents || sectionsLoading.includes('AGENT_FETCH_ALL'));
  }, [agents, sectionsLoading]);

  React.useEffect(() => {
    if (!totalAgents) {
      setTotalPages(1);
    } else {
      setTotalPages(Math.ceil(totalAgents / pageLimit));
    }
  }, [totalAgents]);

  React.useEffect(() => {
    setFilteredAgents(agents ? agents : []);
  }, [agents]);

  const agentInfo = useSelector((state: AppState) => getUserInformation(state));

  const dispatch = useDispatch();

  useEffect(() => {
    if (agentInfo) {
      dispatch(
        fetchAgents(
          agentInfo.branchCode,
          page,
          pageLimit,
          filteredText !== '' ? filteredText : undefined,
        ),
      );
    }
  }, [agentInfo, page, filteredText]);

  const onClickAgent = useCallback(
    (agent: Agent) => {
      if (onAgentSelected) {
        onAgentSelected(agent);
      }
    },
    [agents],
  );

  const renderRow = (props: ListChildComponentProps) => {
    const { index, style } = props;
    const agent = filteredAgents[index];

    const handleResendInvitation = (email: string) => {
      dispatch(agentListResendInvite(email));
    };

    const handleResendInvitationClear = () => {
      dispatch(agentListResendInviteClear());
      dispatch(agentInformationResendInviteClear());
    };
    return (
      <ListItem
        className={classNames(styles['list-item'], {
          [`${styles['list-item-selected']}`]: agent.id === selectedAgent?.id,
        })}
        style={{ ...style, display: 'flex', alignItems: 'center' }}
        key={agent.id}
        data-testid="list-item"
        button
        onClick={() => {
          onClickAgent(agent);
        }}
        disableRipple
        disableTouchRipple
      >
        <div
          className={classNames(styles['item-text'], {
            [`${styles['item-text-selected']}`]: agent.id === selectedAgent?.id,
          })}
        >
          <ListItemText
            onClick={() => {
              handleResendInvitationClear();
            }}
            primary={agent.name}
          />
          {selectedAgent?.id == agent.id &&
            (resendInviteRedux?.success ? (
              <div>
                <div className={styles['done-resend-invitation']}>
                  {' '}
                  <DoneIcon /> <div>Invitation sent successfully!</div>
                </div>
              </div>
            ) : (
              <div
                onClick={() => {
                  handleResendInvitation(agent.email);
                }}
                className={styles['resend-invitation']}
              >
                Resend Invite
              </div>
            ))}
        </div>

        <ChevronRight
          onClick={() => {
            handleResendInvitationClear();
          }}
          className={styles['chevron-right']}
        />
      </ListItem>
    );
  };

  return (
    <div className={styles['root']} data-testid="main-element">
      <div className={styles['search']} data-testid="search-input">
        <SearchInput onChange={setFilteredText} id={'agent-search'} />
      </div>

      <div className={styles['mobile-header']}>
        <Search
          value={filteredText}
          placeHolder={'Search'}
          onChange={setFilteredText}
          id={'agent-search'}
        />
        <div className={styles['divider']}>AGENTS</div>
      </div>
      <div className={styles['list-container']}>
        {loading ? (
          <div className={styles['loading-container']}>
            <NGlicSpinner />
          </div>
        ) : (
          <AutoSizer>
            {({ height, width }) => (
              <FixedSizeList
                height={height}
                width={width}
                itemSize={72}
                itemCount={filteredAgents ? filteredAgents.length : 0}
                className={styles['list']}
              >
                {renderRow}
              </FixedSizeList>
            )}
          </AutoSizer>
        )}
        <div className={styles['pagination-container']}>
          <Pagination
            onPageClick={setCurrentPage}
            numberOfPages={totalPages}
            currentPage={page}
          />
        </div>
      </div>
    </div>
  );
};
