import { Action } from 'redux';

export type ApproverAction = 'ADD_APPROVER';

export interface AddApproverAction extends Action<ApproverAction> {
  type: 'ADD_APPROVER';
  approverId: string | undefined;
  approverName: string | undefined;
  approverEmail: string | undefined;
}

export const addApprover = (
  approverId: string | undefined,
  approverName: string | undefined,
  approverEmail: string | undefined,
): AddApproverAction => ({
  type: 'ADD_APPROVER',
  approverId,
  approverName,
  approverEmail,
});
