import produce from 'immer';
import { Action } from 'redux';
import {
  AddAgentInformationAction,
  AgentInformationAction,
} from './agentInformation.action';

export type GeneralAgentInformation = {
  firstName: string;
  lastName: string;
  email: string;
  mailingPreference: string;
};

export type AgentInformationState = 'INCOMPLETE' | GeneralAgentInformation;

export const agentInformationReducer = (
  state: AgentInformationState = 'INCOMPLETE',
  action: Action<AgentInformationAction>,
): AgentInformationState => {
  return produce(state, () => {
    switch (action.type) {
      case 'ADD_AGENT_INFORMATION':
        const addInformationAction = action as AddAgentInformationAction;
        return {
          firstName: addInformationAction.firstName,
          lastName: addInformationAction.lastName,
          email: addInformationAction.email,
          mailingPreference: addInformationAction.mailingPreference,
        };
      case 'CLEAR_AGENT_INFORMATION':
        return 'INCOMPLETE';

      default:
        return state;
    }
  });
};
