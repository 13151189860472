import React from 'react';
import styles from './Review.module.scss';

export interface StrongParagraphComponentProps {
  strongText?: string;
  value?: string;
  ariaLabel?: string;
}

export const KeyValueTextItem: React.FC<StrongParagraphComponentProps> = ({
  strongText,
  value,
  ariaLabel,
}: StrongParagraphComponentProps) => {
  const showData = strongText !== undefined && value !== undefined;
  return (
    <p className={styles['key-value-text']} aria-label={ariaLabel}>
      <strong>{showData && `${strongText}: `}</strong>
      {showData && value}
    </p>
  );
};
