import { AllCCPDocumentsResponse } from './../../../../service/service.types';
import { ThunkAction } from 'redux-thunk';
import { AppState } from '../../../../rootReducer';
import { Action } from 'redux';
import { loadComplete, loadStarted } from '../../../shared/loading.action';

import { PolicyService } from '../../../../service/PolicyService';
import {
  PartialPolicy,
  Policy,
  PolicyClaim,
} from '../../../../service/service.types';
import { getCredentials } from '../../../shared/selectors/getCredentials';
import { isUserInfo } from '../../../shared/selectors/basicUserInfo.selector';
import { getUserInformation } from '../../../shared/selectors/getUserInformation';
import { AgentService } from '../../../../service/AgentService';
import { isUserFuneralHome } from '@nglic/utilities-ts/build';
import { FuneralHomeService } from '../../../../service/FuneralHomeService';

export type PoliciesActionType =
  | 'POLICIES_FETCH_INITIAL_FAILURE'
  | 'POLICIES_FETCH_INITIAL_SUCCESS'
  | 'POLICIES_FETCH_SUCCESS'
  | 'POLICIES_FETCH_FAILURE'
  | 'POLICY_CLAIM_FETCH_SUCCESS'
  | 'POLICY_CLAIM_FETCH_FAILURE'
  | 'FETCH_POLICY_REQUEST'
  | 'FETCH_POLICY_REQUEST_SUCCESS'
  | 'FETCH_POLICY_REQUEST_FAILURE'
  | 'RESET_POLICIES'
  | 'FETCH_CCP_DOCUMENTS_REQUEST'
  | 'FETCH_CPP_DOCUMENTS_SUCCESS'
  | 'FETCH_CPP_DOCUMENTS_FAILURE'
  | 'NONE';

export interface FetchAllPoliciesSuccessAction
  extends Action<PoliciesActionType> {
  policies: PartialPolicy[];
  totalPolicies: number;
  funeralHomePolicies: boolean | undefined;
}

export interface FetchPolicyClaimSuccessAction
  extends Action<PoliciesActionType> {
  policyClaim: PolicyClaim;
}

export interface FetchAllPoliciesFailureAction
  extends Action<PoliciesActionType> {
  error: Error;
}

export interface FetchPolicyFailureAction extends Action<PoliciesActionType> {
  error: Error;
}

export interface FetchPolicyFailureSuccess extends Action<PoliciesActionType> {
  policy: Policy;
}

interface FetchPoliciesParams {
  page: number;
  limit: number;
  query?: string;
  policyId?: string | null;
}

export const resetPolicies = (): Action<PoliciesActionType> => ({
  type: 'RESET_POLICIES',
});

export interface FetchAllCCPDocumentsFailureAction
  extends Action<PoliciesActionType> {
  type: 'FETCH_CPP_DOCUMENTS_FAILURE';
  error: Error;
}

export interface FetchAllCCPDocumentsSuccessAction
  extends Action<PoliciesActionType> {
  type: 'FETCH_CPP_DOCUMENTS_SUCCESS';
  result: AllCCPDocumentsResponse;
}

export const fetchPolicies =
  ({
    page = 1,
    limit = 50,
    query,
    policyId = null,
  }: FetchPoliciesParams): ThunkAction<void, AppState, any, any> =>
  async (dispatch, getState: () => AppState) => {
    dispatch(loadStarted('POLICIES_FETCH_ALL'));
    const tokens = getCredentials(getState());
    const userInfo = getUserInformation(getState());

    if (!isUserInfo(userInfo)) {
      dispatch({
        type: 'POLICIES_FETCH_INITIAL_FAILURE',
        error: new Error('No User information present'),
      });
      return;
    }
    const { agentId, branchCode, roles } = userInfo;
    if (policyId) {
      try {
        const { policy } = await PolicyService.getPolicy({
          credentials: tokens,
          policyId,
        });

        dispatch({
          type: 'POLICIES_FETCH_SUCCESS',
          policies: [policy],
          totalPolicies: 1,
        });
      } catch (error) {
        dispatch({
          type: 'POLICIES_FETCH_FAILURE',
          error,
        });
      }
    } else {
      try {
        if (isUserFuneralHome(roles)) {
          const { funeralHomePolicies, policies, totalPolicies } =
            await FuneralHomeService.getAllFuneralHomePolicies({
              credentials: tokens,
              agentId,
              page: 1,
              limit: 8,
              query: query === '' ? undefined : query,
            });
          dispatch({
            type: 'POLICIES_FETCH_SUCCESS',
            policies,
            totalPolicies,
            funeralHomePolicies,
          });
        } else {
          const { policies, totalPolicies } =
            await PolicyService.getAllPolicies({
              credentials: tokens,
              branchCode,
              agentId,
              page,
              limit,
              query: query === '' ? undefined : query,
            });
          dispatch({
            type: 'POLICIES_FETCH_SUCCESS',
            policies,
            totalPolicies,
          });
        }
      } catch (error) {
        dispatch({
          type: 'POLICIES_FETCH_FAILURE',
          error,
        });
      }
    }
    dispatch(loadComplete('POLICIES_FETCH_ALL'));
  };

export const fetchInitialPolicies =
  (): ThunkAction<void, AppState, any, any> =>
  async (dispatch, getState: () => AppState) => {
    const initialPolicies = getState().presentation.policies.initialPolicies;

    if (initialPolicies.length > 0) {
      return;
    }

    dispatch(loadStarted('POLICIES_FETCH_INITIAL'));
    const tokens = getCredentials(getState());
    const userInfo = getUserInformation(getState());

    if (!isUserInfo(userInfo)) {
      dispatch({
        type: 'POLICIES_FETCH_INITIAL_FAILURE',
        error: new Error('No User information present'),
      });
      return;
    }

    const { agentId, branchCode, roles } = userInfo;
    try {
      if (isUserFuneralHome(roles)) {
        const { funeralHomePolicies, policies, totalPolicies } =
          await FuneralHomeService.getAllFuneralHomePolicies({
            credentials: tokens,
            agentId,
            page: 1,
            limit: 8,
            query: undefined,
          });

        dispatch({
          type: 'POLICIES_FETCH_INITIAL_SUCCESS',
          policies,
          totalPolicies,
          funeralHomePolicies,
        });
      } else {
        const { policies, totalPolicies } = await PolicyService.getAllPolicies({
          credentials: tokens,
          branchCode,
          agentId,
          page: 1,
          limit: 8,
          query: undefined,
        });
        dispatch({
          type: 'POLICIES_FETCH_INITIAL_SUCCESS',
          policies,
          totalPolicies,
        });
      }
    } catch (error) {
      dispatch({
        type: 'POLICIES_FETCH_INITIAL_FAILURE',
        error,
      });
    }
    dispatch(loadComplete('POLICIES_FETCH_INITIAL'));
  };

export const fetchPolicyClaim =
  (policyId: string): ThunkAction<void, AppState, any, any> =>
  async (dispatch, getState: () => AppState) => {
    dispatch(loadStarted('POLICY_CLAIM_FETCH_REQUEST'));
    const tokens = getCredentials(getState());
    try {
      const { policyClaim } = await PolicyService.getPolicyClaim(
        tokens,
        policyId,
      );
      dispatch({
        type: 'POLICY_CLAIM_FETCH_SUCCESS',
        policyClaim,
      });
    } catch (error) {
      dispatch({
        type: 'POLICY_CLAIM_FETCH_FAILURE',
        error,
      });
    }
    dispatch(loadComplete('POLICY_CLAIM_FETCH_REQUEST'));
  };

export const fetchPolicy =
  (policyId: string): ThunkAction<void, AppState, any, any> =>
  async (dispatch, getState: () => AppState) => {
    dispatch(loadStarted('FETCH_POLICY_REQUEST'));
    const tokens = getCredentials(getState());
    try {
      const { policy } = await PolicyService.getPolicy({
        credentials: tokens,
        policyId,
      });
      dispatch({
        type: 'FETCH_POLICY_REQUEST_SUCCESS',
        policy,
      });
    } catch (error) {
      dispatch({
        type: 'FETCH_POLICY_REQUEST_FAILURE',
        error,
      });
    }
    dispatch(loadComplete('FETCH_POLICY_REQUEST'));
  };

export const fetchCCPDocuments =
  (policyNumber: string): ThunkAction<void, AppState, any, any> =>
  async (dispatch, getState: () => AppState) => {
    dispatch(loadStarted('FETCH_CPP_DOCUMENTS_REQUEST'));
    const tokens = getCredentials(getState());
    const userInfo = getUserInformation(getState());

    if (!isUserInfo(userInfo)) {
      dispatch({
        type: 'FETCH_CPP_DOCUMENTS_FAILURE',
        error: new Error('No User information present'),
      });
      return;
    }

    const { agentId, branchCode } = userInfo;

    try {
      const result = await AgentService.getCCPDocuments(
        agentId,
        branchCode,
        policyNumber,
        tokens,
      );
      dispatch({
        type: 'FETCH_CPP_DOCUMENTS_SUCCESS',
        result,
      });
    } catch (error) {
      dispatch({
        type: 'FETCH_CPP_DOCUMENTS_FAILURE',
        error,
      });
    }

    dispatch(loadComplete('FETCH_CPP_DOCUMENTS_REQUEST'));
  };
