import React from 'react';
import styles from './QuickLinks.module.scss';
import Information from '../../../../../assets/img/productInformation/Information.png';
import Report from '../../../../../assets/img/reportIcon/Report.png';
import Overview from '../../../../../assets/img/overviewIcon/Overview.png';
import Demographics from '../../../../../assets/img/demographicsIcon/Demographics.png';
import RateCalculator from '../../../../../assets/img/rateCalculator/RateCalculator.png';
import { ActionLink } from '../ActionLink/ActionLink';
import { AppQoreActionLink } from './AppQoreActionLink/AppQoreActionLink';
import { ThysseActionLink } from './ThysseActionLink/ThysseActionLink';

export interface QuickLinksProps {
  onClickRateCalculator?: () => void;
}

export const QuickLinks: React.FC<QuickLinksProps> = ({
  onClickRateCalculator,
}) => {
  return (
    <div className={styles['root']}>
      <div className={styles['image']}>
        <img src={Overview} />
      </div>
      <div>
        <div className={styles['header']}>Quick Links</div>
        <div className={styles['link-list']}>
          <ThysseActionLink />
          <ActionLink text="Product Information" image={Information} />
          <ActionLink text="Reports mynglic.com" image={Report} />
          <AppQoreActionLink />
          <ActionLink text="Media Demographics" image={Demographics} />
          <ActionLink
            text="Rate Calculator"
            image={RateCalculator}
            actionOnClick={onClickRateCalculator}
          />
        </div>
      </div>
    </div>
  );
};
