import produce from 'immer';
import { Action } from 'redux';
import { AddCommentAction, HierarchyAction } from './hierarchy.action';

export type Comment = {
  comment: string;
};

export interface CommentInitState {
  comment: string;
}

export type CommentErrorState = {
  error: Error;
} & CommentInitState;

export type CommentState = CommentInitState | CommentErrorState;

export const commentReducer = (
  state: CommentState = { comment: '' },
  action: Action<HierarchyAction>,
): CommentState => {
  return produce(state, (draftState) => {
    switch (action.type) {
      case 'ADD_COMMENT':
        const addCommentAction = action as AddCommentAction;
        return {
          ...draftState,
          comment: addCommentAction.comment,
        };

      default:
        return state;
    }
  });
};
