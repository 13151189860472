import { Action } from 'redux';
import produce from 'immer';

import {
  FetchReportFiltersErrorAction,
  FetchReportFiltersSuccessAction,
  ReportFiltersActionType,
  SetReportFiltersAction,
} from './reportFilters.action';
import { IProducerOptions, IReportFilters } from './Report.types';

export interface ReportFiltersInitState {
  filterOptions: {
    dateFilters: string[];
    funeralHomeIds: { id: string; name: string }[];
    producerIds: IProducerOptions[];
  };
  currentFilters: IReportFilters;
}

export type ReportFiltersErrorState = {
  error: Error;
} & ReportFiltersInitState;

export type ReportFiltersState =
  | ReportFiltersInitState
  | ReportFiltersErrorState;

export const reportFiltersReducer = (
  state: ReportFiltersInitState = {
    filterOptions: {
      dateFilters: [],
      funeralHomeIds: [],
      producerIds: [],
    },
    currentFilters: {
      dateGrouping: 'this_year',
      dateFrom: '',
      dateTo: '',
      funeralHomeIds: [],
      producerIds: [],
    },
  },
  action: Action<ReportFiltersActionType>,
): ReportFiltersState => {
  return produce(state, (draftState: ReportFiltersState) => {
    switch (action.type) {
      case 'REPORT_FILTERS_FETCH_SUCCESS': {
        draftState = {
          ...draftState,
          filterOptions: {
            ...(action as FetchReportFiltersSuccessAction).result,
          },
        };
        return draftState;
      }
      case 'REPORT_FILTERS_FETCH_FAILURE': {
        draftState = {
          ...draftState,
          error: (action as FetchReportFiltersErrorAction).error,
          filterOptions: {
            dateFilters: [],
            funeralHomeIds: [],
            producerIds: [],
          },
        };
        return draftState;
      }
      case 'SET_REPORT_FILTERS': {
        draftState = {
          ...draftState,
          currentFilters: {
            ...(action as SetReportFiltersAction).currentFilters,
          },
        };
        return draftState;
      }
      case 'RESET_REPORT_FILTERS': {
        draftState = {
          ...draftState,
          currentFilters: {
            dateGrouping: 'this_year',
            dateFrom: '',
            dateTo: '',
            funeralHomeIds: [],
            producerIds: [],
          },
        };
        return draftState;
      }
      default:
        return state;
    }
  });
};
