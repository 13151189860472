import produce from 'immer';
import { Action } from 'redux';
import {
  FeatureActionTypes,
  FetchFeatureViewableSuccessAction,
} from './features.action';

export type FeautureVisibilityState = {
  manageBusinessVisible: boolean;
  manageHierarchiesVisible: boolean;
  knowledgeCenterVisible: boolean;
  commissionsVisible: boolean;
  overviewVisible: boolean;
  policyManagementVisible: boolean;
};

export const featureViewableReducer = (
  state: FeautureVisibilityState = {
    manageBusinessVisible: false,
    manageHierarchiesVisible: false,
    knowledgeCenterVisible: false,
    commissionsVisible: false,
    overviewVisible: false,
    policyManagementVisible: false,
  },
  action: Action<FeatureActionTypes>,
): FeautureVisibilityState => {
  return produce(state, (draftState) => {
    switch (action.type) {
      case 'FETCH_FEATURE_VIEWABLE_SUCCESS':
        draftState = (action as FetchFeatureViewableSuccessAction).result;
        return draftState;
      case 'FETCH_FEATURE_VIEWABLE_FAILURE':
        return draftState;
      default:
        return state;
    }
  });
};
