import React from 'react';

import styles from './ReserveAccount.module.scss';
import { ProducerFundReservesResponse } from '../../../../../../service/service.types';

interface ReservesDataPercentageProps {
  reserveAccount: ProducerFundReservesResponse | undefined;
}

export const ReserveAccount: React.FC<ReservesDataPercentageProps> = ({
  reserveAccount,
}) => {
  return (
    <div className={styles['root']}>
      <div className={styles['desktop-content']}>
        <div className={styles['data-content']}>
          <div className={styles['data']}>
            {reserveAccount?.singlePayPercent} % <p>Single Pay</p>
          </div>
          <div className={styles['divider']} />
          <div className={styles['data']}>
            {reserveAccount?.directBillPercent} % <p>Direct Bill</p>
          </div>
          <div className={styles['divider']} />
          <div className={styles['data']}>
            {reserveAccount?.renewalPercent} % <p>Renewal</p>
          </div>
          <div className={styles['divider']} />
          <div className={styles['data']}>
            {reserveAccount?.eftPercent} % <p>EFT</p>
          </div>
          <div className={styles['divider']} />
          <div className={styles['data']}>
            {reserveAccount?.creditCardPercent} % <p>Credit Card</p>
          </div>
        </div>
      </div>
    </div>
  );
};
