import React from 'react';
import styles from './PasswordRequirements.module.scss';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';

export interface PasswordValidity {
  minChars: boolean | undefined;
  capitalLetter: boolean | undefined;
  lowercaseLetter: boolean | undefined;
  number: boolean | undefined;
  specialChar: boolean | undefined;
}

interface PasswordRequirements {
  passwordValidity: PasswordValidity;
}
export const PasswordRequirements: React.FC<PasswordRequirements> = ({
  passwordValidity: {
    minChars,
    capitalLetter,
    lowercaseLetter,
    number,
    specialChar,
  },
}) => {
  return (
    <div className={styles['root']}>
      <div className={styles['title']}>Password Requirements</div>
      <div className={styles['requirements']}>
        <PasswordRequirementsItem
          isValid={minChars}
          text={'8 characters (minimum)'}
        />
        <PasswordRequirementsItem
          isValid={capitalLetter}
          text={'Capital letter'}
        />
        <PasswordRequirementsItem
          isValid={lowercaseLetter}
          text={'Lowercase letter'}
        />
        <PasswordRequirementsItem isValid={number} text={'Number (#)'} />
        <PasswordRequirementsItem
          isValid={specialChar}
          text={'Special character ! @ # $ % ^ & * ( )'}
        />
      </div>
    </div>
  );
};

interface PasswordRequirementsItemProps {
  isValid: boolean | undefined;
  text: string;
}

export const PasswordRequirementsItem: React.FC<PasswordRequirementsItemProps> =
  ({ isValid, text }) => {
    const highlightedClass = isValid
      ? 'success'
      : isValid !== undefined
      ? 'danger'
      : '';
    const icon =
      isValid === true || isValid === undefined ? <CheckIcon /> : <CloseIcon />;
    return (
      <div className={styles['root-item']}>
        <div className={styles[highlightedClass]}>{icon}</div>
        <div className={styles[highlightedClass]}>
          <div className={styles['text']}>{text}</div>
        </div>
      </div>
    );
  };
