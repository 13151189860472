import styles from './Search.module.scss';
import { SearchInput } from '@nglic/component-lib/build';
import React, { useState } from 'react';

export const Search: React.FC<{
  value?: string;
  placeHolder?: string;
  onChange: (val: string) => void;
  id: string;
}> = ({ value, placeHolder = 'Search', onChange, id }) => {
  const [query, setQuery] = useState<string>(value ?? '');
  const handleChange = (input: string) => {
    onChange(input);
    setQuery(input);
  };
  return (
    <div className={styles['search']} data-testid="search-input">
      <SearchInput
        value={query}
        placeHolder={placeHolder}
        onChange={handleChange}
        id={id}
      />
      <div
        onClick={() => {
          handleChange('');
        }}
        className={styles['cancel-search']}
      >
        Cancel
      </div>
    </div>
  );
};
