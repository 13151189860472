import { NglicModal } from '@nglic/component-lib';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../../../rootReducer';
import {
  DemographicInformation,
  Policy,
} from '../../../../../service/service.types';
import styles from './ContactModal.module.scss';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import PhoneIcon from '@material-ui/icons/Phone';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import PersonIcon from '@material-ui/icons/Person';
import { fetchBasePersonAddressesAndPhones } from './demographicInformation.action';
import classNames from 'classnames';
import { getUserInformation } from '../../../../shared/selectors/getUserInformation';

interface ContactModalProps {
  open: boolean;
  handleClose: () => void;
  policy?: Policy;
  isInsured: boolean;
}

export const PhoneList: React.FC<{
  demographicInformation: DemographicInformation | undefined;
}> = ({ demographicInformation }) => {
  return (
    <div className={styles['demographic-text']}>
      {demographicInformation?.phones?.map((phone) => {
        return (
          <div
            className={styles['demographic-value']}
          >{`${phone.areaCode}-${phone.dialNumber}`}</div>
        );
      })}
    </div>
  );
};

export const AddressList: React.FC<{
  demographicInformation: DemographicInformation | undefined;
}> = ({ demographicInformation }) => {
  return (
    <div className={styles['demographic-text']}>
      {demographicInformation?.addresses?.map((address) => {
        return (
          <div className={styles['demographic-value']}>
            <div>{address.addressLine1}</div>
            <div>{address.addressLine2}</div>
            <div>{`${address.city}, ${address.state} ${address.zip}`}</div>
          </div>
        );
      })}
    </div>
  );
};

export const ContactModal: React.FC<ContactModalProps> = ({
  open,
  handleClose,
  policy,
  isInsured,
}) => {
  const dispatch = useDispatch();
  const userInfo = useSelector((state: AppState) => getUserInformation(state));

  const demographicInformation: DemographicInformation | undefined =
    useSelector(
      (state: AppState) =>
        state.presentation.demographicInformation.demographicInformation,
    );

  useEffect(() => {
    if (userInfo) {
      dispatch(
        fetchBasePersonAddressesAndPhones(policy?.insured?.demographicId ?? ''),
      );
    }
  }, [userInfo, policy?.insured?.demographicId]);

  return (
    <NglicModal isOpened={open}>
      <div className={styles['modal-content']}>
        <div className={styles['header']}>
          <IconButton onClick={handleClose} className={styles['icon-button']}>
            <CloseIcon className={styles['close-icon']} />
          </IconButton>
        </div>
        <div className={styles['content']}>
          <div className={styles['text']}>{policy?.insuredName}</div>
          <div className={styles['text-label']}>
            {isInsured ? 'Insured' : 'Policy Owner'}
          </div>
          <div className={styles['demographic-information']}>
            <PersonIcon className={styles['icons']} />
            <div
              className={classNames(
                styles['demographic-text'],
                styles['demographic-value'],
              )}
            >
              {policy?.insured?.gender ?? ''}
            </div>
          </div>
          <div className={styles['demographic-information']}>
            <PhoneIcon className={styles['icons']} />
            <PhoneList demographicInformation={demographicInformation} />
          </div>
          <div className={styles['demographic-information']}>
            <LocationOnIcon className={styles['icons']} />
            <AddressList demographicInformation={demographicInformation} />
          </div>
        </div>
      </div>
    </NglicModal>
  );
};
