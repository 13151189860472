import React from 'react';
import { Product } from '../../../../../../../service/service.types';
import { TextInput } from '@nglic/component-lib';
import { ProductsEnum } from '../../../products.enum';

export const downPaymentVisibility = (
  product: Product | undefined,
): boolean => {
  const productsNotAllowed = [
    ProductsEnum.SERIES15,
    ProductsEnum.ASSETGUARD2,
    ProductsEnum.SERIES20,
  ];
  if (product) {
    if (!productsNotAllowed.includes(product.name as ProductsEnum)) {
      return true;
    }
  }
  return false;
};

export interface DownPaymentProps {
  onChange: (val: string) => void;
  product?: Product;
}

export const DownPayment: React.FC<DownPaymentProps> = ({
  product,
  onChange,
}) => {
  return (
    <div data-testid={'rate-calculator-down-payment'}>
      <div>
        {downPaymentVisibility(product) && (
          <TextInput
            label="Down Payment"
            onChange={(value) => onChange(value)}
          />
        )}
      </div>
    </div>
  );
};
