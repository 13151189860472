import { AgentProduct, Hierarchy } from '../service.types';
import getUuidByString from 'uuid-by-string';
import { HierarchyUpdate } from '../../components/Pages/Agent/AddAgent/Hierarchy/hierarchy.reducer';

const transformHierarchy = (
  hierarchy: Hierarchy,
  updatedHierarchies: AgentProduct[],
  sortBeforeProcessing = true,
): Hierarchy => {
  // We need to ensure that the Agent products are transformed commission level descending
  // In some cases we don't need to sort since they are in the correct order i.e. From the Add agent flow
  const originalHierarchy = {
    ...hierarchy,
    agents: sortBeforeProcessing
      ? sortByCommissionLevelDesc([...hierarchy.agents])
      : hierarchy.agents,
  };
  updatedHierarchies = sortBeforeProcessing
    ? sortByCommissionLevelDesc([...updatedHierarchies])
    : updatedHierarchies;

  const transformedAgents = updatedHierarchies.reduce<AgentProduct[]>(
    (acc, next, index) => {
      const wasLowestInOriginalHierarchy =
        originalHierarchy.agents[originalHierarchy.agents.length - 1]?.id ===
        next.id;
      if (index === updatedHierarchies.length - 1) {
        const uplineAgent = acc[index - 1];
        return [
          ...acc,
          transformAgentProducts(
            originalHierarchy,
            next,
            wasLowestInOriginalHierarchy,
            uplineAgent,
          ),
        ];
      } else if (index === 0) {
        const downlineAgent = updatedHierarchies[index + 1];
        return [
          ...acc,
          transformAgentProducts(
            originalHierarchy,
            next,
            wasLowestInOriginalHierarchy,
            undefined,
            downlineAgent,
          ),
        ];
      } else {
        const uplineAgent = acc[index - 1];
        const downlineAgent = updatedHierarchies[index + 1];
        return [
          ...acc,
          transformAgentProducts(
            originalHierarchy,
            next,
            wasLowestInOriginalHierarchy,
            uplineAgent,
            downlineAgent,
          ),
        ];
      }
    },
    [],
  );

  return {
    ...originalHierarchy,
    agents: transformedAgents,
  };
};

const sortByCommissionLevelDesc = (
  original: AgentProduct[],
): AgentProduct[] => {
  const originalCopy = [...original];
  const levelZeroOldPositionMap = original.reduce((acc, node, index) => {
    if (node.commissionLevel !== '0') {
      return acc;
    }

    return {
      ...acc,
      [getUuidByString(JSON.stringify(node))]: originalCopy.length - 1 - index,
    };
  }, {});
  const agentsSortedByCommissionLevel = originalCopy.sort((a, b) => {
    return parseFloat(b.commissionLevel) - parseFloat(a.commissionLevel);
  });

  return insertAgentProductAtPosition(
    original,
    agentsSortedByCommissionLevel,
    levelZeroOldPositionMap,
  );
};

const insertAgentProductAtPosition = (
  original: AgentProduct[],
  currentHierarchy: AgentProduct[],
  positionMap: Record<string, number>,
): AgentProduct[] => {
  const resLen = currentHierarchy.length === 0 ? 0 : currentHierarchy.length;
  const res = new Array(resLen);
  const positionEntries = Object.entries(positionMap);
  if (positionEntries.length === 0) {
    return currentHierarchy;
  }

  // Place entries in the map in their correct position
  Object.entries(positionMap).forEach(([uuid, position]) => {
    const entryValue = currentHierarchy.find((ap) => {
      return getUuidByString(JSON.stringify(ap) ?? '') === uuid;
    });
    if (!entryValue) {
      return;
    }

    res[position] = entryValue;
  });

  const takenPositions = Object.values(positionMap);
  // Place values into array around where the level zero positions are sitting
  currentHierarchy.map((ap) => {
    if (ap.commissionLevel === '0') {
      return;
    }

    const position = getNextHighestPosition(
      0,
      currentHierarchy.length - 1,
      takenPositions,
    );

    if (position === undefined) {
      return;
    }

    res[position] = ap;
    takenPositions.push(position);
  });

  return res;
};

const findAgent = (
  agentProducts: AgentProduct[],
  id?: string,
): AgentProduct | undefined => {
  const agentIndex = agentProducts.findIndex((agent) => agent?.id === id);
  return agentIndex >= 0 ? agentProducts[agentIndex] : undefined;
};

const transformAgentProducts = (
  originalHierarchy: Hierarchy,
  agentProduct: AgentProduct,
  isLowestInHierarchy: boolean,
  uplineAgent?: AgentProduct,
  downLineAgent?: AgentProduct,
): AgentProduct => {
  const originalDownLine = findAgent(
    originalHierarchy.agents,
    downLineAgent?.id,
  );
  const originalAgent = findAgent(originalHierarchy.agents, agentProduct?.id);
  const originalAgentIndex = originalHierarchy.agents.findIndex(
    (originalAgent) => originalAgent.id === agentProduct?.id,
  );
  const originalUpline =
    originalAgentIndex !== originalHierarchy.agents.length - 1
      ? originalHierarchy.agents[originalAgentIndex - 1]
      : undefined;

  const hasDifferentUplineOrDownline =
    agentProduct.commissionLevel !== originalAgent?.commissionLevel ||
    originalDownLine?.id !== downLineAgent?.id ||
    originalUpline?.id !== uplineAgent?.id;

  const isNewWritingLevelAgent =
    !originalAgent &&
    (uplineAgent?.id ===
      originalHierarchy.agents[originalHierarchy.agents.length - 1]?.id ||
      !uplineAgent?.id);

  const isNewOverride =
    !isNewWritingLevelAgent &&
    !isLowestInHierarchy &&
    hasDifferentUplineOrDownline;

  return {
    id: isNewOverride ? undefined : agentProduct.id,
    agentId: agentProduct.agentId ? `${agentProduct.agentId}` : undefined,
    agentName: agentProduct.agentName,
    productId: agentProduct.productId,
    producerId: agentProduct.producerId,
    productName: agentProduct.productName,
    upLineAgentId: uplineAgent?.id
      ? `${uplineAgent?.id}`
      : agentProduct.upLineAgentId,
    upLineAgentName: uplineAgent?.agentName || agentProduct.upLineAgentName,
    commissionLevel: agentProduct.commissionLevel,
    overrideFlag: isNewOverride || !!originalAgent?.overrideFlag,
  };
};

const transformMultipleHierarchies = (
  hierarchies: HierarchyUpdate[],
  sortBeforeProcessing = true,
): Hierarchy[] => {
  return hierarchies.reduce<Hierarchy[]>((acc, next) => {
    return [
      ...acc,
      transformHierarchy(
        next.originalHierarchy,
        next.updatedHierarchy,
        sortBeforeProcessing,
      ),
    ];
  }, []);
};

const getNextHighestPosition = (
  index: number,
  maxPosition: number,
  takenPositions: number[],
): number | undefined => {
  if (index > maxPosition) {
    return undefined;
  }

  if (!takenPositions.includes(index)) {
    return index;
  }

  return getNextHighestPosition(index + 1, maxPosition, takenPositions);
};

const getNextLowestPosition = (
  index: number,
  takenPositions: number[],
): number | undefined => {
  if (index < 0) {
    return undefined;
  }

  if (!takenPositions.includes(index)) {
    return index;
  }

  return getNextLowestPosition(index - 1, takenPositions);
};

export const HierarchyUtil = {
  transformMultipleHierarchies,
  transformHierarchy,
  sortByCommissionLevelDesc,
  insertAgentProductAtPosition,
  getNextHighestPosition,
  getNextLowestPosition,
};
