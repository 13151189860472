import React from 'react';
import { Product } from '../../../../../../../service/service.types';
import { FlexDirection, NglicRadioGroup } from '@nglic/component-lib';
import { ProductsEnum } from '../../../products.enum';

export interface FuneralAmountOrAffordabilityProps {
  onClick: (value: 'Funeral Amount' | 'Affordability') => void;
  product?: Product;
  option?: 'Funeral Amount' | 'Affordability';
}

export const funeralAmountAffordabilityOptionVisibility = (
  product: Product | undefined,
): boolean => {
  return !product?.name.includes(ProductsEnum.ASSETGUARD2);
};

export const FuneralAmountOrAffordability: React.FC<FuneralAmountOrAffordabilityProps> =
  ({ product, onClick, option }) => {
    const funeralAmountorAffordability = [
      {
        label: 'Funeral Amount',
        onClick: () => onClick('Funeral Amount'),
        selected: option === 'Funeral Amount',
      },
      {
        label: 'Affordability',
        onClick: () => onClick('Affordability'),
        selected: option === 'Affordability',
      },
    ];

    return (
      <div>
        {funeralAmountAffordabilityOptionVisibility(product) && (
          <NglicRadioGroup
            radios={funeralAmountorAffordability}
            title="Funeral Amount or Affordability"
            radiosFlexDirection={FlexDirection.COLUMN}
          />
        )}
      </div>
    );
  };
