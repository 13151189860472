import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { AppState } from '../../../../rootReducer';
import { AgentService } from '../../../../service/AgentService';
import { Address } from '../../../../service/service.types';
import { loadComplete, loadStarted } from '../../../shared/loading.action';
import { getContractUserDemographics } from '../PersonalInformation/personalInformationSelector';
import { getPaymentData } from '../PaymentMethod/paymentMethod.selector';
import { getCredentials } from '../../../shared/selectors/getCredentials';
import { getUserInformation } from '../../../shared/selectors/getUserInformation';

export type ReviewActionType =
  | 'REVIEW_SIGN_CONTRACT'
  | 'REVIEW_SIGN_CONTRACT_FAILURE'
  | 'REVIEW_SIGN_CONTRACT_ERROR';

export interface ReviewSignContractAction extends Action<ReviewActionType> {
  type: 'REVIEW_SIGN_CONTRACT';
}

export interface ReviewSignContractFailureAction
  extends Action<ReviewActionType> {
  type: 'REVIEW_SIGN_CONTRACT_FAILURE';
  error: Error;
}

export interface ClearReviewSignContractErrorAction
  extends Action<ReviewActionType> {
  type: 'REVIEW_SIGN_CONTRACT_ERROR';
}

const createReviewSignContractFailureAction = (
  error: Error,
): ReviewSignContractFailureAction => ({
  type: 'REVIEW_SIGN_CONTRACT_FAILURE',
  error,
});

export const createClearReviewSignContractErrorAction =
  (): ClearReviewSignContractErrorAction => ({
    type: 'REVIEW_SIGN_CONTRACT_ERROR',
  });

export const signContract =
  (): ThunkAction<void, AppState, any, any> => async (dispatch, getState) => {
    dispatch(loadStarted('SIGN_CONTRACT'));

    const userInfo = getUserInformation(getState());
    const reserves = getState().presentation.contract.agentReservePercentages;

    if (!userInfo) {
      dispatch(
        createReviewSignContractFailureAction(
          new Error('basicInfo not initialized'),
        ),
      );
      dispatch(loadComplete('SIGN_CONTRACT'));
      return;
    }

    if (reserves === 'NOT_INITIALIZED') {
      dispatch(
        createReviewSignContractFailureAction(
          new Error('reserves not initialized'),
        ),
      );
      dispatch(loadComplete('SIGN_CONTRACT'));
      return;
    }

    const { agentId, branchCode, email, marketingPartnerId } = userInfo;

    const contractUserDemographics = getContractUserDemographics(getState());

    if (!contractUserDemographics) {
      dispatch(
        createReviewSignContractFailureAction(
          new Error('contractUserDemographics not initialized'),
        ),
      );
      dispatch(loadComplete('SIGN_CONTRACT'));
      return;
    }

    const {
      firstName,
      lastName,
      middleName,
      gender,
      birthday,
      ssn,
      phoneNumber,
      phoneNumberType,
      homeAddress,
      workAddress,
      // agentWorkingStates,
      // noMiddleName,
      communicationPreference,
    } = contractUserDemographics;

    const addresses: Address[] = [];
    let state = '';

    if (homeAddress) {
      addresses.push({
        ...homeAddress,
        type: 'HOME',
      });
      state = homeAddress.state;
    }

    if (workAddress) {
      addresses.push({
        ...workAddress,
        type: 'WORK',
      });
      if (state === '') {
        state = workAddress.state;
      }
    }
    const { backgroundData, willReceiveCopyOfBackgroundCheck } =
      getState().presentation.contract.background;

    const paymentMethod = getPaymentData(getState());
    const tokens = getCredentials(getState());

    if (!paymentMethod) {
      dispatch(
        createReviewSignContractFailureAction(
          new Error('paymentMethod not initialized'),
        ),
      );
      dispatch(loadComplete('SIGN_CONTRACT'));
      return;
    }

    const agent = await AgentService.getAgent(agentId, branchCode, tokens);

    const formatApprovingAgentName = (agent: {
      firstName: string;
      lastName: string;
      id3SearchName: string;
    }) => {
      if (agent.firstName && agent.lastName) {
        return `${agent.firstName} ${agent.lastName}`;
      } else if (agent.firstName) {
        return `${agent.firstName}`;
      } else if (agent.id3SearchName) {
        return `${agent.id3SearchName}`;
      }
      return 'N/A';
    };

    const promise = async (): Promise<Partial<{ success: boolean }>> => {
      return await AgentService.signContract(
        agentId,
        branchCode,
        {
          agentId,
          firstName,
          lastName,
          middleName,
          dob: birthday,
          sex: gender,
          ssn,
          state,
          email,
          branchCode,
          marketingPartnerId,
          addresses,
          phoneNumber,
          phoneNumberType,
          payment: paymentMethod,
          backgroundData,
          reserves,
          noMiddleName: !middleName,
          approvingAgentEmail: agent.approvingAgent?.email,
          approvingAgentName: formatApprovingAgentName(agent.approvingAgent),
          willReceiveCopyOfBackgroundCheck,
          agentWorkingStates: state,
          communicationPreference,
        },
        tokens,
      );
    };

    dispatch({
      types: [
        'SIGN_CONTRACT',
        'REVIEW_SIGN_CONTRACT',
        'REVIEW_SIGN_CONTRACT_FAILURE',
      ],
      promise,
      bypassGlobalError: false,
    });

    loadComplete('SIGN_CONTRACT');
  };
