import { ThunkAction } from 'redux-thunk';
import { AppState } from '../../rootReducer';
import { removeMasqueradeUserAction } from './basicUserInfo.action';
import { StorageItemKey } from '../../util/getItemInLocalStorageIfExists';
import { removeCredentials } from './credentials.action';
import { resetPolicies } from '../Pages/ManageBusiness/Policies/policies.action';
import { resetCommissions } from '../Pages/ManageBusiness/Commissions/commissions.action';

export const logoutAction =
  (): ThunkAction<void, AppState, any, any> => async (dispatch) => {
    localStorage.removeItem(StorageItemKey.MASQUERADE_UUID);
    localStorage.removeItem(StorageItemKey.COGNITO_ID_TOKEN);
    localStorage.removeItem(StorageItemKey.COGNITO_ACCESS_TOKEN);
    localStorage.removeItem(StorageItemKey.COGNITO_REFRESH_TOKEN);
    dispatch(removeMasqueradeUserAction());
    dispatch(removeCredentials());
  };

export const exitMasqueradeUserAction =
  (): ThunkAction<void, AppState, any, any> => async (dispatch) => {
    localStorage.removeItem(StorageItemKey.MASQUERADE_UUID);
    dispatch(removeMasqueradeUserAction());
    dispatch(resetPolicies());
    dispatch(resetCommissions());
  };
