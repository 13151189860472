import React from 'react';
import styles from './CorrespondenceTableHeader.module.scss';

export const CorrespondenceTableHeader: React.FC = () => {
  return (
    <div className={styles['root']}>
      <div className={styles['title-date']}>DATE</div>
      <div className={styles['title-document']}>DOCUMENT</div>
    </div>
  );
};
