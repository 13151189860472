import React from 'react';
import styles from './ActionLink.module.scss';
import { CircularProgress } from '@material-ui/core';
import classNames from 'classnames';

export interface ActionLinkProps {
  text: string;
  image: string;
  actionOnClick?: () => void;
  href?: string;
  ariaLabel?: string;
  loading?: boolean;
}

export const ActionLink: React.FC<ActionLinkProps> = ({
  text,
  image,
  actionOnClick,
  href,
  ariaLabel,
  loading,
}) => {
  return (
    <a
      href={href}
      onClick={!loading ? actionOnClick : undefined}
      role={'button'}
      aria-label={ariaLabel}
    >
      <div
        tabIndex={0}
        className={classNames(styles['link-item'], {
          [styles['loading']]: loading,
        })}
      >
        <div className={styles['icons']}>
          {loading ? (
            <CircularProgress size={30} />
          ) : (
            <img src={image} alt={`${text}-image`} />
          )}
        </div>
        {text}
      </div>
    </a>
  );
};
