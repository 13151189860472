import { OnboardingStatus, Role } from '../service/service.types';
import { intersection } from 'lodash';
import { isUserFuneralHome } from '@nglic/utilities-ts/build';

export const RESET_PASSWORD_ROUTE = '/resetpassword';

export const getRouteForContractingStatus = (
  contractingStatus: OnboardingStatus,
  roles: Role[] = [],
): string => {
  if (isUserFuneralHome(roles)) {
    return '/manage-business/policies';
  }
  if (contractingStatus === OnboardingStatus.COMPLETE || !contractingStatus) {
    if (isSupport(roles)) {
      return '/support';
    }

    if (
      isAdminOrContractManager(roles) &&
      (contractingStatus === OnboardingStatus.COMPLETE || !contractingStatus)
    ) {
      return '/agent';
    }
  }

  switch (contractingStatus) {
    case OnboardingStatus.NOT_STARTED:
    case OnboardingStatus.INVITED:
      return '/';
    case OnboardingStatus.PENDING:
      return '/contract/submitted';
    default:
      return '/profile';
  }
};

const isSupport = (roles) => {
  return roles.includes(Role.SUPPORT);
};

const isAdminOrContractManager = (roles) => {
  return intersection(roles, [Role.CONTRACT_MANAGERS, Role.ADMIN]).length > 0;
};
