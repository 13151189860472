import produce from 'immer';
import { Action } from 'redux';
import {
  EarlyPayOff,
  PolicyPayment,
} from '../../../../../../service/service.types';
import {
  FetchEarlyPayOffSuccessAction,
  FetchPaymentHistorySuccessAction,
  PaymentHistoryActionType,
} from './paymentHistory.action';

export interface PaymentHistoryState {
  policyPayments: PolicyPayment[] | undefined;
  earlyPayOffs: EarlyPayOff[] | undefined;
}

export const paymentHistoryReducer = (
  state: PaymentHistoryState = {
    policyPayments: undefined,
    earlyPayOffs: undefined,
  },
  action: Action<PaymentHistoryActionType>,
): PaymentHistoryState => {
  return produce(state, (draftState) => {
    switch (action.type) {
      case 'PAYMENT_HISTORY_FETCH__SUCCESS':
        draftState.policyPayments = (
          action as FetchPaymentHistorySuccessAction
        ).policyPayments;
        return draftState;
      case 'PAYMENT_HISTORY_FETCH_FAILURE':
        draftState.policyPayments = [];
        return draftState;
      case 'EARLY_PAYOFF_FETCH_SUCCESS':
        draftState.earlyPayOffs = (
          action as FetchEarlyPayOffSuccessAction
        ).earlyPayOffs;
        return draftState;
      case 'EARLY_PAYOFF_FETCH_FAILURE':
        draftState.earlyPayOffs = [];
        return draftState;
      default:
        return state;
    }
  });
};
