import styles from './RequestToken.module.scss';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import logo from '../../../../assets/img/logo/logo-ngl.png';
import logo2x from '../../../../assets/img/logo/logo-ngl@2x.png';
import logo3x from '../../../../assets/img/logo/logo-ngl@3x.png';
import { Link, PrimaryButton } from '@nglic/component-lib/build';
import { goToLoginSequence, requestMFAEmail } from '../login.action';
import { useHistory } from 'react-router-dom';

export const RequestToken: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [rememberDevice, setRememberDevice] = useState<boolean>(false);

  const goToLogin = () => {
    dispatch(goToLoginSequence('USERNAME_AND_PASSWORD'));
    history.replace('/login');
  };

  const requestToken = () => {
    dispatch(requestMFAEmail(rememberDevice));
  };

  const onRememberDeviceClick = () => {
    setRememberDevice(!rememberDevice);
  };

  return (
    <div className={styles['root']} data-testid={'request-token'}>
      <div className={styles['logo-container']}>
        <img
          className={styles['responsive-img']}
          srcSet={`${logo}, ${logo2x} 2x, ${logo3x} 3x`}
        />
      </div>
      <div className={styles['text-header']}>
        We need to make sure it's really you.
      </div>
      <div className={styles['text-content']}>
        NGL takes the security of your data very seriously. We have enabled
        two-factor authentication for your account as an enhanced security
        measure. To continue, click the button below to request a token. You
        will receive an email with a link to complete login.
      </div>

      <div className={styles['checkbox-container']}>
        <input type="checkbox" id="remember" onClick={onRememberDeviceClick} />
        <label htmlFor="remember">Remember this device for 90 days</label>
      </div>

      <div className={styles['button-container']}>
        <PrimaryButton text="Request Token" onClick={requestToken} />
      </div>

      <div className={styles['link-container']}>
        <Link text="Return to Login" onClick={goToLogin} />
      </div>
    </div>
  );
};
