import React from 'react';
import {
  formatDate,
  NglicModal,
  PrimaryButton,
  SecondaryButton,
} from '@nglic/component-lib';
import { IconButton } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { EarlyPayOff } from '../../../../../../service/service.types';

import styles from './EarlyPayOffModal.module.scss';

const currencyFormat = (numberString: string) => {
  const num = parseInt(numberString);
  return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};

export interface EarlyPayOffModalProps {
  isOpen: boolean;
  onClose?: () => void;
  earlyPayOff: EarlyPayOff;
}

export const EarlyPayOffModal: React.FC<EarlyPayOffModalProps> = ({
  isOpen,
  onClose,
  earlyPayOff,
}: EarlyPayOffModalProps) => {
  return (
    <NglicModal isOpened={isOpen}>
      <div className={styles['root']}>
        <div className={styles['header']}>
          <div className={styles['text']}>Early Payoff Quote</div>
          <IconButton className={styles['close-button']} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <div className={styles['horizontal-line']} />
        <div className={styles['text']}>
          The following is regarding the payoff balance on this policy as of{' '}
          {formatDate(earlyPayOff.quoteDate, 'MM/DD/YYYY')}.
          <br />
          <br />
          <strong>{currencyFormat(earlyPayOff.epoTotalPremium)}</strong> is due
          in full no later than{' '}
          <u>{formatDate(earlyPayOff.epoEffectiveDate, 'MM/DD/YYYY')}</u> to be
          considered paid in full. If payment is not received by this date, you
          will need to request a new payoff amount. <br />
          <br />
          If this policy is set up for Electronic Funds Transfer (EFT), payments
          will continue until pay off has been received and processed.
        </div>
        <div className={styles['horizontal-line']} />
        {/* <div className={styles['button-container']}>
          <SecondaryButton text={'Request New Quote'} />
          <PrimaryButton text={'Download Quote'} />
        </div> */}
      </div>
    </NglicModal>
  );
};
