import { combineReducers } from 'redux';

import {
  documentReducer,
  UploadSubPageState,
} from './Documents/documents.reducer';
import {
  paymentHistoryReducer,
  PaymentHistoryState,
} from './Policies/PolicyDetail/PaymentHistory/paymentHistory.reducer';
import {
  reportDataReducer,
  ReportDataState,
} from './Performance/ReportData/reportData.reducer';
import {
  reportFiltersReducer,
  ReportFiltersState,
} from './Performance/ReportData/reportFilters.reducer';
import {
  commissionActivitiesReducer,
  CommissionActivitiesState,
} from './Commissions/commissions.reducer';
import {
  CommissionDetailByPeriodInitState,
  commissionsDetailByPeriodReducer,
} from './Performance/ReportData/CommissionsDetailModal/commissionsDetailByPeriod.reducer';
import {
  ClaimData,
  createClaimReducer,
} from './Policies/PolicyDetail/ClaimsModal/claim.reducer';
import {
  rateCalculatorReducer,
  RateCalculatorState,
} from './RateCalculator/rateCalculator.reducer';

export type ManageBusinessVisibilityState = boolean;

export interface ManageBusinessState {
  documents: UploadSubPageState;
  paymentHistory: PaymentHistoryState;
  reportData: ReportDataState;
  commissions: CommissionActivitiesState;
  reportFilters: ReportFiltersState;
  commissionDetailByPeriod: CommissionDetailByPeriodInitState;
  policyClaim: ClaimData;
  rateCalculator: RateCalculatorState;
}

export const manageBusinessReducer = combineReducers({
  documents: documentReducer,
  paymentHistory: paymentHistoryReducer,
  reportData: reportDataReducer,
  reportFilters: reportFiltersReducer,
  commissions: commissionActivitiesReducer,
  commissionDetailByPeriod: commissionsDetailByPeriodReducer,
  policyClaim: createClaimReducer,
  rateCalculator: rateCalculatorReducer,
});
