import { ThunkAction } from 'redux-thunk';
import { AppState } from '../../../rootReducer';
import { Action } from 'redux';
import { loadComplete, loadStarted } from '../../shared/loading.action';

import { User } from '../../../service/service.types';
import { getCredentials } from '../../shared/selectors/getCredentials';
import { isUserInfo } from '../../shared/selectors/basicUserInfo.selector';
import { UserService } from '../../../service/UserService';
import { getUserInformation } from '../../shared/selectors/getUserInformation';

export type UsersActionType =
  | 'USERS_FETCH_SUCCESS'
  | 'USERS_FETCH_FAILURE'
  | 'NONE';

export interface FetchAllUsersSuccessAction extends Action<UsersActionType> {
  users: User[];
  totalUsers: number;
}

export interface FetchAllUsersFailureAction extends Action<UsersActionType> {
  error: Error;
}

interface FetchUsersParams {
  page: number;
  limit: number;
  query?: string;
}

export const fetchUsers =
  ({
    page = 1,
    limit = 5,
    query,
  }: FetchUsersParams): ThunkAction<void, AppState, any, any> =>
  async (dispatch, getState: () => AppState) => {
    dispatch(loadStarted('USERS_FETCH_ALL'));
    const tokens = getCredentials(getState());
    const userInfo = getUserInformation(getState());

    if (!isUserInfo(userInfo)) {
      dispatch({
        type: 'USERS_FETCH_FAILURE',
        error: new Error('No User information present'),
      });
      dispatch(loadComplete('USERS_FETCH_ALL'));

      return;
    }

    try {
      const { users, totalUsers } = await UserService.getUsersAsSupportUser(
        query ?? '',
        limit,
        page,
        tokens,
      );
      dispatch({
        type: 'USERS_FETCH_SUCCESS',
        users,
        totalUsers,
      });
    } catch (error) {
      dispatch({
        type: 'USERS_FETCH_FAILURE',
        error,
      });
    }

    dispatch(loadComplete('USERS_FETCH_ALL'));
  };
