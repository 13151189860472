import { combineReducers } from 'redux';
import {
  AddAgentHierarchyState,
  hierarchyReducer,
} from './Hierarchy/hierarchy.reducer';
import { reservesReducer, ReservesState } from './Reserves/reserves.reducer';
import {
  agentInformationReducer,
  AgentInformationState,
} from './AgentInformation/agentInformation.reducer';
import { approverReducer } from './Review/Approval/approver.reducer';
import { reviewAddAgentReducer, ReviewState } from './Review/review.reducer';
import { commentReducer, CommentState } from './Hierarchy/comment.reducer';

export interface ApproverState {
  approverId: string;
  approverName: string;
  approverEmail: string;
}

export interface AddAgentState {
  approver: ApproverState;
  generalInformation: AgentInformationState;
  hierarchy: AddAgentHierarchyState;
  reserves: ReservesState;
  reviewAddAgent: ReviewState;
  comment: CommentState;
}

export const addAgentReducer = combineReducers({
  approver: approverReducer,
  generalInformation: agentInformationReducer,
  hierarchy: hierarchyReducer,
  reserves: reservesReducer,
  reviewAddAgent: reviewAddAgentReducer,
  comment: commentReducer,
});
