import React from 'react';
import styles from './QuoteTable.module.scss';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  withStyles,
} from '@material-ui/core';

export interface RatePlanItem {
  plan: string;
  faceAmount: string;
  multiPayPremium: string;
  funeralAmount: string;
  totalDueWithApp: string;
}

export interface QuoteTableProps {
  data: RatePlanItem[] | undefined;
}

const CustomTableCell = withStyles({
  root: {
    padding: '10px',
    fontSize: '14px',
    textAlign: 'center',
  },
  head: {
    fontSize: '12px',
    fontWeight: 'bold',
    borderBottom: '3px solid #48AAD0',
    letterSpacing: '1.5px',
  },
})(TableCell);

const CustomLeftHeaderTableCell = withStyles({
  body: {
    fontWeight: 'bold',
    fontSize: '16px',
    padding: '10px 10px 10px 20px',
  },
})(TableCell);
export const formatUSD = (value: string): string => {
  if (Number(value)) {
    const newStr = value.replace(/,/g, '');
    return '$' + newStr.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  return value;
};
export const QuoteTable: React.FC<QuoteTableProps> = ({ data }) => {
  return (
    <div className={styles['root']} data-testid={'quote-table'}>
      <div className={styles['table']}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <CustomTableCell />
                <CustomTableCell>FUNERAL COST</CustomTableCell>
                <CustomTableCell>FACE AMOUNT</CustomTableCell>
                <CustomTableCell>PREMIUM</CustomTableCell>
                <CustomTableCell>TOTAL DUE WITH APP</CustomTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((ratePlan, idx) => {
                return (
                  <TableRow className={styles['custom-table-row']} key={idx}>
                    <CustomLeftHeaderTableCell>
                      {ratePlan.plan}
                    </CustomLeftHeaderTableCell>
                    <CustomTableCell>
                      {formatUSD(ratePlan.funeralAmount.toString())}
                    </CustomTableCell>
                    <CustomTableCell>
                      {formatUSD(ratePlan.faceAmount.toString())}
                    </CustomTableCell>
                    <CustomTableCell>
                      {formatUSD(ratePlan.multiPayPremium.toString())}
                    </CustomTableCell>
                    <CustomTableCell>
                      {formatUSD(ratePlan.totalDueWithApp.toString())}
                    </CustomTableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};
