import React from 'react';
import styles from './PreneedPolicyInformation.module.scss';
import { PolicyInformationRow } from '../PolicyInformationRow/PolicyInformationRow';
import {
  CellColor,
  CellSize,
  PolicyInformationCell,
  ValueDisplayType,
} from '../PolicyInformationCell/PolicyInformationCell';
import { formatNumberUSD } from '@nglic/utilities-ts/build';
import { calculateAge, substringDate } from '../PolicyInformationTransform';
import { Policy } from '../../../../../../../service/service.types';

export const PreneedPolicyInformation: React.FC<{ policy?: Policy }> = ({
  policy,
}) => {
  return (
    <div className={styles['root']}>
      <PolicyInformationRow>
        <PolicyInformationCell
          header="POLICY NUMBER"
          value={policy?.policyNumber}
        />
        <PolicyInformationCell
          header="ISSUED DATE"
          value={substringDate(policy?.effectiveDate)}
        />
        <PolicyInformationCell header="" value="" />
        <PolicyInformationCell
          header="EARLY PAY OFF"
          value={formatNumberUSD(policy?.earlyPayOff)}
        />
        <PolicyInformationCell header="" value="" />
      </PolicyInformationRow>
      <PolicyInformationRow>
        <PolicyInformationCell
          header="DATE OF BIRTH"
          value={substringDate(policy?.insured?.dob)}
          cellSize={CellSize.ThreeQuarters}
        />
        <PolicyInformationCell
          header="AGE"
          value={calculateAge(policy?.insured?.dob)}
          cellSize={CellSize.Quarter}
        />
        <PolicyInformationCell
          header="BASE CASH VALUE"
          value={formatNumberUSD(policy?.baseCashValue)}
        />
        <PolicyInformationCell
          header="LOAN BALANCE"
          value={formatNumberUSD(policy?.loanBalance)}
        />
        <PolicyInformationCell
          header="SURRENDER CHARGE"
          value={formatNumberUSD(policy?.surrenderCharge)}
        />
        <PolicyInformationCell
          header="NET SURRENDER VALUE"
          value={formatNumberUSD(policy?.netSurrenderValue)}
        />
      </PolicyInformationRow>
      <PolicyInformationRow>
        <PolicyInformationCell
          header="CURRENT VALUE"
          value={formatNumberUSD(policy?.currentPolicyValue)}
          cellColor={CellColor.Grey}
        />
        <PolicyInformationCell
          header="OWNER"
          value={policy?.policyOwner?.displayName}
          cellSize={CellSize.Double}
          valueDisplayType={ValueDisplayType.Bold}
        />
        <PolicyInformationCell
          header="FUNERAL PROVIDER"
          value={policy?.funeralHomeName}
          cellSize={CellSize.Double}
          valueDisplayType={ValueDisplayType.Bold}
        />
      </PolicyInformationRow>
      <PolicyInformationRow>
        <PolicyInformationCell
          header="FACE VALUE"
          value={formatNumberUSD(policy?.faceValue)}
          cellColor={CellColor.Grey}
        />
        <PolicyInformationCell
          header="TRUST"
          value={policy?.trust?.displayName}
          cellSize={CellSize.Double}
          valueDisplayType={ValueDisplayType.Bold}
        />
        <PolicyInformationCell
          header="BENEFICIARY"
          value={policy?.beneficiary?.displayName}
          cellSize={CellSize.Double}
          valueDisplayType={ValueDisplayType.Bold}
        />
      </PolicyInformationRow>
    </div>
  );
};
