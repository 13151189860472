import React, { useEffect, useState } from 'react';
import styles from './Review.module.scss';
import { TitleWithButtonInParallel } from '../../../Contract/Review/TitleWithButtonInParallel';
import { PrimaryButton } from '@nglic/component-lib/build';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../../../rootReducer';
import { KeyValueTextItem } from '../../../Contract/Review/KeyValueTextItem';
import {
  AgentProducer,
  AgentProduct,
  Product,
  Reserves,
} from '../../../../../service/service.types';
import {
  AddAgentHierarchyState,
  HierarchyUpdate,
} from '../Hierarchy/hierarchy.reducer';
import { GeneralAgentInformation } from '../AgentInformation/agentInformation.reducer';
import { addAgentRoutes } from '../AddAgent';
import { Approval } from './Approval/Approval';
import { addApprover } from './Approval/approver.action';
import { ReservesReview } from '../../../ReservesReview/ReservesReview';
import { addAgent } from './review.action';
import { clearAddAgentData } from '../addagent.action';
import { uniqueId } from 'lodash';
import { CommentState } from '../Hierarchy/comment.reducer';

type ProductListProps = {
  agents: AgentProduct[];
  product?: Product;
  uplineAgent?: AgentProducer;
};
export const Review: React.FC<{
  routeTo: (route: string) => void;
  routeToNext?: () => void;
}> = ({ routeToNext, routeTo }) => {
  const agentInfo: GeneralAgentInformation | undefined = useSelector(
    (state: AppState) =>
      state.presentation.addAgent.generalInformation !== 'INCOMPLETE'
        ? state.presentation.addAgent.generalInformation
        : undefined,
  );

  const reserves: Reserves | undefined = useSelector((state: AppState) =>
    state.presentation.addAgent.reserves !== 'INCOMPLETE'
      ? state.presentation.addAgent.reserves
      : undefined,
  );

  const hierarchyData: AddAgentHierarchyState = useSelector(
    (state: AppState) => state.presentation.addAgent.hierarchy,
  );

  const globalError = useSelector(
    (state: AppState) => state.presentation.error.error,
  );

  const addAgentState = useSelector(
    (data: AppState) => data.presentation.addAgent,
  );

  const comment: CommentState | '' = useSelector((state: AppState) =>
    state.presentation.addAgent.comment.comment !== ''
      ? state.presentation.addAgent.comment
      : '',
  );

  const renderProductsHierarchy = (data: AddAgentHierarchyState) =>
    Object.values(data).reduce<React.ReactElement[]>(
      (acc, hierarchyUpdates) => {
        const allHierarchiesInSection = Object.values(
          hierarchyUpdates.newAgentHierarchies,
        ).map((agents) => (
          <CommissionHierarchyReview
            agents={agents.updatedHierarchy}
            uplineAgent={hierarchyUpdates.searchParams.agent}
            product={hierarchyUpdates.searchParams.product}
          />
        ));
        return [...acc, ...allHierarchiesInSection];
      },
      [],
    );

  const CommissionHierarchyReview = ({
    agents,
    product,
    uplineAgent,
  }: ProductListProps) => {
    if (!agents || agents.length === 0) {
      return null;
    }

    const uplineAgentIndex = agents.findIndex(
      (agent) => agent.producerId === uplineAgent?.producerId,
    );
    if (uplineAgentIndex < 0) {
      return null;
    }

    const slicedArray = agents.slice(uplineAgentIndex);
    return (
      <div className={styles['product-list-container']}>
        <p className={styles['product-name']}>{product?.name}</p>
        {slicedArray.length > 0 &&
          slicedArray.map((agent) => (
            <KeyValueTextItem
              ariaLabel={`${agent?.agentName}-${agent?.commissionLevel}`}
              key={agent?.commissionLevel + uniqueId()}
              strongText={agent?.agentName}
              value={'Level ' + agent?.commissionLevel}
            />
          ))}
      </div>
    );
  };

  const approverData = useSelector(
    (state: AppState) => state.presentation.addAgent.approver,
  );

  const [hasAuthority, setHasAuthority] = useState<boolean>();
  const [submitting, setSubmitting] = useState<boolean>(true);
  const [disabled, setDisabled] = useState<boolean>(true);

  const handleSubmission = () => {
    const hierarchy = Object.values(hierarchyData).reduce<HierarchyUpdate[]>(
      (acc, next) => {
        return [
          ...acc,
          ...Object.values<HierarchyUpdate>(next.newAgentHierarchies),
        ];
      },
      [],
    );
    if (agentInfo && reserves) {
      setSubmitting(true);
      dispatch(
        addAgent(
          agentInfo,
          hierarchy,
          reserves,
          approverData.approverId,
          comment ? comment.comment : '',
        ),
      );
    }
  };

  useEffect(() => {
    if (globalError === null) {
      setSubmitting(false);
    }
  }, [globalError]);

  const dispatch = useDispatch();

  const handleOnclickApprover = (value) => {
    if (value === 'Yes') {
      setHasAuthority(true);
      dispatch(addApprover(undefined, undefined, undefined));
    }
    if (value === 'No') {
      setHasAuthority(false);
      setDisabled(true);
    }
  };

  useEffect(() => {
    if (hasAuthority === undefined) {
      setDisabled(true);
    } else if (hasAuthority || approverData.approverId !== undefined) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [hasAuthority, approverData]);

  useEffect(() => {
    if (addAgentState.reviewAddAgent !== 'INCOMPLETE') {
      if (addAgentState.reviewAddAgent.error) {
        setSubmitting(false);
      } else if (addAgentState.reviewAddAgent.submitted) {
        setSubmitting(false);
        dispatch(clearAddAgentData());
        routeToNext && routeToNext();
      }
    }
  }, [addAgentState]);
  return (
    <div data-testid={'review'} className={styles['container']}>
      <div className={styles['agent-information']}>
        <TitleWithButtonInParallel
          title="Agent Information"
          onButtonClick={() => {
            routeTo(addAgentRoutes.GENERAL_INFO);
          }}
        />
        {agentInfo && (
          <React.Fragment>
            <KeyValueTextItem
              strongText="Name"
              value={`${agentInfo.firstName} ${agentInfo.lastName}`}
            />
            <KeyValueTextItem strongText="Email" value={agentInfo?.email} />
          </React.Fragment>
        )}
      </div>
      <div className={styles['commission-hierarchies']}>
        <TitleWithButtonInParallel
          title="Commission Hierarchies"
          onButtonClick={() => routeTo(addAgentRoutes.HIERARCHY)}
        />
        <div className={styles['large-section']}>
          {renderProductsHierarchy(addAgentState.hierarchy)}
        </div>
      </div>
      <div className={styles['reserves']}>
        <TitleWithButtonInParallel
          title="Reserves"
          onButtonClick={() => routeTo(addAgentRoutes.RESERVES)}
        />
        <div>
          <ReservesReview reserves={reserves} />
        </div>
      </div>
      <div className={styles['footer']}>
        <div className={styles['approval']}>
          <Approval onClick={(event, value) => handleOnclickApprover(value)} />
        </div>
        <div className={styles['button-container']}>
          <PrimaryButton
            text="Invite Agent"
            disabled={disabled || submitting}
            loading={submitting}
            onClick={!submitting ? handleSubmission : undefined}
          />
        </div>
        <div className={styles['spam-message']}>
          NOTE: Invitation email may land in recipient's spam folder
        </div>
      </div>
    </div>
  );
};
