import {
  CredentialsActionType,
  setCredentials,
} from '../../shared/credentials.action';
import {
  ConfirmForgotPasswordCreds,
  Credentials,
  isAccountLockedResponse,
  isCustomChallengeResponse,
  isNewPasswordRequiredResponse,
  isSuccessfulAuthResponse,
  TempPasswordCreds,
} from '../../../service/service.types';
import { ThunkAction } from 'redux-thunk';
import { AppState } from '../../../rootReducer';
import { Action } from 'redux';
import { loadComplete, loadStarted } from '../../shared/loading.action';
import { LoginType } from './login.reducer';
import { CredentialsService } from '../../../service/CredentialsService';
import {
  getItemInLocalStorageIfExists,
  StorageItemKey,
} from '../../../util/getItemInLocalStorageIfExists';
import getUuidByString from 'uuid-by-string';

export type LoginActionType =
  | 'RESET_PASSWORD_REQUIRED'
  | 'CHECK_YOUR_EMAIL'
  | 'CUSTOM_CHALLENGE_REQUIRED'
  | 'CUSTOM_CHALLENGE_RESPONSE'
  | 'CUSTOM_CHALLENGE_FAILURE'
  | 'NONE'
  | 'LOGIN_FAILURE'
  | 'LOGIN_SUCCESS'
  | 'RESET_TEMP_PASSWORD_FAILURE'
  | 'RESET_TEMP_PASSWORD_SUCCESS'
  | 'FORGOT_PASSWORD'
  | 'RESET_PASSWORD_SUCCESS'
  | 'RESET_PASSWORD_FAILURE'
  | 'GEN_PASSWORD_RESET_CODE_SUCCESS'
  | 'GEN_PASSWORD_RESET_CODE_FAILURE'
  | 'CLEAR_PASSWORD_ERROR'
  | 'GO_TO_LOGIN_SEQUENCE';

export interface CheckYourEmailAction extends Action<LoginActionType> {
  type: 'CHECK_YOUR_EMAIL';
  session: string;
  username: string;
}

export const checkYourEmail = (
  username: string,
  session: string,
): CheckYourEmailAction => ({
  type: 'CHECK_YOUR_EMAIL',
  session,
  username,
});

export interface CustomChallengeRequiredAction extends Action<LoginActionType> {
  type: 'CUSTOM_CHALLENGE_REQUIRED';
  session: string;
  username: string;
}

export const customChallengeRequired = (
  username: string,
  session: string,
): CustomChallengeRequiredAction => ({
  type: 'CUSTOM_CHALLENGE_REQUIRED',
  session,
  username,
});

export interface ResetPasswordRequiredAction extends Action<LoginActionType> {
  session: string;
  username: string;
}

export const resetPasswordRequired = (
  username: string,
  session: string,
): ResetPasswordRequiredAction => ({
  type: 'RESET_PASSWORD_REQUIRED',
  session,
  username,
});

export interface LoginError extends Action<LoginActionType> {
  errorMessage?: string;
  accountLocked: boolean;
  password: string;
  username: string;
}

export const loginFailure = (
  errorMessage?: string,
  accountLocked = false,
  username = '',
  password = '',
): LoginError => ({
  type: 'LOGIN_FAILURE',
  errorMessage,
  accountLocked,
  password,
  username,
});

export const loginSuccess = (): Action<LoginActionType> => ({
  type: 'LOGIN_SUCCESS',
});

export const resetTempPasswordSuccess = (): Action<LoginActionType> => ({
  type: 'RESET_TEMP_PASSWORD_SUCCESS',
});

export const resetPasswordSuccess = (): Action<LoginActionType> => ({
  type: 'RESET_PASSWORD_SUCCESS',
});

export const resetPasswordFailure = (): Action<LoginActionType> => ({
  type: 'RESET_PASSWORD_FAILURE',
});

export interface GenPasswordResetCodeSuccess extends Action<LoginActionType> {
  username: string;
}

export const genPasswordResetCodeSuccess = (
  username: string,
): GenPasswordResetCodeSuccess => ({
  type: 'GEN_PASSWORD_RESET_CODE_SUCCESS',
  username,
});

export const genPasswordResetCodeFailure = (): Action<LoginActionType> => ({
  type: 'GEN_PASSWORD_RESET_CODE_FAILURE',
});

export const clearPasswordError = (): Action<LoginActionType> => ({
  type: 'CLEAR_PASSWORD_ERROR',
});

export interface GoToLoginSequenceAction extends Action<LoginActionType> {
  sequence: LoginType;
}

const deviceInformation = [
  navigator.platform,
  navigator.userAgent,
  navigator.appVersion,
  navigator.vendor,
];

const deviceKey = getUuidByString(JSON.stringify(deviceInformation));

export const goToLoginSequence = (
  sequence: LoginType,
): GoToLoginSequenceAction => ({
  type: 'GO_TO_LOGIN_SEQUENCE',
  sequence,
});

export const resetTempPasswordFailure = (
  errorMessage?: string,
  accountLocked = false,
  username = '',
  password = '',
): LoginError => ({
  type: 'RESET_TEMP_PASSWORD_FAILURE',
  errorMessage,
  accountLocked,
  password,
  username,
});

export const customChallengeFailure = (
  errorMessage?: string,
  accountLocked = false,
  username = '',
  password = '',
): LoginError => ({
  type: 'CUSTOM_CHALLENGE_FAILURE',
  errorMessage,
  accountLocked,
  password,
  username,
});

export const resetPassword =
  (creds: ConfirmForgotPasswordCreds): ThunkAction<void, AppState, any, any> =>
  async (dispatch) => {
    dispatch(loadStarted('RESET_PASSWORD'));
    try {
      await CredentialsService.resetPassword(creds);
      dispatch(resetPasswordSuccess());
    } catch (e) {
      dispatch(resetPasswordFailure());
    }

    dispatch(loadComplete('RESET_PASSWORD'));
  };

export const login =
  (creds: Credentials): ThunkAction<void, AppState, any, any> =>
  async (dispatch) => {
    dispatch(loadStarted('LOGIN_SUBMISSION'));
    const res = await CredentialsService.login(creds, {
      deviceInformation: deviceKey,
    });
    if (isSuccessfulAuthResponse(res)) {
      const { idToken, refreshToken, accessToken } = res;
      dispatch(setCredentials(accessToken, idToken, refreshToken));
      dispatch(loginSuccess());
    } else if (isCustomChallengeResponse(res)) {
      dispatch(customChallengeRequired(creds.username, res.session));
    } else if (isNewPasswordRequiredResponse(res)) {
      dispatch(resetPasswordRequired(creds.username, res.session));
    } else if (isAccountLockedResponse(res)) {
      dispatch(loginFailure(res.message, true, creds.username, creds.password));
    } else {
      dispatch(
        loginFailure(res.message, false, creds.username, creds.password),
      );
    }
    dispatch(loadComplete('LOGIN_SUBMISSION'));
  };

export const requestMFAEmail =
  (rememberDevice: boolean): ThunkAction<void, AppState, any, any> =>
  async (dispatch) => {
    dispatch(loadStarted('LOGIN_SUBMISSION'));
    const session = getItemInLocalStorageIfExists(
      StorageItemKey.MFA_LOGIN_SESSION,
    );
    const username = getItemInLocalStorageIfExists(
      StorageItemKey.MFA_LOGIN_USERNAME,
    );
    if (rememberDevice) {
      localStorage.setItem(StorageItemKey.MFA_DEVICE_INFORMATION, deviceKey);
    }
    const res = await CredentialsService.requestMFAEmail({
      session,
      username,
    });

    if (isCustomChallengeResponse(res)) {
      dispatch(checkYourEmail(username, res.session));
    } else {
      dispatch(customChallengeFailure());
    }

    dispatch(loadComplete('LOGIN_SUBMISSION'));
  };

export const completeMFA =
  (code: string): ThunkAction<void, AppState, any, any> =>
  async (dispatch) => {
    dispatch(loadStarted('LOGIN_SUBMISSION'));
    const session = getItemInLocalStorageIfExists(
      StorageItemKey.MFA_LOGIN_SESSION,
    );
    const username = getItemInLocalStorageIfExists(
      StorageItemKey.MFA_LOGIN_USERNAME,
    );
    const deviceKey = getItemInLocalStorageIfExists(
      StorageItemKey.MFA_DEVICE_INFORMATION,
    );

    const res = await CredentialsService.respondToCustomChallenge(
      {
        session,
        code,
        username,
      },
      deviceKey !== '' ? { deviceInformation: deviceKey } : undefined,
    );
    if (isSuccessfulAuthResponse(res)) {
      const { idToken, refreshToken, accessToken } = res;
      dispatch(setCredentials(accessToken, idToken, refreshToken));
      dispatch(loginSuccess());
    } else {
      dispatch(customChallengeFailure());
    }
    dispatch(loadComplete('LOGIN_SUBMISSION'));
  };

export const resetTempPassword =
  (
    creds: TempPasswordCreds,
  ): ThunkAction<
    void,
    AppState,
    any,
    Action<CredentialsActionType | LoginActionType>
  > =>
  async (dispatch) => {
    const res = await CredentialsService.resetTempPassword(creds);
    if (isSuccessfulAuthResponse(res)) {
      const { idToken, refreshToken, accessToken } = res;
      dispatch(setCredentials(accessToken, idToken, refreshToken));
      dispatch(resetTempPasswordSuccess());
      return;
    }

    dispatch(resetTempPasswordFailure());
  };

export const genPasswordResetCode =
  (email: string): ThunkAction<void, AppState, any, any> =>
  async (dispatch) => {
    dispatch(loadStarted('GEN_PASSWORD_RESET_CODE'));
    try {
      await CredentialsService.generatePasswordResetCode(email);
      dispatch(genPasswordResetCodeSuccess(email));
    } catch (e) {
      dispatch(genPasswordResetCodeFailure());
    } finally {
      dispatch(loadComplete('GEN_PASSWORD_RESET_CODE'));
    }
  };
