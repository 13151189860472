export interface ReviewData {
  submitted: boolean;
  error?: Error;
}

export interface AgentInformation {
  name: string;
  dateOfBirth: string;
  gender: string;
  socialSecurityNumber: string;
  phoneNumber: string;
  homeAddress: string;
  workAddress: string;
}
export interface PaymentMethodDetails {
  paymentMethod: string;
  bank: string;
  accountType: string;
  accountNumber: string;
  routingNumber: string;
  paymentFrequency: string;
}

export enum MixedField {
  WORK_ADDRESS = 'workAddress',
  SSN = 'socialSecurityNumber',
  SINGLE_PAY = 'singlePay',
  CREDIT_CARD = 'creditCard',
  RENEWAL = 'renewal',
  EFT = 'eft',
  DIRECT_BILL = 'directBill',
  MIN_BALANCE = 'minAmount',
  MAX_BALANCE = 'maxAmount',
  PHONE_NUMBER = 'phoneNumber',
}
