import styles from './ResetPasswordConfirmation.module.scss';
import React from 'react';
import { useDispatch } from 'react-redux';
import logo from '../../../../assets/img/logo/logo-ngl.png';
import logo2x from '../../../../assets/img/logo/logo-ngl@2x.png';
import logo3x from '../../../../assets/img/logo/logo-ngl@3x.png';
import { goToLoginSequence } from '../login.action';
import { Link } from '@nglic/component-lib/build';
import { useHistory } from 'react-router-dom';

export const ResetPasswordConfirmation: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const goToLogin = (): void => {
    dispatch(goToLoginSequence('USERNAME_AND_PASSWORD'));
    history.replace('/login');
  };

  return (
    <div className={styles['root']} data-testid={'reset-password-confirmation'}>
      <div>
        <div className={styles['logo-container']}>
          <img
            className={styles['responsive-img']}
            srcSet={`${logo}, ${logo2x} 2x, ${logo3x} 3x`}
          />
          <div className={styles['text-content']}>
            Your password has been reset succesfully.
          </div>
        </div>
        <div className={styles['link-container']}>
          <Link
            arialLabel={'forgot-password-link'}
            text={'Back to Login'}
            onClick={goToLogin}
          />
        </div>
      </div>
    </div>
  );
};
