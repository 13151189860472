import React, { useEffect } from 'react';
import styles from './Review.module.scss';
import { PrimaryButton } from '@nglic/component-lib/build';
import CheckIcon from '@material-ui/icons/Check';
import { withTracker } from 'ga-4-react';
import { useDispatch, useSelector } from 'react-redux';
import { TitleWithButtonInParallel } from './TitleWithButtonInParallel';
import { KeyValueTextItem } from './KeyValueTextItem';
import {
  AgentInformation,
  MixedField,
  PaymentMethodDetails,
} from './Review.types';
import {
  capitalizeFirstLetter,
  maskSSN,
  objToStringV2,
  maskPhoneNumber,
} from '@nglic/utilities-ts/build';
import { AppState } from '../../../../rootReducer';
import { PaymentData } from '../PaymentMethod/PaymentMethod.types';
import { Reserves, DocumentType } from '../../../../service/service.types';
import { BasicInfoState } from '../../../shared/basicUserInfo.reducer';
import {
  createClearReviewSignContractErrorAction,
  signContract,
} from './review.action';
import { SectionName } from '../../../shared/loading.action';
import { contractRoutes } from '../ContractPage';
import { ReservesReview } from '../../ReservesReview/ReservesReview';
import { fetchAgentReservePercentages } from './reserves/reserves.action';
import { PaymentMethod } from '../PaymentMethod/PaymentMethod';
import { getUserInformation } from '../../../shared/selectors/getUserInformation';

export interface Item {
  key: string;
  value: string;
}

const documentTypeToString = (type: DocumentType) => {
  switch (type) {
    case DocumentType.ANTI_MONEY_LAUNDERING_TRAINING:
      return 'Anti-Money Laundering Certificate';
    case DocumentType.STATE_LICENSE:
      return 'State License';
    case DocumentType.TRAINING_DOCUMENT:
      return 'Training Document';
    case DocumentType.VOIDED_CHECK:
      return 'VOIDED_CHECK';
    case DocumentType.OTHER:
      return 'OTHER';
  }
};

export const Review: React.FC<{
  routeTo: (route: string) => void;
}> = ({ routeTo }) => {
  const dispatch = useDispatch();
  const [agentInformation, setPersonalInformation] =
    React.useState<AgentInformation | undefined>(undefined);
  const [paymentDetails, setPaymentDetails] =
    React.useState<PaymentMethodDetails | undefined>(undefined);
  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const data = useSelector((data: AppState) => data.presentation.contract);

  const basicInfo = useSelector((state: AppState) => getUserInformation(state));
  const loadingSections: SectionName[] = useSelector(
    (state: AppState) => state.presentation.loading.sectionsLoading,
  );
  const documentTypes: DocumentType[] = useSelector((data: AppState) => {
    const documents = data.presentation.contract.documents.documents;
    const types = new Set(documents.map((document) => document.type));
    return [...types];
  });

  const reserveAccount = useSelector(
    (state: AppState) => state.presentation.contract.agentReservePercentages,
  );

  useEffect(() => {
    if (basicInfo) {
      dispatch(fetchAgentReservePercentages());
    }
  }, [basicInfo]);

  useEffect(() => {
    setSubmitting(loadingSections.includes('SIGN_CONTRACT'));
  }, [loadingSections]);

  useEffect(() => {
    if (data.personalInformation !== 'INCOMPLETE') {
      const { homeAddress, workAddress } = data.personalInformation;
      setPersonalInformation({
        name:
          data.personalInformation.firstName +
          ' ' +
          data.personalInformation.middleName +
          ' ' +
          data.personalInformation.lastName,
        dateOfBirth: data.personalInformation.birthday,
        gender: data.personalInformation.gender,
        socialSecurityNumber: data.personalInformation.ssn,
        phoneNumber: data.personalInformation.phoneNumber,
        homeAddress: homeAddress ? objToStringV2(homeAddress) : '',
        workAddress: workAddress ? objToStringV2(workAddress) : '',
      });
      const paymentInfo = data.paymentMethod as PaymentData;

      if (paymentInfo !== null) {
        setPaymentDetails({
          paymentMethod:
            paymentInfo.paymentType === 'CHECK'
              ? 'Bi-Weekly by check'
              : 'Direct Deposit',
          bank: paymentInfo.bankName,
          accountType: paymentInfo.accountType,
          routingNumber: paymentInfo.routingNumber,
          accountNumber: paymentInfo.accountNumber,
          paymentFrequency: paymentInfo.paymentFrequency,
        });
      }
    }

    if (data.review !== 'INCOMPLETE') {
      if (data.review.error) {
        dispatch(createClearReviewSignContractErrorAction());
      } else if (data.review.submitted) {
        routeTo('/contract/submitted');
      }
    }
  }, [data]);

  const renderKeyValueItems = (
    object: AgentInformation | PaymentMethodDetails | Reserves | undefined,
  ) => {
    const data: Array<Item> = [];
    let styledValue;
    if (object) {
      for (const [key, value] of Object.entries(object)) {
        switch (key) {
          case MixedField.SSN:
            styledValue = maskSSN(value);
            break;
          case MixedField.MIN_BALANCE:
          case MixedField.MAX_BALANCE:
            styledValue = `$${value}`;
            break;
          case MixedField.PHONE_NUMBER:
            styledValue = maskPhoneNumber(value);
            break;
          default:
            styledValue = value;
        }
        const keyRegex = key.replace(/([a-z])([A-Z])/g, '$1 $2');
        data.push({
          key: capitalizeFirstLetter(keyRegex),
          value: styledValue,
        });
      }
    }
    return <ItemList data={data} />;
  };

  const ItemList = ({ data }) => (
    <div>
      {data.map((item) => (
        <KeyValueTextItem
          key={item.key}
          strongText={item.key}
          value={item.value}
        />
      ))}
    </div>
  );
  const handleOnSubmit = () => {
    dispatch(signContract());
  };
  return (
    <div className={styles['review']} data-testid="review">
      <div className={styles['desktop-container']}>
        <div className={styles['header-container']}>
          <TitleWithButtonInParallel
            title="Agent Information"
            removeBorder={false}
            onButtonClick={() => routeTo(contractRoutes.PERSONAL_INFORMATION)}
          />
        </div>
        <div className={styles['large-section']}>
          <div className={styles['change-flex-direction']}>
            <div className={styles['column']}>
              {renderKeyValueItems(agentInformation)}
            </div>
          </div>
        </div>
        <TitleWithButtonInParallel title="Commissions" removeButton />
        <div className={styles['large-section']}>
          <div className={styles['divider']}>
            <TitleWithButtonInParallel
              title="Direct Deposit"
              removeBorder
              decreaseSpaceBetween
              onButtonClick={() => routeTo(contractRoutes.PAYMENT_METHOD)}
            />
            <TitleWithButtonInParallel
              title="Reserve Account"
              removeBorder
              decreaseSpaceBetween
              helpIcon
              toolTipMessage="Your Reserve Account will be used by NGL to offset commission chargebacks or other unrecovered advances
             and are set by your marketing organization. NGL can only change this with partner permission."
            />
          </div>
          <div className={styles['change-flex-direction']}>
            <div className={styles['column']}>
              {paymentDetails !== undefined &&
                renderKeyValueItems(paymentDetails)}
            </div>
            <div className={styles['column']}>
              <ReservesReview reserves={reserveAccount} />
            </div>
          </div>
        </div>
        <TitleWithButtonInParallel
          title="Documents"
          onButtonClick={() => routeTo(contractRoutes.DOCUMENT_UPLOAD)}
        />
        <div className={styles['small-section']}>
          {documentTypes.map((type, index) => {
            return (
              <div className={styles['documents-row']} key={index}>
                <CheckIcon className={styles['check-icon']} />
                <p className={styles['note']}>{documentTypeToString(type)}</p>
              </div>
            );
          })}
        </div>
        <div className={styles['button-container']}>
          <PrimaryButton
            loading={submitting}
            text="Submit"
            onClick={handleOnSubmit}
            disabled={submitting}
          />
        </div>
      </div>
      <div className={styles['mobile-container']}>
        <div className={styles['personal-information']}>
          <div className={styles['divider']} />
          <div className={styles['header-container']}>
            <TitleWithButtonInParallel
              title="Agent Information"
              removeBorder={false}
              onButtonClick={() => routeTo(contractRoutes.PERSONAL_INFORMATION)}
            />
          </div>
          <div className={styles['large-section']}>
            <div className={styles['change-flex-direction']}>
              <div className={styles['column']}>
                {renderKeyValueItems(agentInformation)}
              </div>
            </div>
          </div>
        </div>
        <div className={styles['payment-information']}>
          <TitleWithButtonInParallel
            title="Payment Information"
            onButtonClick={() => routeTo(contractRoutes.PAYMENT_METHOD)}
          />
          <div className={styles['direct-deposit']}>
            <TitleWithButtonInParallel
              title="Direct Deposit"
              removeBorder
              decreaseSpaceBetween
              removeButton
            />
            <div className={styles['data']}>
              {paymentDetails !== undefined &&
                renderKeyValueItems(paymentDetails)}
            </div>
          </div>
          <div className={styles['reserves']}>
            <TitleWithButtonInParallel
              title="Reserve Account"
              removeBorder
              decreaseSpaceBetween
              removeButton
            />
            <div className={styles['data']}>
              <ReservesReview reserves={reserveAccount} />
            </div>
          </div>
        </div>
        <div className={styles['documents']}>
          <TitleWithButtonInParallel
            title="Documents"
            onButtonClick={() => routeTo(contractRoutes.DOCUMENT_UPLOAD)}
          />
          <div className={styles['list']}>
            {documentTypes.map((type, index) => {
              return (
                <div className={styles['documents-row']} key={index}>
                  <CheckIcon className={styles['check-icon']} />
                  <p className={styles['note']}>{documentTypeToString(type)}</p>
                </div>
              );
            })}
          </div>
        </div>
        <div className={styles['button-container']}>
          <PrimaryButton
            loading={submitting}
            text="Submit"
            onClick={handleOnSubmit}
            disabled={submitting}
          />
        </div>
      </div>
    </div>
  );
};

export const TrackedReview: React.FC<any> = withTracker(Review);
