import React from 'react';
import styles from './NotAuthorizedPage.module.scss';
import securityIllustration from '../../../assets/img/notAuthorized/security-illustration.png';

export const NotAuthorizedPage: React.FC = () => {
  return (
    <div className={styles['container']} data-testid={'not-authorized'}>
      <img
        className={styles['responsive-img']}
        srcSet={`${securityIllustration}, ${securityIllustration} 2x`}
      />
      <div className={styles['text-container']}>
        <h1 className={styles['title']}>
          401 <br />
          ERROR
        </h1>
        <h3>Authorization required</h3>
        <p className={styles['text']}>
          You do not have permission to view this page using the credentials you
          provided.
        </p>
      </div>
    </div>
  );
};
