import { FC } from 'react';
import { Announcement } from './Announcement/Announcement';

export enum TemplateType {
  Announcement = 'announcement',
}

const getTemplateForType = (type: TemplateType): FC<any> => {
  switch (type) {
    case TemplateType.Announcement:
      return Announcement;
    default:
      throw new Error(`No Component Defined for type: ${type}`);
  }
};

export const TemplateFactory = { getTemplateForType };
