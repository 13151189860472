export enum HttpMethod {
  GET = 'GET',
  POST = 'POST',
  DELETE = 'DELETE',
}

export abstract class AHttpClient {
  abstract getBaseURL(): string;

  async get<T>(path: string, headers?: Record<string, any>): Promise<T> {
    const response = await fetch(`${this.getBaseURL()}${path}`, {
      headers: { ...headers },
    });
    let body;

    try {
      body = await response.json();
    } catch (ex) {}

    if (!response.ok) {
      throw response;
    }

    return body;
  }

  private async requestWithBody<T>(
    method: HttpMethod,
    path: string,
    body?: Record<string, any>,
    headers: Record<string, any> = {},
  ): Promise<T> {
    const response = await fetch(`${this.getBaseURL()}${path}`, {
      headers: {
        ...headers,
        'Content-Type': 'application/json',
      },
      method,
      body: body ? JSON.stringify(body) : null,
    });

    if (!response.ok) {
      throw response;
    }

    return await response.json();
  }

  async post<T>(
    path: string,
    body: Record<string, any>,
    headers: Record<string, any> = {},
  ): Promise<T> {
    return await this.requestWithBody<T>(HttpMethod.POST, path, body, headers);
  }

  async delete<T>(
    path: string,
    body?: Record<string, any>,
    headers: Record<string, any> = {},
  ): Promise<T> {
    return await this.requestWithBody<T>(
      HttpMethod.DELETE,
      path,
      body,
      headers,
    );
  }
}
