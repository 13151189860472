export const ENV = {
  COGNITO_CLIENT_ID:
    process.env.REACT_APP_COGNITO_CLIENT_ID || '77vlt4ul6mlff56df9h2upe5qh',
  REGION: 'us-east-2',
  POOL_ID: process.env.REACT_APP_POOL_ID || 'us-east-2_PA1KMRgFH',
  BFF_BASE_URL:
    process.env.REACT_APP_BFF_BASE_URL || 'http://localhost:3000/bff', // If working locally, be sure to check setupProxy
  ROLLBAR_TOKEN:
    process.env.REACT_APP_ROLLBAR_TOKEN || 'de2d072a09f44c249da164fe1ba0e19e',
  MFA_COMPLETE_ROUTE: process.env.REACT_APP_MFA_COMPLETE_ROUTE || '/redirect',
  MFA_RETRY_ROUTE: process.env.REACT_APP_MFA_RETRY_ROUTE || '/requestlink',
  NODE_TLS_REJECT_UNAUTHORIZED: !(process.env.STAGE || 'dev' in ['qa', 'prod'])
    ? '0'
    : '1',
  GOOGLE_ANALYTICS_PROPERTY_ID: process.env.GOOGLE_ANALYTICS_PROPERTY_ID || '',
  // local config
  USE_LOCAL_BFF: (process.env.USE_LOCAL_BFF || 'false') === 'true',
  SUPPORT_EMAIL:
    process.env.REACT_APP_SUPPORT_EMAIL || 'agentsupport@nglic.com',
  USERNAME_AUTOFILL: process.env.REACT_APP_USERNAME_AUTOFILL || '',
  PASSWORD_AUTOFILL: process.env.REACT_APP_PASSWORD_AUTOFILL || '',
  APPQORE_BASE_URL: process.env.REACT_APP_APPQORE_BASE_URL || '',
  THYSSE_BASE_URL:
    process.env.REACT_APP_THYSSE_BASE_URL ||
    'https://nglsupplies.myprintdesk.net',
  MEDIA_DEMOGRAPHICS_BASE_URL:
    process.env.REACT_APP_MEDIA_DEMOGRAPHICS_BASE_URL || '',
  APPQORE_IPD: process.env.REACT_APP_APPQORE_IDP || 'TESTNGLLINK',
  APP_ORIGIN: process.env.REACT_APP_APP_ORIGIN || '',
  ENVIRONMENT: process.env.ENVIRONMENT || 'local',
};
