import styles from './CheckYourEmail.module.scss';
import React from 'react';
import { useDispatch } from 'react-redux';
import logo from '../../../../assets/img/logo/logo-ngl.png';
import logo2x from '../../../../assets/img/logo/logo-ngl@2x.png';
import logo3x from '../../../../assets/img/logo/logo-ngl@3x.png';
import { Link } from '@nglic/component-lib/build';
import { goToLoginSequence } from '../login.action';
import { useHistory } from 'react-router-dom';
import { ENV } from '../../../../env';

import gmail from '../../../../assets/img/mfa/gmail.png';
import outlook from '../../../../assets/img/mfa/outlook.png';
import yahoo from '../../../../assets/img/mfa/yahoo.png';
import icloud from '../../../../assets/img/mfa/icloud.png';

enum EmailClientType {
  GMAIL = 'GMAIL',
  OUTLOOK = 'OUTLOOK',
  YAHOO = 'YAHOO',
  ICLOUD = 'ICLOUD',
}

const getEmailClientText = (type: EmailClientType) => {
  switch (type) {
    case EmailClientType.GMAIL:
      return 'Go to Gmail';
    case EmailClientType.OUTLOOK:
      return 'Go to Outlook';
    case EmailClientType.YAHOO:
      return 'Go to Yahoo';
    case EmailClientType.ICLOUD:
      return 'Go to Mail';
  }
};

const getEmailClientImage = (type: EmailClientType) => {
  let imgsrc = '';

  switch (type) {
    case EmailClientType.GMAIL:
      imgsrc = gmail;
      break;
    case EmailClientType.OUTLOOK:
      imgsrc = outlook;
      break;
    case EmailClientType.YAHOO:
      imgsrc = yahoo;
      break;
    case EmailClientType.ICLOUD:
      imgsrc = icloud;
      break;
  }

  return <img src={imgsrc} />;
};

const getEmailClientLink = (type: EmailClientType) => {
  switch (type) {
    case EmailClientType.GMAIL:
      return 'https://www.gmail.com';
    case EmailClientType.OUTLOOK:
      return 'https://www.outlook.com';
    case EmailClientType.YAHOO:
      return 'https://www.yahoo.com';
    case EmailClientType.ICLOUD:
      return 'https://www.icloud.com/mail/';
  }
};

const emailClients: Array<EmailClientType> = [
  EmailClientType.GMAIL,
  EmailClientType.OUTLOOK,
  EmailClientType.YAHOO,
  EmailClientType.ICLOUD,
];

export const CheckYourEmail: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const goToLogin = () => {
    dispatch(goToLoginSequence('USERNAME_AND_PASSWORD'));
    history.replace('/login');
  };

  return (
    <div className={styles['root']} data-testid={'check-your-email'}>
      <div className={styles['logo-container']}>
        <img
          className={styles['responsive-img']}
          srcSet={`${logo}, ${logo2x} 2x, ${logo3x} 3x`}
        />
      </div>
      <div className={styles['text-header']}>Check your email</div>
      <div className={styles['text-content']}>
        Check your email and follow the link the complete login.
      </div>
      <div className={styles['email-client-container']}>
        {emailClients.map((client) => {
          return (
            <div
              className={styles['email-client']}
              onClick={() => {
                window.open(getEmailClientLink(client), '_blank');
              }}
            >
              <div className={styles['logo-container']}>
                {getEmailClientImage(client)}
              </div>
              <div className={styles['text']}>{getEmailClientText(client)}</div>
            </div>
          );
        })}
      </div>
      {/* <div className={styles['link-container']}>
        <Link text="I didn't get an email" onClick={onClickHelp} />
      </div>
      {
        showNoEmail &&
        <div className={styles['help-content']}>
          Check your spam folder. If you still cannot find the email, contact Agent Support at <a href={`mailto:${ENV.SUPPORT_EMAIL}`}>${ENV.SUPPORT_EMAIL}</a>.
        </div>
      } */}
      <div className={styles['help-content']}>
        Check your spam folder. If you still cannot find the email, contact
        Agent Support at{' '}
        <a href={`mailto:${ENV.SUPPORT_EMAIL}`}>{ENV.SUPPORT_EMAIL}</a>.
      </div>
      <div className={styles['link-container']}>
        <Link text="Return to Login" onClick={goToLogin} />
      </div>
    </div>
  );
};
