import { ENV } from '../env';
import { AHttpClient } from './AHttpClient';

export enum HttpMethod {
  GET = 'GET',
  POST = 'POST',
  DELETE = 'DELETE',
}

export class ThysseClient extends AHttpClient {
  getBaseURL(): string {
    return ENV.THYSSE_BASE_URL;
  }
}
