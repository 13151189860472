export enum AddAgentHeaderTitleEnum {
  GENERAL_INFORMATION = 'General Information',
  HIERARCHY = 'Hierarchy',
  RESERVES = 'Reserves',
  REVIEW = 'Review',
}

export interface AddAgentSubnavigationItem {
  currentPage: string;
  nextPage?: string;
  active: boolean;
}
