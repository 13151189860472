import { createSelector } from 'reselect';
import { CredentialsState } from '../credentials.reducer';
import { AppState } from '../../../rootReducer';

const getCredentialsState = (state: AppState) => state.data.user.credentials;

export const getCredentials = createSelector<
  AppState,
  CredentialsState,
  { accessToken: string; idToken: string }
>([getCredentialsState], (credentials) => {
  return {
    accessToken: credentials.accessToken,
    idToken: credentials.idToken,
  };
});
