import produce from 'immer';
import {
  SetCredentialsAction,
  CredentialsActionType,
} from './credentials.action';
import { Action } from 'redux';
import {
  getItemInLocalStorageIfExists,
  StorageItemKey,
} from '../../util/getItemInLocalStorageIfExists';

export interface CredentialsState {
  accessToken: string;
  idToken: string;
  refreshToken: string;
}

const initCredentialsState = (): CredentialsState => {
  return {
    accessToken: getItemInLocalStorageIfExists(
      StorageItemKey.COGNITO_ACCESS_TOKEN,
    ),
    idToken: getItemInLocalStorageIfExists(StorageItemKey.COGNITO_ID_TOKEN),
    refreshToken: getItemInLocalStorageIfExists(
      StorageItemKey.COGNITO_REFRESH_TOKEN,
    ),
  };
};

export const credentialsReducer = (
  state: CredentialsState = initCredentialsState(),
  action: Action<CredentialsActionType>,
): CredentialsState => {
  return produce(state, (draftState) => {
    switch (action.type) {
      case 'SET_CREDENTIALS':
        const { accessToken, idToken, refreshToken } =
          action as SetCredentialsAction;
        localStorage.setItem(StorageItemKey.COGNITO_ID_TOKEN, idToken);
        localStorage.setItem(StorageItemKey.COGNITO_ACCESS_TOKEN, accessToken);
        localStorage.setItem(
          StorageItemKey.COGNITO_REFRESH_TOKEN,
          refreshToken,
        );
        draftState = {
          accessToken,
          idToken,
          refreshToken,
        };
        return draftState;
      default:
        return state;
    }
  });
};
