import { BFFClient } from '../clients/BFFClient';
import { Product } from './service.types';
import { BaseService } from './BaseService';
import { RatePlanItem } from '../components/Pages/ManageBusiness/RateCalculator/RightPane/Quote/QuoteTable/QuoteTable';
import { Option } from '@nglic/component-lib';

const PolicyServicePaths = {
  RATE_PLANS: (
    product: string,
    state: string | undefined,
    funeralAmount: string | undefined,
    age: string | undefined,
    gender: string | undefined,
    bump: string | 'No',
    mode: string | undefined,
    healthQuestion?: string | undefined,
    downpayment?: string | undefined,
    growthRate?: string | undefined,
    funeralAmountOrAffordability?: string | undefined,
  ) =>
    `/ao/rate-calculator?product=${product}&state=${state}&funeralAmount=${funeralAmount}&age=${age}&gender=${gender}&bump=${bump}&mode=${mode}&healthQuestion=${healthQuestion}&downpayment=${downpayment}&growthRate=${growthRate}&funeralAmountOrAffordability=${funeralAmountOrAffordability}`,
};

interface GetRatePlansParams {
  credentials: { accessToken: string; idToken: string };
  product: Product;
  state: Option | undefined;
  funeralAmount: string | undefined;
  age: string | undefined;
  gender: string | undefined;
  bump: string | 'No';
  mode: Option | undefined;
  healthQuestion?: string | undefined;
  downpayment?: string | undefined;
  growthRate?: Option | undefined;
  funeralAmountOrAffordability?: string | undefined;
}

export class RateCalculatorService extends BaseService {
  static getBFFClient(): BFFClient {
    return new BFFClient();
  }

  static async getRatePlans({
    credentials,
    product,
    state,
    funeralAmount,
    age,
    gender,
    bump,
    mode,
    healthQuestion,
    downpayment,
    growthRate,
    funeralAmountOrAffordability,
  }: GetRatePlansParams): Promise<{
    ratePlanItems: RatePlanItem[];
  }> {
    return await RateCalculatorService.getBFFClient().get<{
      ratePlanItems: RatePlanItem[];
    }>(
      PolicyServicePaths.RATE_PLANS(
        product.name,
        state?.id,
        funeralAmount,
        age,
        gender,
        bump,
        mode?.id,
        healthQuestion,
        downpayment,
        growthRate?.id,
        funeralAmountOrAffordability,
      ),
      RateCalculatorService.getBaseHeaders(
        credentials.accessToken,
        credentials.idToken,
      ),
    );
  }
}
