import { ThunkAction } from 'redux-thunk';
import { AppState } from '../../../rootReducer';
import { Action } from 'redux';
import { loadComplete, loadStarted } from '../../shared/loading.action';

import { AgentService } from '../../../service/AgentService';
import { Agent } from '../../../service/service.types';
import { getCredentials } from '../../shared/selectors/getCredentials';
import { ResendInviteState } from './agent.reducer';
import { setGlobalError } from '../../shared/error.action';

export type AgentActionType =
  | 'AGENT_FETCH_SUCCESS'
  | 'AGENT_FETCH_FAILURE'
  | 'AGENT_LIST_RESEND_INVITE_SUCCESS'
  | 'AGENT_LIST_RESEND_INVITE_FAILURE'
  | 'AGENT_INFORMATION_RESEND_INVITE_SUCCESS'
  | 'AGENT_INFORMATION_RESEND_INVITE_FAILURE'
  | 'AGENT_INFORMATION_RESEND_INVITE_CLEAR_RESPONSE'
  | 'AGENT_LIST_RESEND_INVITE_CLEAR_RESPONSE'
  | 'NONE';

export interface FetchAllAgentsSuccessAction extends Action<AgentActionType> {
  agents: Agent[];
  totalAgents: number;
}

export interface AgentListResendInviteAction extends Action<AgentActionType> {
  agentListResendInvite: ResendInviteState;
}
export interface AgentListResendInviteClearAction
  extends Action<AgentActionType> {
  agentListResendInvite: ResendInviteState | undefined;
}

export interface AgentInformationResendInviteAction
  extends Action<AgentActionType> {
  agentInformationResendInvite: ResendInviteState;
}

export interface AgentInformationResendInviteClearAction
  extends Action<AgentActionType> {
  agentInformationResendInvite: ResendInviteState | undefined;
}

export interface ResendInviteFailureAction extends Action<AgentActionType> {
  error: Error;
}

export interface FetchAllAgentsFailureAction extends Action<AgentActionType> {
  error: Error;
}

export const fetchAgents =
  (
    branchCode: string,
    page = 1,
    limit = 50,
    query?: string,
  ): ThunkAction<void, AppState, any, any> =>
  async (dispatch, getState: () => AppState) => {
    dispatch(loadStarted('AGENT_FETCH_ALL'));
    const tokens = getCredentials(getState());

    try {
      const { agents, totalAgents } = await AgentService.getAllAgents(
        branchCode,
        tokens,
        page,
        limit,
        query === '' ? undefined : query,
      );

      dispatch({
        type: 'AGENT_FETCH_SUCCESS',
        agents,
        totalAgents,
      });
    } catch (error) {
      dispatch({
        type: 'AGENT_FETCH_FAILURE',
        error,
      });
    }
    dispatch(loadComplete('AGENT_FETCH_ALL'));
  };

export const agentListResendInvite =
  (email: string): ThunkAction<void, AppState, any, any> =>
  async (dispatch, getState: () => AppState) => {
    dispatch(loadStarted('AGENT_LIST_RESEND_INVITE_REQUEST'));
    const tokens = getCredentials(getState());

    try {
      const agentListResendInvite = await AgentService.resendInvite(
        email,
        tokens,
      );
      dispatch({
        type: 'AGENT_LIST_RESEND_INVITE_SUCCESS',
        agentListResendInvite,
      });
      dispatch({
        type: 'AGENT_LIST_RESEND_INVITE_SUCCESS',
        agentListResendInvite,
      });
    } catch (error) {
      dispatch(setGlobalError(error));
      dispatch({
        type: 'AGENT_LIST_RESEND_INVITE_FAILURE',
        error,
      });
    }
    dispatch(loadComplete('AGENT_LIST_RESEND_INVITE_REQUEST'));
  };

export const agentListResendInviteClear =
  (): ThunkAction<void, AppState, any, any> => async (dispatch) => {
    dispatch(loadStarted('AGENT_LIST_RESEND_INVITE_CLEAR_RESPONSE_REQUEST'));

    dispatch({
      type: 'AGENT_LIST_RESEND_INVITE_CLEAR_RESPONSE',
      agentInformationResendInvite: undefined,
    });
    dispatch(loadComplete('AGENT_LIST_RESEND_INVITE_CLEAR_RESPONSE_REQUEST'));
  };

export const agentInformationResendInvite =
  (email: string): ThunkAction<void, AppState, any, any> =>
  async (dispatch, getState: () => AppState) => {
    dispatch(loadStarted('AGENT_INFORMATION_RESEND_INVITE_REQUEST'));
    const tokens = getCredentials(getState());

    try {
      const agentInformationResendInvite = await AgentService.resendInvite(
        email,
        tokens,
      );
      dispatch({
        type: 'AGENT_INFORMATION_RESEND_INVITE_SUCCESS',
        agentInformationResendInvite,
      });
    } catch (error) {
      dispatch(setGlobalError(error));
      dispatch({
        type: 'AGENT_INFORMATION_RESEND_INVITE_FAILURE',
        error,
      });
    }
    dispatch(loadComplete('AGENT_INFORMATION_RESEND_INVITE_REQUEST'));
  };

export const agentInformationResendInviteClear =
  (): ThunkAction<void, AppState, any, any> => async (dispatch) => {
    dispatch(
      loadStarted('AGENT_INFORMATION_RESEND_INVITE_CLEAR_RESPONSE_REQUEST'),
    );
    dispatch({
      type: 'AGENT_INFORMATION_RESEND_INVITE_CLEAR_RESPONSE',
      agentInformationResendInvite: undefined,
    });
    dispatch(
      loadComplete('AGENT_INFORMATION_RESEND_INVITE_CLEAR_RESPONSE_REQUEST'),
    );
  };
