import React, { useEffect } from 'react';
import styles from './LocationAutocompleteComponent.module.scss';
import { TextInput, useNglScreenSize } from '@nglic/component-lib/build';
import { containsEmptyFieldV2 } from '@nglic/utilities-ts/build';
import { Address } from '../../Pages/Contract/PersonalInformation/PersonalInformation.types';

export enum AddressType {
  WORK = 'work',
  HOME = 'home',
}

type GoogleMapsFormProps = {
  baseInputId: string;
  addressType?: AddressType;
  workAddressType?: boolean;
  setIsValid: (status: boolean) => void;
  onAddressChange: (address: Address) => void;
  value: Address | undefined;
};

const LocationAutocompleteComponent: React.FC<GoogleMapsFormProps> = ({
  baseInputId,
  addressType = AddressType.HOME,
  setIsValid,
  value,
  onAddressChange,
}) => {
  const { isMobile } = useNglScreenSize();
  let autocomplete: google.maps.places.Autocomplete;
  const [addressLine, setStreetAddress] = React.useState(
    value?.addressLine || '',
  );
  const [unit, setUnit] = React.useState<string | undefined>(value?.unit ?? '');
  const [city, setCity] = React.useState(value?.city || '');
  const [state, setState] = React.useState(value?.state || '');
  const [postalCode, setPostalCode] = React.useState(value?.zip || '');
  const [baseId, setBaseId] = React.useState(baseInputId);

  useEffect(() => {
    setBaseId(baseInputId);
  }, [baseInputId]);

  const addressPieceMapping = {
    locality: 'long_name',
    administrative_area_level_1: 'short_name',
    postal_code: 'short_name',
  };

  React.useEffect(() => {
    initAutocomplete();
  }, []);

  React.useEffect(() => {
    const requiredFields: Array<string> = [
      addressLine ?? value?.addressLine ?? '',
      city ?? value?.city ?? '',
      postalCode ?? value?.zip ?? '',
      state ?? value?.state ?? '',
    ];
    setIsValid(!containsEmptyFieldV2(requiredFields));
    onAddressChange({
      addressLine,
      unit,
      city,
      state,
      zip: postalCode,
    });
  }, [addressLine, city, postalCode, state, unit]);
  const initAutocomplete = () => {
    autocomplete = new google.maps.places.Autocomplete(
      document.getElementById(baseInputId) as HTMLInputElement,
      { types: ['geocode'] },
    );
    autocomplete.addListener('place_changed', fillInAddress);
  };

  const setFieldValue = (id: string, val: string) => {
    if (document.getElementById(id) as HTMLInputElement) {
      (document.getElementById(id) as HTMLInputElement).value = val;
    }
  };

  const fillInAddress = () => {
    const { address_components } = autocomplete.getPlace();
    for (const component of address_components as google.maps.GeocoderAddressComponent[]) {
      const addressPortion = component.types[0];

      let val;
      if (addressPieceMapping[addressPortion]) {
        val = component[addressPieceMapping[addressPortion]];

        setFieldValue(`${baseId}_${addressPortion}_${addressType}`, val);
      }

      switch (`${baseId}_${addressPortion}_${addressType}`) {
        case `${baseId}_locality_${addressType}`:
          setCity(val);
          break;
        case `${baseId}_administrative_area_level_1_${addressType}`:
          setState(val);
          break;
        case `${baseId}_postal_code_${addressType}`:
          setPostalCode(val);
          break;
        default:
          break;
      }
    }

    let addressLine;
    if (
      address_components?.length &&
      address_components[0]['short_name'] &&
      address_components[1]['short_name']
    ) {
      addressLine = `${address_components[0]['short_name']} ${address_components[1]['short_name']}`;
    }
    if (addressLine) {
      setFieldValue(baseInputId, addressLine);
      setStreetAddress(addressLine);
    }
  };

  const getLabelForAddressType = (addressType: AddressType) => {
    switch (addressType) {
      case AddressType.HOME:
        return 'Home Address';
      case AddressType.WORK:
        return 'Work Address';
    }
  };

  const handleLabelVisibility = (label: string) => {
    switch (label) {
      case 'City':
        return city === '' ? 'City' : '';
      case 'State':
        return state === '' ? 'State' : '';
      case 'PostalCode':
        return postalCode === '' ? (isMobile ? 'Zip' : 'Zip Code') : '';
      case 'Unit':
        return unit === '' ? 'Unit' : '';
      default:
        return '';
    }
  };
  return (
    <div
      data-testid="personal-information-component"
      className={styles['personal-information-root']}
    >
      <div className={styles['container']}>
        <div className={styles['row']}>
          <div className={styles['address-line']}>
            <TextInput
              value={addressLine || value?.addressLine}
              id={baseId}
              onChange={setStreetAddress}
              label={getLabelForAddressType(addressType)}
            />
          </div>
          <div className={styles['mobile-unit']}>
            <TextInput
              value={unit || value?.unit}
              label={handleLabelVisibility('Unit')}
              onChange={setUnit}
              id={`${baseId}_unit`}
            />
          </div>
        </div>
      </div>
      <div className={styles['container']}>
        <div className={styles['row']}>
          <div>
            <TextInput
              id={`${baseId}_locality_${addressType}`}
              value={city || value?.city}
              onChange={setCity}
              label={handleLabelVisibility('City')}
            />
          </div>
          <div className={styles['row']}>
            <TextInput
              value={postalCode || value?.zip}
              label={handleLabelVisibility('PostalCode')}
              onChange={setPostalCode}
              id={`${baseId}_postal_code_${addressType}`}
            />
            <TextInput
              value={state || value?.state}
              id={`${baseId}_administrative_area_level_1_${addressType}`}
              label={handleLabelVisibility('State')}
              onChange={setState}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LocationAutocompleteComponent;
