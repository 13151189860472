import React, { useEffect, useState } from 'react';
import styles from './RecentCommissionActivityMobile.module.scss';
import { useSelector } from 'react-redux';
import { AppState } from 'rootReducer';
import { CommissionsActivity } from '../../../../../service/service.types';
import { SectionName } from '../../../../../components/shared/loading.action';
import { useHistory } from 'react-router-dom';
import { ListItem, ListItemText } from '@material-ui/core';
import { formatDate } from '../../Policies/PolicyDetail/PolicyInformation/PolicyInformationTransform';
import { convertToLocalString } from '@nglic/utilities-ts/build';
import { commissionsRoute } from './RecentCommissionActivity';

interface RecentCommissionActivityMobileProps {
  commissions?: CommissionsActivity[];
}

const secondaryTypographyProps = {
  style: {
    fontSize: '10px',
    color: '#5E6168',
    letterSpacing: '1.5px',
  },
};

const secondaryTypographyPropsAgentName = {
  style: {
    color: '#5E6168',
    fontSize: '13px',
  },
};

const primaryTypographyProps = {
  style: {
    color: '#2F3034',
    fontSize: '22px',
  },
};
export const RecentCommissionActivityMobile: React.FC<RecentCommissionActivityMobileProps> =
  ({ commissions }) => {
    const [loading, setLoading] = useState<boolean>(true);
    const history = useHistory();

    const sectionsLoading: SectionName[] = useSelector(
      (state: AppState) => state.presentation.loading.sectionsLoading,
    );

    useEffect(() => {
      setLoading(
        !commissions ||
          sectionsLoading.includes('COMMISSION_ACTIVITIES_FETCH_ALL'),
      );
    }, [commissions, sectionsLoading]);

    const createRouteTo = (route: string) => () => {
      history.push(route);
    };
    return (
      <>
        <div className={styles['root']}>
          <div className={styles['header']}>Recent Commission Activity</div>
          <div className={styles['table']}>
            {commissions?.map((commission) => {
              return (
                <ListItem
                  className={styles['list-item']}
                  style={{ display: 'flex' }}
                  key={commission.id}
                  button
                  disableRipple
                  disableTouchRipple
                >
                  <div className={styles['left-item-text']}>
                    <ListItemText
                      secondary={commission.commissionsActivityTypeDescription}
                      secondaryTypographyProps={secondaryTypographyProps}
                    />
                    <ListItemText
                      primary={'$' + convertToLocalString(commission.earned)}
                      secondary={commission.sellingAgentFullName}
                      secondaryTypographyProps={
                        secondaryTypographyPropsAgentName
                      }
                      primaryTypographyProps={primaryTypographyProps}
                    />
                  </div>
                  <div className={styles['right-item-text']}>
                    <ListItemText
                      className={styles['item-issuedDate']}
                      secondaryTypographyProps={secondaryTypographyProps}
                      secondary={formatDate(commission.commissionsActivityDate)}
                    />
                  </div>
                </ListItem>
              );
            })}
          </div>
        </div>
        <div
          className={styles['link']}
          onClick={createRouteTo(commissionsRoute.COMMISSIONS)}
        >
          View All Commission Activity
        </div>
      </>
    );
  };
