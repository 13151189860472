import React, { ReactElement } from 'react';
import { AppPageLayoutWrapper } from '../AppPageLayoutWrapper';
import { RouteComponentProps } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { withTracker } from 'ga-4-react';
import { PageEnum } from '@nglic/component-lib';
import { ProductInformation } from './ProductInformation/ProductInformation';

export interface SubNavigationItemConfig {
  name: string;
  selected: boolean;
  onClick: (...args) => void;
  altText?: string;
  id: string;
  icon?: ReactElement;
}

export enum KnowledgeCenterPages {
  PRODUCT_INFORMATION = 'product-information',
}

const routePrefix = '/knowledge-center';
export const knowledgeCenterRoutes = {
  PRODUCT_INFORMATION: `${routePrefix}/${KnowledgeCenterPages.PRODUCT_INFORMATION}`,
};

export const KnowledgeCenter: React.FC<
  Pick<RouteComponentProps<{ subPage: string }>, 'match' | 'history'>
> = (props) => {
  const { match, history } = props;
  const subPage = match.params.subPage;
  const dispatch = useDispatch();

  const createRouteTo = (route: string) => () => {
    history.push(route);
  };

  const renderComponent = (page: string) => {
    let Component;
    switch (page) {
      case KnowledgeCenterPages.PRODUCT_INFORMATION:
        Component = withTracker(ProductInformation);
        return (
          <Component
            routeToNext={createRouteTo(
              knowledgeCenterRoutes.PRODUCT_INFORMATION,
            )}
          />
        );
    }
  };

  const subHeaderComponent = () => {
    switch (subPage) {
      case KnowledgeCenterPages.PRODUCT_INFORMATION:
        return <div>Product Information</div>;
    }
  };

  return (
    <AppPageLayoutWrapper
      title={'Knowledge Center'}
      page={PageEnum.KNOWLEDGE_CENTER}
      subHeaderMobileConfig={subHeaderComponent()}
    >
      {renderComponent(subPage)}
    </AppPageLayoutWrapper>
  );
};

export default KnowledgeCenter;
