import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AppState } from '../../../../../../rootReducer';
import { Policy } from '../../../../../../service/service.types';
import { containsEmptyFieldV2 } from '@nglic/utilities-ts/build';
import { CauseOfDeath, Claim, CREATE_CLAIM_USER_ROLES } from './claim.types';
import { clearClaim } from './claim.action';
import { fetchPolicyClaim } from '../../policies.action';
import { NotSubmittedClaim } from './NotSubmittedClaim';
import { SubmittedClaim } from './SubmittedClaim';
import {
  getUserInformation,
  userHasPrivileges,
} from '../../../../../shared/selectors/getUserInformation';

interface ClaimsModalProps {
  open: boolean;
  handleClose: () => void;
  policy: Policy;
}

const causeOfDeathMap = {
  [CauseOfDeath.NATURAL]: 'Natural',
  [CauseOfDeath.ACCIDENTAL]: 'Accidental',
  [CauseOfDeath.SUICIDE]: 'Suicide',
  [CauseOfDeath.HOMICIDE]: 'Homicide',
  [CauseOfDeath.UNKNOWN]: 'Unknown',
};

export const ClaimsModal: React.FC<ClaimsModalProps> = ({
  open,
  handleClose,
  policy,
}) => {
  const dispatch = useDispatch();
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [submitClicked, setSubmitClicked] = useState(false);

  const [claimsData, setClaimsData] = useState<Claim>({
    dateOfDeath: '',
    funeralCost: '',
    causeOfDeath: undefined,
    userSubmittedClaim: '',
  });

  const canCreateClaim = useSelector((state: AppState) =>
    userHasPrivileges(CREATE_CLAIM_USER_ROLES)(state),
  );

  const claimCreated = useSelector(
    (state: AppState) => state.presentation.manageBusiness.policyClaim,
  );
  const userInfo = useSelector((state: AppState) => getUserInformation(state));

  React.useEffect(() => {
    const values = Object.values(claimsData);
    if (canCreateClaim) {
      setSubmitDisabled(containsEmptyFieldV2(values));
    } else {
      setSubmitDisabled(claimsData.dateOfDeath === '');
    }
  }, [claimsData]);

  const handleOnChangeInput = React.useCallback(
    (inputName: string, newValue: string) => {
      setClaimsData({
        ...claimsData,
        [inputName]: newValue,
      });
    },
    [claimsData],
  );

  useEffect(() => {
    setClaimsData({
      ...claimsData,
      userSubmittedClaim:
        `${userInfo?.firstName}` + ' ' + `${userInfo?.lastName}`,
    });
  }, [userInfo]);

  const causeOfDeathRadioGroups = [
    {
      label: causeOfDeathMap[CauseOfDeath.NATURAL],
      onClick: () =>
        handleOnChangeInput(
          'causeOfDeath',
          causeOfDeathMap[CauseOfDeath.NATURAL].toUpperCase(),
        ),
    },
    {
      label: causeOfDeathMap[CauseOfDeath.ACCIDENTAL],
      onClick: () =>
        handleOnChangeInput(
          'causeOfDeath',
          causeOfDeathMap[CauseOfDeath.ACCIDENTAL].toUpperCase(),
        ),
    },
    {
      label: causeOfDeathMap[CauseOfDeath.SUICIDE],
      onClick: () =>
        handleOnChangeInput(
          'causeOfDeath',
          causeOfDeathMap[CauseOfDeath.SUICIDE].toUpperCase(),
        ),
    },
    {
      label: causeOfDeathMap[CauseOfDeath.HOMICIDE],
      onClick: () =>
        handleOnChangeInput(
          'causeOfDeath',
          causeOfDeathMap[CauseOfDeath.HOMICIDE].toUpperCase(),
        ),
    },
    {
      label: causeOfDeathMap[CauseOfDeath.UNKNOWN],
      onClick: () =>
        handleOnChangeInput(
          'causeOfDeath',
          causeOfDeathMap[CauseOfDeath.UNKNOWN].toUpperCase(),
        ),
    },
  ];

  const handleCloseSubmit = () => {
    handleClose();
    dispatch(clearClaim());
    dispatch(fetchPolicyClaim(policy.id));
    setSubmitClicked(false);
    setClaimsData({
      dateOfDeath: '',
      funeralCost: '',
      causeOfDeath: undefined,
      userSubmittedClaim: '',
    });
  };

  return (
    <div>
      {!claimCreated.submitted && (
        <NotSubmittedClaim
          open={open}
          handleClose={handleClose}
          policy={policy}
          claimsData={claimsData}
          submitClicked={submitClicked}
          setClaimsData={setClaimsData}
          setSubmitClicked={setSubmitClicked}
          handleOnChangeInput={handleOnChangeInput}
          causeOfDeathRadioGroups={causeOfDeathRadioGroups}
          submitDisabled={submitDisabled}
        />
      )}
      {claimCreated.submitted && (
        <SubmittedClaim
          open={open}
          handleCloseSubmit={handleCloseSubmit}
          clearClaim={clearClaim}
          setSubmitClicked={setSubmitClicked}
        />
      )}
    </div>
  );
};
