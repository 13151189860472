import {
  GenericTable,
  TableFieldsProps,
  useNglScreenSize,
} from '@nglic/component-lib';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../../../rootReducer';
import { CommissionsTableViewAction } from '../../../../modules/CommissionsTableViewAction/CommissionsTableViewAction';
import { Pagination } from '../../../../modules/Pagination/Pagination';
import { SectionName } from '../../../../shared/loading.action';
import { formatDate } from '../../Policies/PolicyDetail/PolicyInformation/PolicyInformationTransform';
import { fetchCommissionActivities } from '../commissions.action';
import { CommissionsActivityMobile } from '../CommissionsActivityMobile/CommissionsActivityMobile';
import styles from './CommissionsTable.module.scss';
import { CommissionsTableActivityTypeAction } from '../../../../modules/CommissionsTableActivityTypeAction/CommissionsTableActivityTypeAction';
import { getUserInformation } from '../../../../shared/selectors/getUserInformation';
import { isCommissionActivitiesErrorState } from '../commissions.reducer';

export const tableHeader: TableFieldsProps[] = [
  {
    titleName: 'Date',
    dataField: 'commissionsActivityDate',
    displayFormat: (value: string): string => {
      return value && formatDate(value, true);
    },
  },
  {
    titleName: 'Insured Name',
    dataField: 'insuredName',
  },
  {
    titleName: 'Policy ID',
    dataField: 'policyId',
  },
  {
    titleName: 'Product',
    dataField: 'productName',
  },
  {
    titleName: 'Rate',
    dataField: 'rate',
    displayFormat: (value: string): string => {
      return value && parseFloat(value).toFixed(1) + '%';
    },
  },
  {
    titleName: 'Face Value',
    dataField: 'faceValue',
    displayFormat: (value: string): string => {
      return value && '$' + value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
  },
  {
    titleName: 'Amount',
    dataField: 'earned',
    displayFormat: (value: string): string => {
      if (parseInt(value) < 0) {
        const newValue = value?.replace('-', '');
        return (
          newValue && '-$' + newValue?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        );
      } else {
        return value && '$' + value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
    },
  },
  {
    titleName: 'Activity Type',
    dataField: '',
    type: 'action',
    actionComponent: CommissionsTableActivityTypeAction,
  },
  {
    titleName: 'Selling Agent',
    dataField: 'sellingAgentFullName',
  },
  {
    titleName: '',
    dataField: '',
    type: 'action',
    actionComponent: CommissionsTableViewAction,
  },
];

export const CommissionsTable: React.FC = () => {
  const dispatch = useDispatch();
  const { isMobile } = useNglScreenSize();

  // Using 5 items for desktop here is a stop gap solution until we modify the design
  const ITEMS_PER_PAGE = 5;

  const [loading, setLoading] = useState<boolean>(true);
  const [page, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);

  const commissionsState = useSelector(
    (state: AppState) => state.presentation.manageBusiness.commissions,
  );

  const {
    totalCommissionsActivities,
    commissionsActivities,
    initialCommissions,
  } = commissionsState;

  const sectionsLoading: SectionName[] = useSelector(
    (state: AppState) => state.presentation.loading.sectionsLoading,
  );

  const basicInfo = useSelector((state: AppState) => getUserInformation(state));

  useEffect(() => {
    if (basicInfo && page !== 1) {
      dispatch(fetchCommissionActivities(page, ITEMS_PER_PAGE));
    }
  }, [basicInfo, page]);

  useEffect(() => {
    if (!totalCommissionsActivities) {
      setTotalPages(1);
    } else {
      setTotalPages(Math.ceil(totalCommissionsActivities / ITEMS_PER_PAGE));
    }
  }, [totalCommissionsActivities]);

  const commissionsToRender =
    page === 1 ? initialCommissions : commissionsActivities;

  useEffect(() => {
    setLoading(
      !commissionsToRender ||
        sectionsLoading.includes('FETCH_COMMISSION_ACTIVITIES_REQUEST') ||
        (sectionsLoading.includes(
          'FETCH_INITIAL_COMMISSION_ACTIVITIES_REQUEST',
        ) &&
          !isCommissionActivitiesErrorState(commissionsState)),
    );
  }, [commissionsActivities, sectionsLoading]);

  return (
    <>
      {isMobile && (
        <CommissionsActivityMobile data={commissionsToRender} loading={loading}>
          <div className={styles['pagination-container']}>
            <Pagination
              onPageClick={setCurrentPage}
              numberOfPages={totalPages}
              currentPage={page}
            />
          </div>
        </CommissionsActivityMobile>
      )}
      {!isMobile && (
        <GenericTable
          loading={loading}
          pagination={true}
          data={commissionsToRender}
          columns={tableHeader}
        >
          <div className={styles['pagination-container']}>
            <Pagination
              onPageClick={setCurrentPage}
              numberOfPages={totalPages}
              currentPage={page}
            />
          </div>
        </GenericTable>
      )}
      {!isMobile && isCommissionActivitiesErrorState(commissionsState) && (
        <div className={styles['error-container']}>
          There was an issue retrieving commission activities
        </div>
      )}
    </>
  );
};
