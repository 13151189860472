import produce from 'immer';
import { Action } from 'redux';
import {
  AddUserActionType,
  AddUserFailureAction,
  AddUserSuccessAction,
} from './addUser.action';

export interface AddUserInitState {
  success: boolean | undefined;
}

export type RateCalculatorErrorState = {
  error: Error;
} & AddUserInitState;

export type AddUserState = AddUserInitState | RateCalculatorErrorState;

export const addUserReducer = (
  state: AddUserState = { success: undefined },
  action: Action<AddUserActionType>,
): AddUserState => {
  return produce(state, (draftState) => {
    switch (action.type) {
      case 'ADD_USER_SUCCESS':
        draftState.success = (action as AddUserSuccessAction).result.success;
        return draftState;
      case 'ADD_USER_FAILURE':
        draftState = {
          ...draftState,
          error: (action as AddUserFailureAction).error,
          success: false,
        };
        return draftState;
      case 'ADD_USER_DELETE_RESPONSE':
        draftState = {
          ...draftState,
          success: undefined,
        };
        return draftState;
      default:
        return state;
    }
  });
};
