import produce from 'immer';
import { Action } from 'redux';
import { Reserves } from '../../../../../service/service.types';
import {
  ReservesActionTypes,
  FetchReservesSuccessAction,
} from './reserves.action';

export type ReservesState = Reserves | 'NOT_INITIALIZED';

export const reservesReducer = (
  state: ReservesState = 'NOT_INITIALIZED',
  action: Action<ReservesActionTypes>,
): Reserves | 'NOT_INITIALIZED' => {
  return produce(state, (draftState) => {
    switch (action.type) {
      case 'FETCH_RESERVES_SUCCESS':
        draftState = (action as FetchReservesSuccessAction).result;
        return draftState;
      case 'FETCH_RESERVES_FAILURE':
        draftState = 'NOT_INITIALIZED';
        return draftState;
      default:
        return state;
    }
  });
};
