import React, { useCallback, useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import {
  EarlyPayOff,
  PolicyPayment,
} from '../../../../../../service/service.types';
import { AppState } from '../../../../../../rootReducer';
import {
  fetchEarlyPayoffs,
  fetchPolicyPayments,
} from './paymentHistory.action';
import styles from './PaymentHistory.module.scss';
import { PaymentHistoryTableContent } from './PaymentHistoryTable/PaymentHistoryTableContent';
import { PaymentHistoryTableHeader } from './PaymentHistoryTable/PaymentHistoryTableHeader';
import {
  PrimaryButton,
  useNglScreenSize,
  ButtonSize,
} from '@nglic/component-lib';
import { EarlyPayOffModal } from './EarlyPayOffModal';
import { getUserInformation } from '../../../../../shared/selectors/getUserInformation';
interface PaymentHistoryProps {
  policyId?: string;
  onPolicyPaymentSelected?: (policyPayment: PolicyPayment) => void;
}

export const PaymentHistory: React.FC<PaymentHistoryProps> = ({
  policyId,
  onPolicyPaymentSelected,
}) => {
  const dispatch = useDispatch();
  const userInfo = useSelector((state: AppState) => getUserInformation(state));

  const paymentHistory: PolicyPayment[] | undefined = useSelector(
    (state: AppState) =>
      state.presentation.manageBusiness.paymentHistory.policyPayments,
  );
  const [filteredPaymentHistory, setFilteredPaymentHistory] = useState<
    PolicyPayment[]
  >([]);

  const [isShowingEarlyPayOffModal, setIsShowingEarlyPayOffModal] =
    useState<boolean>(false);

  const earlyPayOffs: EarlyPayOff[] | undefined = useSelector(
    (state: AppState) =>
      state.presentation.manageBusiness.paymentHistory.earlyPayOffs,
  );

  const mostRecentEPO = _.head(earlyPayOffs);

  const isLoading = useMemo(() => {
    return paymentHistory === undefined && earlyPayOffs === undefined;
  }, [paymentHistory, earlyPayOffs]);

  const showEarlyPayOffButton = earlyPayOffs && earlyPayOffs.length > 0;

  const [showMore, setShowMore] = useState(false);

  useEffect(() => {
    if (userInfo && policyId) {
      dispatch(fetchPolicyPayments(policyId));
      dispatch(fetchEarlyPayoffs(policyId));
    }
  }, [userInfo, policyId]);

  useEffect(() => {
    if (paymentHistory) {
      showMore
        ? setFilteredPaymentHistory(paymentHistory)
        : setFilteredPaymentHistory(paymentHistory?.slice(0, 4));
    } else {
      setFilteredPaymentHistory([]);
    }
  }, [paymentHistory, showMore]);

  const showMorePayments = () => {
    setShowMore(!showMore);
  };

  const { isMobile } = useNglScreenSize();

  const onClickPolicyPayment = useCallback(
    (policyPayment: PolicyPayment) => {
      if (onPolicyPaymentSelected) {
        onPolicyPaymentSelected(policyPayment);
      }
    },
    [filteredPaymentHistory],
  );

  const renderEPOButton = () => {
    const text = isMobile ? 'View EPO' : 'View Early Payoff';

    return (
      <div>
        {!isLoading && showEarlyPayOffButton && (
          <PrimaryButton
            text={text}
            onClick={() => setIsShowingEarlyPayOffModal(true)}
            size={isMobile ? ButtonSize.SMALL : ButtonSize.LARGE}
          />
        )}
        {/* <PrimaryButton
          text={text}
          onClick={() => setIsShowingEarlyPayOffModal(true)}
          size={isMobile ? ButtonSize.SMALL : ButtonSize.LARGE}
        /> */}
      </div>
    );
  };

  return (
    <div className={styles['root']}>
      <div className={styles['header']}>
        <div className={styles['text']}>
          {isMobile ? 'Recent Payments' : 'Payment History'}
        </div>
        {renderEPOButton()}
      </div>
      <div className={styles['table']}>
        {!isMobile && <PaymentHistoryTableHeader />}
        {!isLoading &&
          filteredPaymentHistory?.map((data, index) => {
            return (
              <div
                key={index}
                onClick={() => {
                  onClickPolicyPayment(data);
                }}
              >
                <PaymentHistoryTableContent policyPayment={data} />
              </div>
            );
          })}
      </div>
      {!isMobile && paymentHistory && paymentHistory?.length > 4 && (
        <div className={styles['showMore']} onClick={showMorePayments}>
          {showMore ? 'Show less' : 'Show more'}
        </div>
      )}
      {mostRecentEPO && (
        <EarlyPayOffModal
          isOpen={isShowingEarlyPayOffModal}
          onClose={() => setIsShowingEarlyPayOffModal(false)}
          earlyPayOff={mostRecentEPO}
        />
      )}
    </div>
  );
};
