import { BFFClient } from '../clients/BFFClient';
import { Hierarchy } from './service.types';
import { BaseService } from './BaseService';
import { HierarchyUtil } from './transformers/Hierarchy';

const AgentServicePaths = {
  HIERARCHIES: '/ao/add-agent/hierarchies',
  MANAGE_HIERARCHIES: '/ao/manage-hierarchies/hierarchies',
  EDIT_HIERARCHY: '/ao/manage-hierarchies/edit-hierarchy',
  CREATE_HIERARCHY: '/ao/manage-hierarchies/create-hierarchy',
};

export class HierarchyService extends BaseService {
  static getBFFClient(): BFFClient {
    return new BFFClient();
  }

  static async getHierarchiesForNewAgent(
    producerId: string,
    branchCode: string,
    productId: string,
    credentials: { accessToken: string; idToken: string },
  ): Promise<Hierarchy[]> {
    const { hierarchies } = await HierarchyService.getBFFClient().get<{
      hierarchies: Hierarchy[];
    }>(
      `${AgentServicePaths.HIERARCHIES}/${producerId}?productId=${productId}&branchCode=${branchCode}`,
      HierarchyService.getBaseHeaders(
        credentials.accessToken,
        credentials.idToken,
      ),
    );

    return hierarchies;
  }

  static async findHierarchies(
    searchValue: string,
    branchCode: string,
    credentials: { accessToken: string; idToken: string },
    productId?: string,
    includeInactive?: string,
  ): Promise<Hierarchy[]> {
    const { hierarchies } = await HierarchyService.getBFFClient().get<{
      hierarchies: Hierarchy[];
    }>(
      `${
        AgentServicePaths.MANAGE_HIERARCHIES
      }?branchCode=${branchCode}&query=${searchValue}${
        productId ? `&productId=${productId}` : ''
      }${includeInactive ? `&includeInactive=${includeInactive}` : ''}`,
      HierarchyService.getBaseHeaders(
        credentials.accessToken,
        credentials.idToken,
      ),
    );

    return hierarchies;
  }

  static async editHierarchy(
    hierarchy: Partial<Hierarchy>,
    originalHierarchy: Hierarchy,
    credentials: { accessToken: string; idToken: string },
    branchCode?: string,
  ): Promise<boolean> {
    const sortedCommissionLevelDesc = {
      ...originalHierarchy,
      agents: HierarchyUtil.sortByCommissionLevelDesc(originalHierarchy.agents),
    };
    const { success } = await HierarchyService.getBFFClient().post<{
      success: boolean;
    }>(
      `${AgentServicePaths.EDIT_HIERARCHY}?branchCode=${branchCode}`,
      {
        updatedHierarchy: hierarchy,
        originalHierarchy: sortedCommissionLevelDesc,
      },
      HierarchyService.getBaseHeaders(
        credentials.accessToken,
        credentials.idToken,
      ),
    );

    return success;
  }

  static async createHierarchy(
    hierarchy: Partial<Hierarchy>,
    branchCode: string,
    credentials: { accessToken: string; idToken: string },
  ): Promise<boolean> {
    const { sucess } = await HierarchyService.getBFFClient().post<{
      sucess: boolean;
    }>(
      `${AgentServicePaths.CREATE_HIERARCHY}?branchCode=${branchCode}`,
      { hierarchy },
      HierarchyService.getBaseHeaders(
        credentials.accessToken,
        credentials.idToken,
      ),
    );

    return sucess;
  }
}
