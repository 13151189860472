import React from 'react';

import { ReactComponent as DocumentUploadLogo } from '../../../assets/img/fourOhFour/404.svg';

import styles from './FourOhFour.module.scss';

export const FourOhFour: React.FC = () => {
  return (
    <div data-testid={'404-page'} className={styles['root']}>
      <div className={styles['desktop-image']}>
        <DocumentUploadLogo />
      </div>
      <div className={styles['mobile-text']}>
        <div className={styles['header']}>404</div>
        <div className={styles['sub-header']}>Oops! Page not found.</div>
        <div className={styles['description']}>
          The page you’re looking for doesn’t <br />
          exist. If you think something is broken,
          <br /> please contact Agent Support.
        </div>
      </div>
    </div>
  );
};
