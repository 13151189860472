import produce from 'immer';
import { Action } from 'redux';
import {
  ReviewActionType,
  ReviewSignContractFailureAction,
} from './review.action';
import { ReviewData } from './Review.types';

export type ReviewState = 'INCOMPLETE' | ReviewData;

export const reviewReducer = (
  state: ReviewState = 'INCOMPLETE',
  action: Action<ReviewActionType>,
): ReviewState => {
  return produce(state, () => {
    switch (action.type) {
      case 'REVIEW_SIGN_CONTRACT':
        return {
          submitted: true,
          error: undefined,
        };
      case 'REVIEW_SIGN_CONTRACT_FAILURE':
        return {
          submitted: true,
          error: (action as ReviewSignContractFailureAction).error,
        };
    }
  });
};
