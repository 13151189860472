import React, { useEffect, useState } from 'react';
import styles from './LeftPane.module.scss';
import { Product } from '../../../../../service/service.types';
import { useDispatch } from 'react-redux';
import { fetchStates } from '../../../../shared/state.action';
import { ProductDropdown } from '../../../../modules/ProductDropdown/ProductDropdown';
import { Option, PrimaryButton } from '@nglic/component-lib';
import { isNumber } from '@nglic/utilities-ts/build';

import { ErrorMessage } from '../../../Contract/Background/Background.types';
import { fetchRatePlans } from '../rateCalculator.action';
import {
  healthQuestionVisibility,
  InsuredInformation,
} from './InsuredInformation/InsuredInformation';
import { GrowthRate, growthRateVisibility } from './GrowthRate/GrowthRate';
import { downPaymentVisibility } from './PriceVariables/DownPayment/DownPayment';
import { funeralAmountAffordabilityOptionVisibility } from './PriceVariables/FuneralAmountOrAffordability/FuneralAmntOrAffordability';
import { PriceVariables } from './PriceVariables/PriceVariables';

const ErrorMessageInit: ErrorMessage = {
  error: false,
  message: '',
};

interface LeftPaneProps {
  onCalculate: (
    bump: string,
    product?: Product,
    state?: Option,
    age?: string,
    funeralAmount?: string,
    paymentMethod?: Option,
  ) => void;
}

export const LeftPane: React.FC<LeftPaneProps> = ({ onCalculate }) => {
  const [selectedProduct, setSelectedProduct] = useState<Product>();
  const [residentState, setResidentState] = useState<Option>();
  const [insuredAge, setInsuredAge] = useState<string | undefined>(undefined);
  const [gender, setGender] =
    useState<'male' | 'female' | undefined>(undefined);
  const [faceAmount, setFaceAmount] = useState<string | undefined>(undefined);
  const [downPayment, setDownPayment] = useState<string>('');
  const [growthRate, setGrowthRate] = useState<Option | undefined>();
  const [paymentMethod, setPaymentMethod] = useState<Option | undefined>();
  const [healthQuestion, setHealthQuestion] =
    useState<'Yes' | 'No' | undefined>();
  const [funeralAmountOrAffordability, setFuneralAmountOrAffordability] =
    useState<'Funeral Amount' | 'Affordability' | undefined>('Funeral Amount');
  const [bump, setBump] = React.useState<'Yes' | 'No'>('No');

  const [ageError, setAgeError] = React.useState(ErrorMessageInit);
  const [faceAmountError, setFaceAmountError] =
    React.useState(ErrorMessageInit);

  const [calculateDisabled, setCalculateDisabled] = React.useState(true);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchStates());
  }, []);

  const validateAgeInput = (value: string) => {
    const notValidNumber = !isNumber(value);
    if (notValidNumber) {
      setAgeError({ error: true, message: 'Value must be numeric' });
    } else if (!value || Number(value) <= 0 || Number(value) > 99) {
      setAgeError({ error: true, message: 'Age must be between 1-99' });
    } else {
      setAgeError({ error: false, message: '' });
      setInsuredAge(value);
    }
  };

  const validateFaceAmountInput = (value: string, label: string) => {
    const notValidNumber = !isNumber(value);
    if (label === 'Affordability') {
      setFaceAmountError({ error: false, message: '' });
      setFaceAmount(value);
    } else if (notValidNumber) {
      setFaceAmountError({ error: true, message: 'Value must be numeric' });
    } else if (Number(value) < 500) {
      setFaceAmountError({
        error: true,
        message: 'Value must be at least 500',
      });
    } else {
      setFaceAmountError({ error: false, message: '' });
      setFaceAmount(value);
    }
  };

  const onClickFuneralAmountOrAffordability = (value) => {
    if (value === 'Affordability') {
      setFaceAmountError({ error: false, message: '' });
    } else if (value === 'Funeral Amount' && Number(faceAmount) < 500) {
      setFaceAmountError({
        error: true,
        message: 'Value must be at least 500',
      });
    }
    setFuneralAmountOrAffordability(value);
  };

  const updateInputsToDefault = (): void => {
    setGrowthRate(undefined);
    setResidentState(undefined);
    setInsuredAge(undefined);
    setFaceAmount(undefined);
    setPaymentMethod(undefined);
    setHealthQuestion(undefined);
    setDownPayment('');
    setGender(undefined);
    setGrowthRate(undefined);
    setFuneralAmountOrAffordability('Funeral Amount');
    setAgeError({ error: false, message: '' });
    setFaceAmountError({ error: false, message: '' });
  };
  useEffect(() => {
    setCalculateDisabled(
      !selectedProduct ||
        !residentState ||
        !insuredAge ||
        ageError.error ||
        !faceAmount ||
        faceAmountError.error ||
        !paymentMethod ||
        (downPaymentVisibility(selectedProduct) && !downPayment) ||
        (healthQuestionVisibility(selectedProduct) && !healthQuestion) ||
        (growthRateVisibility(selectedProduct) && !growthRate) ||
        (funeralAmountAffordabilityOptionVisibility(selectedProduct) &&
          !funeralAmountOrAffordability),
    );
  }, [
    selectedProduct,
    residentState,
    insuredAge,
    ageError,
    faceAmount,
    faceAmountError,
    downPayment,
    growthRate,
    paymentMethod,
    healthQuestion,
    funeralAmountOrAffordability,
  ]);

  const calculate = () => {
    onCalculate(
      bump,
      selectedProduct,
      residentState,
      insuredAge,
      faceAmount,
      paymentMethod,
    );
    dispatch(
      fetchRatePlans(
        selectedProduct,
        residentState,
        faceAmount,
        insuredAge,
        gender,
        bump,
        paymentMethod,
        healthQuestion,
        downPayment,
        growthRate,
        funeralAmountOrAffordability,
      ),
    );
  };
  return (
    <div className={styles['root']} data-testid={'rate-calculator-left-pane'}>
      <div className={styles['header']}>Rate Calculator</div>
      <div className={styles['product-selection']}>
        <div className={styles['title']}> Select Product</div>
        <ProductDropdown
          onProductSelect={(product) => {
            setSelectedProduct(product);
            setCalculateDisabled(true);
            updateInputsToDefault();
          }}
          value={selectedProduct}
        />
      </div>
      <div className={styles['growth-rate']}>
        <GrowthRate
          product={selectedProduct}
          onChange={(value) => setGrowthRate(value)}
          rate={growthRate}
        />
      </div>
      <div className={styles['insured-info']}>
        <InsuredInformation
          product={selectedProduct}
          residentState={{
            onChange: (value) => {
              setResidentState(value);
            },
            state: residentState,
          }}
          gender={{ onClick: (gender) => setGender(gender), gender: gender }}
          healthQuestion={{
            onClick: (value) => setHealthQuestion(value),
            question: healthQuestion,
          }}
          age={{
            validateAgeInput: (ageValue) => {
              validateAgeInput(ageValue);
            },
            error: ageError,
            age: insuredAge,
          }}
        />
      </div>
      <div className={styles['price-variables']}>
        <PriceVariables
          product={selectedProduct}
          faceAmount={{
            onChange: (faceValue, label) => {
              validateFaceAmountInput(faceValue, label);
            },
            error: faceAmountError,
          }}
          downPayment={(value) => setDownPayment(value)}
          paymentMethod={{
            onChange: (value) => setPaymentMethod(value),
            method: paymentMethod,
          }}
          funeralAmountOrAffordability={{
            onClick: (value) => onClickFuneralAmountOrAffordability(value),
            option: funeralAmountOrAffordability,
          }}
          bump={() => setBump('Yes')}
        />
      </div>
      <div className={styles['button']}>
        <PrimaryButton
          text="CALCULATE"
          disabled={calculateDisabled}
          onClick={calculate}
        />
      </div>
    </div>
  );
};
