import produce from 'immer';
import { AddReservesAction, ReservesAction } from './reserves.action';
import { Reserves } from '../../../../../service/service.types';
import { Action } from 'redux';

export type ReservesState = 'INCOMPLETE' | Reserves;

export const reservesReducer = (
  state: ReservesState = 'INCOMPLETE',
  action: Action<ReservesAction>,
): ReservesState => {
  return produce(state, () => {
    switch (action.type) {
      case 'ADD_RESERVES':
        const {
          singlePay,
          creditCard,
          directBill,
          renewal,
          eft,
          minAmount,
          maxAmount,
        } = action as AddReservesAction;
        return {
          singlePay,
          creditCard,
          directBill,
          renewal,
          eft,
          minAmount,
          maxAmount,
        };

      case 'CLEAR_RESERVES':
        return 'INCOMPLETE';

      default:
        return state;
    }
  });
};
