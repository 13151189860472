import { BFFClient } from '../clients/BFFClient';
import { Product } from './service.types';
import { BaseService } from './BaseService';

const ProductServicePaths = {
  MARKETING_PARTNER_PRODUCTS: (branchCode: string) =>
    `/ao/marketing-partners/${branchCode}/products`,
};

export class ProductService extends BaseService {
  static getBFFClient(): BFFClient {
    return new BFFClient();
  }

  static async getProductsByBranchCode(
    branchCode: string,
    credentials: { accessToken: string; idToken: string },
  ): Promise<Product[]> {
    const { products } = await ProductService.getBFFClient().get<{
      products: Product[];
    }>(
      `${ProductServicePaths.MARKETING_PARTNER_PRODUCTS(branchCode)}`,
      ProductService.getBaseHeaders(
        credentials.accessToken,
        credentials.idToken,
      ),
    );

    return products;
  }
}
