import React, { useState } from 'react';
import { RightPane } from './RightPane/RightPane';
import styles from './RateCalculator.module.scss';
import { LeftPane } from './LeftPane/LeftPane';
import { FiltersDataProps } from './RightPane/Quote/PrintQuoteToPDF';

export const RateCalculator: React.FC = () => {
  const [filtersData, setFiltersData] = useState<FiltersDataProps>({
    product: undefined,
    state: undefined,
    age: undefined,
    funeralAmount: undefined,
    bump: '',
    paymentMethod: undefined,
  });

  return (
    <div className={styles['root']} data-testid={'rate-calculator'}>
      <div className={styles['content']}>
        <div className={styles['left-pane']}>
          <LeftPane
            onCalculate={(
              bump,
              product,
              state,
              age,
              funeralAmount,
              paymentMethod,
            ) => {
              setFiltersData({
                product,
                state,
                age,
                funeralAmount,
                bump,
                paymentMethod,
              });
            }}
          />
        </div>
        <div className={styles['right-pane']}>
          <RightPane filtersData={filtersData} />
        </div>
      </div>
    </div>
  );
};
