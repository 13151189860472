import React from 'react';
import styles from './Bump.module.scss';
import { Product } from '../../../../../../../service/service.types';
import { NglicCheckbox } from '@nglic/component-lib';
import { ProductsEnum } from '../../../products.enum';

export interface BumpProps {
  onClick: () => void;
  product?: Product;
}

export const Bump: React.FC<BumpProps> = ({ onClick, product }) => {
  const bumpVisibility = (product: Product | undefined): boolean => {
    return !product?.name.includes(ProductsEnum.SERIES9);
  };

  return (
    <div className={styles['root']} data-testid={'rate-calculator-bump'}>
      {bumpVisibility(product) && (
        <div className={styles['bump']}>
          <div className={styles['bump-title']}>Bump ?</div>
          <div>
            <NglicCheckbox onClick={onClick} />
            Yes, this quote includes a bump
          </div>
        </div>
      )}
    </div>
  );
};
