import React, {
  useState,
  useCallback,
  useRef,
  ChangeEvent,
  useEffect,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import {
  NglicSimpleSelect,
  PrimaryButton,
  NGlicSpinner,
} from '@nglic/component-lib';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import { withTracker } from 'ga-4-react';

import {
  DocumentType,
  DocumentResponse,
} from '../../../../service/service.types';
import { getAgentId } from '../../../shared/selectors/basicUserInfo.selector';

import { ReactComponent as DocumentUploadLogo } from '../../../../assets/img/documentUpload/document-upload.svg';

import styles from './DocumentUpload.module.scss';

import { DocumentPendingUpload } from './document.types';
import { AppState } from '../../../../rootReducer';
import {
  fetchDocuments,
  deleteDocument,
  uploadDocument,
  createClearErrorDocumentAction,
} from './document.action';

interface Option {
  id: string;
  name: string;
  type: DocumentType;
}

const documentOptions: Option[] = [
  {
    id: 'Anti-Money Laundering Training',
    name: 'Anti-Money Laundering Training',
    type: DocumentType.ANTI_MONEY_LAUNDERING_TRAINING,
  },
  {
    id: 'State License',
    name: 'State License',
    type: DocumentType.STATE_LICENSE,
  },
  {
    id: 'Training Documents',
    name: 'Training Documents',
    type: DocumentType.TRAINING_DOCUMENT,
  },
  {
    id: 'Voided Check',
    name: 'Voided Check',
    type: DocumentType.VOIDED_CHECK,
  },
  {
    id: 'Other',
    name: 'Other',
    type: DocumentType.OTHER,
  },
];

const getOptionByName = (name: string) => {
  const option = documentOptions.find((type) => type.name === name);
  return option ?? { id: 'Other', name: 'Other', type: DocumentType.OTHER };
};

const getOptionByType = (type: DocumentType) => {
  const option = documentOptions.find((option) => option.type === type);
  return option ?? { id: 'Other', name: 'Other', type: DocumentType.OTHER };
};

export const DocumentUpload: React.FC<{ routeToNext?: () => void }> = ({
  routeToNext,
}) => {
  const dispatch = useDispatch();
  const [selectedDocumentType, setSelectedDocumentType] = useState<string>('');
  const fileSelector = useRef<HTMLInputElement | null>(null);
  const agentId = useSelector((state: AppState) => getAgentId(state));
  const documents: DocumentResponse[] = useSelector(
    (state: AppState) => state.presentation.contract.documents.documents,
  );

  const error = useSelector((state: AppState) =>
    _.get(state, 'presentation.contract.documents.error'),
  );

  useEffect(() => {
    if (agentId) {
      dispatch(fetchDocuments());
    }
  }, [agentId]);

  const handleOnDocumentTypeChanged = useCallback(
    (newValue: Option) => {
      setSelectedDocumentType(newValue.name);
    },
    [selectedDocumentType],
  );

  const handleOnSelectedFile = useCallback(
    (newValue: File) => {
      const selectedOption = getOptionByName(selectedDocumentType);
      const documentPendingUpload: DocumentPendingUpload = {
        documentType: selectedOption.type,
        file: newValue,
      };
      dispatch(uploadDocument(documentPendingUpload));
      setSelectedDocumentType('');
    },
    [documents, selectedDocumentType],
  );

  const handleOnFileDelete = useCallback(
    (index: number) => {
      dispatch(deleteDocument(documents[index].id));
    },
    [documents],
  );

  const handleFileSelect = () => {
    fileSelector.current?.click();
  };

  const handleNext = () => {
    routeToNext && routeToNext();
  };

  useEffect(() => {
    if (error) {
      window.alert(
        'Oops, we ran into an issue on our end. Please try uploading your documents again.',
      );
      dispatch(createClearErrorDocumentAction());
    }
  }, [error]);

  if (!agentId) {
    return (
      <div className={styles['loading-container']}>
        <NGlicSpinner />
      </div>
    );
  }

  const renderList = () => {
    return (
      <div className={styles['list']}>
        <div className={styles['header']}>
          <div className={styles['name']}>File</div>
          <div className={styles['type']}>Document Type</div>
        </div>
        <div className={styles['divider']}></div>
        <div className={styles['content']}>
          {documents.map((document, index) => {
            return (
              <div className={styles['row']} key={index}>
                <div className={styles['name-container']}>
                  <div className={styles['name']}>{document.name}</div>
                </div>
                <div className={styles['type']}>
                  {getOptionByType(document.type).name}
                </div>
                <div className={styles['icon-container']}>
                  <IconButton
                    className={styles['icon-button']}
                    onClick={() => {
                      handleOnFileDelete(index);
                    }}
                  >
                    <DeleteIcon className={styles['icon']} />
                  </IconButton>
                </div>
              </div>
            );
          })}
        </div>
        <div className={styles['divider']}></div>
      </div>
    );
  };

  return (
    <div className={styles['root']} data-testid="document-upload">
      <div className={styles['icon-container']}>
        <DocumentUploadLogo className={styles['logo']} />
        <div className={styles['title']}>Document Upload</div>
        <div className={styles['description']}>
          Upload your agent documents here to ensure we have everything needed
          to get you contracted. Documents can also be uploaded after your
          contract is completed.
        </div>
      </div>
      <div className={styles['container']}>
        <div className={styles['select-text']}>
          Select file from your computer
        </div>
        <div className={styles['upload-container']}>
          <div className={styles['dropdown']}>
            <NglicSimpleSelect
              selectedOption={selectedDocumentType}
              options={documentOptions}
              label={'Document Type'}
              onChange={(value) => handleOnDocumentTypeChanged(value as Option)}
            />
          </div>
          <div className={styles['choose-file']}>
            <PrimaryButton
              text="Choose File"
              onClick={handleFileSelect}
              disabled={selectedDocumentType === ''}
            />
          </div>
          <input
            id="myInput"
            type="file"
            ref={fileSelector}
            style={{ display: 'none' }}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              const { files } = event.target;
              if (files) {
                const file = files[0];
                handleOnSelectedFile(file);
              }
              if (fileSelector.current) {
                fileSelector.current.value = '';
              }
            }}
          />
        </div>
      </div>
      {documents.length > 0 && renderList()}
      <div className={styles['next']}>
        <PrimaryButton text="Next" onClick={handleNext} />
      </div>
    </div>
  );
};

export const TrackedDocumentUpload: React.FC<any> = withTracker(DocumentUpload);
