import { Option } from '@nglic/component-lib';
import React, { useState } from 'react';
import { Product } from '../../../../service/service.types';
import { ProductDropdown } from '../../../modules/ProductDropdown/ProductDropdown';
import { StatePicker } from '../../../shared/components/StatePicker';
import styles from './ProductInformation.module.scss';

export const ProductInformation: React.FC<{ routeToNext?: () => void }> =
  () => {
    const [selectedProduct, setSelectedProduct] = useState<Product>();
    const [selectedState, setSelectedState] = useState<Option | undefined>();

    return (
      <div className={styles['root']}>
        <div className={styles['header']}>Product Information</div>
        <div className={styles['dropdown-container']}>
          <div className={styles['product-dropdown-container']}>
            <ProductDropdown
              onProductSelect={(product) => {
                setSelectedProduct(product);
              }}
              value={selectedProduct}
            />
          </div>
          <div className={styles['state-dropdown-container']}>
            <StatePicker onSelectedState={(state) => setSelectedState(state)} />
          </div>
        </div>
      </div>
    );
  };
