const base64ToArrayBuffer = (_base64Str: string): Uint8Array => {
  const binaryString = window.atob(_base64Str);
  const binaryLen = binaryString.length;
  const bytes = new Uint8Array(binaryLen).map((value, i) => {
    const ascii = binaryString.charCodeAt(i);
    const newByte = ascii;
    return newByte;
  });
  return bytes;
};

const showDocument = (_base64Str: string, _contentType: string): void => {
  const byte = base64ToArrayBuffer(_base64Str);
  const blob = new Blob([byte], { type: _contentType });
  window.open(URL.createObjectURL(blob), '_blank');
};

export const DocumentClient = {
  base64ToArrayBuffer,
  showDocument,
};
