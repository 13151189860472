import * as jwt from 'jsonwebtoken';
import { ENV } from '../env';
import { JwtHeader, SigningKeyCallback } from 'jsonwebtoken';
import JwksClient from 'jwks-rsa';
import JwksRsa from 'jwks-rsa';

export interface CognitoAccessToken {
  sub: string;
  client_id: string;
  email_verified: boolean;
  token_use: string;
  auth_time: number;
  iss: string;
  'cognito:username': string;
  exp: number;
  given_name: string;
  iat: number;
  email: string;
}

const cognitoPublicKeyURL = `https://cognito-idp.${ENV.REGION}.amazonaws.com/${ENV.POOL_ID}/.well-known/jwks.json`;
const validIssuer = `https://cognito-idp.${ENV.REGION}.amazonaws.com/${ENV.POOL_ID}`;
export const getJwksClient = (): JwksRsa.JwksClient =>
  JwksClient({
    jwksUri: cognitoPublicKeyURL,
  });

export const getKey = (
  header: JwtHeader,
  callback: SigningKeyCallback,
): void => {
  JwtClient.getJwksClient().getSigningKey(header.kid as string, (err, key) => {
    if (!err) {
      callback(null, key.getPublicKey());
    } else {
      callback(err, undefined);
    }
  });
};

const isFromValidIssuer = (iss: string) => {
  return iss === validIssuer;
};

const isExpired = (expTime: number) => {
  return expTime > Date.now();
};

const isCorrectAudience = (aud: string) => {
  return aud === ENV.COGNITO_CLIENT_ID;
};

const verifyAccessToken = (token: string): Promise<boolean> => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return new Promise((resolve, reject) => {
    jwt.verify(token, getKey, {}, function (err, decoded) {
      const token = decoded as CognitoAccessToken;
      if (
        !err &&
        isFromValidIssuer(token.iss) &&
        !isExpired(token.exp) &&
        isCorrectAudience(token.client_id)
      ) {
        resolve(true);
      } else {
        resolve(false);
      }
    });
  });
};

const decodeToken = (token: string): Promise<Record<string, any>> => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return new Promise((resolve, reject) => {
    jwt.verify(token, getKey, {}, function (err, decoded) {
      if (!err) {
        if (!decoded) {
          resolve({});
        } else {
          resolve(decoded as Record<string, any>);
        }
      } else {
        resolve({});
      }
    });
  });
};

export const JwtClient = {
  verifyAccessToken,
  decodeToken,
  getJwksClient,
};
