import React from 'react';
import styles from './HierarchyTableHeader.module.scss';
import { Product } from '../../../../service/service.types';
import { Column } from '../../../modules/DrawerTable/DrawerTable';
import { ProductSlidingDrawer } from '../../../modules/ProductSlidingDrawer/ProductSlidingDrawer';
import { Search } from '../../../modules/Search/Search';

export const HierarchyTableMobileHeader: React.FC<{
  onProductSelect: (product: Product) => void;
  onSearchTextChange: (val: string) => void;
  onSubmitSearch: (val: string) => void;
  columns: Column[];
  openModal: () => void;
  onProductClear: () => void;
  numberOfHierarchies: number;
}> = ({
  onProductSelect,
  onSearchTextChange,
  onSubmitSearch,
  columns,
  openModal,
  onProductClear,
  numberOfHierarchies,
}) => {
  return (
    <React.Fragment>
      <div className={styles['search-container']}>
        <Search
          placeHolder={'Search'}
          onChange={onSearchTextChange}
          id={'hierarchy-search'}
        />
      </div>
      <div className={styles['table-header-container']}>
        <div>{`${numberOfHierarchies} results`}</div>
        <div className={styles['mobile-products-container']}>
          <ProductSlidingDrawer onProductSelect={onProductSelect} />
        </div>
      </div>
    </React.Fragment>
  );
};
