import { BFFClient } from '../clients/BFFClient';
import {
  AgentReserveFundBalance,
  DemographicInformation,
  EarlyPayOff,
  PartialPolicy,
  Policy,
  PolicyClaim,
  PolicyPayment,
} from './service.types';
import { BaseService } from './BaseService';
import { Claim } from '../components/Pages/ManageBusiness/Policies/PolicyDetail/ClaimsModal/claim.types';

const PolicyServicePaths = {
  POLICIES: (
    page = 1,
    limit = 50,
    branchCode,
    agentId: string,
    query?: string,
  ) =>
    `/ao/policies/branch/${branchCode}/agent/${agentId}?page=${page}&limit=${limit}${
      query ? `&query=${query}` : ''
    }`,
  POLICY: (policyId) => `/ao/policy-detail/${policyId}`,
  POLICY_PAYMENTS: (policyId: string) => `/ao/policies/${policyId}/payments`,
  POLICY_EARLY_PAYOFFS: (policyId: string) =>
    `/ao/policies/${policyId}/earlypayoffs`,
  DEMOGRAPHIC_INFORMATIONS: (demographicId: string) =>
    `/ao/policies/${demographicId}/demographic-information`,
  POLICY_CLAIM: (policyId: string) => `/ao/policies/${policyId}/claim`,
  CREATE_POLICY_CLAIM: () => `/ao/policies/claim`,

  AGENT_RESERVE_FUND_BALANCE: (producerIds: string[]) =>
    `/ao/reserve-fund-balance?producerIds=${producerIds}`,
};

interface GetAllPoliciesParams {
  credentials: { accessToken: string; idToken: string };
  page: number;
  limit: number;
  branchCode: string;
  agentId: string;
  query?: string;
}

interface GetPolicyParams {
  credentials: { accessToken: string; idToken: string };
  policyId: string;
}

export class PolicyService extends BaseService {
  static getBFFClient(): BFFClient {
    return new BFFClient();
  }

  static async getAllPolicies({
    credentials,
    branchCode,
    page,
    limit,
    query,
    agentId,
  }: GetAllPoliciesParams): Promise<{
    policies: PartialPolicy[];
    totalPolicies: number;
  }> {
    return await PolicyService.getBFFClient().get<{
      policies: Policy[];
      totalPolicies: number;
    }>(
      PolicyServicePaths.POLICIES(page, limit, branchCode, agentId, query),
      PolicyService.getBaseHeaders(
        credentials.accessToken,
        credentials.idToken,
      ),
    );
  }

  static async getPolicy({ credentials, policyId }: GetPolicyParams): Promise<{
    policy: Policy;
  }> {
    return await PolicyService.getBFFClient().get<{
      policy: Policy;
    }>(
      PolicyServicePaths.POLICY(policyId),
      PolicyService.getBaseHeaders(
        credentials.accessToken,
        credentials.idToken,
      ),
    );
  }

  static async getPolicyPayments(
    credentials: { accessToken: string; idToken: string },
    policyId: string,
  ): Promise<{ policyPayments: PolicyPayment[] }> {
    return await PolicyService.getBFFClient().get<{
      policyPayments: PolicyPayment[];
    }>(
      PolicyServicePaths.POLICY_PAYMENTS(policyId),
      PolicyService.getBaseHeaders(
        credentials.accessToken,
        credentials.idToken,
      ),
    );
  }

  static async getEarlyPayOffs(
    credentials: { accessToken: string; idToken: string },
    policyDetailId: string,
  ): Promise<{ earlyPayOffs: EarlyPayOff[] }> {
    return await PolicyService.getBFFClient().get<{
      earlyPayOffs: EarlyPayOff[];
    }>(
      PolicyServicePaths.POLICY_EARLY_PAYOFFS(policyDetailId),
      PolicyService.getBaseHeaders(
        credentials.accessToken,
        credentials.idToken,
      ),
    );
  }

  static async getBasePersonAddressesAndPhones(
    credentials: { accessToken: string; idToken: string },
    demographicId: string,
  ): Promise<{ demographicInformation: DemographicInformation }> {
    return await PolicyService.getBFFClient().get<{
      demographicInformation: DemographicInformation;
    }>(
      PolicyServicePaths.DEMOGRAPHIC_INFORMATIONS(demographicId),
      PolicyService.getBaseHeaders(
        credentials.accessToken,
        credentials.idToken,
      ),
    );
  }

  static async getPolicyClaim(
    credentials: { accessToken: string; idToken: string },
    policyId: string,
  ): Promise<{ policyClaim: PolicyClaim }> {
    return await PolicyService.getBFFClient().get<{
      policyClaim: PolicyClaim;
    }>(
      PolicyServicePaths.POLICY_CLAIM(policyId),
      PolicyService.getBaseHeaders(
        credentials.accessToken,
        credentials.idToken,
      ),
    );
  }

  static async getAgentReserveFundBalance(
    credentials: { accessToken: string; idToken: string },
    producerIds: string[],
  ): Promise<{ agentReserveFundBalances: AgentReserveFundBalance[] }> {
    return await PolicyService.getBFFClient().get<{
      agentReserveFundBalances: AgentReserveFundBalance[];
    }>(
      PolicyServicePaths.AGENT_RESERVE_FUND_BALANCE(producerIds),
      PolicyService.getBaseHeaders(
        credentials.accessToken,
        credentials.idToken,
      ),
    );
  }

  static async createPolicyClaim(
    claim: Claim,
    policy: Policy,
    credentials: { accessToken: string; idToken: string },
  ): Promise<{ success: boolean }> {
    return await PolicyService.getBFFClient().post<{ success: boolean }>(
      PolicyServicePaths.CREATE_POLICY_CLAIM(),
      {
        claim,
        policy,
      },
      PolicyService.getBaseHeaders(
        credentials.accessToken,
        credentials.idToken,
      ),
    );
  }
}
