import React from 'react';
import { useHistory } from 'react-router';
import styles from './CommissionsTableViewAction.module.scss';

export const CommissionsTableViewAction: React.FC<{
  row: Record<string, any>;
}> = ({ row }) => {
  const history = useHistory();
  return (
    <div
      className={styles['root']}
      onClick={() => history.push(`/manage-business/policies/${row.policyId}`)}
    >
      <span>VIEW</span>
    </div>
  );
};
