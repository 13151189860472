import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core';

// Redux
import { AppState } from '../../../../../rootReducer';
import { SectionName } from '../../../../shared/loading.action';
import { fetchReserveCommissionActivities } from '../commissions.action';
import { BasicInfoState } from '../../../../shared/basicUserInfo.reducer';

// Components
import {
  GenericTable,
  TableFieldsProps,
  useNglScreenSize,
} from '@nglic/component-lib';
import { ReserveAccount } from './Reserves/ReserveAccount';
import { Pagination } from '../../../../modules/Pagination/Pagination';
import { CurrentBalance } from './CurrentBalance/CurrentBalance';

// Helpers
import { substringDate } from '../../Policies/PolicyDetail/PolicyInformation/PolicyInformationTransform';
import {
  AgentReserveFundBalance,
  Reserves,
} from '../../../../../service/service.types';

// Styles
import styles from './ReservesDrawer.module.scss';
import { getUserInformation } from '../../../../shared/selectors/getUserInformation';

const styledBy = (property, mapping) => (props) => mapping[props[property]];

const StyledDrawer = withStyles({
  paper: {
    width: styledBy('screenWidth', {
      default: '40%',
      mobile: '100%',
    }),
    overflow: 'hidden',
  },
})(Drawer);

interface ReservesDrawerProps {
  isOpen: boolean;
  onClose: () => void;
}

export const ReservesDrawer: React.FC<ReservesDrawerProps> = ({
  isOpen,
  onClose,
}) => {
  const ITEMS_PER_PAGE = 8;

  const dispatch = useDispatch();
  const { isMobile } = useNglScreenSize();

  const [loading, setLoading] = useState<boolean>(true);
  const [page, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);

  const totalReserveActivities: number | undefined = useSelector(
    (state: AppState) =>
      state.presentation.manageBusiness.commissions
        .totalReservesCommissionsActivities ?? 0,
  );
  const [reservePercentages, setReservePercentages] =
    useState<Reserves | undefined>(undefined);

  const agentReservesPercentages = useSelector(
    (state: AppState) => state.presentation.contract.agentReservePercentages,
  );

  const [reserveFundBalances, setReserveFundBalances] = useState<
    AgentReserveFundBalance[] | []
  >([]);

  const agentReserveFundBalance = useSelector(
    (state: AppState) => state.presentation.reserveFundBalance,
  );

  const reserveCommissionActivities = useSelector(
    (state: AppState) =>
      state.presentation.manageBusiness.commissions
        .reservesCommissionsActivities,
  );

  const basicInfo = useSelector((state: AppState) => getUserInformation(state));

  const sectionsLoading: SectionName[] = useSelector(
    (state: AppState) => state.presentation.loading.sectionsLoading,
  );

  useEffect(() => {
    if (basicInfo && page !== 1) {
      dispatch(fetchReserveCommissionActivities(page, ITEMS_PER_PAGE));
    }
  }, [basicInfo, page]);

  useEffect(() => {
    setLoading(
      !reserveCommissionActivities ||
        sectionsLoading.includes('FETCH_COMMISSION_ACTIVITIES_REQUEST') ||
        sectionsLoading.includes('FETCH_INITIAL_COMMISSION_ACTIVITIES_REQUEST'),
    );
  }, [reserveCommissionActivities, sectionsLoading]);

  useEffect(() => {
    if (agentReservesPercentages !== 'NOT_INITIALIZED') {
      setReservePercentages(agentReservesPercentages);
    }
  }, [agentReservesPercentages]);

  useEffect(() => {
    if (agentReserveFundBalance !== 'NOT_INITIALIZED') {
      setReserveFundBalances(agentReserveFundBalance);
    }
  }, [agentReserveFundBalance]);

  useEffect(() => {
    if (!totalReserveActivities) {
      setTotalPages(1);
    } else {
      setTotalPages(Math.ceil(totalReserveActivities / ITEMS_PER_PAGE));
    }
  }, [totalReserveActivities]);

  const columnConfig: TableFieldsProps[] = [
    {
      titleName: 'Date',
      dataField: 'commissionsActivityDate',
      displayFormat: (value: string): string => {
        return value && substringDate(value);
      },
    },
    {
      titleName: 'Activity Type',
      dataField: 'commissionsActivityTypeCode',
      onHoverShowText: 'commissionsActivityTypeDescription',
    },
    {
      titleName: 'Amount',
      dataField: 'earned',
      displayFormat: (value: string): string => {
        if (parseInt(value) < 0) {
          const newValue = value?.replace('-', '');
          return (
            newValue && '-$' + newValue?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          );
        } else {
          return value && '$' + value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        }
      },
    },
  ];

  return (
    <div className={styles['root']}>
      <StyledDrawer
        anchor={'right'}
        screenWidth={isMobile ? 'mobile' : 'default'}
        open={isOpen}
        onClose={onClose}
      >
        <div className={styles['drawer-content']}>
          <div className={styles['drawer-top']} />
          <div className={styles['drawer-title']}>
            <h4>Reserve Account</h4>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </div>
          <div className={styles['drawer-body']}>
            <div className={styles['current-balance']}>
              <CurrentBalance reserveFundBalances={reserveFundBalances} />
            </div>
            <GenericTable
              loading={loading}
              pagination={true}
              data={reserveCommissionActivities || []}
              columns={columnConfig}
            >
              <div className={styles['pagination-container']}>
                <Pagination
                  onPageClick={setCurrentPage}
                  numberOfPages={totalPages}
                  currentPage={page}
                />
              </div>
            </GenericTable>
          </div>
        </div>
      </StyledDrawer>
    </div>
  );
};
