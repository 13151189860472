import React from 'react';
import classNames from 'classnames';
import styles from './Reporting.module.scss';

interface ReportingProps {
  selectedCommissionCategory?: CommissionsCategoriesEnum;
  onCommissionCategorySelected?: (
    reportType: CommissionsCategoriesEnum,
  ) => void;
}

export enum CommissionsCategoriesEnum {
  COMMISSIONS_BY_PERIOD = 'commissions_by_period',
  COMMISSIONS_BY_PRODUCT = 'commissions_by_product',
  COMMISSIONS_OVER_TIME = 'commissions_over_time',
  NET_COMMISSIONS = 'net_commissions',
  COMMISSIONS_BY_AGENT = 'commissions_by_agent',
}
export const Reporting: React.FC<ReportingProps> = ({
  selectedCommissionCategory,
  onCommissionCategorySelected,
}) => {
  const handleOnClick = (value: CommissionsCategoriesEnum) => {
    if (onCommissionCategorySelected) {
      onCommissionCategorySelected(value);
    }
  };

  const isSelected = (value: string) => {
    return classNames(styles['content-item'], {
      [`${styles['content-item-selected']}`]:
        selectedCommissionCategory === value,
    });
  };

  const renderItems = () => {
    return (
      <>
        <div
          className={isSelected(
            CommissionsCategoriesEnum.COMMISSIONS_BY_PERIOD,
          )}
          onClick={() =>
            handleOnClick(CommissionsCategoriesEnum.COMMISSIONS_BY_PERIOD)
          }
        >
          By Period
        </div>
        <div
          className={isSelected(
            CommissionsCategoriesEnum.COMMISSIONS_BY_PRODUCT,
          )}
          onClick={() =>
            handleOnClick(CommissionsCategoriesEnum.COMMISSIONS_BY_PRODUCT)
          }
        >
          By Product
        </div>
        <div
          className={isSelected(CommissionsCategoriesEnum.NET_COMMISSIONS)}
          onClick={() =>
            handleOnClick(CommissionsCategoriesEnum.NET_COMMISSIONS)
          }
        >
          Net Commissions
        </div>
        <div
          className={isSelected(
            CommissionsCategoriesEnum.COMMISSIONS_OVER_TIME,
          )}
          onClick={() =>
            handleOnClick(CommissionsCategoriesEnum.COMMISSIONS_OVER_TIME)
          }
        >
          Over Time
        </div>
      </>
    );
  };
  return (
    <div className={styles['root']} data-testid="main-element">
      <div className={styles['header']}>REPORTING</div>
      <div className={styles['content-title']}>COMMISSIONS</div>
      <div className={styles['content']}>{renderItems()}</div>
    </div>
  );
};
