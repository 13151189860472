import React, { useState } from 'react';

import styles from './EditProfile.module.scss';
import LocationAutocompleteComponent, {
  AddressType,
} from '../../../modules/LocationAutocompleteComponent/LocationAutocompleteComponent';
import { Address } from '../../Contract/PersonalInformation/PersonalInformation.types';
import { containsEmptyFieldV2 } from '@nglic/utilities-ts/build';

export const AddressSection: React.FC<{
  address: Address;
  onChange: (params: { address: Address; valid: boolean }) => void;
}> = ({ address, onChange }) => {
  const [addressValue, setAddressValue] = useState<Address>(address);
  const handleChange = (updateAddress: Address) => {
    // TODO use the resulting value from the isValid function provided to the location elem
    const requiredFields: string[] = [
      updateAddress.addressLine,
      updateAddress.city,
      updateAddress.zip,
      updateAddress.state,
    ];
    onChange({
      address: {
        ...updateAddress,
        id: address.id,
        type: address.type,
      },
      valid: !containsEmptyFieldV2(requiredFields),
    });
    setAddressValue(updateAddress);
  };

  return address.id ? (
    <div className={styles['controls']}>
      <div>
        <LocationAutocompleteComponent
          addressType={addressValue.type?.toLowerCase() as AddressType}
          value={addressValue}
          baseInputId={address.id}
          setIsValid={() => {
            return;
          }}
          onAddressChange={handleChange}
        />
      </div>
    </div>
  ) : null;
};
