import React from 'react';
import { Policy } from '../../../../../../service/service.types';
import styles from './PolicyAdditionalInformationMobile.module.scss';
import Calendar from '../../../../../../assets/img/calendarIcon/Calendar.png';
import { substringDate } from './PolicyInformationTransform';

interface PolicyInformationProps {
  policy?: Policy;
}

export const PolicyAdditionalInformationMobile: React.FC<PolicyInformationProps> =
  (props: PolicyInformationProps) => {
    const { policy } = props;
    return (
      <div className={styles['root']}>
        <div className={styles['first-row']}>
          <div className={styles['date-issued']}>
            <div className={styles['icon']}>
              <img src={Calendar} />
            </div>
            <div className={styles['date-issued-value']}>
              {substringDate(policy?.effectiveDate)}
            </div>
            <div className={styles['date-issued-label']}>Date Issued</div>
          </div>
          <div className={styles['policy-owner']}>
            <div className={styles['policy-owner-label']}>POLICY OWNER</div>
            <div className={styles['policy-owner-value']}>
              {policy?.policyOwner?.displayName}
            </div>
          </div>
        </div>
        <div className={styles['second-row']}>
          <div className={styles['header']}>Additional Information</div>
          <div className={styles['title']}>Beneficiary</div>
          <div className={styles['value']}>
            {policy?.beneficiary?.displayName}
          </div>
          <div className={styles['title']}>Trust</div>
          <div className={styles['value']}>{policy?.trust?.displayName}</div>
          <div className={styles['title']}>Funeral Provider</div>
          <div className={styles['value']}>{policy?.funeralHomeName} </div>
        </div>
      </div>
    );
  };
