import { CognitoIdentityServiceProvider } from 'aws-sdk';
import { ENV } from '../env';
import {
  ConfirmForgotPasswordCreds,
  Credentials,
} from '../service/service.types';

export class CognitoClient {
  private client: CognitoIdentityServiceProvider;
  constructor(client?: CognitoIdentityServiceProvider) {
    this.client = client
      ? client
      : new CognitoIdentityServiceProvider({ region: 'us-east-2' });
  }

  generatePasswordResetCode(
    email: string,
  ): Promise<CognitoIdentityServiceProvider.Types.ForgotPasswordResponse> {
    const params: CognitoIdentityServiceProvider.Types.ForgotPasswordRequest = {
      Username: email,
      ClientId: ENV.COGNITO_CLIENT_ID,
    };
    return new Promise((resolve, reject) => {
      this.client.forgotPassword(params, (err, data) => {
        if (err) {
          reject(err);
        } else {
          resolve(data);
        }
      });
    });
  }

  login(
    creds: Credentials,
  ): Promise<CognitoIdentityServiceProvider.Types.InitiateAuthResponse> {
    const params: CognitoIdentityServiceProvider.Types.InitiateAuthRequest = {
      AuthFlow: 'USER_PASSWORD_AUTH',
      AuthParameters: {
        USERNAME: creds.username,
        PASSWORD: creds.password,
      },
      ClientId: ENV.COGNITO_CLIENT_ID,
    };
    return new Promise((resolve, reject) => {
      this.client.initiateAuth(params, (err, data) => {
        if (err) {
          reject(err);
        } else {
          resolve(data);
        }
      });
    });
  }

  respondToChallenge(
    session: string,
    challengeName: string,
    challengeResponse?: Record<string, string>,
    clientMetaData?: Record<string, string>,
  ): Promise<CognitoIdentityServiceProvider.Types.RespondToAuthChallengeResponse> {
    const params: CognitoIdentityServiceProvider.Types.RespondToAuthChallengeRequest =
      {
        ChallengeName: challengeName,
        ClientId: ENV.COGNITO_CLIENT_ID,
        Session: session,
        ChallengeResponses: {
          ...challengeResponse,
        },
        ClientMetadata: {
          ...clientMetaData,
        },
      };
    return new Promise((resolve, reject) => {
      this.client.respondToAuthChallenge(params, (err, data) => {
        if (err) {
          reject(err);
        } else {
          resolve(data);
        }
      });
    });
  }

  refreshTokens(
    refreshToken: string,
  ): Promise<CognitoIdentityServiceProvider.Types.InitiateAuthResponse> {
    const params: CognitoIdentityServiceProvider.Types.InitiateAuthRequest = {
      AuthFlow: 'REFRESH_TOKEN_AUTH',
      AuthParameters: {
        REFRESH_TOKEN: refreshToken,
      },
      ClientId: ENV.COGNITO_CLIENT_ID,
    };
    return new Promise((resolve, reject) => {
      this.client.initiateAuth(params, (err, data) => {
        if (err) {
          reject(err);
        } else {
          resolve(data);
        }
      });
    });
  }

  confirmForgotPassword(
    creds: ConfirmForgotPasswordCreds,
  ): Promise<CognitoIdentityServiceProvider.Types.ConfirmForgotPasswordResponse> {
    const params: CognitoIdentityServiceProvider.Types.ConfirmForgotPasswordRequest =
      {
        Username: creds.username,
        Password: creds.password,
        ConfirmationCode: creds.tempPassword,
        ClientId: ENV.COGNITO_CLIENT_ID,
      };
    return new Promise((resolve, reject) => {
      this.client.confirmForgotPassword(params, (err, data) => {
        if (err) {
          reject(err);
        } else {
          resolve(data);
        }
      });
    });
  }
}
