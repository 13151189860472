import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { AppState } from '../../../../rootReducer';
import { completeMFA, goToLoginSequence } from '../login.action';
import styles from './Redirect.module.scss';
import { NGlicSpinner } from '@nglic/component-lib/build';

export const Redirect: React.FC<{ navTo: (path: string) => void }> = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const loginType = useSelector(
    (state: AppState) => state.presentation.login.loginType,
  );

  useEffect(() => {
    if (loginType === 'SUCCESSFUL_LOGIN') {
      history.replace('/login');
    }
  }, [loginType]);

  const goToLogin = () => {
    dispatch(goToLoginSequence('USERNAME_AND_PASSWORD'));
    history.replace('/login');
  };

  const search = location.search;
  const code = new URLSearchParams(search).get('code') || '';

  useEffect(() => {
    if (code === '') {
      goToLogin();
    } else if (loginType !== 'SUCCESSFUL_LOGIN') {
      dispatch(completeMFA(code));
    }
  });

  return (
    <div className={styles['loading-container']}>
      <NGlicSpinner />
    </div>
  );
};
