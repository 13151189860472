import { Action } from 'redux';
import produce from 'immer';

import {
  AgentActionType,
  FetchAllAgentsSuccessAction,
  FetchAllAgentsFailureAction,
  ResendInviteFailureAction,
  AgentListResendInviteAction,
  AgentInformationResendInviteAction,
} from './agent.action';
import { Agent } from '../../../service/service.types';

export interface ResendInviteState {
  success: boolean;
}
export interface AgentInitState {
  agents: Agent[] | undefined;
  totalAgents: number | undefined;
  agentListResendInvite: ResendInviteState | undefined;
  agentInformationResendInvite: ResendInviteState | undefined;
}

export type AgentErrorState = {
  error: Error;
} & AgentInitState;

export type AgentState = AgentInitState | AgentErrorState;

export const agentReducer = (
  state: AgentInitState = {
    agents: undefined,
    totalAgents: undefined,
    agentListResendInvite: undefined,
    agentInformationResendInvite: undefined,
  },
  action: Action<AgentActionType>,
): AgentState => {
  return produce(state, (draftState: AgentState) => {
    switch (action.type) {
      case 'AGENT_FETCH_SUCCESS':
        draftState = {
          ...draftState,
          agents: (action as FetchAllAgentsSuccessAction).agents,
          totalAgents: (action as FetchAllAgentsSuccessAction).totalAgents,
        };
        return draftState;
      case 'AGENT_FETCH_FAILURE':
        draftState = {
          ...draftState,
          error: (action as FetchAllAgentsFailureAction).error,
          agents: [],
        };
        return draftState;
      case 'AGENT_LIST_RESEND_INVITE_SUCCESS':
        const agentListResendInvite = action as AgentListResendInviteAction;
        console.log(
          'agentListResendInvite inside reducer',
          agentListResendInvite,
        );
        draftState = {
          ...draftState,
          agentListResendInvite: agentListResendInvite.agentListResendInvite,
        };
        return draftState;
      case 'AGENT_LIST_RESEND_INVITE_FAILURE':
        draftState = {
          ...draftState,
          error: (action as ResendInviteFailureAction).error,
          agentListResendInvite: undefined,
        };
        return draftState;
      case 'AGENT_LIST_RESEND_INVITE_CLEAR_RESPONSE':
        draftState = {
          ...draftState,
          agentListResendInvite: undefined,
        };
        return draftState;
      case 'AGENT_INFORMATION_RESEND_INVITE_SUCCESS':
        const agentInformationResendInvite =
          action as AgentInformationResendInviteAction;
        draftState = {
          ...draftState,
          agentInformationResendInvite:
            agentInformationResendInvite.agentInformationResendInvite,
        };
        return draftState;
      case 'AGENT_INFORMATION_RESEND_INVITE_FAILURE':
        draftState = {
          ...draftState,
          error: (action as ResendInviteFailureAction).error,
          agentInformationResendInvite: undefined,
        };
        return draftState;
      case 'AGENT_INFORMATION_RESEND_INVITE_CLEAR_RESPONSE':
        draftState = {
          ...draftState,
          agentInformationResendInvite: undefined,
        };
        return draftState;
      default:
        return state;
    }
  });
};
