import React from 'react';
import { HalfPageLayout } from '@nglic/component-lib/build';
import { UsernameAndPasswordLogin } from './UsernameAndPassword/UsernameAndPasswordLogin';
import { useSelector } from 'react-redux';
import { AppState } from '../../../rootReducer';
import { ResetInitialPassword } from './ResetInitialPassword/ResetInitialPassword';
import { LoginType } from './login.reducer';
import { ForgotPassword } from './ForgotPassword/ForgotPassword';
import { ResetPassword } from './ResetPassword/ResetPassword';
import { ResetPasswordConfirmation } from './ResetPasswordConfirmation/ResetPasswordConfirmation';
import { SuccessfulLogin } from './SuccessfulLogin/SuccessfulLogin';
import { RouteComponentProps, useLocation } from 'react-router-dom';
import { RESET_PASSWORD_ROUTE } from '../../../util/routing';
import { CheckYourEmail } from './MFA/CheckYourEmail';
import { RequestToken } from './MFA/RequestToken';

export const Login: React.FC<
  Pick<RouteComponentProps<{ subPage: string }>, 'match' | 'history'>
> = ({ history }) => {
  const loginType = useSelector(
    (state: AppState) => state.presentation.login.loginType,
  );
  const navTo = (route: string) => {
    history.push(route);
  };

  const getLoginComponent = (loginType: LoginType) => {
    const location = useLocation();

    if (
      location.pathname === RESET_PASSWORD_ROUTE &&
      loginType !== 'CONFIRM_FORGOT_PASSWORD_SUCCESS'
    ) {
      return <ResetPassword />;
    }

    switch (loginType) {
      case 'SUCCESSFUL_LOGIN':
        return <SuccessfulLogin navTo={navTo} />;
      case 'RESET_TEMP_PASSWORD':
        return <ResetInitialPassword />;
      case 'FORGOT_PASSWORD':
        return <ForgotPassword />;
      case 'CONFIRM_FORGOT_PASSWORD':
        return <ResetPassword />;
      case 'CONFIRM_FORGOT_PASSWORD_SUCCESS':
        return <ResetPasswordConfirmation />;
      case 'REQUEST_EMAIL':
        return <RequestToken />;
      case 'CHECK_YOUR_EMAIL':
        return <CheckYourEmail />;
      default:
        return <UsernameAndPasswordLogin />;
    }
  };

  return loginType === 'SUCCESSFUL_LOGIN' ? (
    getLoginComponent(loginType)
  ) : (
    <HalfPageLayout>{getLoginComponent(loginType)}</HalfPageLayout>
  );
};
