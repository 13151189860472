import produce from 'immer';
import {
  GoToLoginSequenceAction,
  LoginActionType,
  LoginError,
  ResetPasswordRequiredAction,
  GenPasswordResetCodeSuccess,
  CustomChallengeRequiredAction,
  CheckYourEmailAction,
} from './login.action';
import { Action } from 'redux';
import { StorageItemKey } from '../../../util/getItemInLocalStorageIfExists';

export type LoginType =
  | 'USERNAME_AND_PASSWORD'
  | 'REQUEST_EMAIL'
  | 'CHECK_YOUR_EMAIL'
  | 'RESET_TEMP_PASSWORD'
  | 'FORGOT_PASSWORD'
  | 'SUCCESSFUL_LOGIN'
  | 'CONFIRM_FORGOT_PASSWORD'
  | 'CONFIRM_FORGOT_PASSWORD_SUCCESS'
  | 'UNSUCCESSFUL_LOGIN';

export interface LoginInitState {
  loginType: LoginType;
}

export type LoginPasswordResetState = LoginInitState & {
  username: string;
};

export type LoginCustomChallengeState = LoginInitState & {
  username: string;
  session: string;
};

export type LoginErrorState = {
  error: true;
  errorMessage?: LoginType;
  accountLocked: boolean;
  username: string;
  password: string;
} & LoginInitState;

export type LoginTempPasswordUpdateState = {
  session: string;
  username: string;
} & LoginInitState;

export type LoginState =
  | LoginInitState
  | LoginPasswordResetState
  | LoginTempPasswordUpdateState
  | LoginCustomChallengeState
  | LoginErrorState;

export function isLoginTempPasswordUpdateState(
  state: LoginState,
): state is LoginTempPasswordUpdateState {
  return (state as LoginTempPasswordUpdateState).session !== undefined;
}

export function isLoginErrorState(state: LoginState): state is LoginErrorState {
  return (state as LoginErrorState).error !== undefined;
}

export function isLoginPasswordResetState(
  state: LoginState,
): state is LoginPasswordResetState {
  return (state as LoginPasswordResetState).username !== undefined;
}

export const loginReducer = (
  state: LoginState = { loginType: 'USERNAME_AND_PASSWORD' },
  action: Action<LoginActionType>,
): LoginState => {
  return produce(state, (draftState: LoginState) => {
    switch (action.type) {
      case 'CUSTOM_CHALLENGE_REQUIRED':
        localStorage.setItem(
          StorageItemKey.MFA_LOGIN_SESSION,
          (action as CustomChallengeRequiredAction).session,
        );
        localStorage.setItem(
          StorageItemKey.MFA_LOGIN_USERNAME,
          (action as CustomChallengeRequiredAction).username,
        );
        draftState = {
          ...draftState,
          session: (action as CustomChallengeRequiredAction).session,
          username: (action as CustomChallengeRequiredAction).username,
          loginType: 'REQUEST_EMAIL',
        };
        return draftState;
      case 'CHECK_YOUR_EMAIL':
        localStorage.setItem(
          StorageItemKey.MFA_LOGIN_SESSION,
          (action as CheckYourEmailAction).session,
        );
        localStorage.setItem(
          StorageItemKey.MFA_LOGIN_USERNAME,
          (action as CheckYourEmailAction).username,
        );
        draftState = {
          ...draftState,
          session: (action as CheckYourEmailAction).session,
          username: (action as CheckYourEmailAction).username,
          loginType: 'CHECK_YOUR_EMAIL',
        };
        return draftState;
      case 'RESET_PASSWORD_REQUIRED':
        draftState = {
          ...draftState,
          session: (action as ResetPasswordRequiredAction).session,
          username: (action as ResetPasswordRequiredAction).username,
          loginType: 'RESET_TEMP_PASSWORD',
        };
        return draftState;
      case 'RESET_TEMP_PASSWORD_FAILURE':
      case 'CUSTOM_CHALLENGE_FAILURE':
      case 'LOGIN_FAILURE':
        localStorage.removeItem(StorageItemKey.MFA_LOGIN_USERNAME);
        localStorage.removeItem(StorageItemKey.MFA_LOGIN_SESSION);
        localStorage.removeItem(StorageItemKey.MFA_DEVICE_INFORMATION);
        draftState = {
          ...draftState,
          loginType: 'UNSUCCESSFUL_LOGIN',
          error: true,
          accountLocked: (action as LoginError).accountLocked,
          username: (action as LoginError).username,
          password: (action as LoginError).password,
        };
        return draftState;
      case 'CLEAR_PASSWORD_ERROR':
        draftState = {
          loginType: 'UNSUCCESSFUL_LOGIN',
        };
        return draftState;
      case 'RESET_TEMP_PASSWORD_SUCCESS':
      case 'LOGIN_SUCCESS':
        localStorage.removeItem(StorageItemKey.MFA_LOGIN_USERNAME);
        localStorage.removeItem(StorageItemKey.MFA_LOGIN_SESSION);
        localStorage.removeItem(StorageItemKey.MFA_DEVICE_INFORMATION);
        draftState.loginType = 'SUCCESSFUL_LOGIN';
        return draftState;
      case 'GO_TO_LOGIN_SEQUENCE':
        draftState.loginType = (action as GoToLoginSequenceAction).sequence;
        return draftState;
      case 'GEN_PASSWORD_RESET_CODE_SUCCESS':
        draftState = {
          ...draftState,
          loginType: 'CONFIRM_FORGOT_PASSWORD',
          username: (action as GenPasswordResetCodeSuccess).username,
        };
        return draftState;
      case 'RESET_PASSWORD_SUCCESS':
        draftState = {
          ...draftState,
          loginType: 'CONFIRM_FORGOT_PASSWORD_SUCCESS',
        };
        return draftState;
      default:
        return state;
    }
  });
};
