import React, { useCallback, useEffect, useState } from 'react';
import { CommissionsCategoriesEnum, Reporting } from './Reporting/Reporting';
import styles from './Performance.module.scss';
import { ReportData } from './ReportData/ReportData';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../../rootReducer';
import { fetchReportData } from './ReportData/reportData.action';
import { fetchReportFilters } from './ReportData/reportFilters.action';
import { getUserInformation } from '../../../shared/selectors/getUserInformation';

export const Performance: React.FC<{ routeToNext?: () => void }> = ({}) => {
  const dispatch = useDispatch();
  const userInfo = useSelector((state: AppState) => getUserInformation(state));
  const [selectedCommissionCategory, setSelectedCommissionCategory] =
    useState<CommissionsCategoriesEnum>(
      CommissionsCategoriesEnum.COMMISSIONS_BY_PERIOD,
    );

  useEffect(() => {
    if (userInfo) {
      dispatch(fetchReportFilters());
      dispatch(
        fetchReportData(selectedCommissionCategory, {
          dateGrouping: 'this_year',
        }),
      );
    }
  }, [userInfo, selectedCommissionCategory]);

  const onCommissionCategorySelected = useCallback(
    (value?: CommissionsCategoriesEnum) => {
      if (value) {
        setSelectedCommissionCategory(value);
      }
    },
    [selectedCommissionCategory],
  );
  return (
    <div className={styles['root']} data-testid="main-element">
      <div className={styles['left-pane']}>
        <Reporting
          selectedCommissionCategory={selectedCommissionCategory}
          onCommissionCategorySelected={onCommissionCategorySelected}
        />
      </div>
      <div className={styles['right-pane']}>
        <ReportData reportCategory={selectedCommissionCategory} />
      </div>
    </div>
  );
};
