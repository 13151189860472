import React, { useEffect, useRef, useState } from 'react';
import { NglicModal } from '@nglic/component-lib';
import { TextInput } from '@nglic/component-lib/build';
import styles from './QuoteDetailsModal.module.scss';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { EmailInput } from '../../../../../../modules/EmailInput/EmailInput';
import { PhoneInput } from '../../../../../../modules/PhoneInput/PhoneInput';
import { containsEmptyFieldV2, isEmailValid } from '@nglic/utilities-ts/build';

import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../../../../rootReducer';
import { getUserInformation } from '../../../../../../shared/selectors/getUserInformation';
import { FiltersDataProps, PrintQuoteToPDF } from '../PrintQuoteToPDF';
import ReactToPrint from 'react-to-print';
import { RatePlanItem } from '../QuoteTable/QuoteTable';

interface QuoteDetailsModalProps {
  open: boolean;
  handleClose: () => void;
  filtersData: FiltersDataProps;
  tableData: RatePlanItem[];
}

export const QuoteDetailsModal: React.FC<QuoteDetailsModalProps> = ({
  open,
  handleClose,
  filtersData,
  tableData,
}) => {
  const componentRef = useRef(null);
  const basicInfo = useSelector((state: AppState) => getUserInformation(state));

  const [downloadButtonDisabled, setDownloadButtonDisabled] = useState(true);
  const [quoteDetailsData, setQuoteDetailsData] = React.useState({
    fullName: basicInfo?.firstName + ' ' + basicInfo?.lastName,
    email: basicInfo?.email || '',
    phoneNumber: '',
    clientName: '',
  });

  const handleOnChangeInput = React.useCallback(
    (inputName: string, newValue: string) => {
      setQuoteDetailsData({
        ...quoteDetailsData,
        [inputName]: newValue,
      });
    },
    [quoteDetailsData],
  );
  useEffect(() => {
    const isValid = isEmailValid(quoteDetailsData.email);
    const values = Object.values(quoteDetailsData);
    setDownloadButtonDisabled(containsEmptyFieldV2(values) || !isValid);
  }, [quoteDetailsData]);

  const setToDefaultState = () => {
    setQuoteDetailsData({
      fullName: basicInfo?.firstName + ' ' + basicInfo?.lastName,
      email: basicInfo?.email || '',
      phoneNumber: '',
      clientName: '',
    });
  };
  return (
    <NglicModal isOpened={open}>
      <div className={styles['root']} data-testid="claim-details-modal">
        <div className={styles['header']} onClick={handleClose}>
          <div className={styles['title']}>Customize Quote</div>
          <IconButton
            onClick={() => {
              handleClose();
              setToDefaultState();
            }}
            className={styles['icon-button']}
          >
            <CloseIcon className={styles['close-icon']} />
          </IconButton>
        </div>
        <div className={styles['content']}>
          <div className={styles['sub-title']}>Agent Information</div>
          <div className={styles['input']}>
            <TextInput
              value={quoteDetailsData.fullName}
              label={'Full Name'}
              onChange={(value) => handleOnChangeInput('fullName', value)}
            />
          </div>
          <div className={styles['input']}>
            <EmailInput
              label={'Email Address'}
              email={quoteDetailsData.email}
              onChange={(value) => handleOnChangeInput('email', value)}
            />
          </div>
          <div className={styles['input']}>
            <PhoneInput
              label={'Phone Number'}
              onChange={(value) => handleOnChangeInput('phoneNumber', value)}
              currentValue={quoteDetailsData.phoneNumber}
            />
          </div>
          <div className={styles['sub-title']}>Client Name</div>
          <TextInput
            label={'Prospective Client Name'}
            onChange={(value) => handleOnChangeInput('clientName', value)}
          />
        </div>
        <div className={styles['footer']}>
          <div>
            <ReactToPrint
              trigger={() => (
                <div
                  className={classNames(styles['download'], {
                    [`${styles['download-disabled']}`]: downloadButtonDisabled,
                  })}
                >
                  DOWNLOAD
                </div>
              )}
              content={() => componentRef.current}
            />
            <div style={{ display: 'none' }}>
              <PrintQuoteToPDF
                ref={componentRef}
                agentInformation={quoteDetailsData}
                ratePlans={tableData}
                filtersData={filtersData}
              />
            </div>
          </div>
        </div>
      </div>
    </NglicModal>
  );
};
