import { Action } from 'redux';
import produce from 'immer';
import { DocumentResponse } from '../../../../service/service.types';
import {
  AddedDocumentAction,
  ConfirmedDocumentAction,
  DeleteDocumentAction,
  DeleteDocumentFailureAction,
  DocumentActionType,
  UploadDocumentFailureAction,
  GetDocumentsAction,
  GetDocumentsFailureAction,
} from './document.action';

export interface DocumentInitState {
  documents: DocumentResponse[];
}

export type DocumentErrorState = {
  error: Error;
} & DocumentInitState;

export type DocumentState = DocumentInitState | DocumentErrorState;

export const documentReducer = (
  state: DocumentState = { documents: [] },
  action: Action<DocumentActionType>,
): DocumentState => {
  return produce(state, (draftState: DocumentState) => {
    switch (action.type) {
      case 'DOCUMENT_GET_DOCUMENTS':
        const documents = (action as GetDocumentsAction).documents;
        return {
          ...draftState,
          documents,
        };
      case 'DOCUMENT_ADDED_DOCUMENT':
        const document = (action as AddedDocumentAction).document;
        return {
          ...draftState,
          documents: [...draftState.documents, document],
        };
      case 'DOCUMENT_UPLOADED_DOCUMENT':
        return state;
      case 'DOCUMENT_CONFIRMED_DOCUMENT':
        const confirmedDocument = (action as ConfirmedDocumentAction).document;
        return {
          ...draftState,
          documents: [...state.documents].map((document) => {
            const doc = { ...document };
            if (doc.id === confirmedDocument.id) {
              doc.isConfirmed = true;
            }
            return doc;
          }),
        };
      case 'DOCUMENT_DELETED':
        const documentId = (action as DeleteDocumentAction).documentId;
        const newDocuments = state.documents.filter(
          (doc) => doc.id !== documentId,
        );
        return {
          ...draftState,
          documents: newDocuments,
        };
      case 'DOCUMENT_UPLOAD_FAILURE':
        draftState = {
          ...draftState,
          error: (action as UploadDocumentFailureAction).error,
        };
        return draftState;
      case 'DOCUMENT_DELETE_FAILURE':
        draftState = {
          ...draftState,
          error: (action as DeleteDocumentFailureAction).error,
        };
        return draftState;
      case 'DOCUMENT_GET_DOCUMENTS_FAILURE':
        draftState = {
          ...draftState,
          error: (action as GetDocumentsFailureAction).error,
        };
        return draftState;
      case 'DOCUMENT_CLEAR_ERROR':
        draftState = {
          ...draftState,
          error: undefined,
        };
        return draftState;
      default:
        return state;
    }
  });
};
