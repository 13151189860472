import React from 'react';
import { IconButton } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { NglicModal, DateRangePicker } from '@nglic/component-lib';
import styles from './DatePickerModal.module.scss';
import 'react-day-picker/lib/style.css';

export interface DatePickerModalProps {
  isOpen: boolean;
  onClose: (range: { from: string; to: string }) => void;
  onCancel: () => void;
}

export const DatePickerModal: React.FC<DatePickerModalProps> = ({
  isOpen = false,
  onClose,
  onCancel,
}) => {
  return (
    <NglicModal isOpened={isOpen}>
      <div className={styles['modal-content']}>
        <header className={styles['header']}>
          <IconButton onClick={onCancel}>
            <CloseIcon />
          </IconButton>
        </header>
        <DateRangePicker
          onRangeChange={(range) => {
            onClose(range);
          }}
        />
      </div>
    </NglicModal>
  );
};
