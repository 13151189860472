import React from 'react';
import { AgentInformation } from './AgentInformation/AgentInformation';
import styles from './AddAgent.module.scss';
import { SetReserves } from './Reserves/SetReserves';
import {
  MobileStepperHeader,
  NglicBreadcrumbs,
  PageEnum,
  SectionTitle,
} from '@nglic/component-lib';
import {
  AddAgentHeaderTitleEnum,
  AddAgentSubnavigationItem,
} from './AddAgent.types';
import { NewAgentHierarchy } from './Hierarchy/NewAgentHierarchy';
import { Review } from './Review/Review';
import { RouteComponentProps } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../rootReducer';
import { AddAgentState } from './addagent.reducer';
import { AppPageLayoutWrapper } from '../../AppPageLayoutWrapper';
import InfoIcon from '@material-ui/icons/Info';
import classNames from 'classnames';

const routePrefix = '/agent/add-agent';
export enum AddAgentPages {
  GENERAL_INFO = 'general-information',
  HIERARCHY = 'hierarchy',
  RESERVES = 'reserves',
  REVIEW = 'review',
}

export const addAgentRoutes = {
  GENERAL_INFO: `${routePrefix}/${AddAgentPages.GENERAL_INFO}`,
  HIERARCHY: `${routePrefix}/${AddAgentPages.HIERARCHY}`,
  RESERVES: `${routePrefix}/${AddAgentPages.RESERVES}`,
  REVIEW: `${routePrefix}/${AddAgentPages.REVIEW}`,
  AGENT_MANAGEMENT: '/agent',
};

const pageOrder: Record<AddAgentPages, number> = {
  [AddAgentPages.GENERAL_INFO]: 1,
  [AddAgentPages.HIERARCHY]: 2,
  [AddAgentPages.RESERVES]: 3,
  [AddAgentPages.REVIEW]: 4,
};

const getLastCompletedPage = (state: AddAgentState) => {
  const agentInfoComplete = state.generalInformation !== 'INCOMPLETE';
  const hierarchyValues = Object.values(state.hierarchy);
  const hierarchyComplete = hierarchyValues.reduce<boolean>((acc, next) => {
    return acc || Object.values(next?.newAgentHierarchies ?? {}).length > 0;
  }, hierarchyValues.length > 0);

  const reservesComplete = state.reserves !== 'INCOMPLETE';

  if (agentInfoComplete && hierarchyComplete && reservesComplete) {
    return AddAgentPages.REVIEW;
  }

  if (agentInfoComplete && hierarchyComplete) {
    return AddAgentPages.RESERVES;
  }

  if (agentInfoComplete) {
    return AddAgentPages.HIERARCHY;
  }

  return AddAgentPages.GENERAL_INFO;
};

export const AddAgent: React.FC<
  Pick<RouteComponentProps<{ subPage: string }>, 'match' | 'history'>
> = (props) => {
  const { match, history } = props;
  const subPage = match.params.subPage;
  const addAgentState = useSelector(
    (state: AppState) => state.presentation.addAgent,
  );

  React.useEffect(() => {
    const lastCompletedPage = getLastCompletedPage(addAgentState);
    const lastCompletedPageNumber = pageOrder[lastCompletedPage];
    const currentPageNumber = pageOrder[subPage];

    if (currentPageNumber > lastCompletedPageNumber) {
      history.push(`${routePrefix}/${lastCompletedPage}`);
    }
  }, []);

  const agentInfoComplete = addAgentState.generalInformation !== 'INCOMPLETE';
  const hierarchyComplete = true;
  const reservesComplete = addAgentState.reserves !== 'INCOMPLETE';
  const createRouteTo = (route: string) => () => {
    history.push(route);
  };

  const mobileSubnavItems: AddAgentSubnavigationItem[] = [
    {
      currentPage: AddAgentHeaderTitleEnum.GENERAL_INFORMATION,
      nextPage: AddAgentHeaderTitleEnum.HIERARCHY,
      active: subPage === AddAgentPages.GENERAL_INFO,
    },
    {
      currentPage: AddAgentHeaderTitleEnum.HIERARCHY,
      nextPage: AddAgentHeaderTitleEnum.RESERVES,
      active: subPage === AddAgentPages.HIERARCHY,
    },
    {
      currentPage: AddAgentHeaderTitleEnum.RESERVES,
      nextPage: AddAgentHeaderTitleEnum.REVIEW,
      active: subPage === AddAgentPages.RESERVES,
    },
    {
      currentPage: AddAgentHeaderTitleEnum.REVIEW,
      active: subPage === AddAgentPages.REVIEW,
    },
  ];

  const breadcrumbItems = [
    {
      label: AddAgentHeaderTitleEnum.GENERAL_INFORMATION.toUpperCase(),
      active: subPage === AddAgentPages.GENERAL_INFO,
      onClick: createRouteTo(addAgentRoutes.GENERAL_INFO),
    },
    {
      label: AddAgentHeaderTitleEnum.HIERARCHY.toUpperCase(),
      active: subPage === AddAgentPages.HIERARCHY,
      onClick: agentInfoComplete
        ? createRouteTo(addAgentRoutes.HIERARCHY)
        : undefined,
    },
    {
      label: AddAgentHeaderTitleEnum.RESERVES.toUpperCase(),
      active: subPage === AddAgentPages.RESERVES,
      onClick: hierarchyComplete
        ? createRouteTo(addAgentRoutes.RESERVES)
        : undefined,
    },
    {
      label: AddAgentHeaderTitleEnum.REVIEW.toUpperCase(),
      active: subPage === AddAgentPages.REVIEW,
      onClick: reservesComplete
        ? createRouteTo(addAgentRoutes.REVIEW)
        : undefined,
    },
  ];

  const renderComponent = (page: string) => {
    switch (page) {
      case AddAgentPages.GENERAL_INFO:
        return (
          <AgentInformation
            routeToNext={createRouteTo(addAgentRoutes.HIERARCHY)}
          />
        );
      case AddAgentPages.HIERARCHY:
        return (
          <NewAgentHierarchy
            routeToNext={createRouteTo(addAgentRoutes.RESERVES)}
          />
        );
      case AddAgentPages.RESERVES:
        return (
          <SetReserves routeToNext={createRouteTo(addAgentRoutes.REVIEW)} />
        );
      case AddAgentPages.REVIEW:
        return (
          <Review
            routeTo={history.push}
            routeToNext={createRouteTo(addAgentRoutes.AGENT_MANAGEMENT)}
          />
        );
    }
  };

  const renderTitle = (page: string) => {
    switch (page) {
      case AddAgentPages.GENERAL_INFO:
        return AddAgentHeaderTitleEnum.GENERAL_INFORMATION;
      case AddAgentPages.HIERARCHY:
        return AddAgentHeaderTitleEnum.HIERARCHY;
      case AddAgentPages.RESERVES:
        return AddAgentHeaderTitleEnum.RESERVES;
      case AddAgentPages.REVIEW:
        return AddAgentHeaderTitleEnum.REVIEW;
    }
  };
  return (
    <AppPageLayoutWrapper title={'Add Agent'} page={PageEnum.AGENT}>
      <div className={styles['root']}>
        <div className={styles['bread-crumbs']}>
          <NglicBreadcrumbs items={breadcrumbItems} />
        </div>
        <div className={styles['mobile-stepper']}>
          <MobileStepperHeader items={mobileSubnavItems} />
        </div>
        <div
          className={classNames(styles['container'], {
            [styles['review-page-container']]: subPage === 'review',
            [styles['reserves-page-container']]: subPage === 'reserves',
          })}
        >
          <span className={styles['section-title']}>
            <SectionTitle>{renderTitle(subPage)}</SectionTitle>
          </span>
          {renderComponent(subPage)}
        </div>
      </div>
    </AppPageLayoutWrapper>
  );
};
