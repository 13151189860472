import React from 'react';
import styles from './TitleWithButtonInParallel.module.scss';
import { Link } from '@nglic/component-lib/build';
import HelpIcon from '@material-ui/icons/Help';
import { Tooltip } from '@material-ui/core';

export interface TitleWithEditButtonProps {
  title: string;
  onButtonClick?: () => void;
  removeButton?: boolean;
  removeBorder?: boolean;
  decreaseSpaceBetween?: boolean;
  helpIcon?: boolean;
  toolTipMessage?: string;
  customStyle?: boolean;
}

export const TitleWithButtonInParallel: React.FC<TitleWithEditButtonProps> = ({
  title,
  onButtonClick,
  removeButton,
  removeBorder,
  decreaseSpaceBetween,
  helpIcon,
  toolTipMessage,
  customStyle,
}: TitleWithEditButtonProps) => {
  return (
    <div
      className={
        styles[decreaseSpaceBetween ? 'less-space-container' : 'container']
      }
      style={removeBorder ? { borderColor: '#FFFFFF' } : {}}
    >
      <p className={customStyle ? styles['custom-title'] : styles['title']}>
        {title}
      </p>
      {!removeButton &&
        ((!helpIcon && <Link text="Edit" onClick={onButtonClick} />) || (
          <Tooltip title={toolTipMessage ? toolTipMessage : ''}>
            <HelpIcon
              className={
                customStyle ? styles['custom-tool-tip'] : styles['tool-tip']
              }
            />
          </Tooltip>
        ))}
    </div>
  );
};
