import React, { useState, useEffect } from 'react';
import styles from './Overview.module.scss';
import { QuickLinks } from './QuickLinks/QuickLinks';
import { RecentCommissionActivity } from './RecentCommissionActivity/RecentCommissionActivity';
import { RecentPolicies } from './RecentPolicies/RecentPolicies';
import { RateCalculator } from '../RateCalculator/RateCalculator';
import { isUserFuneralHome } from '@nglic/utilities-ts/build';

import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../../rootReducer';
import { getUserInformation } from '../../../shared/selectors/getUserInformation';
import {
  TemplateFactory,
  TemplateType,
} from '../../../cms-templates/TemplateFactory';
import {
  Announcement,
  AnnouncementLocationEnum,
  AnnouncementStatusEnum,
} from '../../../../service/service.types';
import { fetchAnnouncements } from './announcement.action';
import { fetchAllFeaturesVisibility } from '../../../shared/features.action';

export const Overview: React.FC<{ routeToNext?: () => void }> = () => {
  const [rateCalculatorShow, setRateCalculatorShow] = useState(false);
  const basicInfo = useSelector((state: AppState) => getUserInformation(state));
  const dispatch = useDispatch();

  const announcements: Announcement[] | undefined = useSelector(
    (state: AppState) => state.presentation.announcements.announcements,
  );

  const [filteredAnnouncements, setFilteredAnnouncements] = useState<
    Announcement[]
  >([]);

  const featureVisibilityState = useSelector(
    (state: AppState) => state.presentation.features,
  );

  useEffect(() => {
    dispatch(fetchAnnouncements());
  }, []);
  useEffect(() => {
    const filteredAnnouncements = announcements?.filter(
      (item) =>
        item?.Announcement_Location?.includes(
          AnnouncementLocationEnum.OVERVIEW_HEADER,
        ) && item?.status === AnnouncementStatusEnum.PUBLISHED,
    );
    setFilteredAnnouncements(filteredAnnouncements as Announcement[]);
  }, announcements);

  const Component = TemplateFactory.getTemplateForType(
    TemplateType.Announcement,
  );
  return (
    <div className={styles['root']}>
      <div className={styles['announcements']}>
        <div className={styles['announcements-list']}>
          {filteredAnnouncements?.map((announcement) => {
            return <Component {...announcement} />;
          })}
        </div>
      </div>
      {!rateCalculatorShow ? (
        <>
          <QuickLinks
            onClickRateCalculator={() => {
              setRateCalculatorShow(true);
            }}
          />
          <RecentPolicies />
          {featureVisibilityState.policyManagementVisible && <RecentPolicies />}
          {!isUserFuneralHome(basicInfo?.roles) &&
            featureVisibilityState.commissionsVisible && (
              <RecentCommissionActivity />
            )}
        </>
      ) : (
        <RateCalculator />
      )}
    </div>
  );
};
