import produce from 'immer';
import { AddApproverAction } from './approver.action';

export type Approver = {
  approverId: string | undefined;
  approverName: string | undefined;
  approverEmail: string | undefined;
};

export type ApproverState = 'INCOMPLETE' | Approver;

export const approverReducer = (
  state: ApproverState = 'INCOMPLETE',
  action: AddApproverAction,
): ApproverState => {
  return produce(state, () => {
    switch (action.type) {
      case 'ADD_APPROVER':
        return {
          approverId: action.approverId,
          approverName: action.approverName,
          approverEmail: action.approverEmail,
        };

      default:
        return state;
    }
  });
};
