import styles from './AgentInformation.module.scss';
import React from 'react';

export const Action: React.FC<{
  frontIcon?;
  endIcon?;
  handleClick?;
  ariaLabel?;
}> = ({ handleClick, children, ariaLabel }) => {
  return (
    <div
      aria-label={ariaLabel}
      className={styles['action-menu-button']}
      onClick={handleClick}
    >
      {children}
    </div>
  );
};
