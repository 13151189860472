import { Action } from 'redux';
import { ContractUserDemographics } from './personalInformation.reducer';

export type PersonalInformationAction = 'ADD_PERSONAL_INFORMATION';

export interface AddPersonalInformationAction
  extends Action<PersonalInformationAction> {
  type: 'ADD_PERSONAL_INFORMATION';
  payload: ContractUserDemographics;
}

export const addPersonalInformation = (
  payload: ContractUserDemographics,
): AddPersonalInformationAction => ({
  type: 'ADD_PERSONAL_INFORMATION',
  payload,
});
