import React from 'react';
import styles from './PaymentHistoryTableHeader.module.scss';

export const PaymentHistoryTableHeader: React.FC = () => {
  return (
    <div className={styles['root']}>
      <div className={styles['title-payor']}>PAYOR</div>
      <div className={styles['title-date']}>PAYMENT DATE</div>
      <div className={styles['title-type']}>TYPE</div>
      <div className={styles['title-amount']}>AMOUNT</div>
    </div>
  );
};
