import React from 'react';
import { Phone } from '../../../../service/service.types';
import { PhoneSection } from './PhoneSection';
import { AddressSection } from './AddressSection';
import styles from './ContactSection.module.scss';
import { Option } from '@nglic/component-lib/build';
import AddCircleIcon from '@material-ui/icons/AddCircleOutline';

import { Address } from '../../Contract/PersonalInformation/PersonalInformation.types';
import getUuidByString from 'uuid-by-string';
import { isUnique } from '@nglic/utilities-ts/build';

export const ContactSection: React.FC<{
  header: string;
  contactMap: {
    addresses: Address[];
    phones: Phone[];
  };
  maxPhones?: number;
  phoneOptions: Option[];
  onAddressChange: (address: { address: Address; valid: boolean }) => void;
  onPhoneChange: (phone: { phone: Phone; valid: boolean }) => void;
  onRemovePhone: (phone: Phone) => void;
  onAddPhone: () => void;
}> = ({
  onAddPhone,
  onPhoneChange,
  onRemovePhone,
  onAddressChange,
  contactMap,
  header,
  phoneOptions,
  maxPhones = 3,
}) => {
  if (contactMap.addresses.length === 0 && contactMap.phones.length) {
    return null;
  }

  return (
    <div className={styles['root']}>
      <div className={styles['header']}>{header}</div>
      <div className={styles['sub-header']}>Address</div>
      {contactMap.addresses.map((address) => (
        <AddressSection
          key={
            address.id ? address.id : getUuidByString(JSON.stringify(address))
          }
          address={address}
          onChange={onAddressChange}
        />
      ))}
      <div className={styles['sub-header']}>Phone</div>
      {contactMap.phones.map((phone) => (
        <PhoneSection
          isRemovable={contactMap.phones.length > 1}
          key={phone.id ? phone.id : getUuidByString(JSON.stringify(phone))}
          phoneOptions={phoneOptions}
          currentContact={phone}
          onChange={onPhoneChange}
          onRemove={onRemovePhone}
        />
      ))}
      {contactMap.phones.length < maxPhones && (
        <div onClick={onAddPhone} className={styles['add-phone']}>
          <AddCircleIcon className={styles['add-icon']} /> {' Add phone number'}
        </div>
      )}
      {!isUnique(contactMap.phones.map((phone) => phone.type as string)) && (
        <div className={styles['phone-error']}>
          You are only allowed a single phone number for each phone type
        </div>
      )}
    </div>
  );
};
