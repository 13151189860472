import { Action } from 'redux';
import { AgentReserveFundBalance } from '../../../../../../service/service.types';
import { ThunkAction } from 'redux-thunk';
import { AppState } from '../../../../../../rootReducer';
import { setGlobalError } from '../../../../../shared/error.action';
import {
  loadComplete,
  loadStarted,
} from '../../../../../shared/loading.action';
import { getCredentials } from '../../../../../shared/selectors/getCredentials';
import { PolicyService } from '../../../../../../service/PolicyService';
import { AgentService } from '../../../../../../service/AgentService';
import { getUserInformation } from '../../../../../shared/selectors/getUserInformation';

export type ReserveFundBalanceActionTypes =
  | 'FETCH_RESERVE_FUND_BALANCE_REQUEST'
  | 'FETCH_RESERVE_FUND_BALANCE_SUCCESS'
  | 'FETCH_RESERVE_FUND_BALANCE_FAILURE'
  | 'NONE';

export interface FetchReserveFundBalanceSuccessAction
  extends Action<ReserveFundBalanceActionTypes> {
  type: 'FETCH_RESERVE_FUND_BALANCE_SUCCESS';
  result: AgentReserveFundBalance[];
}

export interface FetchReserveFundBalanceFailureAction
  extends Action<ReserveFundBalanceActionTypes> {
  type: 'FETCH_RESERVE_FUND_BALANCE_FAILURE';
  error: Error;
}

export const fetchAgentReserveFundBalance =
  (): ThunkAction<void, AppState, any, any> =>
  async (dispatch, getState: () => AppState) => {
    const tokens = getCredentials(getState());
    const userInfo = getUserInformation(getState());
    dispatch(loadStarted('FETCH_RESERVE_FUND_BALANCE_REQUEST'));

    if (!userInfo) {
      dispatch(setGlobalError(new Error('basicInfo not initialized')));
      dispatch(loadComplete('FETCH_RESERVE_FUND_BALANCE_REQUEST'));
      return;
    }

    const { agentId, branchCode } = userInfo;
    const agent = await AgentService.getAgent(agentId, branchCode, tokens);

    const promise = async (): Promise<{
      agentReserveFundBalances: AgentReserveFundBalance[];
    }> => {
      return await PolicyService.getAgentReserveFundBalance(
        tokens,
        agent?.producerIds,
      );
    };

    dispatch({
      types: [
        'FETCH_RESERVE_FUND_BALANCE_REQUEST',
        'FETCH_RESERVE_FUND_BALANCE_SUCCESS',
        'FETCH_RESERVE_FUND_BALANCE_FAILURE',
      ],
      promise,
      bypassGlobalError: true,
    });
    loadComplete('FETCH_RESERVE_FUND_BALANCE_REQUEST');
  };
