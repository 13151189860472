import produce from 'immer';
import { Action } from 'redux';
import {
  TerminateAgentActionType,
  TerminateAgentFailureAction,
} from './agentTermination.action';

interface AgentTermination {
  success: boolean;
  error?: Error;
}
export type AgentTerminationState = 'INCOMPLETE' | AgentTermination;

export const terminateAgentReducer = (
  state: AgentTerminationState = 'INCOMPLETE',
  action: Action<TerminateAgentActionType>,
): AgentTerminationState => {
  return produce(state, () => {
    switch (action.type) {
      case 'TERMINATE_AGENT':
        return {
          success: true,
          error: undefined,
        };
      case 'TERMINATE_AGENT_FAILURE':
        return {
          success: true,
          error: (action as TerminateAgentFailureAction).error,
        };
    }
  });
};
