import { Action } from 'redux';
import produce from 'immer';

import {
  PoliciesActionType,
  FetchAllPoliciesSuccessAction,
  FetchAllPoliciesFailureAction,
  FetchPolicyClaimSuccessAction,
  FetchPolicyFailureSuccess,
  FetchAllCCPDocumentsSuccessAction,
} from './policies.action';
import {
  CCPDocument,
  PartialPolicy,
  Policy,
  PolicyClaim,
} from '../../../../service/service.types';

export interface PolicyInitState {
  initialPolicies: PartialPolicy[];
  selectedPolicy: Policy | undefined;
  policies: PartialPolicy[] | undefined;
  totalPolicies: number | undefined;
  policyClaim: PolicyClaim | undefined;
  documents: CCPDocument[] | undefined;
  funeralHomePolicies: boolean | undefined;
}

export type PolicyErrorState = {
  error: Error;
} & PolicyInitState;

export type PoliciesState = PolicyInitState | PolicyErrorState;

export const policiesReducer = (
  state: PolicyInitState = {
    initialPolicies: [],
    policies: undefined,
    selectedPolicy: undefined,
    totalPolicies: undefined,
    policyClaim: undefined,
    documents: undefined,
    funeralHomePolicies: false,
  },
  action: Action<PoliciesActionType>,
): PoliciesState => {
  return produce(state, (draftState: PoliciesState) => {
    switch (action.type) {
      case 'POLICIES_FETCH_INITIAL_SUCCESS':
        draftState = {
          ...draftState,
          initialPolicies: (action as FetchAllPoliciesSuccessAction).policies,
          totalPolicies: (action as FetchAllPoliciesSuccessAction)
            .totalPolicies,
          funeralHomePolicies: (action as FetchAllPoliciesSuccessAction)
            .funeralHomePolicies,
        };
        return draftState;
      case 'POLICIES_FETCH_INITIAL_FAILURE':
        draftState = {
          ...draftState,
          error: (action as FetchAllPoliciesFailureAction).error,
          policies: [],
        };
        return draftState;
      case 'POLICIES_FETCH_SUCCESS':
        draftState = {
          ...draftState,
          policies: (action as FetchAllPoliciesSuccessAction).policies,
          totalPolicies: (action as FetchAllPoliciesSuccessAction)
            .totalPolicies,
          funeralHomePolicies: (action as FetchAllPoliciesSuccessAction)
            .funeralHomePolicies,
        };
        return draftState;
      case 'POLICIES_FETCH_FAILURE':
        draftState = {
          ...draftState,
          error: (action as FetchAllPoliciesFailureAction).error,
          policies: [],
        };
        return draftState;
      case 'POLICY_CLAIM_FETCH_SUCCESS':
        draftState.policyClaim = (
          action as FetchPolicyClaimSuccessAction
        ).policyClaim;
        return draftState;
      case 'FETCH_POLICY_REQUEST_SUCCESS':
        draftState.selectedPolicy = (
          action as FetchPolicyFailureSuccess
        ).policy;
        return draftState;
      case 'FETCH_POLICY_REQUEST_FAILURE':
        draftState.selectedPolicy = undefined;
        return draftState;
      case 'POLICY_CLAIM_FETCH_FAILURE':
        draftState.policyClaim = undefined;
        return draftState;
      case 'RESET_POLICIES':
        draftState.policyClaim = undefined;
        draftState.policies = undefined;
        draftState.initialPolicies = [];
        draftState.selectedPolicy = undefined;
        draftState.totalPolicies = undefined;
        return draftState;
      case 'FETCH_CPP_DOCUMENTS_FAILURE':
        draftState = {
          ...draftState,
          error: (action as FetchAllPoliciesFailureAction).error,
          documents: [],
        };
        return draftState;
      case 'FETCH_CPP_DOCUMENTS_SUCCESS':
        draftState = {
          ...draftState,
          documents: (action as FetchAllCCPDocumentsSuccessAction).result
            .documents,
        };
        return draftState;
      default:
        return state;
    }
  });
};
