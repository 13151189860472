import produce from 'immer';
import { BackgroundData, BackgroundTitlesEnum } from './Background.types';
import {
  BackgroundAction,
  HandleSelectRowAction,
  HandleWillReceiveCopy,
} from './background.action';
import { Action } from 'redux';

export interface BackgroundState {
  backgroundData: Array<BackgroundData>;
  willReceiveCopyOfBackgroundCheck: boolean;
}

export const initialState: BackgroundState = {
  backgroundData: [
    {
      title: BackgroundTitlesEnum.COMPLAINT_FILED,
      selected: '',
      stateNames: [],
    },
    {
      title: BackgroundTitlesEnum.FILED_BANKRUPTCY,
      selected: '',
      explanation: '',
    },
    {
      title: BackgroundTitlesEnum.JUDGEMENT,
      selected: '',
      explanation: '',
    },
    {
      title: BackgroundTitlesEnum.FELONY,
      selected: '',
      explanation: '',
    },
    {
      title: BackgroundTitlesEnum.BEEN_BONDED,
      selected: '',
      explanation: '',
    },
    {
      title: BackgroundTitlesEnum.HAD_A_LICENSE,
      selected: '',
      explanation: '',
    },
    {
      title: BackgroundTitlesEnum.INDEBTED,
      selected: '',
      explanation: '',
    },
  ],
  willReceiveCopyOfBackgroundCheck: false,
};

export const backgroundReducer = (
  state = initialState,
  action: Action<BackgroundAction>,
): BackgroundState => {
  return produce(state, () => {
    switch (action.type) {
      case 'SELECT_ROW_INPUT':
        const selectRowAction = action as HandleSelectRowAction;
        const elementIndex = state.backgroundData.findIndex(
          (backgroundTitle) =>
            backgroundTitle.title === selectRowAction.payload.title,
        );
        const newArray = [...state.backgroundData];
        newArray[elementIndex] = {
          title: selectRowAction.payload.title,
          selected: selectRowAction.payload.selected,
          explanation: selectRowAction.payload.explanation,
          stateNames: selectRowAction.payload.states,
        };
        return {
          ...state,
          backgroundData: newArray,
        };
      case 'SET_BACKGROUND_CHECK_COPY':
        const willReceiveCopyOfBackgroundCheck =
          action as HandleWillReceiveCopy;
        return {
          ...state,
          willReceiveCopyOfBackgroundCheck:
            willReceiveCopyOfBackgroundCheck.payload
              .willReceiveCopyOfBackgroundCheck,
        };
      default:
        return state;
    }
  });
};
