import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import { RatePlanItem } from './RightPane/Quote/QuoteTable/QuoteTable';
import { RateCalculatorService } from '../../../../service/RateCalculatorService';
import { loadComplete, loadStarted } from '../../../shared/loading.action';
import { setGlobalError } from '../../../shared/error.action';
import { getCredentials } from '../../../shared/selectors/getCredentials';
import { AppState } from '../../../../rootReducer';
import { Product } from '../../../../service/service.types';
import { Option } from '@nglic/component-lib';

export type RateCalculatorActionType =
  | 'RATE_CALCULATOR_FETCH_SUCCESS'
  | 'RATE_CALCULATOR_FETCH_FAILURE'
  | 'NONE';

export interface FetchRatePlansSuccessAction
  extends Action<RateCalculatorActionType> {
  type: 'RATE_CALCULATOR_FETCH_SUCCESS';
  result: {
    ratePlanItems: RatePlanItem[];
  };
}

export interface FetchRatePlansFailureAction
  extends Action<RateCalculatorActionType> {
  error: Error;
}

export interface AddUserErrorAction extends Action<RateCalculatorActionType> {
  type: 'RATE_CALCULATOR_FETCH_FAILURE';
  error: Error;
}

export const fetchRatePlans =
  (
    product: Product | undefined,
    state: Option | undefined,
    funeralAmount: string | undefined,
    age: string | undefined,
    gender: string | undefined,
    bump: string | 'No',
    mode: Option | undefined,
    healthQuestion?: string,
    downpayment?: string,
    growthRate?: Option | undefined,
    funeralAmountOrAffordability?: string,
  ): ThunkAction<void, AppState, any, any> =>
  async (dispatch, getState) => {
    dispatch(loadStarted('GET_RATE_PLANS'));

    const tokens = getCredentials(getState());

    if (!product) {
      dispatch(setGlobalError(new Error('Product not provided!')));
      dispatch(loadComplete('GET_RATE_PLANS'));
      return;
    }
    const promise = async (): Promise<{ ratePlanItems: RatePlanItem[] }> => {
      return await RateCalculatorService.getRatePlans({
        credentials: tokens,
        product,
        state,
        funeralAmount,
        age,
        gender,
        bump,
        mode,
        healthQuestion,
        downpayment,
        growthRate,
        funeralAmountOrAffordability,
      });
    };

    dispatch({
      types: [
        'GET_RATE_PLANS',
        'RATE_CALCULATOR_FETCH_SUCCESS',
        'RATE_CALCULATOR_FETCH_FAILURE',
      ],
      promise,
      bypassGlobalError: false,
    });
    loadComplete('GET_RATE_PLANS');
  };
