import React from 'react';
import styles from './CommissionsTableActivityTypeAction.module.scss';

export const CommissionsTableActivityTypeAction: React.FC<{
  row: { commissionsActivityTypeCode: string; description: string };
}> = ({ row }) => {
  const displayTest = (typeCode: string, description: string) => {
    if (typeCode.startsWith('Z')) {
      return (
        <div>
          {typeCode}
          <div className={styles['on-hover-description']}>{description}</div>
        </div>
      );
    }
    return <div>{description}</div>;
  };

  return (
    <div className={styles['root']}>
      <div className={styles['content']}>
        {displayTest(row.commissionsActivityTypeCode, row.description)}
      </div>
    </div>
  );
};
