import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../../../rootReducer';
import { SectionName } from '../../../../../shared/loading.action';
import styles from './PolicyInformationHeader.module.scss';
import {
  ClaimStatus,
  Policy,
  PolicyClaim,
} from '../../../../../../service/service.types';
import { ContactModal } from '../ContactModal';
import { ClaimsModal } from '../ClaimsModal/ClaimsModal';
import { ClaimDetailsModal } from '../ClaimDetailsModal/ClaimDetailsModal';
import { PrimaryButton } from '@nglic/component-lib/build';
import { getUserInformation } from '../../../../../shared/selectors/getUserInformation';
import { isUserFuneralHome } from '@nglic/utilities-ts/build';

const statusMap = {
  [ClaimStatus.OPEN]: 'Open',
  [ClaimStatus.CANCELLED]: 'Cancelled',
  [ClaimStatus.CANCELLED_FOR_REINSTATEMENT]: 'Cancelled for Reinstatement',
  [ClaimStatus.CLOSED]: 'Closed',
  [ClaimStatus.DENIED]: 'Denied',
  [ClaimStatus.PARTIALLY_PAID]: 'Partially paid',
  [ClaimStatus.PENDING]: 'Pending',
  [ClaimStatus.REOPENED]: 'Reopened',
  [ClaimStatus.VOID]: 'Void',
};

export const PolicyInformationHeader: React.FC<{
  policy?: Policy;
  claim?: PolicyClaim;
}> = ({ policy, claim }) => {
  const [show, setShow] = useState(false);
  const [showClaimsModal, setShowClaimsModal] = useState(false);
  const [showClaimDetailsModal, setShowClaimDetailsModal] = useState(false);

  const [loading, setLoading] = useState<boolean>(true);
  const policyClaim = useSelector(
    (state: AppState) => state.presentation.policies.policyClaim,
  );
  const sectionsLoading: SectionName[] = useSelector(
    (state: AppState) => state.presentation.loading.sectionsLoading,
  );

  const userInfo = useSelector((state: AppState) => getUserInformation(state));

  const showModal = () => {
    setShow(true);
  };
  const onClose = () => {
    setShow(false);
  };

  const onClickClaimsModal = () => {
    setShowClaimsModal(true);
  };

  const onClickClaimDetailsModal = () => {
    setShowClaimDetailsModal(true);
  };

  const onCloseClaimsModal = () => {
    setShowClaimsModal(false);
  };

  const onCloseClaimDetailsModal = () => {
    setShowClaimDetailsModal(false);
  };

  useEffect(() => {
    setLoading(sectionsLoading.includes('POLICY_CLAIM_FETCH_REQUEST'));
  }, [policyClaim, sectionsLoading]);

  if (!policy) {
    return <div />;
  }

  return (
    <div className={styles['policy-information-header']}>
      <div className={styles['policy-information-header-left']}>
        <div className={styles['product-code']}>{policy?.productCode}</div>
        <div className={styles['insured-name']} onClick={showModal}>
          {policy?.insuredName}
        </div>
      </div>
      <div className={styles['policy-information-header-right']}>
        <div className={styles['selling-agent']}>
          <div className={styles['selling-agent-value']}>
            {policy?.agentName}
          </div>
          <div className={styles['selling-agent-label']}>Selling Agent</div>
        </div>
        <div className={styles['policy-information-claims']}>
          {claim?.claimStatus === ClaimStatus.OPEN ||
          claim?.claimStatus === ClaimStatus.REOPENED ||
          claim?.claimStatus === ClaimStatus.PENDING ||
          claim?.claimStatus === ClaimStatus.CLOSED ? (
            <div className={styles['open-claim']}>
              <div className={styles['title']}>CLAIM STATUS</div>
              <div
                className={styles['status']}
                onClick={() => {
                  onClickClaimDetailsModal();
                }}
              >
                {statusMap[claim.claimStatus]}
                <div className={styles['on-hover-claim-status']}>
                  View claim details
                </div>
              </div>
            </div>
          ) : (
            <div className={styles['policy-information-claim-button']}>
              <PrimaryButton
                text={
                  isUserFuneralHome(userInfo?.roles)
                    ? 'START CLAIM'
                    : 'Notify of Death'
                }
                disabled={
                  claim?.claimStatus === ClaimStatus.CLAIM_REQUEST_SUBMITTED ||
                  loading
                }
                onClick={onClickClaimsModal}
              />
            </div>
          )}
        </div>
      </div>
      <ContactModal
        policy={policy}
        open={show}
        handleClose={onClose}
        isInsured={true}
      />

      <ClaimsModal
        open={showClaimsModal}
        handleClose={onCloseClaimsModal}
        policy={policy}
      />

      <ClaimDetailsModal
        claim={claim}
        policy={policy}
        open={showClaimDetailsModal}
        handleClose={onCloseClaimDetailsModal}
      />
    </div>
  );
};
