import styles from './ResetPassword.module.scss';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import logo from '../../../../assets/img/logo/logo-ngl.png';
import logo2x from '../../../../assets/img/logo/logo-ngl@2x.png';
import logo3x from '../../../../assets/img/logo/logo-ngl@3x.png';
import {
  Link,
  PrimaryButton,
  TextInput,
  useNglScreenSize,
} from '@nglic/component-lib/build';
import { resetPassword, goToLoginSequence } from '../login.action';
import { AppState } from '../../../../rootReducer';
import { isLoginPasswordResetState } from '../login.reducer';
import { isPasswordValid } from '@nglic/utilities-ts/build';
import { useHistory } from 'react-router-dom';
import { EmailInput } from '../../../modules/EmailInput/EmailInput';
import {
  PasswordRequirements,
  PasswordValidity,
} from '../PasswordRequirements/PasswordRequirements';
import _ from 'lodash';

export const isNumberRegx = /[\d]/;
export const isSpecialCharacterRegx = /[^((0-9)|(a-z)|(A-Z)|\s)]/;
export const isLowerCaseCharRegx = /[a-z]/;
export const isCapitalLetterRegx = /[A-Z]/;

export const ResetPassword: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { isMobile } = useNglScreenSize();
  const [creds, setCreds] = React.useState({
    username: '',
    tempPassword: '',
    newPassword: '',
    confirmPassword: '',
  });
  const [passwordValidity, setPasswordValidity] =
    React.useState<PasswordValidity>({
      minChars: undefined,
      capitalLetter: undefined,
      lowercaseLetter: undefined,
      number: undefined,
      specialChar: undefined,
    });
  const [submitDisabled, setSubmitDisabled] = React.useState(true);
  const [usernameError, setUsernameError] = React.useState({
    error: false,
    message: '',
  });
  const [newPasswordError, setNewPasswordError] = React.useState({
    error: false,
    message: '',
  });
  const [confirmPasswordError, setConfirmPasswordError] = React.useState({
    error: false,
    message: '',
  });
  const [loading, setLoading] = React.useState(false);
  const sectionsLoading = useSelector(
    (state: AppState) => state.presentation.loading.sectionsLoading,
  );

  React.useEffect(() => {
    setLoading(sectionsLoading.includes('RESET_PASSWORD'));
  }, [sectionsLoading]);

  const username = useSelector((state: AppState) =>
    isLoginPasswordResetState(state.presentation.login)
      ? state.presentation.login.username
      : '',
  );

  React.useEffect(() => {
    setSubmitDisabled(
      !Boolean(creds.newPassword.length) ||
        !Boolean(creds.confirmPassword.length) ||
        !Boolean(creds.tempPassword.length) ||
        creds.confirmPassword !== creds.newPassword,
    );
  }, [creds]);

  const newPasswordHandler = React.useCallback(
    (val: string) => {
      setNewPasswordError({ message: '', error: false });
      setCreds({
        ...creds,
        newPassword: val,
      });

      setPasswordValidity({
        minChars: val.length >= 8,
        capitalLetter: isCapitalLetterRegx.test(val),
        lowercaseLetter: isLowerCaseCharRegx.test(val),
        number: isNumberRegx.test(val),
        specialChar: isSpecialCharacterRegx.test(val),
      });
    },
    [creds],
  );
  const confirmPasswordHandler = React.useCallback(
    (val: string) => {
      setConfirmPasswordError({ message: '', error: false });
      setCreds({
        ...creds,
        confirmPassword: val,
      });
    },
    [creds],
  );

  const usernameHandler = React.useCallback(
    (val: string) => {
      setCreds({
        ...creds,
        username: val,
      });
    },
    [creds],
  );

  const tempPasswordHandler = React.useCallback(
    (val: string) => {
      setCreds({
        ...creds,
        tempPassword: _.trim(val),
      });
    },
    [creds],
  );

  const usernameValidator = React.useCallback(
    (val: string) => {
      let error = {
        error: false,
        message: '',
      };
      if (val === '') {
        error = {
          message: 'Username field cannot be empty.',
          error: true,
        };
      }
      setUsernameError(error);
      return error;
    },
    [creds],
  );

  const confirmPasswordValidator = React.useCallback(
    (val: string) => {
      let error = {
        error: false,
        message: '',
      };
      if (creds.newPassword !== val) {
        error = {
          message: 'The passwords do not match.',
          error: true,
        };
      }
      setConfirmPasswordError(error);
      return error;
    },
    [creds],
  );

  const newPasswordValidator = React.useCallback(
    (val: string) => {
      let error = {
        error: false,
        message: '',
      };
      if (!isPasswordValid(val)) {
        error = {
          message: 'Password must meet business security requirements',
          error: true,
        };
      }
      setNewPasswordError(error);
      return error;
    },
    [creds],
  );

  const submit = React.useCallback(() => {
    if (!submitDisabled) {
      setLoading(true);
      dispatch(
        resetPassword({
          username: username !== '' ? username : creds.username,
          password: creds.newPassword,
          tempPassword: creds.tempPassword,
        }),
      );
    }
  }, [creds, submitDisabled]);

  const handleKeyDown = React.useCallback(
    (e): void => {
      if (e.key === 'Enter') {
        submit();
      }
    },
    [submit],
  );

  const goToLogin = () => {
    dispatch(goToLoginSequence('USERNAME_AND_PASSWORD'));
    history.replace('/login');
  };

  const backToLoginLink = <Link text="Back to Login" onClick={goToLogin} />;
  return (
    <div className={styles['root']} data-testid={'reset-password'}>
      <div onKeyDown={handleKeyDown}>
        <div className={styles['logo-container']}>
          <img
            className={styles['responsive-img']}
            srcSet={`${logo}, ${logo2x} 2x, ${logo3x} 3x`}
          />
          <div className={styles['text-content']}>
            A temporary password has been sent to your email
          </div>
        </div>
        {username === '' && (
          <div
            className={styles['input-container']}
            aria-label={`username-input-${
              creds.username.length ? 'filled' : 'empty'
            }`}
          >
            <EmailInput
              id={'username'}
              onChange={usernameHandler}
              label={'Username'}
              email={creds.username}
              validator={usernameValidator}
            />
          </div>
        )}
        <div
          className={styles['input-container']}
          aria-label={`temp-password-input-${
            creds.tempPassword.length ? 'filled' : 'empty'
          }`}
        >
          <TextInput
            id={'temp-password'}
            onChange={tempPasswordHandler}
            label={'Temporary Password'}
          />
        </div>
        <div
          className={styles['input-container']}
          aria-label={`new-password-input-${
            creds.newPassword.length ? 'filled' : 'empty'
          }`}
        >
          <TextInput
            id={'new-password'}
            onChange={newPasswordHandler}
            label={'New Password'}
            hiddenText={true}
            error={newPasswordError}
            validator={newPasswordValidator}
          />
        </div>
        <div
          className={styles['input-container']}
          aria-label={`confirm-password-input-${
            creds.confirmPassword.length ? 'filled' : 'empty'
          }`}
        >
          <TextInput
            id={'confirm-password'}
            onChange={confirmPasswordHandler}
            label={'Confirm Password'}
            hiddenText={true}
            error={confirmPasswordError}
            validator={confirmPasswordValidator}
          />
        </div>
        {isMobile && (
          <div className={styles['validity']}>
            <PasswordRequirements passwordValidity={passwordValidity} />
          </div>
        )}

        <div className={styles['link-container']}>
          <PrimaryButton
            onKeyDown={handleKeyDown}
            loading={loading}
            onClick={submit}
            disabled={submitDisabled}
            text={'SAVE PASSWORD'}
            ariaLabel={`save-password-button-${
              submitDisabled ? 'inactive' : 'active'
            }`}
          />
          {backToLoginLink}
        </div>
      </div>
      {!isMobile && (
        <PasswordRequirements passwordValidity={passwordValidity} />
      )}
    </div>
  );
};
