import React, { useCallback, useEffect, useState } from 'react';
import { PolicyPayment } from 'service/service.types';

import styles from './PolicyDetail.module.scss';

import { ReactComponent as NoPolicySelectedImage } from 'assets/img/documentUpload/document-upload.svg';
import { PolicyInformation } from './PolicyInformation/PolicyInformation';
import { PaymentHistory } from './PaymentHistory/PaymentHistory';
import { useNglScreenSize } from '@nglic/component-lib';
import { PolicyInformationMobile } from './PolicyInformation/PolicyInformationMobile';
import classNames from 'classnames';
import { PolicyAdditionalInformationMobile } from './PolicyInformation/PolicyAdditionalInformationMobile';
import { PaymentHistoryDetailMobile } from './PaymentHistory/PaymentHistoryDetailMobile';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchCCPDocuments,
  fetchPolicy,
  fetchPolicyClaim,
} from '../policies.action';
import { AppState } from '../../../../../rootReducer';
import { PolicyInformationHeader } from './PolicyDetailHeader/PolicyInformationHeader';
import { Correspondence } from './Correspondence/Correspondence';

export const NoPolicySelected: React.FC = () => {
  return (
    <div className={styles['root']}>
      <div
        className={styles['no-policy-selected']}
        aria-label={'no policy selected pane'}
      >
        <NoPolicySelectedImage className={styles['image']} />
        <div className={styles['text']}>
          Search and select a policy to view more details
        </div>
      </div>
    </div>
  );
};

export const PolicyDetail: React.FC<{ policyId: string }> = ({ policyId }) => {
  const dispatch = useDispatch();
  const policyClaim = useSelector(
    (state: AppState) => state.presentation.policies.policyClaim,
  );
  const policy = useSelector(
    (state: AppState) => state.presentation.policies.selectedPolicy,
  );
  const sectionsLoading = useSelector(
    (state: AppState) => state.presentation.loading.sectionsLoading,
  );
  const loadingPolicyInformation = sectionsLoading.includes(
    'FETCH_POLICY_REQUEST',
  );
  const documents = useSelector(
    (state: AppState) => state.presentation.policies.documents,
  );

  const [selectedPolicyPayment, setSelectedPolicyPayment] =
    useState<PolicyPayment | undefined>(undefined);

  const onPolicyPaymentSelected = useCallback(
    (policyPayment?: PolicyPayment) => {
      setSelectedPolicyPayment(policyPayment);
    },
    [selectedPolicyPayment],
  );

  useEffect(() => {
    dispatch(fetchPolicyClaim(policyId));
    dispatch(fetchPolicy(policyId));
  }, [policyId]);

  useEffect(() => {
    if (policy) {
      dispatch(fetchCCPDocuments(policy.policyNumber));
    }
  }, [policy]);

  const { isMobile } = useNglScreenSize();
  return (
    <div className={styles['root']}>
      <div
        className={classNames(
          styles['policy-selected'],
          isMobile && styles['policy-selected-mobile'],
        )}
      >
        <div className={styles['content']}>
          {!isMobile && (
            <>
              <PolicyInformationHeader policy={policy} claim={policyClaim} />
              <PolicyInformation
                policy={policy}
                loading={loadingPolicyInformation}
              />
              <PaymentHistory
                policyId={policy?.id}
                onPolicyPaymentSelected={onPolicyPaymentSelected}
              />
              {documents && documents.length > 0 && (
                <Correspondence documents={documents} />
              )}
            </>
          )}
          {isMobile &&
            (selectedPolicyPayment ? (
              <PaymentHistoryDetailMobile
                onPolicyPaymentSelected={() =>
                  onPolicyPaymentSelected(undefined)
                }
                policyPayment={selectedPolicyPayment}
              />
            ) : (
              <>
                <PolicyInformationMobile policy={policy} />
                <PaymentHistory
                  policyId={policy?.id}
                  onPolicyPaymentSelected={onPolicyPaymentSelected}
                />
                <PolicyAdditionalInformationMobile policy={policy} />
              </>
            ))}
        </div>
      </div>
    </div>
  );
};
