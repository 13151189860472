import React from 'react';
import styles from './Row.module.scss';
import classNames from 'classnames';
import { Link } from '@nglic/component-lib/build';
import { Column } from '../DrawerTable';

interface RowProps<T> {
  columns: Array<Column>;
  rowData: T;
}

export const Row = <T,>(props: RowProps<T>) => {
  const { columns, rowData } = props;
  const renderColumn = (column: Column, data: T, index) => {
    const { isLink, dataLocation, linkText, style } = column;
    const renderRenderLink = isLink && data[dataLocation];
    return renderRenderLink ? (
      <div
        key={index}
        onClick={column.onClick}
        className={classNames(styles['column'], {
          [styles['link']]: column.isLink,
        })}
        style={style}
      >
        <Link text={linkText || ''} onClick={data[dataLocation]} />
      </div>
    ) : (
      <div
        key={index}
        onClick={column.onClick}
        className={classNames(styles['column'], {
          [styles['link']]: column.isLink,
        })}
        style={style}
      >
        {data[dataLocation]}
      </div>
    );
  };

  return (
    <div className={styles['row']}>
      {columns.map((column, index) => renderColumn(column, rowData, index))}
    </div>
  );
};
