import React, { useCallback, useState } from 'react';
import { Address } from '../../Contract/PersonalInformation/PersonalInformation.types';
import {
  Option,
  PrimaryButton,
  SecondaryButton,
} from '@nglic/component-lib/build';
import {
  Agent,
  AllContactType,
  Contact,
  Phone,
  ServiceAddress,
} from '../../../../service/service.types';
import { Divider } from '@material-ui/core';

import styles from './EditProfile.module.scss';
import { ContactSection } from './ContactSection';
import { v4 as uuidv4 } from 'uuid';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../rootReducer';
import { useNglScreenSize } from '@nglic/component-lib';

const createContactMap = (contacts: Contact[]) => {
  return contacts.reduce<{
    addresses: Contact[];
    phones: Contact[];
  }>(
    (acc, next) => {
      if (
        next.type === AllContactType.WORK ||
        next.type === AllContactType.HOME
      ) {
        acc.addresses.push(next);
      } else {
        acc.phones.push(next);
      }
      return acc;
    },
    { addresses: [], phones: [] },
  );
};

const isCellUnique = (values: { phone: Phone; valid: boolean }[]): boolean => {
  const uniques = values.filter((p) => p.phone.type === 'CELL');
  return uniques.length > 1;
};

export const EditProfile: React.FC<{
  onSave: (agent: any) => void;
  onSuccess: () => void;
  onCancel: () => void;
  currentAgent?: Agent;
}> = ({ onSave, currentAgent, onCancel, onSuccess }) => {
  const { isMobile } = useNglScreenSize();

  const [agent] = useState<Agent | undefined>(currentAgent);

  const saving = useSelector((state: AppState) =>
    state.presentation.loading.sectionsLoading.includes('EDIT_PROFILE'),
  );

  const editProfile = useSelector(
    (state: AppState) => state.presentation.editProfile,
  );

  React.useEffect(() => {
    if (editProfile === 'NOT_INITIALIZED') {
      return;
    }

    if (editProfile.success) {
      onSuccess();
    }
  }, [editProfile]);

  const contactGroups = agent?.contacts.reduce<{
    work: Contact[];
    home: Contact[];
  }>(
    (acc, next) => {
      if (next.type?.toLocaleLowerCase().includes('work')) {
        return {
          ...acc,
          work: [...acc.work, next],
        };
      } else {
        return {
          ...acc,
          home: [...acc.home, next],
        };
      }
    },
    {
      work: [],
      home: [],
    },
  );

  const transformContactsToAddress = (
    contacts: Contact[],
  ): { address: Address; valid: boolean }[] => {
    return contacts.map((contact) => {
      const address = contact.rawContact as ServiceAddress;
      return {
        valid: true,
        address: {
          ...address,
          addressLine: address.addressLine1,
          unit: address.addressLine2,
          type: address.addressType,
        },
      };
    });
  };

  const transformContactsToPhones = (
    contacts: Contact[],
  ): { phone: Phone; valid: boolean }[] => {
    return contacts.map((contact) => ({
      phone: {
        id: contact.id,
        phone: contact.value,
        type: contact.type as AllContactType,
      },
      valid: contact.value.length === 10,
    }));
  };

  const workContactMap = createContactMap(contactGroups?.work ?? []);
  const homeContactMap = createContactMap(contactGroups?.home ?? []);

  const [workAddresses, setWorkAddresses] = useState<
    { address: Address; valid: boolean }[]
  >(transformContactsToAddress(workContactMap.addresses));
  const [homeAddresses, setHomeAddresses] = useState<
    { address: Address; valid: boolean }[]
  >(transformContactsToAddress(homeContactMap.addresses));

  const [workPhones, setWorkPhones] = useState<
    { phone: Phone; valid: boolean }[]
  >(transformContactsToPhones(workContactMap.phones));
  const [homePhones, setHomePhones] = useState<
    { phone: Phone; valid: boolean }[]
  >(transformContactsToPhones(homeContactMap.phones));

  const [removedHomePhones, setRemovedHomePhones] = useState<Phone[]>([]);
  const [removedWorkPhones, setRemovedWorkPhones] = useState<Phone[]>([]);
  const [submitDisabled, setSubmitDisabled] = React.useState(false);

  const allPhones: { phone: Phone; valid: boolean }[] =
    homePhones.concat(workPhones);

  const handleSave = useCallback(() => {
    if (!agent || submitDisabled) {
      return;
    }
    onSave({
      id: agent.id,
      addresses: [...workAddresses, ...homeAddresses].map(
        (address) => address.address,
      ),
      phones: [...workPhones, ...homePhones].map((phone) => phone.phone),
      removedPhones: [...removedWorkPhones, ...removedHomePhones],
    });
  }, [
    workPhones,
    homePhones,
    workAddresses,
    homeAddresses,
    agent,
    submitDisabled,
  ]);

  const isValidInput = React.useCallback((): boolean => {
    const addressChangesValid = [...workAddresses, ...homeAddresses].every(
      (address) => address.valid,
    );
    const phoneChangesValid = [...workPhones, ...homePhones].every(
      (phone) => phone.valid,
    );

    const validNumberOfPhones = allPhones.length < 6 ? true : false;

    return phoneChangesValid && addressChangesValid && validNumberOfPhones;
  }, [workPhones, homePhones, workAddresses, homeAddresses]);

  React.useEffect(() => {
    setSubmitDisabled(!isValidInput());
  }, [workPhones, homePhones, workAddresses, homeAddresses]);

  const workPhoneOptions: Option[] = [
    {
      id: AllContactType.WORK_FAX,
      name: 'Fax',
    },
    {
      id: AllContactType.WORK_LANDLINE,
      name: 'Landline',
    },
    {
      id: AllContactType.CELL,
      name: 'Cell',
    },
  ];
  const homePhoneOptions = [
    {
      id: AllContactType.HOME_LANDLINE,
      name: 'Landline',
    },
    {
      id: AllContactType.HOME_FAX,
      name: 'Fax',
    },
    {
      id: AllContactType.CELL,
      name: 'Cell',
    },
  ];

  const updatePhoneList = (
    newParam: { phone: Phone; valid: boolean },
    oldPhones: { phone: Phone; valid: boolean }[],
  ) => {
    return oldPhones.reduce<{ phone: Phone; valid: boolean }[]>((acc, next) => {
      if (next.phone.id === newParam.phone.id) {
        return [...acc, newParam];
      }

      return [
        ...acc,
        {
          ...next,
          primary: !newParam.phone.primary && next.phone.primary,
        },
      ];
    }, []);
  };
  const removeFromPhoneList = (
    deletedPhone: Phone,
    allPhones: { phone: Phone; valid: boolean }[],
  ) => {
    return allPhones.reduce<{ phone: Phone; valid: boolean }[]>((acc, next) => {
      if (next.phone.id === deletedPhone.id) {
        return acc;
      } else return [...acc, next];
    }, []);
  };

  const updateAddressList = (
    newParam: { address: Address; valid: boolean },
    oldAddress: { address: Address; valid: boolean }[],
  ) => {
    return oldAddress.reduce<{ address: Address; valid: boolean }[]>(
      (acc, next) => {
        if (next.address.id === newParam.address.id) {
          return [...acc, newParam];
        } else return [...acc, next];
      },
      [],
    );
  };

  const onHomePhoneChange = useCallback(
    (params: { phone: Phone; valid: boolean }) => {
      setHomePhones(updatePhoneList(params, homePhones));
    },
    [homePhones],
  );

  const onWorkPhoneChange = useCallback(
    (params: { phone: Phone; valid: boolean }) => {
      setWorkPhones(updatePhoneList(params, workPhones));
    },
    [workPhones],
  );

  const onWorkAddressChange = useCallback(
    (params: { address: Address; valid: boolean }) => {
      setWorkAddresses(updateAddressList(params, workAddresses));
    },
    [workAddresses],
  );

  const onHomeAddressChange = useCallback(
    (params: { address: Address; valid: boolean }) => {
      setHomeAddresses(updateAddressList(params, homeAddresses));
    },
    [homeAddresses],
  );

  const onRemoveHomePhone = useCallback(
    (phone: Phone) => {
      const newPhones = removeFromPhoneList(phone, homePhones);
      setRemovedHomePhones([...removedHomePhones, phone]);
      setHomePhones(newPhones);
    },
    [removedHomePhones, homePhones],
  );

  const onRemoveWorkPhone = useCallback(
    (phone: Phone) => {
      const newPhones = removeFromPhoneList(phone, workPhones);
      setRemovedWorkPhones([...removedWorkPhones, phone]);
      setWorkPhones(newPhones);
    },
    [removedWorkPhones, workPhones],
  );

  const onAddWorkPhone = () => {
    setWorkPhones([
      ...workPhones,
      {
        phone: {
          id: uuidv4(),
          phone: '',
        } as Phone,
        valid: false,
      },
    ]);
  };

  const onAddHomePhone = () => {
    setHomePhones([
      ...homePhones,
      {
        phone: {
          id: uuidv4(),
          phone: '',
        } as Phone,
        valid: false,
      },
    ]);
  };

  return (
    <div data-testid="edit-profile" className={styles['edit-profile-root']}>
      {isMobile && (
        <div className={styles['mobile-header']}>
          <div className={styles['header-text']}>My Information</div>
          <div onClick={handleSave} className={styles['header-button']}>
            <div style={{ color: '#005F86' }}>SAVE</div>
          </div>
        </div>
      )}
      <div style={{ padding: '10px' }}>
        <div className={styles['email-container']}>
          <p className={styles['header-text']}>Username / Email Address</p>
          <p className={styles['username']}>{agent?.email}</p>
        </div>
        <Divider light />
        <ContactSection
          onAddPhone={onAddHomePhone}
          phoneOptions={homePhoneOptions}
          contactMap={{
            addresses: homeAddresses.map((elem) => elem.address),
            phones: homePhones.map((elem) => elem.phone),
          }}
          header={'Home Contact'}
          onAddressChange={onHomeAddressChange}
          onPhoneChange={onHomePhoneChange}
          onRemovePhone={onRemoveHomePhone}
        />
        <Divider light />
        <ContactSection
          onAddPhone={onAddWorkPhone}
          phoneOptions={workPhoneOptions}
          contactMap={{
            addresses: workAddresses.map((elem) => elem.address),
            phones: workPhones.map((elem) => elem.phone),
          }}
          header={'Work Contact'}
          onAddressChange={onWorkAddressChange}
          onPhoneChange={onWorkPhoneChange}
          onRemovePhone={onRemoveWorkPhone}
        />
        {isCellUnique(allPhones) && (
          <div className={styles['phone-error']}>
            Only one Cell Phone number is allowed
          </div>
        )}
        <Divider light />
        <div className={styles['button-container']}>
          <SecondaryButton text="Cancel" onClick={onCancel} />
          <PrimaryButton
            disabled={submitDisabled}
            text="Save"
            onClick={handleSave}
            loading={saving}
          />
        </div>
        <div className={styles['additional-information']}>
          <p>This will only update your address with NGL.</p>
          <p>
            If you're changing resident states, you will need to email{' '}
            <a href="mailto:contracting@nglic.com">Contracting@nglic.com</a>{' '}
            with an updated license.
          </p>
        </div>
      </div>
    </div>
  );
};
