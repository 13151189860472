import React from 'react';
import styles from './RightPane.module.scss';
import { Quote } from './Quote/Quote';
import RateCalculatorTableLogo from '../../../../../assets/img/rateCalculator/RateCalculatorTableLogo.png';
import { FiltersDataProps } from './Quote/PrintQuoteToPDF';

interface RightPaneProps {
  filtersData: FiltersDataProps;
}

export const RightPane: React.FC<RightPaneProps> = ({ filtersData }) => {
  return (
    <div className={styles['root']}>
      <Quote filtersData={filtersData} />
      <div
        style={{ display: 'flex', justifyContent: 'center', marginTop: '40px' }}
      >
        <img className={styles['image']} src={RateCalculatorTableLogo} />
      </div>
    </div>
  );
};
