import styles from './Profile.module.scss';
import { formatDate, isDateExpired } from '@nglic/component-lib/build';
import classNames from 'classnames';
import React from 'react';
import { StateLicense } from '../../../service/service.types';
import { StateMap } from '../../shared/state.reducer';

export const StateLicenseRecord: React.FC<{
  license: StateLicense;
  stateMap: StateMap;
}> = ({ license, stateMap }) => {
  const isLicenseDateExpired = isDateExpired(license.expirationDate || '');

  return (
    <div className={styles['state-cell']}>
      <div className={styles['name']}>{stateMap[license.state]}</div>
      <div
        className={classNames(styles['date'], {
          [styles['expired']]: true,
        })}
      >
        {isLicenseDateExpired ? 'Expired' : 'Expires'}{' '}
        {formatDate(license.expirationDate || new Date(), 'MM/DD/YYYY')}
      </div>
    </div>
  );
};

export const StateLicenseSection: React.FC<{
  licenses?: StateLicense[];
  stateMap?: StateMap;
}> = ({ licenses, stateMap }) => {
  if (!stateMap || !licenses) {
    return null;
  }

  return (
    <>
      <div className={styles['header-text']}>State License</div>
      {licenses.map((license) => (
        <StateLicenseRecord license={license} stateMap={stateMap} />
      ))}
    </>
  );
};
