import React from 'react';
import { PaymentInput, TextInput } from '@nglic/component-lib/build';
import styles from './SetReserves.module.scss';
import { PrimaryButton } from '@nglic/component-lib';
import { useDispatch, useSelector } from 'react-redux';
import {
  isNumber,
  checkIfMinIsGreaterOrEqualThanMax,
} from '@nglic/utilities-ts/build';
import { addReserves } from './reserves.action';
import { Reserves } from '../../../../../service/service.types';
import { AppState } from '../../../../../rootReducer';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import { ErrorMessage } from '../../../Contract/Background/Background.types';
import SetReservesIcon from '../../../../../assets/img/addAgent/setReserves.svg';

const ErrorMessageInit: ErrorMessage = {
  error: false,
  message: '',
};

const ReservesMinMaxInitError = {
  minAmount: ErrorMessageInit,
  maxAmount: ErrorMessageInit,
};
const InitPaymentInputValidator = {
  singlePay: ErrorMessageInit,
  creditCard: ErrorMessageInit,
  directBill: ErrorMessageInit,
  renewal: ErrorMessageInit,
  eft: ErrorMessageInit,
};

export const SetReserves: React.FC<{ routeToNext?: () => void }> = ({
  routeToNext,
}) => {
  const [reservesData, setReservesData] = React.useState({
    singlePay: '',
    creditCard: '',
    directBill: '',
    renewal: '',
    eft: '',
    minAmount: '',
    maxAmount: '',
  });
  const reduxReservesData = useSelector(
    (state: AppState) => state.presentation.addAgent.reserves,
  );
  const [minMaxAmountError, setMimMaxAmountError] = React.useState(
    ReservesMinMaxInitError,
  );
  const [paymentInputErrorMessage, setPaymentInputErrorMessage] =
    React.useState(InitPaymentInputValidator);
  const [submitDisabled, setSubmitDisabled] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (reduxReservesData !== 'INCOMPLETE') {
      setReservesData({
        singlePay: reduxReservesData.singlePay,
        creditCard: reduxReservesData.creditCard,
        directBill: reduxReservesData.directBill,
        renewal: reduxReservesData.renewal,
        eft: reduxReservesData.eft,
        minAmount: reduxReservesData.minAmount,
        maxAmount: reduxReservesData.maxAmount,
      });
    }
  }, [reduxReservesData]);

  const dispatch = useDispatch();

  const oneFieldIsRequired = (values: Record<string, string>): boolean => {
    for (const [, value] of Object.entries(values)) {
      if (value && parseInt(value) !== 0) {
        return true;
      }
    }
    return false;
  };

  const paymentInputValidator = (parameter: string, value: string) => {
    const valueInt: number = +value;
    const notValid = valueInt < 0 || valueInt > 100;
    const notValidNumber = !isNumber(value);
    const validPercentObj = {
      singlePay: parameter === 'singlePay' ? value : reservesData.singlePay,
      creditCard: parameter === 'creditCard' ? value : reservesData.creditCard,
      directBill: parameter === 'directBill' ? value : reservesData.directBill,
      renewal: parameter === 'renewal' ? value : reservesData.renewal,
      eft: parameter === 'eft' ? value : reservesData.eft,
    };
    if (notValidNumber) {
      setPaymentInputErrorMessage({
        ...paymentInputErrorMessage,
        [parameter]: {
          error: notValidNumber,
          message: notValidNumber ? `Value must be numeric` : '',
        },
      });
    } else {
      setPaymentInputErrorMessage({
        ...paymentInputErrorMessage,
        [parameter]: {
          error: notValid,
          message: notValid ? `Value must be between 0 and 100` : '',
        },
      });
    }
    if (
      value &&
      parseInt(value) !== 0 &&
      (reservesData.maxAmount.length === 0 ||
        parseInt(reservesData.maxAmount) === 0)
    ) {
      setMimMaxAmountError({
        ...minMaxAmountError,
        maxAmount: {
          error: true,
          message: 'Maximum Amount is required',
        },
      });
    } else if (
      oneFieldIsRequired(validPercentObj) &&
      (reservesData.maxAmount.length === 0 ||
        parseInt(reservesData.maxAmount) === 0)
    ) {
      setMimMaxAmountError({
        ...minMaxAmountError,
        maxAmount: {
          error: true,
          message: 'Maximum Amount is required',
        },
      });
    } else {
      setMimMaxAmountError({
        ...minMaxAmountError,
        maxAmount: ErrorMessageInit,
      });
    }
  };

  React.useEffect(() => {
    validateMaxAmountInput(reservesData.maxAmount);
    validateMinAmountInput(reservesData.minAmount);
  }, [reservesData]);

  const validateMaxAmountInput = (maxAmount: string) => {
    const validPercentObj = {
      singlePay: reservesData.singlePay,
      creditCard: reservesData.creditCard,
      directBill: reservesData.directBill,
      renewal: reservesData.renewal,
      eft: reservesData.eft,
    };
    const parsedValue = parseInt(maxAmount);
    if (parsedValue < 0) {
      setMimMaxAmountError({
        ...minMaxAmountError,
        maxAmount: {
          error: true,
          message: 'Maximum cannot be less than zero',
        },
      });
    } else if (!isNumber(maxAmount.replace(/,/g, ''))) {
      setMimMaxAmountError({
        ...minMaxAmountError,
        maxAmount: {
          error: true,
          message: 'Value must be numeric',
        },
      });
    } else if (
      oneFieldIsRequired(validPercentObj) &&
      (maxAmount === '' || parseInt(maxAmount) === 0)
    ) {
      setMimMaxAmountError({
        ...minMaxAmountError,
        maxAmount: {
          error: true,
          message: 'Maximum Amount is required',
        },
      });
    } else if (oneFieldIsRequired(validPercentObj) || maxAmount !== '') {
      setMimMaxAmountError({
        ...minMaxAmountError,
        maxAmount: ErrorMessageInit,
      });
    }
  };

  const validateMinAmountInput = (newMinValue: string) => {
    const parsedMaxValue =
      reservesData.maxAmount != ''
        ? parseInt(reservesData.maxAmount.replace(/,/g, ''))
        : 0;
    const minValue = parseInt(newMinValue.replace(/,/g, ''));
    const notValidNumber = !isNumber(minValue);

    if (checkIfMinIsGreaterOrEqualThanMax(minValue, parsedMaxValue)) {
      setMimMaxAmountError({
        ...minMaxAmountError,
        minAmount: {
          error: true,
          message: 'Minimum cannot be greater than Max Amount',
        },
      });
    } else if (minValue < 0) {
      setMimMaxAmountError({
        ...minMaxAmountError,
        minAmount: {
          error: true,
          message: 'Minimum cannot be less than zero',
        },
      });
    } else if (notValidNumber && newMinValue !== '') {
      setMimMaxAmountError({
        ...minMaxAmountError,
        minAmount: {
          error: notValidNumber,
          message: 'Value must be numeric',
        },
      });
    } else {
      setMimMaxAmountError({
        ...minMaxAmountError,
        minAmount: {
          error: false,
          message: '',
        },
      });
    }
  };

  const handleOnChangeInput = React.useCallback(
    (inputName: keyof Reserves, newValue: string) => {
      setReservesData({
        ...reservesData,
        [inputName]: newValue,
      });
    },
    [reservesData],
  );

  const processNumberInput = (value: string) => {
    const newStr = value.replace(/,/g, '');
    return newStr.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const handleSubmit = React.useCallback(() => {
    const {
      singlePay,
      creditCard,
      directBill,
      renewal,
      eft,
      minAmount,
      maxAmount,
    } = reservesData;
    dispatch(
      addReserves(
        singlePay,
        creditCard,
        directBill,
        renewal,
        eft,
        minAmount.replace(/,/g, ''),
        maxAmount.replace(/,/g, ''),
      ),
    );
    routeToNext && routeToNext();
  }, [reservesData]);

  React.useEffect(() => {
    const notValidPaymentInput =
      paymentInputErrorMessage.singlePay.error ||
      paymentInputErrorMessage.creditCard.error ||
      paymentInputErrorMessage.directBill.error ||
      paymentInputErrorMessage.renewal.error ||
      paymentInputErrorMessage.eft.error ||
      minMaxAmountError.minAmount.error ||
      minMaxAmountError.maxAmount.error;
    setSubmitDisabled(notValidPaymentInput);
  }, [reservesData, minMaxAmountError, paymentInputErrorMessage]);

  return (
    <div
      className={styles['root']}
      data-testid={'agent-set-reserves-component'}
    >
      <div className={styles['reserves-image']}>
        <img src={SetReservesIcon} />
      </div>
      <div className={styles['payment-input']}>
        <PaymentInput
          value={reservesData.singlePay}
          onChange={(newValue: string) => {
            handleOnChangeInput('singlePay', newValue);
            paymentInputValidator('singlePay', newValue);
          }}
          error={paymentInputErrorMessage.singlePay}
        >
          SINGLE PAY
        </PaymentInput>
        <PaymentInput
          value={reservesData.creditCard}
          error={paymentInputErrorMessage.creditCard}
          onChange={(newValue: string) => {
            handleOnChangeInput('creditCard', newValue);
            paymentInputValidator('creditCard', newValue);
          }}
        >
          CREDIT CARD
        </PaymentInput>
        <PaymentInput
          value={reservesData.directBill}
          error={paymentInputErrorMessage.directBill}
          onChange={(newValue: string) => {
            handleOnChangeInput('directBill', newValue);
            paymentInputValidator('directBill', newValue);
          }}
        >
          DIRECT BILL
        </PaymentInput>
        <PaymentInput
          value={reservesData.renewal}
          error={paymentInputErrorMessage.renewal}
          onChange={(newValue: string) => {
            handleOnChangeInput('renewal', newValue);
            paymentInputValidator('renewal', newValue);
          }}
        >
          RENEWAL
        </PaymentInput>
        <PaymentInput
          value={reservesData.eft}
          error={paymentInputErrorMessage.eft}
          onChange={(newValue: string) => {
            handleOnChangeInput('eft', newValue);
            paymentInputValidator('eft', newValue);
          }}
        >
          EFT
        </PaymentInput>
      </div>
      <div className={styles['min-max-amount']}>
        <div className={styles['min-amount']}>
          <TextInput
            frontIcon={<AttachMoneyIcon />}
            value={processNumberInput(reservesData.minAmount)}
            onChange={(newValue: string) => {
              handleOnChangeInput('minAmount', newValue);
              validateMinAmountInput(newValue);
            }}
            error={minMaxAmountError.minAmount}
            label="$ Minimum Amount"
          />
        </div>
        <div className={styles['max-amount']}>
          <TextInput
            frontIcon={<AttachMoneyIcon />}
            value={processNumberInput(reservesData.maxAmount)}
            onChange={(newValue: string) => {
              handleOnChangeInput('maxAmount', newValue);
              validateMaxAmountInput(newValue);
            }}
            error={minMaxAmountError.maxAmount}
            label="$ Maximum Amount"
          />
        </div>
      </div>
      <div className={styles['buttonContainer']}>
        <PrimaryButton
          text="Next"
          onClick={handleSubmit}
          disabled={submitDisabled}
        />
      </div>
    </div>
  );
};
