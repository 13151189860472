import produce from 'immer';
import { Action } from 'redux';
import { Agent } from '../../../service/service.types';
import {
  AddProfileActionTypes,
  EditProfileActionTypes,
  EditProfileSuccessAction,
  FetchUserProfileSuccessAction,
} from './profile.action';

export type AddProfileState = Agent | 'NOT_INITIALIZED';
export type EditProfileState = { success: boolean } | 'NOT_INITIALIZED';

export const addProfileReducer = (
  state: AddProfileState = 'NOT_INITIALIZED',
  action: Action<AddProfileActionTypes>,
): AddProfileState => {
  return produce(state, (draftState) => {
    switch (action.type) {
      case 'FETCH_USER_PROFILE_SUCCESS':
        draftState = (action as FetchUserProfileSuccessAction).result;
        return draftState;
      case 'FETCH_USER_PROFILE_FAILURE':
        draftState = (action as FetchUserProfileSuccessAction).result;
        return draftState;
      case 'FETCH_USER_PROFILE_CLEAR':
        draftState = 'NOT_INITIALIZED';
        return draftState;
      default:
        return state;
    }
  });
};

export const editProfileReducer = (
  state: EditProfileState = 'NOT_INITIALIZED',
  action: Action<EditProfileActionTypes>,
): EditProfileState => {
  return produce(state, (draftState) => {
    switch (action.type) {
      case 'EDIT_PROFILE_SUCCESS':
        draftState = (action as EditProfileSuccessAction).result;
        return draftState;
      case 'EDIT_PROFILE_FAILURE':
        draftState = 'NOT_INITIALIZED';
        return draftState;
      case 'EDIT_PROFILE_CLEAR':
        draftState = 'NOT_INITIALIZED';
        return draftState;
      default:
        return state;
    }
  });
};
