import React, { useState } from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import styles from './SuccessfulLogin.module.scss';
import { NGlicSpinner } from '@nglic/component-lib/build';
import { AppState } from '../../../../rootReducer';
import { fetchBasicUserInfo } from '../../../shared/basicUserInfo.action';
import { OnboardingStatus, Role } from '../../../../service/service.types';
import { getRouteForContractingStatus } from '../../../../util/routing';
import { JwtClient } from '../../../../clients/JwtClient';

export const SuccessfulLogin: React.FC<{ navTo: (path: string) => void }> = ({
  navTo,
}) => {
  const dispatch = useDispatch();
  const [decodedToken, setDecodedToken] = useState<Record<string, any>>();
  const credentials = useSelector(
    (state: AppState) => state.data.user.credentials,
  );
  React.useEffect(() => {
    JwtClient.decodeToken(credentials.idToken).then((res) =>
      setDecodedToken(res),
    );
  }, []);

  React.useEffect(() => {
    if (decodedToken) {
      dispatch(
        fetchBasicUserInfo(
          decodedToken['cognito:groups'] ?? [],
          decodedToken['email'],
        ),
      );
    }
  }, [decodedToken]);

  const basicInfo = useSelector((state: AppState) => state.data.user.basicInfo);
  React.useEffect(() => {
    if (basicInfo && decodedToken) {
      const agentContractingStatus = _.get(
        basicInfo,
        'contractingStatus.agentContractingStatus',
      ) as OnboardingStatus;

      const loggedInUserGroups = decodedToken['cognito:groups'];
      navTo(
        getRouteForContractingStatus(
          agentContractingStatus,
          loggedInUserGroups,
        ),
      );
    }
  }, [basicInfo]);

  return (
    <div className={styles['loading-container']}>
      <NGlicSpinner />
    </div>
  );
};
