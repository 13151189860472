import React, { useCallback, useEffect, useState } from 'react';
import { AppPageLayout, CustomSnackbar, PageEnum } from '@nglic/component-lib';
import { useHistory } from 'react-router-dom';
import { AppState } from '../../rootReducer';
import { useDispatch, useSelector } from 'react-redux';
import { clearGlobalError } from '../shared/error.action';
import { get } from 'lodash';
import { OnboardingStatus } from '../../service/service.types';
import { getRouteForContractingStatus } from '../../util/routing';
import { SubNavigationItemConfig } from './ManageBusiness/ManageBusiness';
import { ENV } from '../../env';
import { fetchAllFeaturesVisibility } from '../shared/features.action';
import styles from './AppPageLAyoutWrapper.module.scss';
import { getUserInformation } from '../shared/selectors/getUserInformation';
import {
  exitMasqueradeUserAction,
  logoutAction,
} from '../shared/logout.action';
import { HiddenAssets } from '../shared/components/HiddenAssets';

interface AppPageLayoutWrapperProps {
  title: string;
  page?: PageEnum;
  subnavConfig?: SubNavigationItemConfig[];
  subHeaderMobileConfig?: React.ReactElement;
}

export const AppPageLayoutWrapper: React.FC<AppPageLayoutWrapperProps> = ({
  children,
  title,
  page,
  subnavConfig,
  subHeaderMobileConfig,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [showSnackbar, setShowSnackbar] = useState<boolean>(false);

  const basicInfo = useSelector((state: AppState) => getUserInformation(state));
  const isMasqueradeUser = useSelector(
    (state: AppState) =>
      state.data.masqueradeUser.basicInfo !== 'NOT_INITIALIZED',
  );
  const featureVisibilityState = useSelector(
    (state: AppState) => state.presentation.features,
  );
  const globalError = useSelector(
    (state: AppState) => state.presentation.error.error,
  );

  useEffect(() => {
    dispatch(fetchAllFeaturesVisibility());
  }, []);

  useEffect(() => {
    switch (globalError?.name) {
      case 'Email is already associated with a user':
        setShowSnackbar(false);
        break;
      default:
        setShowSnackbar(globalError !== null);
    }
  }, [globalError]);

  const pageHrefMap = {
    [PageEnum.AGENT]: '/agent',
    [PageEnum.MANAGE_HIERARCHIES]: '/manage-hierarchies',
    [PageEnum.PROFILE]: '/profile',
    [PageEnum.BUSINESS_MANAGEMENT]: '/manage-business/overview',
    [PageEnum.KNOWLEDGE_CENTER]: '/knowledge-center/product-information',
  };

  const navigateToPage = (page: string) => {
    if (page === PageEnum.LOGOUT) {
      dispatch(logoutAction());
      window.location.reload();
    } else if (Object.keys(pageHrefMap).includes(page)) {
      history.push(pageHrefMap[page]);
    } else {
      history.push(page);
    }
  };

  const handleClose = () => {
    dispatch(clearGlobalError());
    setShowSnackbar(false);
  };

  const handleOnLogoClick = useCallback(() => {
    if (basicInfo) {
      const agentContractingStatus = get(
        basicInfo,
        'contractingStatus.agentContractingStatus',
      ) as OnboardingStatus;

      const userLandingPage = getRouteForContractingStatus(
        agentContractingStatus,
        basicInfo.roles,
      );
      navigateToPage(userLandingPage);
    }
  }, [basicInfo]);

  const onClickExit = () => {
    dispatch(exitMasqueradeUserAction());
    history.push('/support');
  };

  const getMasqueradeUserFullName = () => {
    if (basicInfo) {
      return `${basicInfo.firstName} ${basicInfo.lastName}`;
    }
  };

  return (
    <>
      {/*By default <HiddenAssets/> component is hidden, we want it here when we build the project so the assets are exported to build file*/}
      <HiddenAssets />
      {isMasqueradeUser && window.location.pathname !== '/support' && (
        <div className={styles['agent-support-header']}>
          <div className={styles['exit-button']} onClick={onClickExit}>
            EXIT
          </div>
          <div className={styles['full-name']}>
            Logged in as {getMasqueradeUserFullName()}
          </div>
        </div>
      )}
      <AppPageLayout
        title={title}
        page={page}
        navigateToPage={navigateToPage}
        userRoles={basicInfo?.roles ?? []}
        onLogoClick={handleOnLogoClick}
        subNavConfig={subnavConfig}
        navConfig={[
          {
            page: PageEnum.BUSINESS_MANAGEMENT,
            visible: featureVisibilityState.manageBusinessVisible,
          },
          {
            page: PageEnum.MANAGE_HIERARCHIES,
            visible: featureVisibilityState.manageHierarchiesVisible,
          },
          {
            page: PageEnum.KNOWLEDGE_CENTER,
            visible: featureVisibilityState.knowledgeCenterVisible,
          },
        ]}
        subHeaderMobileConfig={subHeaderMobileConfig}
      >
        {children}
        <CustomSnackbar
          open={showSnackbar}
          vertical="top"
          horizontal="center"
          message={
            `Something went wrong. Please contact NGL Agent Support. \n\n` +
            `NGL Agent Support Contact Info: \n` +
            `Email: ${ENV.SUPPORT_EMAIL} \n` +
            `Phone: 800.988.0826 \n`
          }
          handleClose={handleClose}
        />
      </AppPageLayout>
    </>
  );
};
