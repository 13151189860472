import {
  personalInformationReducer,
  PersonalInformationState,
} from './PersonalInformation/personalInformation.reducer';
import { combineReducers } from 'redux';
import {
  PaymentInformationState,
  paymentMethodReducer,
} from './PaymentMethod/paymentMethod.reducer';
import {
  backgroundReducer,
  BackgroundState,
} from './Background/background.reducer';
import {
  documentReducer,
  DocumentState,
} from './DocumentUpload/document.reducer';
import { reviewReducer, ReviewState } from './Review/review.reducer';
import {
  reservesReducer,
  ReservesState,
} from './Review/reserves/reserves.reducer';

export interface ContractState {
  personalInformation: PersonalInformationState;
  paymentMethod: PaymentInformationState;
  background: BackgroundState;
  documents: DocumentState;
  review: ReviewState;
  agentReservePercentages: ReservesState;
}
export const contractReducer = combineReducers({
  personalInformation: personalInformationReducer,
  paymentMethod: paymentMethodReducer,
  background: backgroundReducer,
  documents: documentReducer,
  review: reviewReducer,
  agentReservePercentages: reservesReducer,
});
