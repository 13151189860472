import React from 'react';
import { AgentHierarchyRow } from './AgentHierarchyRow';
import { Option } from '@nglic/component-lib/build';
import { AgentProduct } from '../../../service/service.types';

export interface HierarchyItemsProps {
  agentProducts?: AgentProduct[];
  onRemoveItem: (agent: AgentProduct) => void;
  onLevelSelect: (agent: AgentProduct, selectedLevel: Option) => void;
  onLevelZeroPositionMoveUp?: (agentProduct: AgentProduct) => void;
  onLevelZeroPositionMoveDown?: (agentProduct: AgentProduct) => void;
}

export const AgentHierarchyGroup: React.FC<HierarchyItemsProps> = ({
  onRemoveItem,
  onLevelSelect,
  agentProducts = [],
  onLevelZeroPositionMoveUp,
  onLevelZeroPositionMoveDown,
}) => (
  <div>
    {agentProducts.map((agent, index) => {
      const maxCommissionLevel = getMaxCommissionLevel(agentProducts, index);
      const levelRange = maxCommissionLevel
        ? {
            min: getMinimumCommissionLevel(agentProducts, index),
            max: maxCommissionLevel,
          }
        : undefined;
      const isLastAgent = index === agentProducts?.length - 1;
      return (
        <AgentHierarchyRow
          agent={agent}
          key={JSON.stringify(agent)}
          onRemoveItem={onRemoveItem}
          onLevelSelect={onLevelSelect}
          levelRange={levelRange}
          isEditable={!isLastAgent}
          onLevelZeroPositionMoveUp={onLevelZeroPositionMoveUp}
          onLevelZeroPositionMoveDown={onLevelZeroPositionMoveDown}
        />
      );
    })}
  </div>
);

const getMaxCommissionLevel = (
  agentProducts: AgentProduct[],
  currentAgentIndex: number,
) => {
  const upline =
    currentAgentIndex !== agentProducts.length - 1
      ? agentProducts[currentAgentIndex + 1]
      : undefined;

  if (!upline) {
    return undefined;
  }

  return parseFloat(upline?.commissionLevel) === 0
    ? 15
    : parseFloat(upline.commissionLevel) - 0.5;
};

const getMinimumCommissionLevel = (
  agentProducts: AgentProduct[],
  currentAgentIndex: number,
) => {
  const downline =
    currentAgentIndex !== 0 ? agentProducts[currentAgentIndex - 1] : undefined;

  return !downline ? 0 : parseFloat(downline.commissionLevel) + 1;
};
