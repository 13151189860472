import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import { AppState } from '../../rootReducer';
import { ProductService } from '../../service/ProductService';
import { Product } from '../../service/service.types';
import { getCredentials } from './selectors/getCredentials';

export type ProductActionType =
  | 'PRODUCT_FETCH_BY_BRANCH_SUCCESS'
  | 'PRODUCT_FETCH_BY_BRANCH_FAILURE'
  | 'NONE';

export interface FetchProductsByBranchSuccessAction
  extends Action<ProductActionType> {
  result: Product[];
}

export interface FetchProductsByBranchErrorAction
  extends Action<ProductActionType> {
  error: Error;
}

export const fetchProductsByBranchCode =
  (branchCode: string): ThunkAction<void, AppState, any, any> =>
  async (dispatch, getState: () => AppState) => {
    const tokens = getCredentials(getState());
    const sectionsLoading = getState().presentation.loading;
    const products = getState().data.product.products;

    if (
      products ||
      sectionsLoading.sectionsLoading.includes('PRODUCT_FETCH_BY_BRANCH')
    ) {
      return;
    }

    const promise = async (): Promise<Product[]> => {
      return await ProductService.getProductsByBranchCode(branchCode, tokens);
    };

    dispatch({
      types: [
        'PRODUCT_FETCH_BY_BRANCH',
        'PRODUCT_FETCH_BY_BRANCH_SUCCESS',
        'PRODUCT_FETCH_BY_BRANCH_FAILURE',
      ],
      promise,
      bypassGlobalError: true,
    });
  };
