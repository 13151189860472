import React, { useCallback, useEffect, useState } from 'react';
import {
  ConditionalRow,
  NglicMultipleSelect,
  Platforms,
  useNglScreenSize,
} from '@nglic/component-lib';
import { useDispatch, useSelector } from 'react-redux';
import styles from './MultipleSelectRow.module.scss';
import { BackgroundTitlesEnum } from '../Background.types';
import { selectRow } from '../background.action';
import { AppState } from '../../../../../rootReducer';
import { State } from 'service/service.types';

export const MultipleSelectRow: React.FC = () => {
  const [selectedStates, setSelectedStates] = useState<string[]>([]);
  const dispatch = useDispatch();
  const { platform } = useNglScreenSize();

  const backgroundDataTest = useSelector(
    (state: AppState) => state.presentation.contract.background.backgroundData,
  );
  const handleChangeState = useCallback(
    (value: string) => {
      let updatedStates: string[] = [];
      if (selectedStates.includes(value)) {
        updatedStates = selectedStates.reduce<string[]>((acc, next) => {
          if (next !== value) {
            acc.push(next);
          }
          return acc;
        }, []);
      } else {
        updatedStates = [value, ...selectedStates];
      }
      setSelectedStates(updatedStates);
      dispatch(
        selectRow(
          BackgroundTitlesEnum.COMPLAINT_FILED,
          'Yes',
          undefined,
          updatedStates,
        ),
      );
    },
    [selectedStates],
  );

  const states: State[] | undefined = useSelector(
    (state: AppState) => state.data.state.states,
  );

  useEffect(() => {
    if (backgroundDataTest && backgroundDataTest[0].stateNames) {
      setSelectedStates(backgroundDataTest[0].stateNames);
    } else if (states && !selectedStates) {
      const stateCodes = states?.reduce<any>((acc, next) => {
        return {
          ...acc,
          [next?.code]: false,
        };
      }, {});
      setSelectedStates(stateCodes);
    }
  }, [states]);

  const options = states?.reduce<any[]>((acc, next) => {
    return [
      ...acc,
      {
        id: next.code,
        name: next.desc,
        checked: selectedStates.includes(next.code),
        onChange: handleChangeState,
      },
    ];
  }, []);

  const handleOnClickConditionalRow = (
    titleName: string,
    conditionalRowValue: string,
  ) => {
    switch (titleName) {
      case BackgroundTitlesEnum.COMPLAINT_FILED:
        dispatch(
          selectRow(
            BackgroundTitlesEnum.COMPLAINT_FILED,
            conditionalRowValue,
            undefined,
            backgroundDataTest && backgroundDataTest[0].stateNames,
          ),
        );
        break;
    }
  };

  return (
    <div className={styles['container']}>
      <ConditionalRow
        onClick={(titleName: string, value: string) => {
          handleOnClickConditionalRow(titleName, value);
        }}
        value={backgroundDataTest[0].selected as 'Yes' | 'No'}
        title={BackgroundTitlesEnum.COMPLAINT_FILED}
        mobileLayout={platform === Platforms.PHONE}
      >
        <div className={styles['multiple-select']}>
          <NglicMultipleSelect
            options={options || []}
            selectedOption={selectedStates}
          />
        </div>
      </ConditionalRow>
    </div>
  );
};
