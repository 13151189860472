import React, { useEffect, useState } from 'react';

import styles from './CurrentBalance.module.scss';
import { AgentReserveFundBalance } from '../../../../../../service/service.types';
import {
  Table,
  TableCell,
  TableContainer,
  TableBody,
  TableRow,
} from '@material-ui/core';
import { AppState } from '../../../../../../rootReducer';
import { SectionName } from '../../../../../shared/loading.action';
import { useSelector } from 'react-redux';
import { NGlicSpinner } from '@nglic/component-lib';
import ChevronDown from '../../../../../../assets/img/chevron/ChevronDown.png';
import ChevronUp from '../../../../../../assets/img/chevron/ChevronUp.png';
import { ReserveAccount } from '../Reserves/ReserveAccount';
import { formatDate } from '../../../Policies/PolicyDetail/PolicyInformation/PolicyInformationTransform';

interface ReservesDataPercentageProps {
  reserveFundBalances: AgentReserveFundBalance[] | [];
}

export const CurrentBalance: React.FC<ReservesDataPercentageProps> = ({
  reserveFundBalances,
}) => {
  const [loading, setLoading] = useState<boolean>(true);

  const sectionsLoading: SectionName[] = useSelector(
    (state: AppState) => state.presentation.loading.sectionsLoading,
  );

  useEffect(() => {
    setLoading(sectionsLoading.includes('FETCH_RESERVE_FUND_BALANCE_REQUEST'));
  }, [sectionsLoading]);

  return (
    <div className={styles['root']}>
      <div className={styles['header']}>Current Balance</div>
      {loading ? (
        <div className={styles['loading-container']}>
          <NGlicSpinner />
        </div>
      ) : (
        <TableContainer>
          <Table>
            <TableBody>
              {reserveFundBalances?.map((reserveFundBalance) => {
                return (
                  <ProducerReservesTableRow
                    reserveFundBalance={reserveFundBalance}
                  />
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};

interface ReservesDataPercentageTableRowProps {
  reserveFundBalance: AgentReserveFundBalance;
}

const ProducerReservesTableRow: React.FC<ReservesDataPercentageTableRowProps> =
  ({ reserveFundBalance }) => {
    const [showReserves, setShowReserves] = useState(false);

    return (
      <>
        <TableRow>
          <TableCell
            className={styles['value']}
            style={{ borderBottom: showReserves ? 'none' : '' }}
          >
            ${reserveFundBalance?.fund_value}
            <div className={styles['date-value']}>
              {' '}
              as of{' '}
              {formatDate(reserveFundBalance.balance_date.toString(), true)}
            </div>
          </TableCell>
          <TableCell
            className={styles['producer-ids']}
            style={{ borderBottom: showReserves ? 'none' : '' }}
          >
            {reserveFundBalance?.producer_id}
          </TableCell>
          <TableCell
            style={{
              color: '#005F86',
              borderBottom: showReserves ? 'none' : '',
              cursor: 'pointer',
            }}
            onClick={() => {
              setShowReserves(!showReserves);
            }}
          >
            {<img src={showReserves ? ChevronUp : ChevronDown} />}
          </TableCell>
        </TableRow>
        {showReserves && (
          <TableRow>
            <TableCell colSpan={3}>
              <ReserveAccount reserveAccount={reserveFundBalance.reserves} />
            </TableCell>
          </TableRow>
        )}
      </>
    );
  };
