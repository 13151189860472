interface RetryablePromiseProps {
  retries?: number;
  delay?: number;
  functionToRetry: () => Promise<any>;
  catchFunction?: ((error: any) => Promise<any>) | null;
  lastError?: Error | null;
  shouldRetry?: (error: Error) => boolean;
}

const DEFAULT_RETRY = 1;
// const DEFAULT_DELAY = 500

// TODO: Implement retry backoff
// const backoff = (duration) => new Promise(res => setTimeout(res, duration))

export async function retryablePromise(
  props: RetryablePromiseProps,
): Promise<any> {
  const { functionToRetry, retries = DEFAULT_RETRY, lastError = null } = props;

  if (retries < 0) {
    // Throw the last caught error so it is not swallowed by this logic
    throw lastError;
  }

  try {
    return await functionToRetry();
  } catch (error) {
    return await handleError(props, error);
  }
}

const handleError = async (props: RetryablePromiseProps, error: Error) => {
  const { catchFunction = null, retries = DEFAULT_RETRY, shouldRetry } = props;

  if (catchFunction) {
    await catchFunction(error);
  }

  if (shouldRetry && !shouldRetry(error)) {
    throw error;
  }

  return await retryablePromise({
    ...props,
    retries: retries - 1,
    lastError: error,
  });
};
