import { Action } from 'redux';

export type ReservesAction = 'ADD_RESERVES' | 'CLEAR_RESERVES';

export interface AddReservesAction extends Action<ReservesAction> {
  type: 'ADD_RESERVES';
  singlePay: string;
  creditCard: string;
  directBill: string;
  renewal: string;
  eft: string;
  minAmount: string;
  maxAmount: string;
}

export interface ClearReservesAction extends Action<ReservesAction> {
  type: 'CLEAR_RESERVES';
}

export const addReserves = (
  singlePay: string,
  creditCard: string,
  directBill: string,
  renewal: string,
  eft: string,
  minAmount: string,
  maxAmount: string,
): AddReservesAction => ({
  type: 'ADD_RESERVES',
  singlePay,
  creditCard,
  directBill,
  renewal,
  eft,
  minAmount,
  maxAmount,
});

export const clearReserves = (): ClearReservesAction => ({
  type: 'CLEAR_RESERVES',
});
