import { PaymentFrequencyEnum } from '../contract.types';

export interface PaymentData {
  bankName: string;
  accountType: string;
  routingNumber: string;
  accountNumber: string;
  confirmAccountNumber: string;
  paymentFrequency: string;
  paymentType: string;
}

export interface ErrorMessages {
  RoutingNumber: ErrorMessage;
  AccountNumberConfirmation: ErrorMessage;
}

export interface ErrorMessage {
  error: boolean;
  message: string;
}

export const InitErrorMessages = {
  RoutingNumber: {
    error: false,
    message: '',
  },
  AccountNumberConfirmation: {
    error: false,
    message: '',
  },
};

export const PaymentEmptyObject: PaymentData = {
  bankName: '',
  accountType: '',
  routingNumber: '',
  accountNumber: '',
  confirmAccountNumber: '',
  paymentFrequency: PaymentFrequencyEnum.BI_WEEKLY,
  paymentType: 'CHECK',
};

export const PaymentPayByCheck: PaymentData = {
  ...PaymentEmptyObject,
};
