import Rollbar from 'rollbar';
import { ENV } from '../env';

const NODE_ENV = process.env.NODE_ENV;

export const setupLogger = (environment: string = NODE_ENV): void => {
  if (environment === 'production') {
    const rollbar = new Rollbar({
      accessToken: ENV.ROLLBAR_TOKEN,
      captureUncaught: true,
      captureUnhandledRejections: true,
      payload: {
        environment: 'production',
      },
    });

    console.log = (text: string) => {
      rollbar.log(text);
    };

    console.info = (text: string) => {
      rollbar.info(text);
    };

    console.warn = (text: string) => {
      rollbar.warn(text);
    };

    console.error = (text: string) => {
      rollbar.error(text);
    };
  }
};
