import { useNglScreenSize } from '@nglic/component-lib';
import React from 'react';
import { PolicyPayment } from 'service/service.types';
import {
  createFullName,
  substringDate,
} from '../../PolicyInformation/PolicyInformationTransform';
import styles from './PaymentHistoryTableContent.module.scss';
import { formatDate } from '../../PolicyInformation/PolicyInformationTransform';
import Vector from '../../../../../../../assets/img/rightIcon/Vector.png';

interface PaymentHistoryTableContentProps {
  policyPayment?: PolicyPayment;
}

export const PaymentHistoryTableContent: React.FC<PaymentHistoryTableContentProps> =
  ({ policyPayment }) => {
    const { isMobile } = useNglScreenSize();

    return (
      <div className={styles['root']}>
        {!isMobile && (
          <div className={styles['value-payor']}>
            {createFullName(
              policyPayment?.payor?.firstName,
              policyPayment?.payor?.lastName,
            )}
          </div>
        )}
        <div className={styles['value-date']}>
          {isMobile
            ? formatDate(policyPayment?.paymentDate, true)
            : substringDate(policyPayment?.paymentDate)}
        </div>
        {!isMobile && (
          <div className={styles['value-type']}>
            {policyPayment?.paymentMethod}
          </div>
        )}
        <div className={styles['value-amount']}>
          ${policyPayment?.paymentAmount}
        </div>
        {isMobile && (
          <div className={styles['icon']}>
            <img src={Vector} />
          </div>
        )}
      </div>
    );
  };
