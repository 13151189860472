import { Action } from 'redux';

// BASE TYPE DEFS FOR ACTIONS
export type CredentialsActionType =
  | 'SET_CREDENTIALS'
  | 'NONE'
  | 'REMOVE_CREDENTIALS';

export interface SetCredentialsAction extends Action<CredentialsActionType> {
  accessToken: string;
  idToken: string;
  refreshToken: string;
}

export const setCredentials = (
  accessToken: string,
  idToken: string,
  refreshToken: string,
): SetCredentialsAction => ({
  type: 'SET_CREDENTIALS',
  accessToken,
  idToken,
  refreshToken,
});

export const removeCredentials = (): Action<CredentialsActionType> => ({
  type: 'REMOVE_CREDENTIALS',
});
